import publicationIcon from "../../assets/img/publication-icon.png";
import viewIcon from "../../assets/img/viewIcon.svg";
import viewIconOrange from "../../assets/img/viewIconorange.svg";
import { useState } from "react";
import SweetPagination from "sweetpagination";

export type SearchPublicationListProps = {
  title: string;
  date: string;
  size: string;
  link: string;
};

function PublicationListMaker({
  title,
  date,
  size,
  link,
}: SearchPublicationListProps) {
  function formatBytes(bytes: string, decimals: number = 2): string {
    const bytesNum: number = Number(bytes);
    if (bytesNum === 0) return "0Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytesNum) / Math.log(k));

    return parseFloat((bytesNum / Math.pow(k, i)).toFixed(dm)) + sizes[i];
  }

  function formatDate(inputDate: string) {
    return new Date(inputDate).toLocaleDateString("es-ES", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  }

  return (
    <li>
      <div className="flex items-center flex-1 info">
        <img src={publicationIcon} alt="pdf-icon" />
        <div className="flex-1">
          <p>{title}</p>
          <h2 className="hidden lg:block">{`Modificado: ${formatDate(
            date
          )} - Tamaño: ${formatBytes(size, 1)}`}</h2>
        </div>
      </div>
      <a
        href={link}
        target="_blank"
        rel="noreferrer"
        className="items-center lg:flex"
      >
        <img
          src={viewIconOrange}
          className="download-img lg:hidden"
          alt="download Icon"
        />
        <img src={viewIcon} className="hidden lg:block" alt="download Icon" />
        <span className="hidden download-img lg:block">Visualizar</span>
      </a>
    </li>
  );
}

const SearchPublicationList = ({ currDocSelection }: any) => {
  const [currentPageData, setCurrentPageData] = useState(new Array(2));
  return (
    <>
      <ul className="mt-6 publication-results">
        {currentPageData.map((itemlist, i) => (
          <PublicationListMaker
            key={i}
            size={itemlist.size}
            title={itemlist.title}
            date={itemlist.date}
            link={itemlist.link}
          />
        ))}
      </ul>
      <SweetPagination
        onClick={window.scrollTo(0, 0)}
        currentPageData={setCurrentPageData}
        getData={currDocSelection}
        dataPerPage={5}
        navigation={true}
        getStyle={"pagination-costum"}
      />
    </>
  );
};

export default SearchPublicationList;
