import { useEffect, useState } from "react";
import whiteArrow from "../../assets/img/icons/chevron-right.svg";
const GeneralItemsWithOptionsTable = ({ tableList, handleClick }: any) => {
  const [colSpan, setColSpan] = useState(0);
  useEffect(() => {
    if (screen.width < 768) {
      setColSpan(2);
    } else {
      setColSpan(0);
    }
  }, [screen.width]);
  return (
    <tbody className="table-body mb-10">
      {tableList.map((list: any, i: number) =>
        list.serviceType ? (
          <tr
            key={i}
            className="titles-container"
            onClick={() => {
              handleClick(list.serviceName, list.serviceParent);
            }}
          >
            <th>{list.serviceName}</th>
            <th className="mr-5 md:hidden">
              <img
                src={whiteArrow}
                alt="white arrow icon"
                className="white-arrow"
              />
            </th>
            <th className="hidden md:inline-block general-withoption-list-width">
              {list.serviceValue}
            </th>
            <th className="hidden md:inline-block general-withoption-list-width">
              {list.serviceValue2}
            </th>
          </tr>
        ) : (
          <tr key={i} className="table-list">
            <td colSpan={colSpan}>
              <p> {list.serviceName}</p>
              <p className="md:hidden">
                <b>Opcion 1: </b>
                {list.serviceValue}
              </p>
              <p className="md:hidden">
                <b>Opcion 2: </b>
                {list.serviceValue2}
              </p>
            </td>
            <td className="hidden md:inline-block general-withoption-list-width">
              {list.serviceValue}
            </td>
            <td className="hidden md:inline-block general-withoption-list-width">
              {list.serviceValue2}
            </td>
          </tr>
        )
      )}
    </tbody>
  );
};

export default GeneralItemsWithOptionsTable;
