import React, { useEffect, useState } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import axios from "axios";
import { BASE_URL, GET_HEADERS } from "../Helper/requestData";

const SectionComponentThree = () => {
  const [active, setActive] = useState(0);
  const [slideData, setSlideData] = useState([
    {
      title: "",
      iconActive: "",
      iconInactive: "",
      image: "",
      enlace: "",
    },
  ]);
  const mainSlider = React.createRef<Splide>();
  const thumbSlider = React.createRef<Splide>();

  const getData = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Home-S3-Facilidades-Pago-Slider')/items",

        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    setSlideData(
      resultsArr.map((item: any) => {
        return {
          title: item.Title,
          iconActive: item.IconActive.Url,
          iconInactive: item.IconInactive.Url,
          image: item.Image.Url,
          enlace: item.Enlace.Url,
        };
      })
    );
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (
      mainSlider &&
      mainSlider.current &&
      thumbSlider &&
      thumbSlider.current &&
      thumbSlider.current.splide
    )
      mainSlider.current.sync(thumbSlider.current.splide);
  }, []);

  return (
    <div className="pb-0 section seguros banners">
      <Splide
        className="splide top"
        ref={thumbSlider}
        options={{
          drag: false,
          fixedWidth: 320,
          pagination: false,
          isNavigation: true,
          breakpoints: {
            375: {
              fixedWidth: 120,
            },
            425: {
              fixedWidth: 140,
            },
            767: {
              fixedWidth: 165,
            },
            1024: {
              fixedWidth: 250,
            },
          },
        }}
      >
        {(() => {
          return slideData.map((slide: any, i: number) => {
            return (
              <SplideSlide
                key={i}
                onClick={() => {
                  setActive(i);
                }}
              >
                {active !== i ? (
                  <img src={slide.iconInactive} alt={slide.title} />
                ) : (
                  <img src={slide.iconActive} alt={slide.title} />
                )}
                <span>{slide.title}</span>
                {active !== i ? (
                  <div className="splide-border-disable"></div>
                ) : (
                  <div className="splide-border"></div>
                )}
              </SplideSlide>
            );
          });
        })()}
      </Splide>

      <Splide
        options={{
          drag: false,
          pagination: false,
        }}
        className="splide bottom-slide bottom-slide--full"
        ref={mainSlider}
      >
        {(() => {
          return slideData.map((slide: any, i: number) => {
            return (
              <SplideSlide key={i}>
                <a href={slide.enlace}>
                  <img
                    className="block mobile-tuning xl:hidden"
                    src={slide.image}
                    alt={slide.title}
                  />
                  <img
                    className="hidden xl:block w-full"
                    src={slide.image}
                    alt="descriptive image"
                  />
                </a>
              </SplideSlide>
            );
          });
        })()}
      </Splide>
    </div>
  );
};

export default SectionComponentThree;
