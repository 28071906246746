import "./OfficesMapsList.css";
import { useEffect, useState } from "react";

import LocComponent from "./LocComponent";

import MoreInformationCard from "../VariousCardsComponents/moreInfoCard/MoreInformationCard";
import MapComponent from "./MapComponent";
import axios from "axios";
import { BASE_URL, GET_HEADERS } from "../Helper/requestData";

type OfficesDataProps = {
  id: number;
  content: string;
  location: string;
};
type LocationDataProps = {
  id: number;
  locTitle: string;
  officeList: OfficesDataProps[];
};

type centersDataProps = {
  center: { lat: string; lng: string };
};
const OfficesMapsList = () => {
  const [locActive, setLocActive] = useState({
    activeId: 1,
  });

  const [officeActive, setOfficeActive] = useState({
    activeId: 1,
  });

  const [centersData, setCentersData] = useState<centersDataProps[]>([]);

  const [locData, setLocData] = useState<LocationDataProps[]>([]);
  const getOfficesData = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Oficinas-y-Sucursales-Detalles')/items?$select=*,Region/Title&$expand=Region",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    resultsArr.sort(
      (a: { Orden: number }, b: { Orden: number }) => a.Orden - b.Orden
    );

    const officesData: OfficesDataProps[] = resultsArr.map((item: any) => {
      return {
        id: item?.Orden,
        content: item?.Content,
        location: item?.Region.Title,
      };
    });
    setCentersData(
      resultsArr.map((item: any) => {
        return {
          center: { lat: item?.Latitud, lng: item?.Longitud },
        };
      })
    );

    return officesData;
  };

  const getLocData = async () => {
    const officesDatos = await getOfficesData();
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Oficinas-y-Sucursales-Regiones')/items",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    resultsArr.sort(
      (a: { Orden: number }, b: { Orden: number }) => a.Orden - b.Orden
    );

    setLocData(
      resultsArr.map((item: any) => {
        return {
          id: item?.Orden,
          locTitle: item?.Title,
          officeList: officesDatos.filter(
            (office) => office.location === item?.Title
          ),
        };
      })
    );
  };

  useEffect(() => {
    getLocData();
  }, []);

  const renderLocations = () => {
    return locData.map((location) => (
      <LocComponent
        key={location.id}
        officeList={location.officeList}
        id={location.id}
        locTitle={location.locTitle}
        locActive={locActive}
        setLocActive={setLocActive}
        officeActive={officeActive}
        setOfficeActive={setOfficeActive}
      />
    ));
  };

  return (
    <div className="lg:flex lg:justify-between lg:space-x-3">
      <div className="my-6 mb-3 maps-list lg:w-4/12">
        {renderLocations()}
        {screen.width < 1024 && (
          <div className="map-list__map-box lg:hidden">
            <MapComponent
              data={centersData}
              center={centersData[officeActive?.activeId - 1]?.center}
            />
          </div>
        )}
      </div>
      {screen.width >= 1024 && (
        <div className="hidden map-desktop lg:block lg:w-8/12">
          <div className="map-list__map-box">
            <MapComponent
              data={centersData}
              center={centersData[officeActive?.activeId - 1]?.center}
            />
          </div>
          <div className="lg:mt-10">
            <MoreInformationCard />
          </div>
        </div>
      )}
    </div>
  );
};

export default OfficesMapsList;
