import ContactCard from "./ContactCard";

type props = {
  sideCardList: {
    order: number;
    icon: string;
    title: string;
    type: string;
    link: string;
    linkText: string;
    message: string;
  }[];
};

const ContactCardsContainer = ({ sideCardList }: props) => {
  function sideCardRender() {
    const orderedList = [...sideCardList].sort();
    return orderedList.map((card) => (
      <ContactCard
        key={card.order}
        icon={card.icon}
        title={card.title}
        type={card.type}
        link={card.link}
        linkText={card.linkText}
        message={card.message}
      />
    ));
  }
  return (
    <div className="lg:w-4/12 lg:order-last">
      <div className="mt-16 bubbles-block">{sideCardRender()}</div>
    </div>
  );
};

export default ContactCardsContainer;
