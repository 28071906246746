import { BASE_URL, GET_HEADERS } from "../../components/Helper/requestData";
import { useEffect, useState } from "react";

import CardsContainer from "../../components/MenuLandingsLayout/CardsContainer";
import HeroMenuComponent from "../../components/MenuLandingsLayout/HeroMenuComponent";
import ProductsContainer from "../../components/MenuLandingsLayout/ProductsContainer";
import SectionInformation from "../../components/MenuLandingsLayout/SectionInformation";
import axios from "axios";

const Vehicles = () => {
  const [showCards, setShowCards] = useState(false);
  const [vehicleData, setVehicleData] = useState({
    title: "",
    bannerImage: "",
    content: "",
    contentImage: "",
  });
  const [cardData, setCardData] = useState([
    {
      cardId: 0,
      cardImg: "",
      cardTitle: "",
      cardImgHover: "",
      content: "",
      btnLinkCotizar: "",
      btnImgCotizar: "",
      btnTitleCotizar: "",
      btnColorCotizar: "",
      btnLinkBrochure: "",
      btnImgBrochure: "",
      btnTitleBrochure: "",
      btnColorBrochure: "",
      btnLinkPreguntas: "",
      btnImgPreguntas: "",
      btnTitlePreguntas: "",
      btnColorPreguntas: "",
      btnLinkVideo: "",
      btnImgVideo: "",
      btnTitleVideo: "",
      btnColorVideo: "",
      cardListItems: "",
      pageLink: "",
    },
  ]);

  const getVehicleData = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Contenido-de-Secciones')/items?$filter=Title eq 'Seguros de Vehículos'",
        GET_HEADERS
      )
      .then((response) => response.data.value[0])
      .catch((e) => console.error(e));

    setVehicleData({
      title: resultsArr?.TitleRich,
      bannerImage: resultsArr?.BannerImage.Url,
      content: resultsArr?.Content,
      contentImage: resultsArr?.ContentImage.Url,
    });
  };

  const getCardData = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Seguros-Vehiculos-Productos')/items",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    setCardData(
      resultsArr
        .map((item: any) => {
          return {
            cardId: item?.f2if,
            cardImg: item?.CardImg?.Url,
            cardTitle: item?.OData__x0063_qp0,
            cardImgHover: item?.CardImgHover?.Url,
            content: item?.Content,

            btnLinkCotizar: item?.BtnLinkCotizar?.Url,
            btnImgCotizar: item?.BtnImgCotizar?.Url,
            btnTitleCotizar: item?.OData__x0079_ul0,
            btnColorCotizar: item?.BtnColorCotizar,

            btnLinkBrochure: item?.BtnLinkBrochure?.Url,
            btnImgBrochure: item?.BtnImgBrochure?.Url,
            btnTitleBrochure: item?.anwr,
            btnColorBrochure: item?.BtnColorBrochure,

            btnLinkPreguntas: item?.BtnLinkPreguntas?.Url,
            btnImgPreguntas: item?.BtnImgPreguntas?.Url,
            btnTitlePreguntas: item?.BtnTitlePreguntas,
            btnColorPreguntas: item?.BtnColorPreguntas,

            btnLinkVideo: item?.BtnLinkVideo?.Url,
            btnImgVideo: item?.BtnImgVideo?.Url,
            btnTitleVideo: item?.BtnTitleVideo,
            btnColorVideo: item?.BtnColorVideo,

            cardListItems: item?.CardListItems,

            pageLink: item?.PageLink?.Description,
          };
        })
        .sort(
          (a: { cardId: number }, b: { cardId: number }) => a.cardId - b.cardId
        )
    );
  };

  useEffect(() => {
    getVehicleData();
    getCardData();
  }, []);

  return (
    <>
      <HeroMenuComponent
        title={vehicleData?.title}
        bannerImage={vehicleData?.bannerImage}
      />
      <SectionInformation
        content={vehicleData?.content}
        contentImage={vehicleData?.contentImage}
      />
      <ProductsContainer showCards={showCards} setShowCards={setShowCards}>
        <CardsContainer showCards={showCards} cards={cardData} />
      </ProductsContainer>
    </>
  );
};

export default Vehicles;
