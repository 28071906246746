import "./css/btnCards.css";

type TextButtonCardComponentProps = {
  textButtonCardLink: string;
  textButtonCardIcon?: string;
  textButtonCardtTitle?: string;
  textButtonCardtText?: string;
};

const TextButtonCardComponent = ({
  textButtonCardLink,
  textButtonCardIcon,
  textButtonCardtText = "",
  textButtonCardtTitle = "",
}: TextButtonCardComponentProps) => {
  return (
    <>
      <h3 className="mb-5 title-text-button-card">{textButtonCardtText}</h3>
      <a className="flex text-button-card btn-card" href={textButtonCardLink}>
        <img src={textButtonCardIcon} className="w-8 ml-4 icon-img" />
        <span className="btn-title">{textButtonCardtTitle}</span>
      </a>
    </>
  );
};

export default TextButtonCardComponent;
