import { useContext, useEffect, useState } from "react";

import ContactsComponent from "../contacts/ContactsComponent";
import HeaderFooterDataDto from "../../dtos/HeaderFooterDataDto";
import { Link } from "react-router-dom";
import { NavigationContext } from "../../routes/menulinks";
import SocialNetworkComponent from "../socialNetworks/SocialNetworkComponent";
import { addresModalType } from "./type";
import { getHeaderFooterDataService } from "../../services/HeaderFooterDataService";
import xImg from "../../assets/img/icons/xImg.svg";

const AddressModal = ({
  showAddressModal,
  setShowAddressModal,
}: addresModalType) => {
  const [headerFooterData, setHeaderFooterData] = useState<HeaderFooterDataDto>(
    new HeaderFooterDataDto()
  );

  const { menuLinks } = useContext(NavigationContext);

  async function getHeaderFooterData() {
    setHeaderFooterData(await getHeaderFooterDataService());
  }

  useEffect(() => {
    getHeaderFooterData();
  }, []);
  const handleClick = () => {
    setShowAddressModal(!showAddressModal);
  };
  return (
    <div
      id="adressModal"
      className={`transition ease-out transform ${
        showAddressModal ? "" : " -translate-y-full"
      }  adressmodal`}
    >
      <h3 className="no-margin">
        Teléfonos
        <div id="closeAdress " onClick={handleClick}>
          <img src={xImg} alt="x icon" className="w-4 " />
        </div>
      </h3>

      <ContactsComponent
        sucursales={false}
        headerFooterData={headerFooterData}
      />
      <h3>Oficina Principal</h3>
      <div className="footer__contact">
        <img src={headerFooterData?.locationIcon} alt="location icon" />
        <span>Av. Enrique Jiménez Moya, Santo Domingo. Distrito Nacional</span>
      </div>
      <Link to={menuLinks.offices} className="list mb-10">
        Ver listado de sucursales
      </Link>
      <h3>Siguenos en:</h3>
      <SocialNetworkComponent />
    </div>
  );
};

export default AddressModal;
