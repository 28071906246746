import { useContext, useEffect, useState } from "react";
import HeroMenuComponent from "../../components/MenuLandingsLayout/HeroMenuComponent";
import "./css/News.css";
import { NewsStructure } from "../../components/NewsComponents/news.model";
import NewsCardComponent from "../../components/NewsComponents/NewsCardComponent";
import SweetPagination from "sweetpagination";
import {
  SortNewsEnum,
  SelectedViewEnum,
} from "../../components/NewsComponents/news.enum";
import FilterViewContainer from "../../components/NewsComponents/FilterViewContainer";
import HeaderNewsCompoment from "../../components/NewsComponents/HeaderNewsCompoment";
import axios from "axios";
import { BASE_URL, GET_HEADERS } from "../../components/Helper/requestData";
import { NewsContext } from "../../context/News/NewsContext";

const News = () => {
  const [filterSort, setFilterSort] = useState<SortNewsEnum | string>("");
  const { newsList } = useContext(NewsContext);
  const [sortedNews, setSortedNews] = useState<NewsStructure[]>(newsList);
  const [currentPageData, setCurrentPageData] =
    useState<NewsStructure[]>(sortedNews);
  const [selectedView, setSelectedView] = useState<SelectedViewEnum | string>(
    ""
  );

  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  const [heroSideData, setHeroSideData] = useState({
    title: "",
    titleRich: "",
    bannerImage: "",
    secondaryTitle: "",
    sideTitle1: "",
    sideContent1: "",
    sideTitle2: "",
    content: "",
    contentImage: "",
    sideContent2: "",
    middleTitle: "",
    middleContent: "",
  });

  const getSectionContentData = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Contenido-de-Secciones')/items?$filter=Title eq 'Noticias y Novedades'",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    setHeroSideData({
      titleRich: resultsArr[0]?.TitleRich,
      title: resultsArr[0]?.Title,
      bannerImage: resultsArr[0]?.BannerImage?.Url,
      content: resultsArr[0]?.Content,
      secondaryTitle: resultsArr[0]?.SecondaryTitle,
      sideTitle1: resultsArr[0]?.SideTitle1,
      sideTitle2: resultsArr[0]?.SideTitle2,
      sideContent1: resultsArr[0]?.SideContent1,
      contentImage: resultsArr[0]?.ContentImage?.Url,
      sideContent2: resultsArr[0]?.SideContent2,
      middleTitle: resultsArr[0]?.MiddleTitle,
      middleContent: resultsArr[0]?.MiddleContent,
    });
  };

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  const handleViewChange = () => {
    if (selectedView === SelectedViewEnum.groupView) {
      setSelectedView(SelectedViewEnum.listView);
      window.scrollTo(0, 0);
    }
  };
  function mapNewsComponent(newsData) {
    return newsData.map((news: NewsStructure) => (
      <NewsCardComponent
        news={news}
        key={news?.id}
        selectedView={selectedView}
      />
    ));
  }
  let paginationButtons = document.querySelectorAll("button.pageButton");
  function renderNewsComponent() {
    if (selectedView === SelectedViewEnum.groupView) {
      return (
        <div className="news-container lg:grid lg:grid-cols-2 lg:gap-x-9 xl:grid-cols-4 xl:gap-x-5 mt-8 mx-2">
          {sortedNews &&
            mapNewsComponent(
              sortedNews.filter((n) => n.topNews === false)
            ).slice(0, 8)}
        </div>
      );
    }
    return (
      <div className="news-container">
        {currentPageData && mapNewsComponent(currentPageData)}
      </div>
    );
  }
  useEffect(() => {
    window.addEventListener("resize", setDimension);
    if (screenSize.dynamicWidth >= 1024) {
      setSelectedView(SelectedViewEnum.groupView);
    } else {
      setSelectedView(SelectedViewEnum.listView);
    }
    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  useEffect(() => {
    if (filterSort === SortNewsEnum.oldestRecent) {
      const orderedAToB = [...newsList].sort((a, b) =>
        a.date.localeCompare(b.date)
      );
      setSortedNews(orderedAToB);
    } else if (filterSort === SortNewsEnum.recentOldest) {
      const orderedBtoA = [...newsList].sort((a, b) =>
        b.date.localeCompare(a.date)
      );
      setSortedNews(orderedBtoA);
    } else {
      setSortedNews(newsList);
    }
  }, [filterSort, newsList]);

  useEffect(() => {
    getSectionContentData();
  }, []);

  useEffect(() => {
    paginationButtons = document.querySelectorAll("button.pageButton");

    paginationButtons &&
      paginationButtons.forEach((x: any) => (x.type = "button"));
  }, [currentPageData]);

  return (
    <>
      <HeroMenuComponent
        bannerImage={heroSideData.bannerImage}
        title={heroSideData.titleRich}
      />
      <div className="container mx-auto  ">
        <FilterViewContainer
          setFilterSort={setFilterSort}
          selectedView={selectedView}
          setSelectedView={setSelectedView}
          viewTitle={heroSideData.sideTitle1}
          filterPlaceholder={heroSideData.middleTitle}
        />
        <div className="mt-10">
          {selectedView === SelectedViewEnum.groupView && (
            <HeaderNewsCompoment
              news={
                sortedNews && sortedNews.filter((n) => n.topNews === true)[0]
              }
            />
          )}
        </div>
        {renderNewsComponent()}

        {selectedView === SelectedViewEnum.groupView ? (
          <div className="flex justify-center my-20">
            <div
              onClick={handleViewChange}
              className="text-center more-news-btn cursor-pointer"
            >
              {heroSideData.sideTitle2}
            </div>
          </div>
        ) : (
          <div className="my-12 pagination-container">
            {sortedNews && (
              <SweetPagination
                onClick={window.scrollTo(0, 0)}
                currentPageData={setCurrentPageData}
                getData={sortedNews}
                dataPerPage={6}
                navigation={true}
                getStyle={"pagination-costum"}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default News;
