import React, { useState } from "react";
import AccordionLayout from "./AccordionLayout";
const Acordeon = ({ plans, Active }: any) => {
  const [activeIndex, setActiveIndex] = useState(1);
  function renderService1() {
    if (plans[Active]?.planServiceOne[0]?.serviceType === "options") {
      return (
        <tbody className="table-body">
          {plans[Active]?.planServiceOne?.map((p: any, i: number) =>
            p.serviceType ? null : (
              <tr className="table-list-show" key={i}>
                <td className="general-list-width">
                  <p> {p?.serviceName}</p>
                  <p>
                    <b>Opcion 1: </b>
                    {p?.serviceValue}
                  </p>
                  <p>
                    <b>Opcion 2: </b>
                    {p?.serviceValue2}
                  </p>
                </td>
              </tr>
            )
          )}
        </tbody>
      );
    }
    return (
      <tbody className="table-body">
        {plans[Active]?.planServiceOne?.map((p: any, i: number) =>
          p.serviceType ? null : (
            <tr className="table-list-show" key={i}>
              <td className="general-list-width">
                <p> {p?.serviceName}</p>
                <p>
                  <b>Límite: </b>
                  {p?.serviceValue}
                </p>
              </td>
            </tr>
          )
        )}
      </tbody>
    );
  }

  function renderService2() {
    if (
      plans[Active]?.planServiceTwo &&
      plans[Active]?.planServiceTwo[0]?.serviceType !== "other"
    ) {
      return (
        <AccordionLayout
          title={plans[Active]?.planServiceTwo[0]?.serviceName}
          index={2}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
        >
          <table>
            {plans[Active]?.planServiceTwo[0]?.serviceType === "options" ? (
              <tbody className="table-body">
                {plans[Active]?.planServiceTwo?.map((p: any, i: number) =>
                  p.serviceType ? null : (
                    <tr className="table-list-show" key={i}>
                      <td className="general-list-width">
                        <p> {p?.serviceName}</p>
                        <p>
                          <b>Opcion 1: </b>
                          {p?.serviceValue}
                        </p>
                        <p>
                          <b>Opcion 2: </b>
                          {p?.serviceValue2}
                        </p>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            ) : (
              <tbody className="table-body">
                {plans[Active]?.planServiceTwo?.map((p: any, i: number) =>
                  p?.serviceType ? null : (
                    <tr className="table-list-show" key={i}>
                      <td className="general-list-width">
                        <p> {p?.serviceName}</p>
                        <p>
                          {p?.serviceValue && (
                            <>
                              <b>Límite: </b>

                              {p?.serviceValue}
                            </>
                          )}
                        </p>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            )}
          </table>
        </AccordionLayout>
      );
    }
    return null;
  }
  return (
    <div className="flex flex-col">
      <AccordionLayout
        title={plans[Active]?.planServiceOne[0]?.serviceName}
        index={1}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
      >
        <table>{renderService1()}</table>
      </AccordionLayout>
      {renderService2()}
      {plans[Active]?.planAdditionalService ? (
        <AccordionLayout
          title={plans[Active]?.planAdditionalService[0]?.serviceName}
          index={3}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
        >
          <table>
            <tbody className="table-body">
              {plans[Active]?.planAdditionalService?.map((p: any, i: number) =>
                p.serviceType ? null : (
                  <tr className="table-list-show" key={i}>
                    <td className="general-list-width">
                      <p> {p?.serviceName}</p>
                      <p>
                        {p?.serviceValue && (
                          <>
                            <b>{p?.additionalLabel}: </b>

                            {p?.serviceValue}
                          </>
                        )}
                      </p>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </AccordionLayout>
      ) : null}
    </div>
  );
};

export default Acordeon;
