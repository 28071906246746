export const BASE_URL = "/";

export const GET_HEADERS = {
  headers: {
    "X-RequestDigest": (
      document.getElementById("__REQUESTDIGEST") as HTMLInputElement
    ).value,
    Accept: "application/json; odata=nometadata",
    "Content-Type": "application/json; odata=nometadata",
  },
};
