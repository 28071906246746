import SearchPublicationComponentFilter from "./SearchPublicationComponentFilter";
import SearchPublicationList, {
  SearchPublicationListProps,
} from "./SearchPublicationList";
import React from "react";

export type SearchPublicationComponentProps = {
  changeCurrOrder: (value: any) => void;
  changeCurrDocType: (value: any) => void;
  changeCurrDate: (value: any) => void;
  currDocSelection: SearchPublicationListProps[];
  setSearchInput: React.Dispatch<React.SetStateAction<string>>;
};

const SearchPublicationComponent = ({
  changeCurrOrder,
  changeCurrDocType,
  changeCurrDate,
  currDocSelection,
  setSearchInput,
}: SearchPublicationComponentProps) => {
  return (
    <div>
      <SearchPublicationComponentFilter
        changeCurrOrder={changeCurrOrder}
        changeCurrDocType={changeCurrDocType}
        changeCurrDate={changeCurrDate}
        setSearchInput={setSearchInput}
      />
      <SearchPublicationList currDocSelection={currDocSelection} />
    </div>
  );
};

export default SearchPublicationComponent;
