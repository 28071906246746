import { BASE_URL, GET_HEADERS } from "../../components/Helper/requestData";
import { useContext, useEffect, useState } from "react";

import { AboutUsMenuContext } from "../../context/AboutUs/AboutUsMenuContext";
import HeroMenuComponent from "../../components/MenuLandingsLayout/HeroMenuComponent";
import InformationComponent from "../../components/InformationComponent/InformationComponent";
import MembershipCards from "../../components/MenuLandingsLayout/MembershipCards";
import ProductsContainer from "../../components/MenuLandingsLayout/ProductsContainer";
import ReinsuranceCards from "../../components/MenuLandingsLayout/ReinsuranceCards";
import SecondaryNavBar from "../../components/secondaryNavBar/SecondaryNavBar";
import axios from "axios";

function Reinsurance() {
  const [showCards, setShowCards] = useState(false);
  const { menuListData } = useContext(AboutUsMenuContext);
  const [heroSideData, setHeroSideData] = useState({
    title: "",
    titleRich: "",
    secondaryTitle: "",
    bannerImage: "",
    content: "",
    middleTitle: "",
    middleContent: "",
  });
  const [valuesCardsInfo, setValuesCardsInfo] = useState([
    {
      cardId: 1,
      cardImg: "",
      cardTitle: "",
      type: "",
    },
  ]);

  const getSectionContentData = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Contenido-de-Secciones')/items?$filter=Title eq 'Reaseguradores'",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    setHeroSideData({
      titleRich: resultsArr[0]?.TitleRich,
      title: resultsArr[0]?.Title,
      secondaryTitle: resultsArr[0]?.SecondaryTitle,
      bannerImage: resultsArr[0]?.BannerImage?.Url,
      content: resultsArr[0]?.Content,
      middleTitle: resultsArr[0]?.MiddleTitle,
      middleContent: resultsArr[0]?.MiddleContent,
    });
  };
  const getValuesCardsInfo = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Reaseguradores-Membresias')/items",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    const results = resultsArr.sort((a, b) => a.Order - b.Order);

    setValuesCardsInfo(
      results.map((item: any) => {
        return {
          cardId: item?.Order,
          cardImg: item?.CardImg?.Url,
          cardTitle: "",
          type: item?.EntityType,
        };
      })
    );
  };
  useEffect(() => {
    getSectionContentData();
    getValuesCardsInfo();
  }, []);
  return (
    <>
      <HeroMenuComponent
        bannerImage={heroSideData.bannerImage}
        title={heroSideData.titleRich}
      />
      <SecondaryNavBar
        title={heroSideData.secondaryTitle || heroSideData.titleRich}
        menuList={menuListData}
        subMenuList={[]}
      />
      <div className="container mx-auto lg:flex lg:justify-between md:mb-16 ">
        <div className="lg:w-3/5">
          <InformationComponent content={heroSideData.content} />
        </div>
      </div>
      <div>
        <div className="values-slide-fix">
          <ProductsContainer
            title={""}
            showCards={showCards}
            setShowCards={setShowCards}
          >
            <ReinsuranceCards
              showCards={showCards}
              cards={valuesCardsInfo.filter(
                (value) => value.type === "reasegurador"
              )}
            />
          </ProductsContainer>
        </div>
      </div>
      <div
        className="container mx-auto lg:flex lg:justify-between md:mb-16"
        style={{ marginBottom: "0px" }}
      >
        <div className="w-full">
          <h5
            style={{
              color: "#00AEF0",
              textAlign: "left",
              fontFamily: "FreightSansProBold,serif",
            }}
            className="mt-3 lg:mt-0 text-3xl xl:text-4xl"
          >
            {heroSideData.middleTitle}
          </h5>
          <hr
            className="mt-4"
            style={{
              opacity: "0.19",
              border: "2px solid #70707080",
              width: "100%",
            }}
          />
        </div>
      </div>
      <div
        className="container mx-auto lg:flex lg:justify-between md:mb-16"
        style={{ marginBottom: "0px" }}
      >
        <div className="lg:w-3/5">
          <InformationComponent content={heroSideData.middleContent} />
        </div>
      </div>
      <div>
        <div className="values-slide-fix">
          <ProductsContainer
            title={""}
            showCards={showCards}
            setShowCards={setShowCards}
          >
            <MembershipCards
              showCards={showCards}
              cards={valuesCardsInfo.filter(
                (value) => value.type === "membresia"
              )}
            />
          </ProductsContainer>
        </div>
      </div>
    </>
  );
}

export default Reinsurance;
