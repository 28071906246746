import { BASE_URL, GET_HEADERS } from "../../components/Helper/requestData";
import { useContext, useEffect, useState } from "react";

import HeroMenuComponent from "../../components/MenuLandingsLayout/HeroMenuComponent";
import InformationComponent from "../../components/InformationComponent/InformationComponent";
import SecondaryNavBar from "../../components/secondaryNavBar/SecondaryNavBar";
import { VehiclesMenuContext } from "../../context/Vehicles/VehiclesMenuContext";
import axios from "axios";

function Politics() {
  const { menuListData, subMenuListData } = useContext(VehiclesMenuContext);
  const [heroSideData, setHeroSideData] = useState({
    title: "",
    titleRich: "",
    secondaryTitle: "",
    bannerImage: "",
    content: "",
    middleTitle: "",
    middleContent: "",
  });

  const getSectionContentData = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Contenido-de-Secciones')/items?$filter=Title eq 'Políticas de Privacidad'",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    setHeroSideData({
      titleRich: resultsArr[0]?.TitleRich,
      title: resultsArr[0]?.Title,
      secondaryTitle: resultsArr[0]?.SecondaryTitle,
      bannerImage: resultsArr[0]?.BannerImage?.Url,
      content: resultsArr[0]?.Content,
      middleTitle: resultsArr[0]?.MiddleTitle,
      middleContent: resultsArr[0]?.MiddleContent,
    });
  };

  useEffect(() => {
    getSectionContentData();
  }, []);
  return (
    <>
      <HeroMenuComponent
        bannerImage={heroSideData.bannerImage}
        title={heroSideData.titleRich}
      />
      <SecondaryNavBar
        title={heroSideData.secondaryTitle || heroSideData.titleRich}
        menuList={menuListData}
        subMenuList={subMenuListData}
      />
      <div className="container mx-auto lg:flex lg:justify-between md:mb-16 ">
        <div className="lg:w-3/5">
          <InformationComponent content={heroSideData.content} />
        </div>
      </div>
    </>
  );
}

export default Politics;
