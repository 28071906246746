import { useContext, useEffect, useState } from "react";
import InformationComponent from "../../components/InformationComponent/InformationComponent";
import HeroMenuComponent from "../../components/MenuLandingsLayout/HeroMenuComponent";
import SearchPublicationComponent from "../../components/SearchPublicationComponent/SearchPublicationComponent";
import SecondaryNavBar from "../../components/secondaryNavBar/SecondaryNavBar";
import Faq from "../../components/VariousCardsComponents/btnCards/Faq";
import axios from "axios";
import { BASE_URL, GET_HEADERS } from "../../components/Helper/requestData";
import { SearchPublicationListProps } from "../../components/SearchPublicationComponent/SearchPublicationList";
import { AboutUsMenuContext } from "../../context/AboutUs/AboutUsMenuContext";

const InstitutionalPublications = () => {
  const [heroSideData, setHeroSideData] = useState({
    title: "",
    titleRich: "",
    bannerImage: "",
    secondaryTitle: "",
    sideTitle1: "",
    sideContent1: "",
    sideTitle2: "",
    content: "",
  });

  const [plansButtons, setPlansButtons] = useState({
    faqLink: "",
    faqTitle: "",
    faqIcon: "",
    faqActive: true,
  });

  const [currOrder, setCurrOrder] = useState("");
  const [currDocType, setCurrDocType] = useState("");
  const [currDate, setCurrDate] = useState("");

  const [currDocSelection, setCurrDocSelection] = useState<
    SearchPublicationListProps[]
  >([
    {
      title: "",
      date: "",
      size: "",
      link: "",
    },
  ]);

  const [searchInput, setSearchInput] = useState("");

  const { menuListData } = useContext(AboutUsMenuContext);

  const getSectionContentData = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Contenido-de-Secciones')/items?$filter=Title eq 'Publicaciones Institucionales'",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    setHeroSideData({
      titleRich: resultsArr[0]?.TitleRich,
      title: resultsArr[0]?.Title,
      bannerImage: resultsArr[0]?.BannerImage?.Url,
      content: resultsArr[0]?.Content,
      secondaryTitle: resultsArr[0]?.SecondaryTitle,
      sideTitle1: resultsArr[0]?.SideTitle1,
      sideTitle2: resultsArr[0]?.SideTitle2,
      sideContent1: resultsArr[0]?.SideContent1,
    });
  };

  const getPlansButtons = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL + "_api/web/lists/getbytitle('Bottom-Buttons')/items",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    const result = resultsArr.find(
      (r: any) => r.Title === "Publicaciones Institucionales"
    );

    setPlansButtons({
      faqLink: result?.FAQLink?.Url,
      faqIcon: result?.FAQIcon?.Url,
      faqTitle: result?.FAQTitle,
      faqActive: result?.Activo,
    });
  };

  const getCurrentDocSelection = async () => {
    function filterOrderDate() {
      if (currOrder) {
        return `&$orderby=Name ${currOrder}`;
      }
      if (currDate) {
        return `&$orderby=TimeLastModified ${currDate}`;
      }
      return "";
    }
    const resultsArr = await axios
      .get(
        BASE_URL +
          `_api/web/GetFolderByServerRelativeUrl('${BASE_URL}SiteImages/Publicaciones-Institucionales')/Files?${
            currDocType ? `$filter=Title eq '${currDocType}'` : ""
          }${filterOrderDate()}`,
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    setCurrDocSelection(
      resultsArr
        .filter((term) => {
          if (searchInput.length === 0) {
            return term;
          } else {
            const specialChars = { á: "a", é: "e", í: "i", ó: "o", ú: "u" };
            const adjustedName = term.Name.toLowerCase().replace(
              /[^A-Za-z0-9[\] ]/g,
              (a: string | number) => specialChars[a] || a
            );
            const adjustedInput = searchInput
              .toLowerCase()
              .replace(
                /[^A-Za-z0-9[\] ]/g,
                (a: string | number) => specialChars[a] || a
              );
            return adjustedName.includes(adjustedInput);
          }
        })
        .map((item: any) => {
          return {
            title: item?.Name,
            link: item?.ServerRelativeUrl,
            date: item?.TimeLastModified,
            size: item?.Length,
          };
        })
    );
  };

  const changeCurrOrder = function (value) {
    setCurrOrder(value);
  };
  const changeCurrDocType = function (value) {
    setCurrDocType(value);
  };
  const changeCurrDate = function (value) {
    setCurrDate(value);
  };
  const paginationButtons = document.querySelectorAll("button.pageButton");

  useEffect(() => {
    getSectionContentData();
    getPlansButtons();
    getCurrentDocSelection();
  }, []);

  useEffect(() => {
    setCurrDate("");
    getCurrentDocSelection();
  }, [currOrder]);

  useEffect(() => {
    setCurrOrder("");
    getCurrentDocSelection();
  }, [currDate]);

  useEffect(() => {
    getCurrentDocSelection();
  }, [currDocType]);

  useEffect(() => {
    paginationButtons &&
      paginationButtons.forEach((x: any) => (x.type = "button"));
  }, [currDocSelection]);

  useEffect(() => {
    getCurrentDocSelection();
  }, [searchInput]);

  return (
    <>
      <HeroMenuComponent
        bannerImage={heroSideData.bannerImage}
        title={heroSideData.titleRich}
      />
      <SecondaryNavBar
        title={heroSideData.secondaryTitle || heroSideData.titleRich}
        menuList={menuListData}
        subMenuList={[]}
      />
      <div className="container mx-auto lg:flex lg:justify-between">
        <div className="lg:w-3/5">
          <InformationComponent content={heroSideData.content} />
        </div>
      </div>
      <div className="container mx-auto mb-20">
        <SearchPublicationComponent
          changeCurrOrder={changeCurrOrder}
          changeCurrDocType={changeCurrDocType}
          changeCurrDate={changeCurrDate}
          currDocSelection={currDocSelection}
          setSearchInput={setSearchInput}
        />
        <InformationComponent content={heroSideData.sideContent1} />
        <Faq
          faqLink={plansButtons.faqLink}
          faqTitle={plansButtons.faqTitle}
          faqIcon={plansButtons.faqIcon}
          faqActive={plansButtons.faqActive}
        />
      </div>
    </>
  );
};

export default InstitutionalPublications;
