import { Splide, SplideSlide } from "@splidejs/react-splide";
import arrow from "../../assets/img/icons/chevron-dark-gray.svg";
import { CardsInterface2 } from "../../context/models/Insurace.model";

import CardsComponent from "./CardsComponent";

type CardsContainerProps = {
  cards: CardsInterface2[] | undefined;
  showCards: boolean;
};
const CardsContainer = ({ cards, showCards }: CardsContainerProps) => {
  return (
    <div className="cards-container container mx-auto flex flex-col  lg:grid md:grid-cols-2 md:gap-5 lg:grid-cols-4 ">
      {cards?.map((card) => (
        <div
          key={card.cardId}
          className={`${showCards ? "block" : "hidden"} lg:flex`}
        >
          <CardsComponent
            cardImg={card.cardImg}
            cardImgHover={card.cardImgHover}
            cardTitle={card.cardTitle}
            isSlide={false}
            btnColorCotizar={card.btnColorCotizar}
            btnLinkCotizar={card.btnLinkCotizar}
            btnImgCotizar={card.btnImgCotizar}
            btnTitleCotizar={card.btnTitleCotizar}
            btnLinkBrochure={card.btnLinkBrochure}
            btnImgBrochure={card.btnImgBrochure}
            btnTitleBrochure={card.btnTitleBrochure}
            btnColorBrochure={card.btnColorBrochure}
            btnLinkPreguntas={card.btnLinkPreguntas}
            btnImgPreguntas={card.btnImgPreguntas}
            btnTitlePreguntas={card.btnTitlePreguntas}
            btnColorPreguntas={card.btnColorPreguntas}
            btnLinkVideo={card.btnLinkVideo}
            btnImgVideo={card.btnImgVideo}
            btnTitleVideo={card.btnTitleVideo}
            btnColorVideo={card.btnColorVideo}
            btnLinkFormulario={card.btnLinkFormulario}
            btnImgFormulario={card.btnImgFormulario}
            btnTitleFormulario={card.btnTitleFormulario}
            btnColorFormulario={card.btnColorFormulario}
            cardListItems={card.cardListItems}
            content={card.content}
            centroAutomovilista={card.centroAutomovilista}
            pageLink={card.pageLink}
          />
        </div>
      ))}

      <div
        className={`${showCards ? "hidden" : "block self-center"} lg:hidden`}
      >
        <Splide
          renderControls={() => (
            <div className="splide__arrows">
              <div className="container ">
                <div className="splide__arrow splide__arrow--next ">
                  <img src={arrow} alt="arrow icon" />
                </div>
                <div className="splide__arrow splide__arrow--prev ">
                  <img src={arrow} alt="arrow icon" className="left-arrow " />
                </div>
              </div>
            </div>
          )}
        >
          {cards?.map((card) => (
            <SplideSlide key={card.cardId}>
              <>
                <CardsComponent
                  cardImg={card.cardImg}
                  cardImgHover={card.cardImgHover}
                  cardTitle={card.cardTitle}
                  isSlide={true}
                  btnColorCotizar={card.btnColorCotizar}
                  btnLinkCotizar={card.btnLinkCotizar}
                  btnImgCotizar={card.btnImgCotizar}
                  btnTitleCotizar={card.btnTitleCotizar}
                  btnLinkBrochure={card.btnLinkBrochure}
                  btnImgBrochure={card.btnImgBrochure}
                  btnTitleBrochure={card.btnTitleBrochure}
                  btnColorBrochure={card.btnColorBrochure}
                  btnLinkPreguntas={card.btnLinkPreguntas}
                  btnImgPreguntas={card.btnImgPreguntas}
                  btnTitlePreguntas={card.btnTitlePreguntas}
                  btnColorPreguntas={card.btnColorPreguntas}
                  btnLinkVideo={card.btnLinkVideo}
                  btnImgVideo={card.btnImgVideo}
                  btnTitleVideo={card.btnTitleVideo}
                  btnColorVideo={card.btnColorVideo}
                  btnLinkFormulario={card.btnLinkFormulario}
                  btnImgFormulario={card.btnImgFormulario}
                  btnTitleFormulario={card.btnTitleFormulario}
                  btnColorFormulario={card.btnColorFormulario}
                  cardListItems={card.cardListItems}
                  content={card.content}
                  centroAutomovilista={card.centroAutomovilista}
                  pageLink={card.pageLink}
                />
              </>
            </SplideSlide>
          ))}
        </Splide>
      </div>
    </div>
  );
};

export default CardsContainer;
