import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { BASE_URL, GET_HEADERS } from "../../components/Helper/requestData";
import HeroMenuComponent from "../../components/MenuLandingsLayout/HeroMenuComponent";
import OptionsComponentSubsudiaries from "../../components/OptionsComponent/OptionsComponentSubsudiaries";
import SecondaryNavBar from "../../components/secondaryNavBar/SecondaryNavBar";
import { AboutUsMenuContext } from "../../context/AboutUs/AboutUsMenuContext";

const Subsidiaries = () => {
  const [heroSideData, setHeroSideData] = useState({
    title: "",
    titleRich: "",
    bannerImage: "",
    secondaryTitle: "",
    sideTitle1: "",
    sideContent1: "",
    sideTitle2: "",
    content: "",
  });

  const [options, setOptions] = useState([
    {
      id: 1,
      title: "",
      content: "",
      blueBoxImage: "",
      blueBoxText: "",
    },
  ]);

  const [subsidiaries, setSubsidiaries] = useState([
    {
      id: 1,
      image: "",
      info: "",
      link: "",
      parent: "",
      linkText: "",
    },
  ]);

  const { menuListData } = useContext(AboutUsMenuContext);

  const getSectionContentData = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Contenido-de-Secciones')/items?$filter=Title eq 'Filiales'",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    setHeroSideData({
      titleRich: resultsArr[0]?.TitleRich,
      title: resultsArr[0]?.Title,
      bannerImage: resultsArr[0]?.BannerImage?.Url,
      content: resultsArr[0]?.Content,
      secondaryTitle: resultsArr[0]?.SecondaryTitle,
      sideTitle1: resultsArr[0]?.SideTitle1,
      sideTitle2: resultsArr[0]?.SideTitle2,
      sideContent1: resultsArr[0]?.SideContent1,
    });
  };

  const getOptions = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL + "_api/web/lists/getbytitle('Filiales-Opciones')/items",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    const results = resultsArr.sort((a, b) => a.ItemId - b.ItemId);

    setOptions(
      results.map((item: any) => {
        return {
          id: item?.ItemId,
          title: item?.Title,
          content: item?.Content,
          blueBoxImage: item?.BlueBoxImage?.Url,
          blueBoxText: item?.BlueBoxText,
          knowMoreLink: item?.KnowMoreLink?.Url,
          knowMoreText: item?.KnowMoreLink?.Description,
        };
      })
    );
  };

  const getSubsidiaries = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Filiales-Detalles')/items?$select=*,Parent/Title&$expand=Parent/Title",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    setSubsidiaries(
      resultsArr.map((item: any) => {
        return {
          id: item?.Order0,
          title: item?.Title,
          content: item?.Content,
          image: item?.Image?.Url,
          info: item?.Info,
          link: item?.Link?.Url,
          linkText: item?.Link?.Description,
          parent: item?.Parent?.Title,
        };
      })
    );
  };

  useEffect(() => {
    getSectionContentData();
    getOptions();
    getSubsidiaries();
  }, []);

  return (
    <>
      <HeroMenuComponent
        bannerImage={heroSideData.bannerImage}
        title={heroSideData.titleRich}
      />
      <SecondaryNavBar
        title={heroSideData.secondaryTitle || heroSideData.titleRich}
        menuList={menuListData}
        subMenuList={[]}
      />
      <div className="container mx-auto lg:flex lg:justify-between lg:py-20 option-filiales">
        <OptionsComponentSubsudiaries
          contentList={options}
          subsidiaries={subsidiaries}
        />
      </div>
    </>
  );
};

export default Subsidiaries;
