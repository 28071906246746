import { useContext, useEffect, useState } from "react";
import InformationComponent from "../../components/InformationComponent/InformationComponent";
import HeroMenuComponent from "../../components/MenuLandingsLayout/HeroMenuComponent";
import SecondaryNavBar from "../../components/secondaryNavBar/SecondaryNavBar";
import SideBar from "../../components/sideBar/SideBar";
import SideBarList from "../../components/sideBar/SideBarList";
import VideoContainer from "../../components/videoContainer/VideoContainer";
import GeneralConditions from "../../components/VariousCardsComponents/btnCards/GeneralConditions";
import MoreInformationCard from "../../components/VariousCardsComponents/moreInfoCard/MoreInformationCard";
import axios from "axios";
import { BASE_URL, GET_HEADERS } from "../../components/Helper/requestData";
import { ServicesChannelsMenuContext } from "../../context/ServicesChannels/ServicesChannelsMenuContext";
import { SideListModel } from "../../context/models/SideList.model";

const AsistenteVirtual = () => {
  const [heroSideData, setHeroSideData] = useState({
    title: "",
    titleRich: "",
    bannerImage: "",
    secondaryTitle: "",
    sideTitle1: "",
    sideContent1: "",
    sideTitle2: "",
    sideContent2: "",
    content: "",
  });

  const [plansButtons, setPlansButtons] = useState({
    generalConditionLink: "",
    generalConditionTitle: "",
    generalConditionIcon: "",
    generalConditionActive: true,
  });

  const [sideList, setSideList] = useState<SideListModel[]>([]);

  const [additionalInfo, setAdditionalInfo] = useState({
    videoLink: "",
    posterImage: "",
  });

  const { menuListData } = useContext(ServicesChannelsMenuContext);

  const getSectionContentData = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Contenido-de-Secciones')/items?$filter=Title eq 'Asistente Virtual'",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    setHeroSideData({
      titleRich: resultsArr[0]?.TitleRich,
      title: resultsArr[0]?.Title,
      bannerImage: resultsArr[0]?.BannerImage.Url,
      secondaryTitle: resultsArr[0]?.SecondaryTitle,
      sideTitle1: resultsArr[0]?.SideTitle1,
      sideContent1: resultsArr[0]?.SideContent1,
      sideTitle2: resultsArr[0]?.SideTitle2,
      sideContent2: resultsArr[0]?.SideContent2,
      content: resultsArr[0]?.Content,
    });
  };

  const getPlansButtons = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL + "_api/web/lists/getbytitle('Bottom-Buttons')/items",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    const result = resultsArr.find((r: any) => r.Title === "Asistente Virtual");

    setPlansButtons({
      generalConditionLink: result?.GeneralConditionLink?.Description,
      generalConditionIcon: result?.GeneralConditionIcon.Url,
      generalConditionTitle: result?.GeneralConditionTitle,
      generalConditionActive: result?.Activo,
    });
  };

  const getSideList = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Seguros-Servicios-SideLists')/items?$filter=Categoria eq 'Asistente Virtual'",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    resultsArr.sort(
      (a: { Order0: number }, b: { Order0: number }) => a.Order0 - b.Order0
    );

    setSideList(
      resultsArr.map((item: any) => {
        return {
          title: item?.Text,
          id: item?.Order0,
        };
      })
    );
  };

  const getAdditionalInfo = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Video-Data')/items?$filter=Title eq 'Asistente Virtual'",
        GET_HEADERS
      )
      .then((response) => response.data.value[0])
      .catch((e) => console.error(e));

    setAdditionalInfo({
      videoLink: resultsArr?.VideoLink?.Url,
      posterImage: resultsArr?.PosterImage.Url,
    });
  };

  useEffect(() => {
    getSectionContentData();
    getPlansButtons();
    getSideList();
    getAdditionalInfo();
  }, []);

  return (
    <>
      <HeroMenuComponent
        bannerImage={heroSideData.bannerImage}
        title={heroSideData.titleRich}
      />
      <SecondaryNavBar
        title={heroSideData.secondaryTitle || heroSideData.titleRich}
        menuList={menuListData}
        subMenuList={[]}
      />

      <div className="container mx-auto lg:flex lg:justify-between">
        <div className="lg:w-3/5">
          <InformationComponent content={heroSideData.content} />
          <div className="mt-10 lg:mt-10 lg:w-4/4">
            <div className="hidden lg:block">
              <GeneralConditions
                generalConditionLink={plansButtons.generalConditionLink}
                generalConditionTitle={plansButtons.generalConditionTitle}
                generalConditionIcon={plansButtons.generalConditionIcon}
                generalConditionActive={plansButtons.generalConditionActive}
              />
            </div>
            <div className="hidden mt-5 lg:block">
              <MoreInformationCard />
            </div>
          </div>
        </div>
        <div className="mt-10 lg:mt-14 lg:w-1/3">
          <div className="block mt-10 lg:hidden">
            <GeneralConditions
              generalConditionLink={plansButtons.generalConditionLink}
              generalConditionTitle={plansButtons.generalConditionTitle}
              generalConditionIcon={plansButtons.generalConditionIcon}
              generalConditionActive={plansButtons.generalConditionActive}
            />
            <MoreInformationCard />
          </div>
          <SideBar>
            {heroSideData.sideTitle1 && (
              <SideBarList
                sideTitle={heroSideData.sideTitle1}
                sideBarList={sideList}
              />
            )}
          </SideBar>
        </div>
      </div>
      <div className="container mx-auto mb-10 lg:mt-12 lg:mb-20 xl:mb-28">
        <VideoContainer
          videoLink={additionalInfo.videoLink}
          posterImage={additionalInfo.posterImage}
        />
      </div>
    </>
  );
};

export default AsistenteVirtual;
