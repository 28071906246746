import { Splide, SplideSlide } from "@splidejs/react-splide";
import { useState, useRef, useEffect } from "react";
import IntroTitle from "../introTitleComponent/IntroTitle";

const Benefits = ({ benefitsIntro, benefitsSlides }: any) => {
  const [slideIndex, setSlideIndex] = useState(0);
  const [hideIndexTabs, setHideIndexTabs] = useState(0);
  const mainSlider: any = useRef();
  const thumbSlider: any = useRef();
  const renderIcons = (index: number, slide: any) => {
    if (slideIndex === undefined && index === 0) {
      return (
        <img
          className="splide-slide-icon-active"
          src={slide.activeIcon}
          alt="icon-active"
        />
      );
    }
    if (slideIndex === index) {
      return (
        <img
          className="splide-slide-icon-active"
          src={slide.activeIcon}
          alt="icon-active"
        />
      );
    } else {
      return (
        <img
          className="splide-slide-icon-inactive"
          src={slide.inactiveIcon}
          alt="icon-inactive"
        />
      );
    }
  };

  useEffect(() => {
    if (mainSlider.current && thumbSlider.current) {
      mainSlider.current.sync(thumbSlider.current.splide);
    }
  }, [mainSlider, thumbSlider]);
  return (
    <div className="services-channels">
      <div className="w-full mt-10 lg:mt-20 lg:mb-4">
        <IntroTitle content={benefitsIntro} />
      </div>
      <div className="services-slide">
        <Splide
          onActive={() => {
            setSlideIndex(thumbSlider?.current?.splide.index);
          }}
          className="splide services-carrousel services-carrousel--center"
          ref={thumbSlider}
          options={{
            loop: false,
            pagination: false,
            padding: 30,
            gap: "20px",
            drag: true,
            arrows: false,
            isNavigation: true,
            breakpoints: {
              1024: {
                arrows: true,
                focus: "center",
              },
            },
          }}
          renderControls={() => (
            <div className="splide__arrows lg:hidden">
              <button
                type="button"
                className="splide__arrow splide__arrow--next "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="38.732"
                  height="70.464"
                  viewBox="0 0 38.732 70.464"
                >
                  <path
                    id="chevron-right"
                    d="M9,66.564,39.282,36.282,9,6"
                    transform="translate(-4.05 -1.05)"
                    fill="none"
                    stroke="#00aef0"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="7"
                  />
                </svg>
              </button>
              <button
                type="button"
                className="splide__arrow splide__arrow--prev "
              >
                <svg
                  className="left-arrow"
                  xmlns="http://www.w3.org/2000/svg"
                  width="38.732"
                  height="70.464"
                  viewBox="0 0 38.732 70.464"
                >
                  <path
                    id="chevron-right"
                    d="M9,66.564,39.282,36.282,9,6"
                    transform="translate(-4.05 -1.05)"
                    fill="none"
                    stroke="#00aef0"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="7"
                  />
                </svg>
              </button>
            </div>
          )}
        >
          {benefitsSlides.map((slide, index) => (
            <SplideSlide key={`${index}`}>
              {renderIcons(index, slide)}
              <h2>{slide.channelName}</h2>
            </SplideSlide>
          ))}
        </Splide>
        <Splide
          onActive={() => {
            setHideIndexTabs(mainSlider?.current?.splide.index);
          }}
          className="info-slide info-slide--np"
          ref={mainSlider}
          options={{ pagination: false, arrows: false }}
        >
          {benefitsSlides.map((slide) => (
            <SplideSlide key={`${slide}`}>
              <div dangerouslySetInnerHTML={{ __html: slide.channelContent }} />
              {hideIndexTabs === 2 ? (
                <div className="lg:flex lg:gap-8">
                  <div className="lg:w-1/4"></div>
                  <div className="lg:w-3/4"></div>
                </div>
              ) : (
                ""
              )}
            </SplideSlide>
          ))}
        </Splide>
      </div>
    </div>
  );
};

export default Benefits;
