import { BASE_URL, GET_HEADERS } from "../Helper/requestData";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { useEffect, useState } from "react";

import BigCardServices from "./BigCardServices";
import SmallCardServices from "./SmallCardServices";
import axios from "axios";

const SectionComponentTwo = () => {
  const [cardData, setCardData] = useState([
    {
      title: "",
      text: "",
      image: "",
      enlace: "",
      enlaceText: "",
      enlaceColor: "",
      orden: 0,
      size: "",
    },
  ]);

  const getData = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Home-S2-Servicios-Complementarios')/items",

        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    setCardData(
      resultsArr.map((item: any) => {
        return {
          title: item?.Title,
          text: item?.trwn,
          image: item?.Image?.Url,
          enlace: item?.Enlace?.Url,
          enlaceText: item?.d5mx,
          enlaceColor: item?.EnlaceColor,
          orden: item?.ursm,
          size: item?.Size,
        };
      })
    );
  };
  useEffect(() => {
    getData();
  }, []);

  const renderCards = () => {
    return cardData.map((c) => (
      <BigCardServices
        title={c.title}
        text={c.text}
        image={c.image}
        enlace={c.enlace}
        enlaceText={c.enlaceText}
        enlaceColor={c.enlaceColor}
        orden={c.orden}
        size={c.size}
        key={c.orden}
      />
    ));
  };

  const cardsCreator = () => {
    // eslint-disable-next-line no-undef
    const bigCards: JSX.Element[] = [];
    // eslint-disable-next-line no-undef
    const smallCards: JSX.Element[] = [];
    const smallGroupCards = [];
    const allCards = [];

    cardData.forEach((c) => {
      if (c.size === "Big") {
        bigCards.push(
          <BigCardServices
            title={c.title}
            text={c.text}
            image={c.image}
            enlace={c.enlace}
            enlaceText={c.enlaceText}
            enlaceColor={c.enlaceColor}
            orden={c.orden}
            size={c.size}
          />
        );
      }
      if (c.size === "Small") {
        smallCards.push(
          <SmallCardServices
            title={c.title}
            text={c.text}
            image={c.image}
            enlace={c.enlace}
            enlaceText={c.enlaceText}
            enlaceColor={c.enlaceColor}
            orden={c.orden}
            size={c.size}
          />
        );
      }
    });

    for (let x = 0; x < smallCards.length; x += 2) {
      smallGroupCards.push(
        <>
          {smallCards[x]}
          {smallCards[x + 1]}
        </>
      );
    }

    for (let i = 0; i < bigCards.length; i++) {
      allCards.push(bigCards[i]);
      allCards.push(smallGroupCards[i]);
    }
    return allCards;
  };

  return (
    <div className="servicios section">
      <div className="container mx-auto">
        <div className="title">
          Servicios <br className="lg:hidden" /> complementarios
        </div>
        <div className="splide  servicios-splide1">
          <Splide
            className="splide"
            options={{
              type: "slide",
              perPage: 2,
              arrows: false,
              pagination: true,
            }}
          >
            {(() =>
              cardsCreator().map((s: any, i: number) => (
                <SplideSlide key={i}>{s}</SplideSlide>
              )))()}
          </Splide>
        </div>
        <div className="splide servicios-splide2">
          <Splide
            className="splide"
            options={{
              type: "slide",
              perPage: 2,
              arrows: false,
              pagination: true,
              breakpoints: {
                1023: {
                  perPage: 1,
                },
              },
            }}
          >
            {(() =>
              renderCards().map((s: any, i: number) => (
                <SplideSlide key={i}>{s}</SplideSlide>
              )))()}
          </Splide>
        </div>
      </div>
    </div>
  );
};
export default SectionComponentTwo;
