import { useState } from "react";
import AccordionLayout from "./AccordionLayout";
const Acordeon = ({ plans, Active }: any) => {
  const [activeIndex, setActiveIndex] = useState(1);

  const renderRow = (planService) => {
    return planService.map((p: any, i: number) =>
      p?.serviceType ? null : (
        <tr className="table-list-show" key={i}>
          <td className="general-list-width">
            <p> {p?.serviceName}</p>
            <p>
              {p?.serviceValue && (
                <>
                  <b>Límite: </b>

                  {p?.serviceValue}
                </>
              )}
            </p>
          </td>
        </tr>
      )
    );
  };

  return (
    <div className="flex flex-col">
      <AccordionLayout
        title={plans[Active]?.planServiceOne[0]?.serviceName}
        index={1}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
      >
        <table>
          <tbody className="table-body">
            {plans[Active]?.planServiceOne?.map((p: any, i: number) =>
              p.serviceType ? null : (
                <tr className="table-list-show" key={i}>
                  <td className="general-list-width">
                    <p> {p?.serviceName}</p>
                    <p>
                      <b>Límite: </b>
                      {p?.serviceValue}
                    </p>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </AccordionLayout>
      {plans[Active]?.planServiceTwo &&
      plans[Active]?.planServiceTwo[0]?.serviceType !== "other" ? (
        <AccordionLayout
          title={plans[Active]?.planServiceTwo[0]?.serviceName}
          index={2}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
        >
          <table>
            <tbody className="table-body">
              {renderRow(plans[Active]?.planServiceTwo)}
            </tbody>
          </table>
        </AccordionLayout>
      ) : null}

      {plans[Active]?.planServiceThree &&
      plans[Active]?.planServiceThree[0]?.serviceType !== "other" ? (
        <AccordionLayout
          title={plans[Active]?.planServiceThree[0]?.serviceName}
          index={3}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
        >
          <table>
            <tbody className="table-body">
              {renderRow(plans[Active]?.planServiceThree)}
            </tbody>
          </table>
        </AccordionLayout>
      ) : null}
      {plans[Active]?.planServiceFour &&
      plans[Active]?.planServiceFour[0]?.serviceType !== "other" ? (
        <AccordionLayout
          title={plans[Active]?.planServiceFour[0]?.serviceName}
          index={4}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
        >
          <table>
            <tbody className="table-body">
              {renderRow(plans[Active]?.planServiceFour)}
            </tbody>
          </table>
        </AccordionLayout>
      ) : null}
      {plans[Active]?.planServiceFive &&
      plans[Active]?.planServiceFive[0]?.serviceType !== "other" ? (
        <AccordionLayout
          title={plans[Active]?.planServiceFive[0]?.serviceName}
          index={5}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
        >
          <table>
            <tbody className="table-body">
              {renderRow(plans[Active]?.planServiceFive)}
            </tbody>
          </table>
        </AccordionLayout>
      ) : null}

      {plans[Active]?.planServiceSix &&
      plans[Active]?.planServiceSix[0]?.serviceType !== "other" ? (
        <AccordionLayout
          title={plans[Active]?.planServiceSix[0]?.serviceName}
          index={6}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
        >
          <table>
            <tbody className="table-body">
              {renderRow(plans[Active]?.planServiceSix)}
            </tbody>
          </table>
        </AccordionLayout>
      ) : null}

      {plans[Active]?.planServiceSeven &&
      plans[Active]?.planServiceSeven[0]?.serviceType !== "other" ? (
        <AccordionLayout
          title={plans[Active]?.planServiceSeven[0]?.serviceName}
          index={7}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
        >
          <table>
            <tbody className="table-body">
              {renderRow(plans[Active]?.planServiceSeven)}
            </tbody>
          </table>
        </AccordionLayout>
      ) : null}
    </div>
  );
};

export default Acordeon;
