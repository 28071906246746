import { useState, useEffect, useContext } from "react";
import axios from "axios";
import InformationComponent from "../../components/InformationComponent/InformationComponent";
import HeroMenuComponent from "../../components/MenuLandingsLayout/HeroMenuComponent";
import SecondaryNavBar from "../../components/secondaryNavBar/SecondaryNavBar";
import SideBar from "../../components/sideBar/SideBar";
import SideBarList from "../../components/sideBar/SideBarList";

import MoreInformationCard from "../../components/VariousCardsComponents/moreInfoCard/MoreInformationCard";
import { BASE_URL, GET_HEADERS } from "../../components/Helper/requestData";
import { PropertyMenuContext } from "../../context/Properties/PropertyMenuContext";
import { SideListModel } from "../../context/models/SideList.model";

const safeHouse = () => {
  const [heroSideData, setHeroSideData] = useState({
    title: "",
    titleRich: "",
    bannerImage: "",
    secondaryTitle: "",
    sideTitle1: "",
    sideContent1: "",
    sideTitle2: "",
    sideContent2: "",
    content: "",
  });

  const [secondaryNavBarButtons, setSecondaryNavBarButtons] = useState({
    btnLinkBrochure: "",
    btnImgBrochure: "",
    btnTitleBrochure: "",
    btnBlockActive: true,
  });

  // const [plansButtons, setPlansButtons] = useState({
  //   generalConditionLink: "",
  //   generalConditionTitle: "",
  //   generalConditionIcon: "",
  //   generalConditionActive: true,
  //   faqLink: "",
  //   faqTitle: "",
  //   faqIcon: "",
  //   faqActive: true,
  // });

  const [sideList, setSideList] = useState<SideListModel[]>([]);

  const { menuListData, subMenuListData } = useContext(PropertyMenuContext);

  const getSectionContentData = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Contenido-de-Secciones')/items?$filter=Title eq 'Vivienda Segura'",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    setHeroSideData({
      titleRich: resultsArr[0]?.TitleRich,
      title: resultsArr[0]?.Title,
      bannerImage: resultsArr[0]?.BannerImage.Url,
      secondaryTitle: resultsArr[0]?.SecondaryTitle,
      sideTitle1: resultsArr[0]?.SideTitle1,
      sideContent1: resultsArr[0]?.SideContent1,
      sideTitle2: resultsArr[0]?.SideTitle2,
      sideContent2: resultsArr[0]?.SideContent2,
      content: resultsArr[0]?.Content,
    });
  };

  const getSecondaryNavBarButtons = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Secondary-NavBar-Buttons')/items",

        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    const result = resultsArr.find((r: any) => r.Title === "Vivienda Segura");

    setSecondaryNavBarButtons({
      btnLinkBrochure: result?.BtnLinkBrochure?.Description,
      btnImgBrochure: result?.BtnImgBrochure?.Url,
      btnTitleBrochure: result?.BtnTitleBrochure,
      btnBlockActive: result?.Activo,
    });
  };

  const getSideList = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Seguros-Servicios-SideLists')/items?$filter=Categoria eq 'Vivienda Segura'",

        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    resultsArr.sort(
      (a: { Order0: number }, b: { Order0: number }) => a.Order0 - b.Order0
    );

    setSideList(
      resultsArr.map((item: any) => {
        return {
          title: item?.Text,
          id: item?.Order0,
        };
      })
    );
  };

  // const getPlansButtons = async () => {
  //   const resultsArr = await axios
  //     .get(
  //       BASE_URL + "_api/web/lists/getbytitle('Bottom-Buttons')/items",

  //       GET_HEADERS
  //     )
  //     .then((response) => response.data.value)
  //     .catch((e) => console.error(e));

  //   const result = resultsArr.find((r: any) => r.Title === "Vivienda Segura");

  //   // setPlansButtons({
  //   //   generalConditionLink: result?.GeneralConditionLink?.Description,
  //   //   generalConditionIcon: result?.GeneralConditionIcon?.Url,
  //   //   generalConditionTitle: result?.GeneralConditionTitle,
  //   //   generalConditionActive: result?.Activo,
  //   //   faqLink: result?.FAQLink?.Description,
  //   //   faqIcon: result?.FAQIcon?.Url,
  //   //   faqTitle: result?.FAQTitle,
  //   //   faqActive: result?.Activo,
  //   // });
  // };

  useEffect(() => {
    getSectionContentData();
    getSecondaryNavBarButtons();
    // getPlansButtons();
    getSideList();
  }, []);
  return (
    <>
      <HeroMenuComponent
        bannerImage={heroSideData.bannerImage}
        title={heroSideData.titleRich}
      />
      <SecondaryNavBar
        title={heroSideData.secondaryTitle || heroSideData.titleRich}
        menuList={menuListData}
        subMenuList={subMenuListData}
        btnLinkBrochure={secondaryNavBarButtons.btnLinkBrochure}
        btnImgBrochure={secondaryNavBarButtons.btnImgBrochure}
        btnTitleBrochure={secondaryNavBarButtons.btnTitleBrochure}
        btnBlockActive={secondaryNavBarButtons.btnBlockActive}
      />

      <div className="container mx-auto lg:flex lg:justify-between md:mb-16 ">
        <div className="lg:w-3/5">
          <InformationComponent content={heroSideData.content} />
          <div className="mt-10 lg:mt-10 lg:w-3/4"></div>
          <div className="hidden my-10 lg:block lg:mt-16">
            <MoreInformationCard />
          </div>
        </div>
        <div className="pb-12 mt-10 lg:mt-14 lg:w-1/3 lg:pb-0">
          <SideBar>
            {heroSideData.sideTitle1 && (
              <SideBarList
                sideTitle={heroSideData.sideTitle1}
                sideBarList={sideList}
              />
            )}
          </SideBar>
          <div className="block my-10 lg:hidden">
            <MoreInformationCard />
          </div>
        </div>
      </div>
    </>
  );
};

export default safeHouse;
