import { useEffect, useState } from "react";
import blueArrow from "../../assets/img/icons/arrow-dark-blue.svg";
import GeneralItemsTable from "./GeneralItemsTable";
import GeneralItemsWithOptionsTable from "./GeneralItemsWithOptionsTable";
import { selectedService } from "./helper/selectedService";
import OtherServicesTable from "./OtherServicesTable";
import SpaceTablebodyElement from "./SpaceTablebodyElement";
const VehicleSinglePlan = ({
  planTitle,
  planContent,
  planServiceOne,
  planServiceTwo,
  planServiceThree,
  planServiceFour,
}: any) => {
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    if (screen.width >= 1024) {
      setShowContent(true);
    }
  }, []);

  const handleClick = (serviceName: string, serviceParent: string) => {
    const previous = document.getElementsByClassName("titles-container");

    selectedService(previous, serviceName, serviceParent);
  };

  const renderPlanService1Items = () => {
    if (planServiceOne[0]?.serviceType === "general") {
      return (
        <GeneralItemsTable
          tableList={planServiceOne}
          handleClick={handleClick}
        />
      );
    } else if (planServiceOne[0]?.serviceType === "options") {
      return (
        <GeneralItemsWithOptionsTable
          tableList={planServiceOne}
          handleClick={handleClick}
        />
      );
    } else {
      return null;
    }
  };
  const renderPlanService2Items = () => {
    if (planServiceTwo[0]?.serviceType === "general") {
      return (
        <>
          <SpaceTablebodyElement />
          <GeneralItemsTable
            tableList={planServiceTwo}
            handleClick={handleClick}
          />
        </>
      );
    } else if (planServiceTwo[0]?.serviceType === "other") {
      return (
        <>
          <SpaceTablebodyElement />
          <OtherServicesTable
            tableList={planServiceTwo}
            handleClick={handleClick}
          />
        </>
      );
    } else if (planServiceTwo[0]?.serviceType === "options") {
      return (
        <>
          <SpaceTablebodyElement />
          <GeneralItemsWithOptionsTable
            tableList={planServiceTwo}
            handleClick={handleClick}
          />
        </>
      );
    } else {
      return null;
    }
  };
  const renderPlanService3And4Items = (servicePlan) => {
    return (
      <>
        <SpaceTablebodyElement />
        <OtherServicesTable tableList={servicePlan} handleClick={handleClick} />
      </>
    );
  };
  return (
    <div className={`vehicle-plan mb-2 w-full`}>
      <div
        className="plan-title flex items-center justify-between lg:hidden"
        onClick={() => setShowContent(!showContent)}
      >
        <h6>{planTitle}</h6>
        <img
          src={blueArrow}
          alt="blue Arrow"
          className={`${showContent ? "blue-arrow-rotate" : "blue-arrow"}`}
        />
      </div>
      <div className={`text-content my-5  ${showContent ? "block" : "hidden"}`}>
        <div dangerouslySetInnerHTML={{ __html: planContent }}></div>
      </div>

      <div className={`plan-list ${showContent ? "block" : "hidden"}`}>
        <table>
          {planServiceOne && planServiceOne[0]?.show
            ? renderPlanService1Items()
            : null}
          {planServiceTwo && planServiceTwo[0]?.show
            ? renderPlanService2Items()
            : null}
          {planServiceThree
            ? renderPlanService3And4Items(planServiceThree)
            : null}
          {planServiceFour
            ? renderPlanService3And4Items(planServiceFour)
            : null}
          <SpaceTablebodyElement />
        </table>
      </div>
    </div>
  );
};

export default VehicleSinglePlan;
