import { useState, useEffect, useContext } from "react";
import axios from "axios";
import CardsContainer from "../../components/MenuLandingsLayout/CardsContainer";
import HeroMenuComponent from "../../components/MenuLandingsLayout/HeroMenuComponent";
import ProductsContainer from "../../components/MenuLandingsLayout/ProductsContainer";
import SecondaryNavBar from "../../components/secondaryNavBar/SecondaryNavBar";
import VehiclesSectionInformation from "../../components/VehiclesComponent/VehiclesSectionInformation";
import VehiclesPlans from "../../components/VehiclesComponent/VehiclesPlans";
import { BASE_URL, GET_HEADERS } from "../../components/Helper/requestData";
import { VehiclesMenuContext } from "../../context/Vehicles/VehiclesMenuContext";

const MotorVehicles = () => {
  const [showCards, setShowCards] = useState(false);

  const [heroData, setHeroData] = useState({
    title: "",
    titleRich: "",
    bannerImage: "",
    secondaryTitle: "",
    sideTitle1: "",
    sideContent1: "",
    sideTitle2: "",
    sideContent2: "",
    content: "",
  });

  const [secondaryNavBarButtons, setSecondaryNavBarButtons] = useState({
    // btnLinkCotizar: "",
    // btnImgCotizar: "",
    // btnTitleCotizar: "",
    btnLinkBrochure: "",
    btnImgBrochure: "",
    btnTitleBrochure: "",
    btnBlockActive: true,
  });

  const [vehiclesPlansButtons, setVehiclesPlansButtons] = useState({
    generalConditionLink: "",
    generalConditionTitle: "",
    generalConditionIcon: "",
    faqLink: "",
    faqTitle: "",
    faqIcon: "",
    faqActive: true,
  });

  const [cardData, setCardData] = useState([
    {
      cardId: 0,
      cardImg: "",
      cardImgHover: "",
      cardTitle: "",
      content: "",
      centroAutomovilista: false,
    },
  ]);

  const [finalPlans, setFinalPlans] = useState([
    {
      id: 0,
      title: "",
      content: "",
      planServiceOne: null,
      planServiceTwo: null,
      planServiceThree: null,
      planServiceFour: null,
      planAdditionalService: null,
    },
  ]);

  const { menuListData, subMenuListData } = useContext(VehiclesMenuContext);

  const getSectionContentData = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Contenido-de-Secciones')/items?$filter=Title eq 'Vehículos de Motor'",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    setHeroData({
      titleRich: resultsArr[0]?.TitleRich,
      title: resultsArr[0]?.Title,
      bannerImage: resultsArr[0]?.BannerImage.Url,
      secondaryTitle: resultsArr[0]?.SecondaryTitle,
      sideTitle1: resultsArr[0]?.SideTitle1,
      sideContent1: resultsArr[0]?.SideContent1,
      sideTitle2: resultsArr[0]?.SideTitle2,
      sideContent2: resultsArr[0]?.SideContent2,
      content: resultsArr[0]?.Content,
    });
  };

  function groupBy(objectArray: any[], property: string | number) {
    return objectArray.reduce(function (acc, obj) {
      const key = obj[property];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  }

  const getFinalPlans = async () => {
    const planes: any = [];
    const motorVehiclePlans: any = await getMotorVehiclePlanTypes();
    const detailsMotorVehicles: any = await getMotorVehicleDetails();

    motorVehiclePlans.forEach((p: any) => {
      planes.push({
        id: p?.order,
        title: p?.plan,
        content: p?.content,
        planServiceOne:
          groupBy(
            groupBy(detailsMotorVehicles, "serviceParent")[p?.plan],
            "tableGroup"
          )["Daños a Terceros"]?.sort((a: any, b: any) => a.id - b.id) || null,
        planServiceTwo:
          groupBy(
            groupBy(detailsMotorVehicles, "serviceParent")[p?.plan],
            "tableGroup"
          )["Riesgos Comprensivos"]?.sort((a: any, b: any) => a.id - b.id) ||
          null,
        planServiceThree:
          groupBy(
            groupBy(detailsMotorVehicles, "serviceParent")[p?.plan],
            "tableGroup"
          )["Servicios Complementarios"]?.sort(
            (a: any, b: any) => a.id - b.id
          ) || null,
        planServiceFour:
          groupBy(
            groupBy(detailsMotorVehicles, "serviceParent")[p?.plan],
            "tableGroup"
          )["Servicios Opcionales"]?.sort((a: any, b: any) => a.id - b.id) ||
          null,
        planAdditionalService: groupBy(
          groupBy(detailsMotorVehicles, "serviceParent")[p?.plan],
          "tableGroup"
        )["Servicios Adicionales"]?.sort((a: any, b: any) => a.id - b.id),
      });
    });
    setFinalPlans(planes);
  };

  const getSecondaryNavBarButtons = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Secondary-NavBar-Buttons')/items",

        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    const result = resultsArr.find(
      (r: any) => r.Title === "Vehículos de Motor"
    );

    setSecondaryNavBarButtons({
      // btnLinkCotizar: result?.BtnLinkCotizar?.Description,
      // btnImgCotizar: result?.BtnImgCotizar?.Url,
      // btnTitleCotizar: result?.BtnTitleCotizar,
      btnLinkBrochure: result?.BtnLinkBrochure?.Description,
      btnImgBrochure: result?.BtnImgBrochure?.Url,
      btnTitleBrochure: result?.BtnTitleBrochure,
      btnBlockActive: result?.Activo,
    });
  };

  const getVehiclesPlansButtons = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL + "_api/web/lists/getbytitle('Bottom-Buttons')/items",

        {
          headers: {
            "X-RequestDigest": (
              document.getElementById("__REQUESTDIGEST") as HTMLInputElement
            ).value,

            Accept: "application/json; odata=nometadata",
            "Content-Type": "application/json; odata=nometadata",
          },
        }
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    const result = resultsArr.find(
      (r: any) => r.Title === "Vehículos de Motor"
    );

    setVehiclesPlansButtons({
      generalConditionLink: result?.GeneralConditionLink?.Description,
      generalConditionIcon: result?.GeneralConditionIcon.Url,
      generalConditionTitle: result?.GeneralConditionTitle,
      faqLink: result?.FAQLink?.Description,
      faqIcon: result?.FAQIcon.Url,
      faqTitle: result?.FAQTitle,
      faqActive: result?.Activo,
    });
  };

  const getMotorVehiclePlanTypes = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Planes de Vehiculos')/items?$filter=Categoria eq 'Motor'",
        {
          headers: {
            "X-RequestDigest": (
              document.getElementById("__REQUESTDIGEST") as HTMLInputElement
            ).value,

            Accept: "application/json; odata=nometadata",
            "Content-Type": "application/json; odata=nometadata",
          },
        }
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    return resultsArr.map((item: any) => {
      return {
        plan: item?.Title,
        content: item?.Content,
        order: item?.Order0,
      };
    });
  };

  const getMotorVehicleDetails = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Detalles Planes de Vehiculos')/items?$select=*,Plan/CategoriaCalculated,Plan/TituloCategoria&$expand=Plan&$filter=Plan/CategoriaCalculated eq 'Motor'&$top=10000",

        {
          headers: {
            "X-RequestDigest": (
              document.getElementById("__REQUESTDIGEST") as HTMLInputElement
            ).value,

            Accept: "application/json; odata=nometadata",
            "Content-Type": "application/json; odata=nometadata",
          },
        }
      )
      .then((response) => response.data.value.flat())
      .catch((e) => console.error(e));

    return resultsArr.map((item: any) => {
      return {
        id: item?.Order0,
        title: item?.Title,
        serviceName: item?.Title,
        serviceParent: item?.Plan?.TituloCategoria.substring(
          item?.Plan?.TituloCategoria.indexOf("-") + 2
        ),
        serviceValue: item?.Option1,
        additionalInfo: item?.Option1,
        serviceValue2: item?.Option2,
        tableGroup: item?.TableGroup,
        serviceType: item?.TableType,
        additionalLabel: "Límite",
        included: !!item?.Option1,
        additionalCharge: !!item?.Option2,
        bold: true,
        show: item?.Show,
      };
    });
  };

  const getCardData = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL + "_api/web/lists/getbytitle('Vehiculos-Motor-Cards')/items",

        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    setCardData(
      resultsArr.map((item: any) => {
        return {
          cardId: item?.CardId,
          cardImg: item?.CardImg?.Url,
          cardTitle: item?.CardTitle,
          cardImgHover: item?.CardImgHover?.Url,
          content: item.Content,
          centroAutomovilista: item?.CentroAutomovilista,
        };
      })
    );
  };

  useEffect(() => {
    getSectionContentData();
    getSecondaryNavBarButtons();
    getVehiclesPlansButtons();
    getFinalPlans();
    getCardData();
  }, []);

  return (
    <>
      <HeroMenuComponent
        bannerImage={heroData.bannerImage}
        title={heroData.titleRich}
      />
      <SecondaryNavBar
        title={heroData.secondaryTitle || heroData.titleRich}
        menuList={menuListData}
        subMenuList={subMenuListData}
        // btnLinkCotizar={secondaryNavBarButtons.btnLinkCotizar}
        // btnImgCotizar={secondaryNavBarButtons.btnImgCotizar}
        // btnTitleCotizar={secondaryNavBarButtons.btnTitleCotizar}
        btnLinkBrochure={secondaryNavBarButtons.btnLinkBrochure}
        btnImgBrochure={secondaryNavBarButtons.btnImgBrochure}
        btnTitleBrochure={secondaryNavBarButtons.btnTitleBrochure}
        btnBlockActive={secondaryNavBarButtons.btnBlockActive}
      />

      <div className="container mx-auto lg:mb-10 xl:mb-16">
        <VehiclesSectionInformation content={heroData.content} />
      </div>
      <VehiclesPlans
        planList={finalPlans}
        generalConditionLink={vehiclesPlansButtons.generalConditionLink}
        generalConditionTitle={vehiclesPlansButtons.generalConditionTitle}
        generalConditionIcon={vehiclesPlansButtons.generalConditionIcon}
        faqActive={vehiclesPlansButtons.faqActive}
        // faqLink={vehiclesPlansButtons.faqLink}
        // faqTitle={vehiclesPlansButtons.faqTitle}
        // faqIcon={vehiclesPlansButtons.faqIcon}
        comparatorTitle={heroData.titleRich}
      />
      <ProductsContainer
        showCards={showCards}
        setShowCards={setShowCards}
        title="Beneficios Planes de Vehículos"
      >
        <CardsContainer showCards={showCards} cards={cardData} />
      </ProductsContainer>
    </>
  );
};

export default MotorVehicles;
