import React, { useEffect, useState } from "react";

import axios from "axios";

export const NavigationContext = React.createContext({
  menuLinks: {
    home: "",
    segurosVehiculos: "",
    segurosPersonas: "",
    segurosPropiedades: "",
    segurosMultiples: "",
    vehiculosDeMotor: "",
    rescate365: "",
    vehiculosElectricos: "",
    autoInspeccion: "",
    marbeteDigital: "",
    navesAereas: "",
    embarcacionesMaritimas: "",
    viaReservas: "",
    politicas: "",
    multiriesgoVehiculos: "",
    vidaColetiva: "",
    enfermedadesGraves: "",
    accidentesPersonales: "",
    accidentesEstudiantil: "",
    accidentesPersonalesTarjeta: "",
    vidaExtra: "",
    vidaEstudiantil: "",
    vidaReservas: "",
    vidaIndividual: "",
    asistenciaFuneraria: "",
    respaldoMigratorio: "",
    viajesReservas: "",
    planDeAsistencia: "",
    viviendaSegura: "",
    mobiliarioSeguro: "",
    condominioSeguros: "",
    multiriesgoPymes: "",
    incendioYLineasAliadas: "",
    roturaMaquinarias: "",
    riesgoContratista: "",
    riesgoMontaje: "",
    riesgoElectronicos: "",
    calderas: "",
    saludInternacionalJust: "",
    bail: "",
    fidelity: "",
    cilvilLiability: "",
    merchandiseTransports: "",
    glassBreakage: "",
    valuableObjects: "",
    moneyTheft: "",
    servicesLanding: "",
    intermediarios365: "",
    clientes365: "",
    qrCode: "",
    virtualAssistance: "",
    contact: "",
    offices: "",
    error404: "",
    claimsLanding: "",
    claimsPerson: "",
    corporateidentity: "",
    institutionalPublications: "",
    subsidiaries: "",
    workWithUs: "",
    reinsurance: "",
    council: "",
    claimsGeneralRisks: "",
    claimsProperties: "",
    claimsMultiple: "",
    ourHistory: "",
    socialResposibility: "",
    news: "",
    newsDetails: "",
    claimsVehicles: "",
  },
});

type NavigationProps = {
  children?: React.ReactNode;
};

function Navigation({ children }: NavigationProps) {
  const [menuLinks, setMenuLinks] = useState({
    home: "",
    segurosVehiculos: "",
    segurosPersonas: "",
    segurosPropiedades: "",
    segurosMultiples: "",
    vehiculosDeMotor: "",
    rescate365: "",
    vehiculosElectricos: "",
    autoInspeccion: "",
    marbeteDigital: "",
    navesAereas: "",
    embarcacionesMaritimas: "",
    viaReservas: "",
    politicas: "",
    multiriesgoVehiculos: "",
    vidaColetiva: "",
    enfermedadesGraves: "",
    accidentesPersonales: "",
    accidentesEstudiantil: "",
    accidentesPersonalesTarjeta: "",
    vidaExtra: "",
    vidaEstudiantil: "",
    vidaReservas: "",
    vidaIndividual: "",
    asistenciaFuneraria: "",
    respaldoMigratorio: "",
    viajesReservas: "",
    planDeAsistencia: "",
    viviendaSegura: "",
    mobiliarioSeguro: "",
    condominioSeguros: "",
    multiriesgoPymes: "",
    incendioYLineasAliadas: "",
    roturaMaquinarias: "",
    riesgoContratista: "",
    riesgoMontaje: "",
    riesgoElectronicos: "",
    calderas: "",
    saludInternacionalJust: "",
    bail: "",
    fidelity: "",
    cilvilLiability: "",
    merchandiseTransports: "",
    glassBreakage: "",
    valuableObjects: "",
    moneyTheft: "",
    servicesLanding: "",
    intermediarios365: "",
    clientes365: "",
    qrCode: "",
    virtualAssistance: "",
    contact: "",
    offices: "",
    error404: "",
    claimsLanding: "",
    claimsPerson: "",
    corporateidentity: "",
    institutionalPublications: "",
    subsidiaries: "",
    workWithUs: "",
    reinsurance: "",
    council: "",
    claimsGeneralRisks: "",
    claimsProperties: "",
    claimsMultiple: "",
    ourHistory: "",
    socialResposibility: "",
    news: "",
    newsDetails: "",
    claimsVehicles: "",
  });

  async function fetchNavigation() {
    try {
      const navigationTermSetId = document.querySelector<HTMLInputElement>(
        "#navigation-termset-id"
      );
      const navigationTermSetName = document.querySelector<HTMLInputElement>(
        "#navigation-termset-name"
      );
      const navigationTermSetGroup = document.querySelector<HTMLInputElement>(
        "#navigation-termset-group"
      );
      const navigationTermSetSet = document.querySelector<HTMLInputElement>(
        "#navigation-termset-set"
      );

      if (navigationTermSetId && navigationTermSetId.value) {
        const digest =
          document.querySelector<HTMLInputElement>("#__REQUESTDIGEST");
        if (digest && digest.value) {
          return await axios
            .post(
              "/_layouts/15/BRRD.AFP.SitePublico/TermStoreController.aspx/GetTerms",
              {
                request: {
                  Name: navigationTermSetName?.value,
                  Group: navigationTermSetGroup?.value,
                  Set: navigationTermSetSet?.value,
                },
              },
              {
                headers: {
                  Accept: "application/json;odata=verbose",
                  "Content-Type": "application/json;odata=verbose",
                },
              }
            )
            .then((response) => {
              if (
                response.status === 200 &&
                response.data &&
                response.data.d &&
                response.data.d
              ) {
                const results = response.data.d;
                const links: {
                  home: string;
                  segurosVehiculos: string;
                  segurosPersonas: string;
                  segurosPropiedades: string;
                  segurosMultiples: string;
                  vehiculosDeMotor: string;
                  rescate365: string;
                  vehiculosElectricos: string;
                  autoInspeccion: string;
                  marbeteDigital: string;
                  navesAereas: string;
                  embarcacionesMaritimas: string;
                  viaReservas: string;
                  politicas: string;
                  multiriesgoVehiculos: string;
                  vidaColetiva: string;
                  enfermedadesGraves: string;
                  accidentesPersonales: string;
                  accidentesEstudiantil: string;
                  accidentesPersonalesTarjeta: string;
                  vidaExtra: string;
                  vidaEstudiantil: string;
                  vidaReservas: string;
                  vidaIndividual: string;
                  asistenciaFuneraria: string;
                  respaldoMigratorio: string;
                  viajesReservas: string;
                  planDeAsistencia: string;
                  viviendaSegura: string;
                  mobiliarioSeguro: string;
                  condominioSeguros: string;
                  multiriesgoPymes: string;
                  incendioYLineasAliadas: string;
                  roturaMaquinarias: string;
                  riesgoContratista: string;
                  riesgoMontaje: string;
                  riesgoElectronicos: string;
                  calderas: string;
                  saludInternacionalJust: string;
                  bail: string;
                  fidelity: string;
                  cilvilLiability: string;
                  merchandiseTransports: string;
                  glassBreakage: string;
                  valuableObjects: string;
                  moneyTheft: string;
                  servicesLanding: string;
                  intermediarios365: string;
                  clientes365: string;
                  qrCode: string;
                  virtualAssistance: string;
                  contact: string;
                  offices: string;
                  error404: string;
                  claimsLanding: string;
                  claimsPerson: string;
                  corporateidentity: string;
                  institutionalPublications: string;
                  subsidiaries: string;
                  workWithUs: string;
                  reinsurance: string;
                  council: string;
                  claimsGeneralRisks: string;
                  claimsProperties: string;
                  claimsMultiple: string;
                  ourHistory: string;
                  socialResposibility: string;
                  news: string;
                  newsDetails: string;
                  claimsVehicles: string;
                } = {
                  home: "",
                  segurosVehiculos: "",
                  segurosPersonas: "",
                  segurosPropiedades: "",
                  segurosMultiples: "",
                  vehiculosDeMotor: "",
                  rescate365: "",
                  vehiculosElectricos: "",
                  autoInspeccion: "",
                  marbeteDigital: "",
                  navesAereas: "",
                  embarcacionesMaritimas: "",
                  viaReservas: "",
                  politicas: "",
                  multiriesgoVehiculos: "",
                  vidaColetiva: "",
                  enfermedadesGraves: "",
                  accidentesPersonales: "",
                  accidentesEstudiantil: "",
                  accidentesPersonalesTarjeta: "",
                  vidaExtra: "",
                  vidaEstudiantil: "",
                  vidaReservas: "",
                  vidaIndividual: "",
                  asistenciaFuneraria: "",
                  respaldoMigratorio: "",
                  viajesReservas: "",
                  planDeAsistencia: "",
                  viviendaSegura: "",
                  mobiliarioSeguro: "",
                  condominioSeguros: "",
                  multiriesgoPymes: "",
                  incendioYLineasAliadas: "",
                  roturaMaquinarias: "",
                  riesgoContratista: "",
                  riesgoMontaje: "",
                  riesgoElectronicos: "",
                  calderas: "",
                  saludInternacionalJust: "",
                  bail: "",
                  fidelity: "",
                  cilvilLiability: "",
                  merchandiseTransports: "",
                  glassBreakage: "",
                  valuableObjects: "",
                  moneyTheft: "",
                  servicesLanding: "",
                  intermediarios365: "",
                  clientes365: "",
                  qrCode: "",
                  virtualAssistance: "",
                  contact: "",
                  offices: "",
                  error404: "",
                  claimsLanding: "",
                  claimsPerson: "",
                  corporateidentity: "",
                  institutionalPublications: "",
                  subsidiaries: "",
                  workWithUs: "",
                  reinsurance: "",
                  council: "",
                  claimsGeneralRisks: "",
                  claimsProperties: "",
                  claimsMultiple: "",
                  ourHistory: "",
                  socialResposibility: "",
                  news: "",
                  newsDetails: "",
                  claimsVehicles: "",
                };

                for (const category of results) {
                  if (category.Title === "Home") {
                    links.home = category.URL;
                  }
                  if (category.Title === "CanalesDeServicios") {
                    links.servicesLanding = category.URL;
                    category.Children.forEach((subCategory) => {
                      if (subCategory.Title === "AsistenteVirtual") {
                        links.virtualAssistance = subCategory.URL;
                      }
                      if (subCategory.Title === "Clientes365") {
                        links.clientes365 = subCategory.URL;
                      }
                      if (subCategory.Title === "CodigoQR") {
                        links.qrCode = subCategory.URL;
                      }
                      if (subCategory.Title === "Contactanos") {
                        links.contact = subCategory.URL;
                      }
                      if (subCategory.Title === "Intermediarios365") {
                        links.intermediarios365 = subCategory.URL;
                      }
                      if (subCategory.Title === "OficinasYSucursales") {
                        links.offices = subCategory.URL;
                      }
                    });
                  }
                  if (category.Title === "Nosotros") {
                    category.Children.forEach((subCategory) => {
                      if (subCategory.Title === "ConsejoAdministrativo") {
                        links.council = subCategory.URL;
                      }
                      if (subCategory.Title === "Filiales") {
                        links.subsidiaries = subCategory.URL;
                      }
                      if (subCategory.Title === "Historia") {
                        links.ourHistory = subCategory.URL;
                      }
                      if (subCategory.Title === "IdentidadCorporativa") {
                        links.corporateidentity = subCategory.URL;
                      }
                      if (
                        subCategory.Title === "PublicacionesInstitucionales"
                      ) {
                        links.institutionalPublications = subCategory.URL;
                      }
                      if (
                        subCategory.Title === "ResponsabilidadSocialCorporativa"
                      ) {
                        links.socialResposibility = subCategory.URL;
                      }
                      if (subCategory.Title === "TrabajeConNosotros") {
                        links.workWithUs = subCategory.URL;
                      }
                      if (subCategory.Title === "Reaseguradores") {
                        links.reinsurance = subCategory.URL;
                      }
                    });
                  }

                  if (category.Title === "Noticias") {
                    links.news = category.URL;
                    category.Children.forEach((subCategory) => {
                      if (subCategory.Title === "NoticiasDetalles") {
                        links.newsDetails = subCategory.URL;
                      }
                    });
                  }
                  if (category.Title === "Reclamaciones") {
                    links.claimsLanding = category.URL;
                    category.Children.forEach((subCategory) => {
                      if (subCategory.Title === "Multiples") {
                        links.claimsMultiple = subCategory.URL;
                      }
                      if (subCategory.Title === "Personas") {
                        links.claimsPerson = subCategory.URL;
                      }
                      if (subCategory.Title === "Propiedades") {
                        links.claimsProperties = subCategory.URL;
                      }
                      if (subCategory.Title === "RiesgosGenerales") {
                        links.claimsGeneralRisks = subCategory.URL;
                      }
                      if (subCategory.Title === "Vehiculos") {
                        links.claimsVehicles = subCategory.URL;
                      }
                    });
                  }
                  if (category.Title === "404") {
                    links.error404 = category.URL;
                  }
                  if (category.Title === "Seguros") {
                    category.Children.forEach((subCategory) => {
                      if (subCategory.Title === "Multiples") {
                        links.segurosMultiples = subCategory.URL;
                        if (subCategory.Children.length !== 0) {
                          subCategory.Children.forEach((section) => {
                            if (section.Title === "ArticulosValiosos")
                              links.valuableObjects = section.URL;
                            if (section.Title === "Fianzas")
                              links.bail = section.URL;
                            if (section.Title === "Fidelidad")
                              links.fidelity = section.URL;
                            if (section.Title === "ResponsabilidadCivil")
                              links.cilvilLiability = section.URL;
                            if (section.Title === "RoboDineroValores")
                              links.moneyTheft = section.URL;
                            if (section.Title === "RoturaCristalesLetreros")
                              links.glassBreakage = section.URL;
                            if (section.Title === "TransporteMercancias")
                              links.merchandiseTransports = section.URL;
                            if (section.Title === "EmbarcacionesMaritimas") {
                              links.embarcacionesMaritimas = section.URL;
                            }
                            if (section.Title === "NavesAereas") {
                              links.navesAereas = section.URL;
                            }
                          });
                        }
                      }
                      if (subCategory.Title === "Personas") {
                        links.segurosPersonas = subCategory.URL;
                        if (subCategory.Children.length !== 0) {
                          subCategory.Children.forEach((section) => {
                            if (section.Title === "AccidentesPersonales") {
                              links.accidentesPersonales = section.URL;
                              if (section.Children.length !== 0) {
                                section.Children.forEach((subSection) => {
                                  if (
                                    subSection.Title === "AccidentesEstudiantil"
                                  )
                                    links.accidentesEstudiantil =
                                      subSection.URL;
                                  if (subSection.Title === "AccidentesTarjetas")
                                    links.accidentesPersonalesTarjeta =
                                      subSection.URL;
                                });
                              }
                            }
                            if (section.Title === "AsistenciaFuneraria") {
                              links.asistenciaFuneraria = section.URL;
                            }
                            if (section.Title === "EnfermedadesGraves") {
                              links.enfermedadesGraves = section.URL;
                            }
                            if (section.Title === "PlanAsistenciaTurismo") {
                              links.planDeAsistencia = section.URL;
                            }
                            if (section.Title === "RespaldoMigratorio") {
                              links.respaldoMigratorio = section.URL;
                            }
                            if (section.Title === "SaludInternacional") {
                              links.saludInternacionalJust = section.URL;
                            }
                            if (section.Title === "ViajesReservas") {
                              links.viajesReservas = section.URL;
                            }
                            if (section.Title === "Vida") {
                              if (section.Children.length !== 0) {
                                section.Children.forEach((subSection) => {
                                  if (subSection.Title === "VidaColectiva")
                                    links.vidaColetiva = subSection.URL;
                                  if (subSection.Title === "VidaEstudiantil")
                                    links.vidaEstudiantil = subSection.URL;
                                  if (subSection.Title === "VidaExtraSegura")
                                    links.vidaExtra = subSection.URL;
                                  if (subSection.Title === "VidaReservas")
                                    links.vidaReservas = subSection.URL;
                                });
                              }
                            }
                            if (section.Title === "VidaIndividual") {
                              links.vidaIndividual = section.URL;
                            }
                          });
                        }
                      }
                      if (subCategory.Title === "Propiedades") {
                        links.segurosPropiedades = subCategory.URL;
                        if (subCategory.Children.length !== 0) {
                          subCategory.Children.forEach((section) => {
                            if (section.Title === "IncendioLineasAliadas") {
                              links.incendioYLineasAliadas = section.URL;
                            }
                            if (section.Title === "MobiliarioSeguro") {
                              links.mobiliarioSeguro = section.URL;
                            }
                            if (section.Title === "MultiriesgoPymes") {
                              links.multiriesgoPymes = section.URL;
                            }
                            if (section.Title === "SegurosCondominios") {
                              links.condominioSeguros = section.URL;
                            }
                            if (section.Title === "ViviendaSegura") {
                              links.viviendaSegura = section.URL;
                            }
                            if (section.Title === "RamosTecnicos") {
                              if (section.Children.length !== 0) {
                                section.Children.forEach((subSection) => {
                                  if (subSection.Title === "Calderas")
                                    links.calderas = subSection.URL;
                                  if (subSection.Title === "RiesgoContratista")
                                    links.riesgoContratista = subSection.URL;
                                  if (
                                    subSection.Title ===
                                    "RiesgoEquiposElectronicos"
                                  )
                                    links.riesgoElectronicos = subSection.URL;
                                  if (subSection.Title === "RiesgoMontaje")
                                    links.riesgoMontaje = subSection.URL;
                                  if (subSection.Title === "RoturaMaquinarias")
                                    links.roturaMaquinarias = subSection.URL;
                                });
                              }
                            }
                          });
                        }
                      }
                      if (subCategory.Title === "Vehiculos") {
                        links.segurosVehiculos = subCategory.URL;
                        if (subCategory.Children.length !== 0) {
                          subCategory.Children.forEach((section) => {
                            if (section.Title === "Electricos") {
                              links.vehiculosElectricos = section.URL;
                            }
                            if (section.Title === "Motor") {
                              links.vehiculosDeMotor = section.URL;
                            }
                            if (section.Title === "MultiriesgoVehiculos") {
                              links.multiriesgoVehiculos = section.URL;
                            }
                            if (section.Title === "ViaReservas") {
                              links.viaReservas = section.URL;
                            }
                            if (section.Title === "PoliticasDePrivacidad") {
                              links.politicas = section.URL;
                            }
                            if (section.Title === "Servicios Y Facilidades") {
                              if (section.Children.length !== 0) {
                                section.Children.forEach((subSection) => {
                                  if (subSection.Title === "AutoInspeccion")
                                    links.autoInspeccion = subSection.URL;
                                  if (subSection.Title === "MarbeteDigital")
                                    links.marbeteDigital = subSection.URL;
                                  if (subSection.Title === "Rescate365")
                                    links.rescate365 = subSection.URL;
                                });
                              }
                            }
                          });
                        }
                      }
                    });
                  }
                }
                // eslint-disable-next-line no-console
                console.log(links);
                setMenuLinks(links);
              }
            });
        } else {
          return setTimeout(fetchNavigation, 100);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchNavigation();
  }, []);

  return (
    <NavigationContext.Provider value={{ menuLinks }}>
      {children}
    </NavigationContext.Provider>
  );
}

export default Navigation;
