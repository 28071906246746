import axios from "axios";
import { BASE_URL, GET_HEADERS } from "../components/Helper/requestData";
import SocialMediaDto from "../dtos/SocialMediaDto";

export async function getSocialMediaDataService(): Promise<SocialMediaDto[]> {
  const resultsArr = await axios
    .get(
      BASE_URL + "_api/web/lists/getbytitle('Redes Sociales')/items",
      GET_HEADERS
    )
    .then((response) => response.data.value)
    .catch((e) => console.error(e));

  const results = resultsArr.map((item: any) => {
    const socialMediaObj = new SocialMediaDto();
    socialMediaObj.title = item?.Title;
    socialMediaObj.icon = item?.Icon.Url;
    socialMediaObj.link = item?.Link.Url;
    socialMediaObj.order = item?.Order0;
    return socialMediaObj;
  });

  return results.sort(
    (a: { order: number }, b: { order: number }) => a.order - b.order
  );
}
