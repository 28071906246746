import { useEffect, useState } from "react";
import VehiclePlanListSideMenu from "../../components/VehiclesComponent/VehiclePlanListSideMenu";
import VehicleSinglePlan from "../../components/VehiclesComponent/VehicleSinglePlan";

const AccidentesPersonalesTarjetasPlan = ({ planList, mainTitle }: any) => {
  const [active, setActive] = useState({
    activeId: 0,
    activeTitle: "",
  });

  useEffect(() => {
    if (screen.width >= 1024) {
      setActive({
        activeId: planList[0].id,
        activeTitle: planList[0].title,
      });
    } else {
      setActive({
        activeId: 0,
        activeTitle: "",
      });
    }
  }, [screen.width, planList]);

  return (
    <div className="vehicle-plans">
      <div className="container mx-auto ">
        <div className="section-head flex justify-between mb-2">
          <h5>{mainTitle}</h5>
        </div>
        <hr className="hidden md:block" />
        <div className="lg:flex lg:justify-between">
          <VehiclePlanListSideMenu
            setActive={setActive}
            active={active}
            sideMenuList={planList}
          />
          <div className="vehicle-plans-list">
            {planList.map((plan: any) => {
              if (
                (active?.activeTitle === plan?.title &&
                  active?.activeId === plan?.id) ||
                (active?.activeTitle === "" && active?.activeId === 0)
              ) {
                return (
                  <>
                    <VehicleSinglePlan
                      key={plan?.id}
                      planServiceOne={plan?.planServiceOne}
                      planServiceTwo={""}
                      planServiceThree={""}
                      planServiceFour={""}
                      planTitle={plan?.title}
                      planContent={""}
                    />
                    <div className="text-content">
                      <div className="my-5 text-content hidden md:block">
                        <div
                          dangerouslySetInnerHTML={{ __html: plan?.content }}
                        ></div>
                      </div>
                    </div>
                  </>
                );
              }
              return null;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccidentesPersonalesTarjetasPlan;
