import arrow from "../../assets/img/icons/chevron-right-orange-thin.svg";

type modalsBtn = {
  btnLinkCotizar?: string;
  btnImgCotizar?: string;
  btnTitleCotizar?: string;
  btnColorCotizar?: string;
  btnLinkBrochure?: string;
  btnImgBrochure?: string;
  btnTitleBrochure?: string;
  btnColorBrochure?: string;
  btnLinkPreguntas?: string;
  btnImgPreguntas?: string;
  btnTitlePreguntas?: string;
  btnColorPreguntas?: string;
  btnLinkVideo?: string;
  btnImgVideo?: string;
  btnTitleVideo?: string;
  btnColorVideo?: string;
  btnLinkFormulario?: string;
  btnImgFormulario?: string;
  btnTitleFormulario?: string;
  btnColorFormulario?: string;
};

const ModalBtnsComponent = ({
  btnColorCotizar,
  btnLinkCotizar,
  btnImgCotizar,
  btnTitleCotizar,
  btnLinkBrochure,
  btnImgBrochure,
  btnTitleBrochure,
  btnColorBrochure,
  btnLinkPreguntas,
  btnImgPreguntas,
  btnTitlePreguntas,
  btnColorPreguntas,
  btnLinkVideo,
  btnImgVideo,
  btnTitleVideo,
  btnColorVideo,
  btnLinkFormulario,
  btnImgFormulario,
  btnTitleFormulario,
  btnColorFormulario,
}: modalsBtn) => {
  return (
    <div className="modal-btns-container sm:grid sm:grid-cols-2 md:w-11/12 md:mt-8 md:px-5 ">
      {btnLinkCotizar ? (
        <a
          className={`${btnColorCotizar}-btn modal-btn flex items-center`}
          href={btnLinkCotizar}
        >
          <img src={btnImgCotizar} alt={btnTitleCotizar} className="icon-img" />
          <span>{btnTitleCotizar}</span>
          <img src={arrow} className="w-2.5 ml-16" />
        </a>
      ) : null}
      {btnLinkBrochure ? (
        <a
          className={`${btnColorBrochure}-btn modal-btn flex items-center`}
          href={btnLinkBrochure}
        >
          <img
            src={btnImgBrochure}
            alt={btnTitleBrochure}
            className="icon-img"
          />
          <span>{btnTitleBrochure}</span>
        </a>
      ) : null}
      {btnLinkPreguntas ? (
        <a
          className={`${btnColorPreguntas}-btn modal-btn flex items-center`}
          href={btnLinkPreguntas}
        >
          <img
            src={btnImgPreguntas}
            alt={btnTitlePreguntas}
            className="icon-img"
          />
          <span>{btnTitlePreguntas}</span>
        </a>
      ) : null}
      {btnLinkVideo ? (
        <a
          className={`${btnColorVideo}-btn modal-btn flex items-center`}
          href={btnLinkVideo}
        >
          <img src={btnImgVideo} alt={btnTitleVideo} className="icon-img" />
          <span>{btnTitleVideo}</span>
        </a>
      ) : null}
      {btnLinkFormulario ? (
        <a
          className={`${btnColorFormulario}-btn modal-btn flex items-center`}
          href={btnLinkFormulario}
        >
          <img
            src={btnImgFormulario}
            alt={btnTitleFormulario}
            className="icon-img"
          />
          <span>{btnTitleFormulario}</span>
        </a>
      ) : null}
    </div>
  );
};

export default ModalBtnsComponent;
