import "./css/blueArrowList.css";

export type BlueArrowcontentListProp = {
  id: number;
  title: string;
  content?: string;
};

export type BlueArrowListProps = {
  contentList: BlueArrowcontentListProp[];
};
const BlueArrowList = ({ contentList }: BlueArrowListProps) => {
  return (
    <>
      {contentList.map((list: BlueArrowcontentListProp) => (
        <div className="blue-arrow-list" key={list.id}>
          <div className="blue-arrow-list-title">{list.title}</div>
          <div dangerouslySetInnerHTML={{ __html: list.content }}></div>
        </div>
      ))}
    </>
  );
};

export default BlueArrowList;
