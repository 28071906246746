import { useState, useEffect } from "react";
import axios from "axios";
import "./css/asistanceLineCard.css";
import { BASE_URL, GET_HEADERS } from "../../Helper/requestData";

const AsistanceLineCard = () => {
  const [cardData, setCardData] = useState({
    title: "",
    id: 0,
    phoneTitle: "",
    phoneContent: "",
    celContactTitle: "",
    celContactContent: "",
    whatsAppNumber: "",
    appTitle: "",
    appContent: "",
  });

  const getCardData = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL + "_api/web/lists/getbytitle('Linea-Asistencia-Card')/items",

        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    setCardData({
      title: resultsArr[0]?.Title,
      id: resultsArr[0]?.ItemId,
      phoneTitle: resultsArr[0]?.PhoneTitle,
      phoneContent: resultsArr[0]?.PhoneContent,
      celContactTitle: resultsArr[0]?.CelContactTitle,
      celContactContent: resultsArr[0]?.CelContactContent,
      whatsAppNumber: resultsArr[0]?.WhatsAppNumber,
      appTitle: resultsArr[0]?.AppTitle,
      appContent: resultsArr[0]?.AppContent,
    });
  };

  useEffect(() => {
    getCardData();
  }, []);

  return (
    <div className="asistance-line">
      <h3 className="asistance-title mb-2">{cardData.title}</h3>
      <div className="mb-3">
        <p>{cardData.phoneTitle} </p>

        <a
          href={`tel:${cardData.phoneContent}`}
          target="_blank"
          rel="noreferrer"
        >
          <span className="blue-text">{cardData.phoneContent}</span>
        </a>
      </div>
      <div className="mb-3">
        <a
          href={`tel:${cardData.celContactTitle}`}
          target="_blank"
          rel="noreferrer"
        >
          <span className="orange-text">{cardData.celContactTitle}</span>
        </a>
        <p>{cardData.celContactContent}</p>
      </div>
      <div className="mb-3">
        <p>WhatsApp</p>
        <a
          href={`https://api.whatsapp.com/send?phone=${cardData.whatsAppNumber}`}
          target="_blank"
          rel="noreferrer"
        >
          <span className="blue-text">{cardData.whatsAppNumber}</span>
        </a>
      </div>
      <div className="">
        <p className="orange-text">{cardData.appTitle}</p>
        <p>{cardData.appContent}</p>
      </div>
    </div>
  );
};

export default AsistanceLineCard;
