import { Splide, SplideSlide } from "@splidejs/react-splide";
import VideoContainer from "../videoContainer/VideoContainer";

function VideoSlideCreate({ videosData }: any) {
  return (
    <div className="video-slide">
      <Splide
        options={{
          perPage: 2,
          pagination: false,
          gap: "40px",
          breakpoints: {
            1024: {
              perPage: 1,
              gap: 0,
            },
          },
        }}
        renderControls={() => (
          <div className="splide__arrows">
            <div className="">
              <button
                type="button"
                className="splide__arrow splide__arrow--next "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="38.732"
                  height="70.464"
                  viewBox="0 0 38.732 70.464"
                >
                  <path
                    id="chevron-right"
                    d="M9,66.564,39.282,36.282,9,6"
                    transform="translate(-4.05 -1.05)"
                    fill="none"
                    stroke="#e2e2e2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="7"
                  />
                </svg>
              </button>
              <button
                type="button"
                className="splide__arrow splide__arrow--prev "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="38.732"
                  height="70.464"
                  viewBox="0 0 38.732 70.464"
                >
                  <path
                    id="chevron-right"
                    d="M9,66.564,39.282,36.282,9,6"
                    transform="translate(-4.05 -1.05)"
                    fill="none"
                    stroke="#e2e2e2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="7"
                  />
                </svg>
              </button>
            </div>
          </div>
        )}
      >
        {videosData.map((video, i) => (
          <SplideSlide key={i}>
            <div className="relative video-slide__card">
              <VideoContainer
                videoLink={video.source}
                posterImage={video.image}
              />
            </div>
          </SplideSlide>
        ))}
      </Splide>
    </div>
  );
}

const VideoSlide = ({
  title = "Historias de éxito",
  subTitle = "Siempre tenemos espacios para compartir y fortalecer nuestro equipo",
  videosData,
}: any) => {
  return (
    <div className="video-box">
      <h1>{title}</h1>
      <p>{subTitle}</p>
      {<VideoSlideCreate videosData={videosData} />}
    </div>
  );
};

export default VideoSlide;
