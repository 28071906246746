import axios from "axios";
import { useContext, useEffect, useState } from "react";
import Benefits from "../../components/BenefitsSlide/Benefits";
import { BASE_URL, GET_HEADERS } from "../../components/Helper/requestData";
import IntroTitle from "../../components/introTitleComponent/IntroTitle";
import HeroMenuComponent from "../../components/MenuLandingsLayout/HeroMenuComponent";
import SecondaryNavBar from "../../components/secondaryNavBar/SecondaryNavBar";
import ExperienceSlide from "../../components/WorkWithUsSlide/ExperienceSlide";
import VideoSlide from "../../components/WorkWithUsSlide/VideoSlide";
import WorkWithUsSlide from "../../components/WorkWithUsSlide/WorkWithUsSlide";
import { AboutUsMenuContext } from "../../context/AboutUs/AboutUsMenuContext";

const WorkWithUs = () => {
  const [heroSideData, setHeroSideData] = useState({
    title: "",
    titleRich: "",
    bannerImage: "",
    secondaryTitle: "",
    sideTitle1: "",
    sideContent1: "",
    sideTitle2: "",
    content: "",
    contentImage: "",
    sideContent2: "",
    middleTitle: "",
  });

  const [workRequirements, setWorkRequirements] = useState([
    {
      image: "",
      text: "",
    },
  ]);

  const [workExperience, setWorkExperience] = useState([
    {
      id: 1,
      image: "",
      text: "",
      nameAndPosition: "",
    },
  ]);

  const [videosData, setVideosData] = useState([
    {
      source: "",
      image: "",
    },
  ]);

  const [benefitsSlides, setBenefitsSlides] = useState([
    {
      inactiveIcon: "",
      activeIcon: "",
      channelName: "",
      channelContent: "",
    },
  ]);

  const { menuListData } = useContext(AboutUsMenuContext);

  const getSectionContentData = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Contenido-de-Secciones')/items?$filter=Title eq 'Trabaje con Nosotros'",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    setHeroSideData({
      titleRich: resultsArr[0]?.TitleRich,
      title: resultsArr[0]?.Title,
      bannerImage: resultsArr[0]?.BannerImage?.Url,
      content: resultsArr[0]?.Content,
      secondaryTitle: resultsArr[0]?.SecondaryTitle,
      sideTitle1: resultsArr[0]?.SideTitle1,
      sideTitle2: resultsArr[0]?.SideTitle2,
      sideContent1: resultsArr[0]?.SideContent1,
      contentImage: resultsArr[0]?.ContentImage?.Url,
      sideContent2: resultsArr[0]?.SideContent2,
      middleTitle: resultsArr[0]?.MiddleTitle,
    });
  };

  const getWorkRequirements = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Trabaje-con-Nosotros-Requerimientos')/items",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    const results = resultsArr.sort((a, b) => a.Order0 - b.Order0);

    setWorkRequirements(
      results.map((item: any) => {
        return {
          image: item?.Image?.Url,
          text: item?.Text,
        };
      })
    );
  };

  const getWorkExperience = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Trabaje-con-Nosotros-Experiencia')/items",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    const results = resultsArr.sort((a, b) => a.Order0 - b.Order0);

    setWorkExperience(
      results.map((item: any) => {
        return {
          id: item?.Order0,
          image: item?.Image?.Url,
          text: item?.Text,
          nameAndPosition: item?.NameAndPosition,
        };
      })
    );
  };

  const getVideosData = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Video-Data')/items?$filter=Title eq 'Trabaje con Nosotros'",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    const results = resultsArr.sort((a, b) => a.Order0 - b.Order0);

    setVideosData(
      results.map((item: any) => {
        return {
          source: item?.VideoLink?.Url,
          image: item?.PosterImage.Url,
        };
      })
    );
  };

  const getBenefitsSlides = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Trabaje-con-Nosotros-Beneficios')/items",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    const results = resultsArr.sort((a, b) => a.Order0 - b.Order0);

    setBenefitsSlides(
      results.map((item: any) => {
        return {
          inactiveIcon: item?.InactiveIcon?.Url,
          activeIcon: item?.ActiveIcon?.Url,
          channelName: item?.Title,
          channelContent: item?.Content,
        };
      })
    );
  };

  useEffect(() => {
    getSectionContentData();
    getWorkRequirements();
    getWorkExperience();
    getVideosData();
    getBenefitsSlides();
  }, []);
  return (
    <>
      <HeroMenuComponent
        bannerImage={heroSideData.bannerImage}
        title={heroSideData.titleRich}
      />
      <SecondaryNavBar
        title={heroSideData.secondaryTitle || heroSideData.titleRich}
        menuList={menuListData}
        subMenuList={[]}
      />
      <div className="container mx-auto lg:flex lg:justify-between">
        <div className="w-full mt-10 lg:mt-20 lg:mb-20">
          <IntroTitle content={heroSideData.content} />
        </div>
      </div>
      <div className="container mx-auto my-9">
        <WorkWithUsSlide
          workRequirements={workRequirements}
          workLinkText={heroSideData.sideTitle1}
          workLink={heroSideData.contentImage}
        />
      </div>
      <div className="container mx-auto lg:flex lg:justify-between">
        <div className="w-full mt-10 lg:mt-20 lg:mb-20">
          <IntroTitle content={heroSideData.sideContent1} />
        </div>
      </div>
      <div className="container mx-auto mt-9 mb-9 lg:mb-20">
        <ExperienceSlide workExperience={workExperience} />
      </div>
      <div className="video-container">
        <div className="container mx-auto">
          <VideoSlide
            title={heroSideData.sideTitle2}
            subTitle={heroSideData.middleTitle}
            videosData={videosData}
          />
        </div>
      </div>
      <div className="container mx-auto lg:pb-24">
        <Benefits
          benefitsIntro={heroSideData.sideContent2}
          benefitsSlides={benefitsSlides}
        />
      </div>
    </>
  );
};

export default WorkWithUs;
