import React from "react";

type ProductsContainerProps = {
  title?: string;
  children?: React.ReactNode;
  showCards: boolean;
  setShowCards: React.Dispatch<React.SetStateAction<boolean>>;
  disableButton?: boolean;
  subTitle?: string;
};

const ProductsContainer = ({
  children,
  title = "Productos Disponibles",
  showCards,
  setShowCards,
  disableButton,
  subTitle,
}: ProductsContainerProps) => {
  const handleShow = () => {
    setShowCards(!showCards);
  };

  return (
    <div className="products-container">
      {title && <h2 className="title">{title}</h2>}
      {subTitle && <p className="subtitle">{subTitle}</p>}
      {!disableButton && (
        <div className="hide-btn lg:hidden" onClick={handleShow}>
          {showCards ? "Ocultar Todos" : "Ver todos"}
        </div>
      )}

      {children}
    </div>
  );
};

export default ProductsContainer;
