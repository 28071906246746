import { useContext, useState } from "react";
import chevronRight from "../../assets/img/icons/chevron-right-blue.svg";
import { ClaimsVehicleDataContext } from "../../context/Claims/ClaimsVehicleDataContext";
import HowToModal from "../HowToModal/HowToModal";
import HowToModalVideo from "../HowToModal/HowToModalVideo";
import ModalComponent from "../ModalComponent/ModalComponent";
import ModalComponentHow from "../ModalComponent/ModalComponentHow";

export interface ClaimsCardsInterface {
  cardImg: string;
  cardTitle?: string;
  isSlide?: boolean;
  modalTitle?: string;
  modalContent?: string;
  content?: string;
  linkText1?: string;
  linkText2?: string;
  link3?: any;
  cardId?: number;
  modalTexts: {
    title: string;
    text: string;
    title2: string;
    footer: string;
  };
  modalPasos: {
    id: number;
    image: string;
    imagePosition: string;
    info: string;
    category: string;
  }[];
  modal2Image: boolean;
  hasVideo: boolean;
}

const CardsComponentSteps = ({
  cardTitle,
  cardImg,
  isSlide,
  modalTitle,
  linkText1,
  linkText2,
  link3,
  modalContent,
  content,
  modalPasos,
  modalTexts,
  hasVideo,
  modal2Image,
}: ClaimsCardsInterface) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const { videoData } = useContext(ClaimsVehicleDataContext);
  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  function closeModal2() {
    setIsOpen2(false);
  }

  function openModal2() {
    setIsOpen2(true);
  }

  return (
    <div
      className={
        isSlide
          ? "card-box-slide claims-channel-card claims-channel-card--fix "
          : "claims-channel-card claims-channel-card--fix"
      }
    >
      <div className="claims-channel-card__top">
        <img src={cardImg} alt="channel icon" />
        <h2>{cardTitle}</h2>
      </div>
      <div
        className="claims-channels-cards-content"
        dangerouslySetInnerHTML={{ __html: content }}
      ></div>
      <div className="link-block">
        {linkText1 && (
          <div onClick={openModal} className="claims-channels-button">
            {linkText1} <img src={chevronRight} alt="chevron icon" />
          </div>
        )}
        {linkText2 && (
          <div onClick={openModal2} className="claims-channels-button">
            {linkText2} <img src={chevronRight} alt="chevron icon" />
          </div>
        )}
        {link3 && (
          <a href={link3.Url}>
            {link3.Description} <img src={chevronRight} alt="chevron icon" />
          </a>
        )}
      </div>

      {linkText1 && (
        <ModalComponentHow closeModal={closeModal} isOpen={isOpen}>
          <div>
            {hasVideo ? (
              <HowToModalVideo
                title={modalTexts.title}
                text={modalTexts.text}
                posterImage={videoData.posterImage}
                videoLink={videoData.videoLink}
              />
            ) : (
              <HowToModal modalPasos={modalPasos} modalTexts={modalTexts} />
            )}
          </div>
        </ModalComponentHow>
      )}

      <ModalComponent closeModal={closeModal2} isOpen={isOpen2}>
        <div>
          {modal2Image ? (
            <div className="modal-step" style={{ overflowWrap: "anywhere" }}>
              <h1>{modalTitle}</h1>
              <div className="flex justify-between modal-step-text">
                <img src={cardImg} alt="service logo" />
                <p dangerouslySetInnerHTML={{ __html: modalContent }}></p>
              </div>
            </div>
          ) : (
            <div className="modal-step" style={{ overflowWrap: "anywhere" }}>
              <h1 className="mb-6">{modalTitle}</h1>
              <div className="flex justify-between modal-step-text">
                <div
                  className="steps-vehicle"
                  dangerouslySetInnerHTML={{ __html: modalContent }}
                ></div>
              </div>
            </div>
          )}
        </div>
      </ModalComponent>
    </div>
  );
};

export default CardsComponentSteps;
