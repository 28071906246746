import { Link } from "react-router-dom";
import "./css/claimsContentCard.css";

type ClaimsContentCardProps = {
  image: string | undefined;
  imageOnHover: string | undefined;
  title: string | undefined;
  link: string;
};

function ContentCard({
  image,
  imageOnHover,
  title,
  link,
}: ClaimsContentCardProps) {
  return (
    <Link to={link}>
      <div className="card-claim">
        <div className="card-claim__image-box">
          <img className="card-claims__icon" src={image} alt={title} />
          <img
            className="hidden card-claims__hover"
            src={imageOnHover}
            alt={title}
          />
        </div>
        <h2 dangerouslySetInnerHTML={{ __html: title }}></h2>
      </div>
    </Link>
  );
}

const ClaimsContentCard = ({ claimsCards }: any) => {
  return (
    <div className="grid gap-y-4 md:grid-cols-2 md:gap-7 lg:flex lg:flex-wrap link-flex lg:justify-center">
      {claimsCards.map((card, i) => (
        <ContentCard
          key={i}
          link={card.link}
          image={card.image}
          imageOnHover={card.imageOnHover}
          title={card.title}
        />
      ))}
    </div>
  );
};

export default ClaimsContentCard;
