import { BASE_URL, GET_HEADERS } from "../Helper/requestData";
import { useContext, useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { NavigationContext } from "../../routes/menulinks";
import arrow from "../../assets/img/icons/chevron-right.svg";
import axios from "axios";
import logo from "../../assets/img/logo.svg";
import menuX from "../../assets/img/icons/side-menu-x.svg";
import { sideMenuType } from "./type";

export default function SideMenu({
  showSideMenu,
  setShowSideMenu,
}: sideMenuType) {
  const [virtualOffice, setVirtualOffice] = useState(false);
  const [navLinks, setNavLinks] = useState([
    {
      title: "",
    },
  ]);
  const { menuLinks } = useContext(NavigationContext);
  const getData = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL + "_api/web/lists/getbytitle('Main-NavBar-Data')/items",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    setNavLinks(
      resultsArr.map((item: any) => {
        return {
          title: item.Title,
        };
      })
    );
  };

  useEffect(() => {
    getData();
  }, []);
  const handleClick = () => {
    setShowSideMenu(!showSideMenu);
  };
  function renderLinks() {
    return navLinks.map((l, i) => {
      if (l.title === "Seguros de Vehículos") {
        return (
          <Link
            to={menuLinks.segurosVehiculos}
            className="sidemenu__link"
            onClick={handleClick}
            key={i}
          >
            <span>{l.title}</span>
          </Link>
        );
      }
      if (l.title === "Seguros de Personas") {
        return (
          <Link
            to={menuLinks.segurosPersonas}
            className="sidemenu__link"
            onClick={handleClick}
            key={i}
          >
            <span>{l.title}</span>
          </Link>
        );
      }
      if (l.title === "Seguros de Propiedades") {
        return (
          <Link
            to={menuLinks.segurosPropiedades}
            className="sidemenu__link"
            onClick={handleClick}
            key={i}
          >
            <span>{l.title}</span>
          </Link>
        );
      }
      if (l.title === "Seguros Múltiples") {
        return (
          <Link
            to={menuLinks.segurosMultiples}
            className="sidemenu__link"
            onClick={handleClick}
            key={i}
          >
            <span>{l.title}</span>
          </Link>
        );
      }
      return null;
    });
  }

  const handleShow = () => {
    setVirtualOffice(!virtualOffice);
  };

  return (
    <div
      id="sideMenu"
      className={`transition ease-out transform ${
        showSideMenu ? "" : "-translate-x-full "
      }  duration-400 sidemenu`}
    >
      <div className="sidemenu__top">
        <img src={logo} alt="logo" className="w-40" />
        <div
          id="closeSideBar"
          className="sidemenu__close"
          onClick={handleClick}
        >
          <img src={menuX} alt="close" className="w-15" />
        </div>
      </div>
      <div className="sidemenu__middle">
        <Link
          to={menuLinks.corporateidentity}
          className="sidemenu__link"
          onClick={handleClick}
        >
          <span>Nosotros</span>
        </Link>
        <Link
          to={menuLinks.claimsLanding}
          className="sidemenu__link"
          onClick={handleClick}
        >
          <span>Reclamaciones</span>
        </Link>
        <Link
          to={menuLinks.news}
          className="sidemenu__link"
          onClick={handleClick}
        >
          <span>Noticias</span>
        </Link>
        {renderLinks()}
        <a
          type="button"
          className="office office--sidemenu"
          onClick={handleShow}
        >
          Accesos
          <img
            src={arrow}
            alt="arrow"
            className={`${virtualOffice ? "arrow-rotate" : ""}`}
          />
        </a>
        {virtualOffice && (
          <>
            <Link
              to={menuLinks.intermediarios365}
              onClick={handleClick}
              className="sidemenu__link"
            >
              <span>Intermediarios 365</span>
            </Link>
            <a
              target="_blank"
              className="sidemenu__link"
              href="https://app.segurosreservas.com/portalautorizacion/#/autorizaciones/login"
              rel="noreferrer"
              onClick={handleClick}
            >
              <span>Autorizaciones Salud PSS</span>
            </a>
          </>
        )}
      </div>
    </div>
  );
}
