import { Splide, SplideSlide } from "@splidejs/react-splide";
import arrow from "../../assets/img/icons/chevron-dark-gray.svg";
import { CardsInterface2 } from "../../context/models/Insurace.model";

import CardsComponentImageOnly from "./CardsComponentImageOnly";

type CardsContainerProps = {
  cards: CardsInterface2[] | undefined;
  showCards: boolean;
};
const CardsContainerImageOnly = ({ cards, showCards }: CardsContainerProps) => {
  return (
    <div className="cards-container container mx-auto flex flex-col  lg:grid md:grid-cols-2 md:gap-5 lg:grid-cols-4 ">
      {cards?.map((card) => (
        <div
          key={card.cardId}
          className={`${showCards ? "block" : "hidden"} lg:flex`}
        >
          <CardsComponentImageOnly
            cardImg={card.cardImg}
            cardImgHover={card.cardImgHover}
            cardTitle={card.cardTitle}
            isSlide={false}
            btnColorCotizar={card.btnColorCotizar}
            btnLinkCotizar={card.btnLinkCotizar}
            btnImgCotizar={card.btnImgCotizar}
            btnTitleCotizar={card.btnTitleCotizar}
            btnLinkBrochure={card.btnLinkBrochure}
            btnImgBrochure={card.btnImgBrochure}
            btnTitleBrochure={card.btnTitleBrochure}
            btnColorBrochure={card.btnColorBrochure}
            btnLinkPreguntas={card.btnLinkPreguntas}
            btnImgPreguntas={card.btnImgPreguntas}
            btnTitlePreguntas={card.btnTitlePreguntas}
            btnColorPreguntas={card.btnColorPreguntas}
            btnLinkVideo={card.btnLinkVideo}
            btnImgVideo={card.btnImgVideo}
            btnTitleVideo={card.btnTitleVideo}
            btnColorVideo={card.btnColorVideo}
            btnLinkFormulario={card.btnLinkFormulario}
            btnImgFormulario={card.btnImgFormulario}
            btnTitleFormulario={card.btnTitleFormulario}
            btnColorFormulario={card.btnColorFormulario}
            cardListItems={card.cardListItems}
            content={card.content}
            centroAutomovilista={card.centroAutomovilista}
            pageLink={card.pageLink}
          />
        </div>
      ))}

      <div
        className={`${showCards ? "hidden" : "block self-center"} lg:hidden`}
      >
        <Splide
          renderControls={() => (
            <div className="splide__arrows">
              <div className="container ">
                <button
                  type="button"
                  className="splide__arrow splide__arrow--next "
                >
                  <img src={arrow} alt="arrow icon" />
                </button>
                <button
                  type="button"
                  className="splide__arrow splide__arrow--prev "
                >
                  <img src={arrow} alt="arrow icon" className="left-arrow " />
                </button>
              </div>
            </div>
          )}
        >
          {cards?.map((card) => (
            <SplideSlide key={card.cardId}>
              <>
                <CardsComponentImageOnly
                  cardImg={card.cardImg}
                  isSlide={true}
                />
              </>
            </SplideSlide>
          ))}
        </Splide>
      </div>
    </div>
  );
};

export default CardsContainerImageOnly;
