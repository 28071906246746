import { useEffect, useState } from "react";
import blueArrow from "../../assets/img/icons/arrow-dark-blue.svg";
import GeneralItemsTable from "./GeneralItemsTable";
import SpaceTablebodyElement from "./SpaceTablebodyElement";
import { selectedService } from "./helper/selectedService";
const InternationalHealthSinglePlan = ({
  planTitle,
  planContent,
  planServiceOne,
  planServiceTwo,
  planServiceThree,
  planServiceFour,
  planServiceFive,
  planServiceSix,
  planServiceSeven,
}: any) => {
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    if (screen.width >= 1024) {
      setShowContent(true);
    }
  }, []);

  const handleClick = (serviceName: string, serviceParent: string) => {
    const previous = document.getElementsByClassName("titles-container");

    selectedService(previous, serviceName, serviceParent);
  };

  return (
    <div className={`vehicle-plan mb-2 w-full`}>
      <div
        className="plan-title flex items-center justify-between lg:hidden"
        onClick={() => setShowContent(!showContent)}
      >
        <h6>{planTitle}</h6>
        <img
          src={blueArrow}
          alt="blue Arrow"
          className={`${showContent ? "blue-arrow-rotate" : "blue-arrow"}`}
        />
      </div>
      <div className={`text-content my-5  ${showContent ? "block" : "hidden"}`}>
        <div dangerouslySetInnerHTML={{ __html: planContent }}></div>
      </div>

      <div className={`plan-list ${showContent ? "block" : "hidden"}`}>
        <table>
          {planServiceOne && (
            <GeneralItemsTable
              tableList={planServiceOne}
              handleClick={handleClick}
            />
          )}
          {planServiceTwo && (
            <>
              <SpaceTablebodyElement />
              <GeneralItemsTable
                tableList={planServiceTwo}
                handleClick={handleClick}
              />
            </>
          )}
          {planServiceThree && (
            <>
              <SpaceTablebodyElement />
              <GeneralItemsTable
                tableList={planServiceThree}
                handleClick={handleClick}
              />
            </>
          )}
          {planServiceFour && (
            <>
              <SpaceTablebodyElement />
              <GeneralItemsTable
                tableList={planServiceFour}
                handleClick={handleClick}
              />
            </>
          )}
          {planServiceFive && (
            <>
              <SpaceTablebodyElement />
              <GeneralItemsTable
                tableList={planServiceFive}
                handleClick={handleClick}
              />
            </>
          )}
          {planServiceSix && (
            <>
              <SpaceTablebodyElement />
              <GeneralItemsTable
                tableList={planServiceSix}
                handleClick={handleClick}
              />
            </>
          )}
          {planServiceSeven && (
            <>
              <SpaceTablebodyElement />
              <GeneralItemsTable
                tableList={planServiceSeven}
                handleClick={handleClick}
              />
            </>
          )}
          <SpaceTablebodyElement />
        </table>
      </div>
    </div>
  );
};

export default InternationalHealthSinglePlan;
