type heroProps = {
  title: string | undefined;
  bannerImage: string | undefined;
};
const HeroMenuComponent = ({ title, bannerImage }: heroProps) => {
  return (
    <div className="hero-menus">
      <img src={bannerImage} className="hidden lg:block" />
      <div className="container mx-auto">
        <div
          className="text-content"
          dangerouslySetInnerHTML={{ __html: title }}
        ></div>
      </div>
    </div>
  );
};

export default HeroMenuComponent;
