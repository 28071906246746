import { Key } from "react";
import "./css/DirectiveComponent.css";

type DirectorCardProps = {
  name: string;
  image: string;
  position: string;
  id: number;
  email: string;
  leader: boolean;
};

function DirectorCardCreate({
  name,
  image,
  position,
  id,
  email,
  leader,
}: DirectorCardProps) {
  const isLeader = leader ? "" : "admin-card--blue";
  return (
    <div
      className={`flex flex-col flex-wrap items-center justify-center admin-card ${isLeader}`}
      key={id}
    >
      <img src={image} alt="Director picture" />
      <h2>{name}</h2>
      <h3>{position}</h3>
      <a href={`mailto:${email}`}>{email}</a>
    </div>
  );
}

const DirectorCard = ({ area, infoContent }: any) => {
  const mapDirectors = (
    cardData: {
      leader: boolean;
      image: string;
      name: string;
      position: string;
      id: number;
      email: string;
    }[]
  ) => {
    return cardData.map(
      (
        card: {
          leader: boolean;
          image: string;

          name: string;
          position: string;
          id: number;
          email: string;
        },
        i: Key
      ) => (
        <DirectorCardCreate
          key={i}
          leader={card.leader}
          image={card.image}
          name={card.name}
          position={card.position}
          id={card.id}
          email={card.email}
        />
      )
    );
  };

  return (
    <div className="gap-2 flex justify-center flex-wrap lg:gap-5">
      {infoContent &&
        mapDirectors(
          infoContent.filter(
            (card: {
              groupType: string;
              area: string | any[];
              leader: boolean;
            }) =>
              card.groupType === "director" &&
              card.area.includes(area) &&
              card.leader === true
          )
        )}
      {infoContent &&
        infoContent
          .filter(
            (card: { groupType: string; area: string | any[] }) =>
              card.groupType === "director" && card.area.includes(area)
          )
          .some((item: { leader: boolean }) => item.leader === false) && (
          <div className="w-full">
            <div className="w-full title-tabs-director">
              <h2>Direcciones</h2>
            </div>
          </div>
        )}

      {infoContent &&
        mapDirectors(
          infoContent.filter(
            (card: {
              groupType: string;
              area: string | any[];
              leader: boolean;
            }) =>
              card.groupType === "director" &&
              card.area.includes(area) &&
              card.leader === false
          )
        )}
    </div>
  );
};

export default DirectorCard;
