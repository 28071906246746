import TopRibbon from "./TopRibbon";
import NavBar from "./NavBar";
import AddressModal from "./AddressModal";
import { useState } from "react";
import SideMenu from "./SideMenu";

const HeaderComponent = () => {
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [showSideMenu, setShowSideMenu] = useState(false);
  return (
    <header>
      <AddressModal
        showAddressModal={showAddressModal}
        setShowAddressModal={setShowAddressModal}
      />
      <SideMenu showSideMenu={showSideMenu} setShowSideMenu={setShowSideMenu} />
      <TopRibbon
        showAddressModal={showAddressModal}
        setShowAddressModal={setShowAddressModal}
      />
      <NavBar showSideMenu={showSideMenu} setShowSideMenu={setShowSideMenu} />
    </header>
  );
};

export default HeaderComponent;
