import { CardsInterface2 } from "../../context/models/Insurace.model";

const CardsComponentValues = ({
  cardTitle,
  cardImg,
  isSlide,
}: CardsInterface2) => {
  return (
    <div
      className={isSlide ? "card-box-slide values-card-fix " : "values-card"}
    >
      <img src={cardImg} alt="" />
      <h2>{cardTitle}</h2>
    </div>
  );
};

export default CardsComponentValues;
