type videoContainerProps = {
  posterImage: string;
  videoLink: string;
};
const VideoContainer = ({ posterImage, videoLink }: videoContainerProps) => {
  return (
    <video
      src={videoLink}
      width="100%"
      controls
      poster={posterImage}
      preload="auto"
    ></video>
  );
};

export default VideoContainer;
