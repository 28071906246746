/* eslint-disable no-console */

import { BASE_URL, GET_HEADERS } from "../Helper/requestData";
import { useContext, useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { NavigationContext } from "../../routes/menulinks";
import axios from "axios";
import chevronRight from "../../assets/img/icons/chevron-right.svg";
import hamburguerMenu from "../../assets/img/icons/menu-blue.png";
import logo from "../../assets/img/logo.svg";
import { sideMenuType } from "./type";

const NavBar = ({ showSideMenu, setShowSideMenu }: sideMenuType) => {
  const [arrow, setArrow] = useState(false);
  const [navLinks, setNavLinks] = useState([
    {
      title: "",
      icon: "",
    },
  ]);
  const { menuLinks } = useContext(NavigationContext);
  const getData = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL + "_api/web/lists/getbytitle('Main-NavBar-Data')/items",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    setNavLinks(
      resultsArr.map((item: any) => {
        return {
          title: item?.Title,
          icon: item?.Icon?.Url,
        };
      })
    );
  };

  useEffect(() => {
    getData();
  }, []);

  function renderLinks() {
    return navLinks.map((l) => {
      if (l.title === "Seguros de Vehículos") {
        return (
          <>
            <Link to={menuLinks.segurosVehiculos}>
              <img
                src={l.icon}
                alt={l.title}
                className="bottom-nav__links-icon"
              />
              {l.title}
            </Link>
            <span className="navbar-section-separator"></span>
          </>
        );
      }
      if (l.title === "Seguros de Personas") {
        return (
          <>
            <Link to={menuLinks.segurosPersonas}>
              <img
                src={l.icon}
                alt={l.title}
                className="bottom-nav__links-icon"
              />
              {l.title}
            </Link>
            <span className="navbar-section-separator"></span>
          </>
        );
      }
      if (l.title === "Seguros de Propiedades") {
        return (
          <>
            <Link to={menuLinks.segurosPropiedades}>
              <img
                src={l.icon}
                alt={l.title}
                className="bottom-nav__links-icon"
              />
              {l.title}
            </Link>
            <span className="navbar-section-separator"></span>
          </>
        );
      }
      if (l.title === "Seguros Múltiples") {
        return (
          <>
            <Link to={menuLinks.segurosMultiples}>
              <img
                src={l.icon}
                alt={l.title}
                className="bottom-nav__links-icon"
              />
              {l.title}
            </Link>
            <span className="navbar-section-separator"></span>
          </>
        );
      }
      return null;
    });
  }
  const handleClick = () => {
    setShowSideMenu(!showSideMenu);
  };
  const handleArrow = () => {
    setArrow(!arrow);
  };

  useEffect(() => {
    if (arrow === true) {
      window.setTimeout(() => setArrow(false), 5000);
    }
  }, [arrow]);

  return (
    <nav className="nav-bar">
      <div className="middle-nav">
        <div className="container mx-auto">
          <div className="middle-nav__box">
            <div className="middle-nav__menu lg:hidden" onClick={handleClick}>
              <img src={hamburguerMenu} alt="hamburger icon" />
            </div>
            <div className="flex justify-center flex-grow lg:justify-start">
              <Link className="middle-nav__logo" to={menuLinks.home}>
                <img src={logo} alt="logo" />
              </Link>
            </div>
            <div className="hidden middle-nav__links lg:block">
              <Link to={menuLinks.corporateidentity}>NOSOTROS</Link>
              <Link to={menuLinks.claimsLanding}>RECLAMACIONES</Link>
              <Link to={menuLinks.news}>NOTICIAS</Link>
              <div className="office" onClick={handleArrow}>
                Accesos
                <img
                  src={chevronRight}
                  alt="right arrow icon"
                  className={`${arrow ? "arrow-rotate" : ""}`}
                />
              </div>
            </div>
            <div
              className={`more-links-box ${
                arrow ? "more-links-box-show" : ""
              } `}
            >
              <Link to={menuLinks.intermediarios365}>Intermediarios 365</Link>
              <a
                target="_blank"
                href="https://app.segurosreservas.com/portalautorizacion/#/autorizaciones/login"
                rel="noreferrer"
              >
                Autorizaciones Salud PSS
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="hidden bottom-nav lg:block">
        <div className="container mx-auto">
          <div className="flex items-center justify-around bottom-nav__links">
            {renderLinks()}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
