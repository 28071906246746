import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { modalType } from "../MenuLandingsLayout/types/menuLandingsTypes";
import "./modal.css";
import closeX from "../../assets/img/icons/side-menu-x.svg";

const ModalComponentResponsibility = ({
  closeModal,
  isOpen,
  children,
}: modalType) => {
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              /*       enter="ease-out duration-200" */
              enterFrom="opacity-0"
              /*    leave="ease-in duration-100" */
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-70" />
            </Transition.Child>

            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              /* enter="ease-out duration-200" */
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              /*   leave="ease-in duration-100" */
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                className={`modal-fix inline-block w-full  p-6 lg:p-0 text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl`}
              >
                <img
                  src={closeX}
                  alt="close btn"
                  className="modal-close-btn"
                  onClick={closeModal}
                />
                {children}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default ModalComponentResponsibility;
