import "./css/howToModal.css";

type ModalTextsType = {
  title: string;
  text: string;
  title2: string;
  footer: string;
};

type ModalPasosType = {
  id: number;
  image: string;
  imagePosition: string;
  info: string;
  category: string;
};

interface HowToModalProps {
  modalPasos: ModalPasosType[];
  modalTexts: ModalTextsType;
}

const HowToModal = ({ modalPasos, modalTexts }: HowToModalProps) => {
  return (
    <div>
      <div className="how-modal">
        <div className="how-modal__titlebox">
          <h1 className="how-modal__title">{modalTexts.title}</h1>
          <div className="lg:w-2/5">
            <p
              className="how-modal__text"
              dangerouslySetInnerHTML={{ __html: modalTexts.text }}
            ></p>
          </div>
        </div>
        <div>
          {modalPasos
            .filter((data) => data.category === "Parte1")
            .map((element) => {
              if (element.imagePosition === "right") {
                return (
                  <div className="lg:flex lg:justify-between lg:items-center step-box xl:-mt-11">
                    <div
                      className="how-modal__info lg:w-2/5"
                      dangerouslySetInnerHTML={{ __html: element.info }}
                    ></div>
                    <div className="hidden lg:w-3/5 lg:block">
                      <img src={element.image} alt="step image" />
                    </div>
                  </div>
                );
              } else if (element.imagePosition === "left") {
                return (
                  <div className=" lg:flex lg:justify-between lg:items-center step-box xl:-mt-11">
                    <div
                      className="how-modal__info lg:w-2/5"
                      dangerouslySetInnerHTML={{ __html: element.info }}
                    ></div>
                    <div className="hidden lg:w-3/5 lg:order-first lg:block">
                      <img src={element.image} alt="step image" />
                    </div>
                  </div>
                );
              } else {
                return (
                  <div className="step-box">
                    <div
                      className="how-modal__info"
                      dangerouslySetInnerHTML={{ __html: element.info }}
                    ></div>
                    <img
                      src={element.image}
                      className="hidden lg:block"
                      alt="step image"
                    />
                  </div>
                );
              }
            })}
        </div>

        <div className="how-modal__titlebox my-9">
          <h1 className="how-modal__title">{modalTexts.title2}</h1>
        </div>
        <div>
          {modalPasos
            .filter((data) => data.category === "Parte2")
            .map((element) => {
              if (element.imagePosition === "right") {
                return (
                  <div className="lg:flex lg:justify-between lg:items-center step-box">
                    <div
                      className="how-modal__info lg:w-2/5"
                      dangerouslySetInnerHTML={{ __html: element.info }}
                    ></div>
                    <div className="hidden lg:w-3/5 lg:block">
                      <img src={element.image} alt="step image" />
                    </div>
                  </div>
                );
              } else if (element.imagePosition === "left") {
                return (
                  <div className=" lg:flex lg:justify-between lg:items-center step-box">
                    <div
                      className="how-modal__info lg:w-2/5"
                      dangerouslySetInnerHTML={{ __html: element.info }}
                    ></div>
                    <div className="hidden lg:w-3/5 lg:order-first lg:block">
                      <img src={element.image} alt="step image" />
                    </div>
                  </div>
                );
              } else {
                return (
                  <div className="step-box">
                    <div
                      className="how-modal__info "
                      dangerouslySetInnerHTML={{ __html: element.info }}
                    ></div>
                    <img
                      className="hidden lg:block"
                      src={element.image}
                      alt="step image"
                    />
                  </div>
                );
              }
            })}
        </div>
        <div>
          <div
            className="footer-text lg:justify-center lg:flex"
            dangerouslySetInnerHTML={{ __html: modalTexts.footer }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default HowToModal;
