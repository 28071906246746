import axios from "axios";
import React, { useEffect, useState } from "react";
import { BASE_URL, GET_HEADERS } from "../../components/Helper/requestData";

export const ClaimsPeopleDataContext = React.createContext({
  modalTexts: {
    title: "",
    text: "",
    title2: "",
    footer: "",
  },
  modalPasos: [
    {
      id: 1,
      image: "",
      imagePosition: "",
      info: "",
      category: "",
    },
  ],
});

type ClaimsPeopleDataProps = {
  children?: React.ReactNode;
};

function ClaimsPeopleData({ children }: ClaimsPeopleDataProps) {
  const [modalTexts, setModalTexts] = useState({
    title: "",
    text: "",
    title2: "",
    footer: "",
  });
  const [modalPasos, setModalPasos] = useState([
    {
      id: 1,
      image: "",
      imagePosition: "",
      info: "",
      category: "",
    },
  ]);

  const getModalTexts = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Reclamaciones-Modal-Texts')/items?$filter=Category eq 'Personas'",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    setModalTexts({
      title: resultsArr[0]?.Title,
      text: resultsArr[0]?.Text,
      title2: resultsArr[0]?.Title2,
      footer: resultsArr[0]?.FooterText,
    });
  };

  const getModalPasos = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Reclamaciones-Modal-Pasos')/items",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    setModalPasos(
      resultsArr.map((item: any) => {
        return {
          id: item?.Order0,
          image: item?.Image?.Url,
          imagePosition: item?.ImagePosition,
          info: item?.Info,
          category: item?.Category,
        };
      })
    );
  };

  useEffect(() => {
    getModalTexts();
    getModalPasos();
  }, []);

  return (
    <ClaimsPeopleDataContext.Provider value={{ modalTexts, modalPasos }}>
      {children}
    </ClaimsPeopleDataContext.Provider>
  );
}

export default ClaimsPeopleData;
