import { Link } from "react-router-dom";
import { subMenuListType } from "./types";

type secondarySubMenuProp = {
  subMenu: subMenuListType[] | null | undefined;
  showSubMenu: boolean;
  parentList: string;
  externalLink?: string;
};

const SecondarySubMenuMobile = ({
  subMenu,
  showSubMenu,
  parentList,
}: secondarySubMenuProp) => {
  function renderSubMenu() {
    if (subMenu[0].parentMenu === parentList) {
      return (
        <li className={`md:hidden ${showSubMenu ? "flex" : "hidden"}`}>
          <ul className="secondary-sub-menu-mobile">
            {subMenu.map((subList) => {
              if (subList.externalLink) {
                return (
                  <a
                    href={subList.externalLink}
                    key={subList.id}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <li>{subList.title}</li>
                  </a>
                );
              }
              return (
                <Link to={subList.link} key={subList.id}>
                  <li>{subList.title}</li>
                </Link>
              );
            })}
          </ul>
        </li>
      );
    }
    return null;
  }
  return <>{subMenu && renderSubMenu()}</>;
};

export default SecondarySubMenuMobile;
