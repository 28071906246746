import "./css/Responsibility.css";

type ResponsibilityCardsProps = {
  image?: string;
  text?: string;
};

function ResponsibilityCardsCreate({ image, text }: ResponsibilityCardsProps) {
  return (
    <div className="respo-card">
      <img src={image} alt={text} />
      <h2>{text}</h2>
    </div>
  );
}

const ResponsibilityCards = ({ pilars }: any) => {
  return (
    <div className="my-12 md:grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 md:gap-10">
      {pilars.map((card, i) => (
        <ResponsibilityCardsCreate
          key={i}
          image={card.image}
          text={card.text}
        />
      ))}
    </div>
  );
};

export default ResponsibilityCards;
