import axios from "axios";
import React, { useEffect, useState } from "react";
import { BASE_URL, GET_HEADERS } from "../../components/Helper/requestData";

export const ClaimsVehicleDataContext = React.createContext({
  videoData: {
    videoLink: "",
    posterImage: "",
  },
  modalTexts: {
    title: "",
    text: "",
    title2: "",
    footer: "",
  },
  modalPasos: [
    {
      id: 1,
      image: "",
      imagePosition: "",
      info: "",
      category: "",
    },
  ],
  channelsCardsInfo: [],
});

type ClaimsVehicleDataProps = {
  children?: React.ReactNode;
};

function ClaimsVehicleData({ children }: ClaimsVehicleDataProps) {
  const [videoData, setVideoData] = useState({
    videoLink: "",
    posterImage: "",
  });
  const [modalTexts, setModalTexts] = useState({
    title: "",
    text: "",
    title2: "",
    footer: "",
  });
  const [modalPasos, setModalPasos] = useState([
    {
      id: 1,
      image: "",
      imagePosition: "",
      info: "",
      category: "",
    },
  ]);
  const [channelsCardsInfo, setChannelsCardsInfo] = useState([]);

  const getChannelsCardsInfo = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Reclamaciones-Canales-Cards')/items?$filter=Category eq 'Vehículos'&$Select=*,Producto/Title&$expand=Producto/Title",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    setChannelsCardsInfo(
      resultsArr.map((item: any) => {
        return {
          cardId: item?.Order0,
          cardImg: item?.CardImg?.Url,
          cardTitle: item?.Title,
          content: item?.Content,
          linkText1: item?.LinkText1,
          linkText2: item?.LinkText2,
          link3: item?.Link3,
          modalTitle: item?.ModalTitle,
          modalContent: item?.ModalContent,
          producto: item?.Producto?.Title,
          hasVideo: item?.HasVideo,
          modal2Image: item?.Modal2Image,
        };
      })
    );
  };

  const getVideoData = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Video-Data')/items?$filter=Title eq 'Reclamaciones Seguros de Vehículos'",
        GET_HEADERS
      )
      .then((response) => response.data.value[0])
      .catch((e) => console.error(e));

    setVideoData({
      videoLink: resultsArr?.VideoLink?.Url,
      posterImage: resultsArr?.PosterImage.Url,
    });
  };

  const getModalTexts = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Reclamaciones-Modal-Texts')/items?$filter=Category eq 'Vehículos'",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    setModalTexts({
      title: resultsArr[0]?.Title,
      text: resultsArr[0]?.Text,
      title2: resultsArr[0]?.Title2,
      footer: resultsArr[0]?.FooterText,
    });
  };

  const getModalPasos = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Reclamaciones-Modal-Pasos')/items",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    setModalPasos(
      resultsArr.map((item: any) => {
        return {
          id: item?.Order0,
          image: item?.Image?.Url,
          imagePosition: item?.ImagePosition,
          info: item?.Info,
          category: item?.Category,
        };
      })
    );
  };

  useEffect(() => {
    getVideoData();
    getChannelsCardsInfo();
    getModalTexts();
    getModalPasos();
  }, []);

  return (
    <ClaimsVehicleDataContext.Provider
      value={{ videoData, modalTexts, modalPasos, channelsCardsInfo }}
    >
      {children}
    </ClaimsVehicleDataContext.Provider>
  );
}

export default ClaimsVehicleData;
