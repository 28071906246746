import { Splide, SplideSlide } from "@splidejs/react-splide";

import CardReinsuranceValue from "./CardReinsuranceValue";
import { Key } from "react";
import arrow from "../../assets/img/icons/chevron-dark-gray.svg";

function ReinsuranceCards({ cards, showCards }: any) {
  return (
    <div className="container flex flex-col mx-auto cards-container lg:grid lg:grid-cols-5 md:gap-3 channels-card-desktop">
      {cards?.map((card: { cardId: Key; cardImg: any; cardImgHover: any }) => (
        <div
          key={card.cardId}
          className={`${showCards ? "block" : "hidden"} lg:flex`}
        >
          <CardReinsuranceValue
            cardImg={card.cardImg}
            cardImgHover={card.cardImgHover}
            isSlide={false}
          />
        </div>
      ))}

      <div
        className={`${showCards ? "hidden" : "block self-center"} lg:hidden`}
      >
        <Splide
          renderControls={() => (
            <div className="splide__arrows">
              <div className="container ">
                <button
                  type="button"
                  className="splide__arrow splide__arrow--next "
                >
                  <img src={arrow} alt="arrow icon" />
                </button>
                <button
                  type="button"
                  className="splide__arrow splide__arrow--prev "
                >
                  <img src={arrow} alt="arrow icon" className="left-arrow " />
                </button>
              </div>
            </div>
          )}
        >
          {cards?.map((card) => (
            <SplideSlide key={card.cardId}>
              <>
                <CardReinsuranceValue
                  cardImg={card.cardImg}
                  cardImgHover={card.cardImgHover}
                  isSlide={true}
                />
              </>
            </SplideSlide>
          ))}
        </Splide>
      </div>
    </div>
  );
}

export default ReinsuranceCards;
