import { NewsStructure } from "../../../components/NewsComponents/news.model";

export const newsMockHero = {
  title: `<h2>Noticias y</h2> 
    <h3> Novedades</h3>`,
  bannerImage: "/img/news/banner-news.png",
};

export const newsFilterViewMock = [
  {
    filterPlaceHolder: "Filtrar por:",
    dateOptionOne: "De reciente a más antigua",
    dateOptionTwo: "De antigua a más reciente",
  },
  {
    viewTitle: "Vista:",
    listViewIcon: "img/news/icons/menu-list-icon.png",
    groupViewIcon: "img/news/icons/menu-group-icon.png",
  },
  {},
];

export const newsList: NewsStructure[] = [
  {
    id: 1,
    title: "Seguros Reservas agradece por sus 20 años de servicios",
    img: "img/news/news-1.png",
    date: "03/18/2022",
    linkText: "Leer el artículo completo",
    content: `<p>La compañía se ha caracterizado por su excelencia y calidad humana, además de estar comprometidos con el bienestar social y económico del país.</p><p>Seguros Reservas arribó a sus 20 años de servicios, con una misa de acción de gracias en la Iglesia del Convento Regina Angelorum, oficiada por Monseñor Benito Ángeles Fernández, y que fue presidida por Samuel Pereyra, Administrador General del Banco de Reservas y Presidente del Consejo de Administración, junto a Víctor Rojas, Vicepresidente Ejecutivo en la empresa aseguradora.</p>
    <p>Rojas, quien tuvo a su cargo las palabras centrales del acto, expresó que: “Celebramos 20 años de trayectoria de una gran familia que cada día se compromete a trabajar y a unificar esfuerzos, para seguir aportando nuevos aires de esperanza y continuar haciendo de Seguros Reservas ese lugar que respalda con soluciones con calidad, innovación y seguridad en el mercado asegurador local e internacional”. </p>
    <p>  Asimismo, destacó que el principal sustento de la empresa es la eficiencia operativa y una acertada visión de futuro, la cual ha superado de manera continua los indicadores de rentabilidad, servicio y gestión, encaminándose hacia una nueva era de transformación tecnológica, operativa, comercial y sobre todo organizacional.</p>
    <p> La eucaristía contó con la asistencia de los principales ejecutivos de Seguros Reservas, directivos del Banco de Reservas y filiales de la familia Reservas, así como representantes de entidades gubernamentales, corredores y aliados del sector asegurador.</p>
    <h4> Reseña sobre Seguros Reservas</h4>
    <p>Seguros Reservas fue constituido el 18 de octubre del 2001, iniciando sus operaciones el 5 de marzo del 2002 en una oficina ubicada en la Av. Gustavo Mejía Ricart esquina Lope de Vega en Santo Domingo, con pocos empleados. Al día de hoy, cuenta con una amplia red de sucursales y centros de atención compuesta por 12 oficinas que están ubicados estratégicamente en todo el territorio nacional. Además, cuenta con más de 700 colaboradores en sus distintas oficinas, que cuentan con una vasta experiencia en la industria del seguro</p>
    <p> La aseguradora ha afianzado su exitosa trayectoria evidenciada en su fortaleza, fruto de una extraordinaria eficiencia corporativa y competitividad comercial.</p>
    <p> Estos avances han permitido contribuir en el desarrollo y crecimiento financiero del país, al servir de respaldo a una cartera de clientes, compuesta en el 76% por asegurados provenientes del sector privado.</p>
    <p> Su compromiso es seguir superando las expectativas de sus asegurados de manera constante, impulsado por la visión de un equipo que busca ser cada vez más competitivo, apoyado en la capacitación continua de los colaboradores, actualización permanente de las facilidades tecnológicas, así como la inclusión en la comunidad en todas sus etapas de evolución.</p>
    `,
    topNews: true,
    text: "",
  },
  {
    id: 2,
    title:
      "Seguros Reservas celebra XX años de historia, innovación y experiencia",
    img: "img/news/news-2.png",
    linkText: "Leer el artículo completo",
    date: "04/18/2022",
    content: `<p>Desde su constitución, la empresa aseguradora ha afianzado su exitosa trayectoria evidenciada en su fortaleza, fruto de una extraordinaria eficiencia corporativa y competitividad comercial.</p>
   <p> Los ejecutivos de Seguros Reservas Lorem ipsum dolor sit amet consectetur, adipisicing elit. Voluptas, rem quaerat quod aut consequatur necessitatibus.Lorem ipsum dolor sit amet consectetur, adipisicing elit. Voluptas, rem quaerat quod aut consequatur necessitatibus.Lorem ipsum dolor sit amet consectetur, adipisicing elit.</p>
   <p>Voluptas, rem quaerat quod aut consequatur necessitatibus.Lorem ipsum dolor sit amet consectetur, adipisicing elit. Voluptas, rem quaerat quod aut consequatur necessitatibus.</p>
    `,
    topNews: false,
    text: "",
  },
  {
    id: 3,
    title:
      "Cleveland Clinic, nueva alianza para los asegurados de Seguros Reservas",
    img: "img/news/news-3.png",
    date: "04/20/2022",
    linkText: "Leer el artículo completo",
    content: `<p>Seguros Reservas firmó un acuerdo con Cleveland Clinic. Cleveland Clinic ha sido reconocido como el hospital número 2 en el mundo por el ranking de Newsweek Worlds's Best Hospitals 2021 .</p>
    <p>Seguros Reservas amplía su red de prestadores internacionales al firmar un acuerdo con Cleveland ClinicVoluptas, rem quaerat quod aut consequatur necessitatibus.Lorem ipsum dolor sit amet consectetur, adipisicing elit. Voluptas, rem quaerat quod aut consequatur necessitatibus,</p>
    `,
    topNews: false,
    text: "",
  },
  {
    id: 4,
    title: "Seguros Reservas y Hospital Plaza de la Salud acuerdan alianza ",
    img: "img/news/news-4.png",
    date: "03/19/2022",
    linkText: "Leer el artículo completo",
    content: `<p>Seguros Reservas y el Hospital General de la Plaza de la Salud firmaron un convenio de cooperación que pondrá a disposición de los asegurados del seguro médico internacional con cobertura local Just.</p>
    <p>El acuerdo fue suscrito por el Vicepresidente Ejecutivo de Seguros Reservas, Víctor J. Rojas, y el…Voluptas, rem quaerat quod aut consequatur necessitatibus.Lorem ipsum dolor sit amet consectetur, adipisicing elit. Voluptas, rem quaerat quod aut consequatur necessitatibus,</p>
    `,
    topNews: false,
    text: "",
  },
  {
    id: 5,
    title:
      "Broward Health se convierte en el nuevo prestador de servicios de salud de Seguros Reservas",
    img: "img/news/news-5.png",
    date: "04/01/2022",
    linkText: "Leer el artículo completo",
    content: `<p>Seguros Reservas y el Hospital General de la Plaza de la Salud firmaron un convenio de cooperación que pondrá a disposición de los asegurados del seguro médico internacional con cobertura local Just .</p>
    <p>Broward Health, uno de los diez sistemas de hospitales públicos más grandes de EE. UU., se convierte en el nuevo proveedor de servicios de salud de Seguros Reservas para los asegurados de seguros médicos internacionales de JUST.,</p>
    `,
    topNews: false,
    text: "",
  },
  {
    id: 6,
    title:
      "Seguros Reservas logra su mayor desempeño en el último año, un incremento de un 16%",
    img: "img/news/news-6.png",
    date: "06/16/2021",
    linkText: "Leer el artículo completo",
    content: `<p>La revista especializada Global Finance reconoció al Banco de Reservas, Banreservas, como Mejor Banco.</p>
   
   <p>Voluptas, rem quaerat quod aut consequatur necessitatibus.Lorem ipsum dolor sit amet consectetur, adipisicing elit. Voluptas, rem quaerat quod aut consequatur necessitatibus.</p>
    `,
    topNews: false,
    text: "",
  },
  {
    id: 7,
    title:
      "Seguros Reservas firma acuerdos de colaboración con empresas españolas",
    img: "img/news/news-7.png",
    date: "06/16/2021",
    linkText: "Leer el artículo completo",
    content: `<p>Seguros Reservas formalizó dos acuerdos estratégicos con empresas españolas una vinculada al área de la salud y la…</p>
      
    <p>Voluptas, rem quaerat quod aut consequatur necessitatibus.Lorem ipsum dolor sit amet consectetur, adipisicing elit. Voluptas, rem quaerat quod aut consequatur necessitatibus.</p>
    `,
    topNews: false,
    text: "",
  },
  {
    id: 8,
    title: "Seguros Reservas presenta su nueva modalidad “Vía Reservas”",
    img: "img/news/news-8.png",
    date: "03/19/2022",
    linkText: "Leer el artículo completo",
    content: `<p>El seguro vehículos busca optimiza la movilidad urbana y promover una mayor responsabilidad al volante.</p>
    <p>El acuerdo fue suscrito por el Vicepresidente Ejecutivo de Seguros Reservas, Víctor J. Rojas, y el…Voluptas, rem quaerat quod aut consequatur necessitatibus.Lorem ipsum dolor sit amet consectetur, adipisicing elit. Voluptas, rem quaerat quod aut consequatur necessitatibus,</p>
    `,
    topNews: false,
    text: "",
  },
  {
    id: 9,
    title:
      "Seguros Reservas logra su mayor desempeño en el último año, un incremento de un 16%",
    img: "img/news/news-6.png",
    date: "06/16/2021",
    linkText: "Leer el artículo completo",
    content: `<p>La revista especializada Global Finance reconoció al Banco de Reservas, Banreservas, como Mejor Banco .</p>
   
   <p>Voluptas, rem quaerat quod aut consequatur necessitatibus.Lorem ipsum dolor sit amet consectetur, adipisicing elit. Voluptas, rem quaerat quod aut consequatur necessitatibus.</p>
    `,
    topNews: false,
    text: "",
  },
];
