import React from "react";
import "./css/filterViewContainer.css";
import { SelectedViewEnum, SortNewsEnum } from "./news.enum";
import FilterComponent from "./FilterComponent";
import listViewIcon from "../../assets/img/icons/menu-list-icon.png";
import groupViewIcon from "../../assets/img/icons/menu-group-icon.png";
type filterProps = {
  setFilterSort: React.Dispatch<React.SetStateAction<SortNewsEnum>>;
  selectedView: SelectedViewEnum | string;
  setSelectedView: React.Dispatch<React.SetStateAction<string>>;
  viewTitle: string;
  filterPlaceholder: string;
};

const FilterViewContainer = ({
  setFilterSort,
  selectedView,
  setSelectedView,
  viewTitle,
  filterPlaceholder,
}: filterProps) => {
  const handleView = (view: string) => {
    setSelectedView(view);
  };
  return (
    <div className="lg:flex lg:justify-between lg:items-center filter-view mt-6">
      <FilterComponent
        setFilterSort={setFilterSort}
        filterPlaceholder={filterPlaceholder}
      />
      <div className="view-container hidden lg:flex lg:justify-between lg:items-center ">
        <h3>{viewTitle}</h3>
        <div
          onClick={() => handleView(SelectedViewEnum.listView)}
          className={`btn-view ${
            selectedView === SelectedViewEnum.listView && "selected-view"
          }`}
        >
          <img className="view-icon" src={listViewIcon} alt="list view icon" />
        </div>
        <div
          onClick={() => handleView(SelectedViewEnum.groupView)}
          className={`btn-view ${
            selectedView === SelectedViewEnum.groupView && "selected-view"
          }`}
        >
          <img
            className="view-icon"
            src={groupViewIcon}
            alt="group view icon"
          />
        </div>
      </div>
    </div>
  );
};

export default FilterViewContainer;
