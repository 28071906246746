import { Link } from "react-router-dom";
import { subMenuListType } from "./types";

type secondarySubMenuProp = {
  subMenu: subMenuListType[] | null | undefined;
  showSubMenu: boolean;
};

const SecondarySubMenu = ({ subMenu, showSubMenu }: secondarySubMenuProp) => {
  return (
    <div
      className={`secondary-sub-menu p-0 mt-0 ${
        showSubMenu ? "secondary-sub-menu-show" : "hidden"
      }`}
    >
      <ul>
        {subMenu?.map((list: subMenuListType) =>
          list.externalLink ? (
            <a
              href={list?.externalLink}
              key={list.id}
              target="_blank"
              rel="noreferrer"
            >
              <li className="sub-menu-li">{list?.title}</li>
            </a>
          ) : (
            <Link to={list?.link} key={list?.id}>
              <li className="sub-menu-li">{list?.title}</li>
            </Link>
          )
        )}
      </ul>
    </div>
  );
};

export default SecondarySubMenu;
