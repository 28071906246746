import { useContext, useEffect, useState } from "react";
import InformationComponent from "../../components/InformationComponent/InformationComponent";
import HeroMenuComponent from "../../components/MenuLandingsLayout/HeroMenuComponent";
import SecondaryNavBar from "../../components/secondaryNavBar/SecondaryNavBar";
import SideBar from "../../components/sideBar/SideBar";
import SideBarList from "../../components/sideBar/SideBarList";
import VideoContainer from "../../components/videoContainer/VideoContainer";
import AppDownloadComponent from "../../components/appDownloadComponent/AppDownloadComponent";
import axios from "axios";
import { BASE_URL, GET_HEADERS } from "../../components/Helper/requestData";
import { ServicesChannelsMenuContext } from "../../context/ServicesChannels/ServicesChannelsMenuContext";
import { SideListModel } from "../../context/models/SideList.model";

const Clientes365 = () => {
  const [heroSideData, setHeroSideData] = useState({
    title: "",
    titleRich: "",
    bannerImage: "",
    secondaryTitle: "",
    sideTitle1: "",
    sideContent1: "",
    sideTitle2: "",
    sideContent2: "",
    content: "",
    contentImage: "",
  });

  const [secondaryNavBarButtons, setSecondaryNavBarButtons] = useState({
    btnLinkCotizar: "",
    btnImgCotizar: "",
    btnTitleCotizar: "",
    btnLinkBrochure: "",
    btnImgBrochure: "",
    btnTitleBrochure: "",
  });

  const [sideList, setSideList] = useState<SideListModel[]>([]);

  const [additionalInfo, setAdditionalInfo] = useState({
    videoLink: "",
    posterImage: "",
  });

  const { menuListData } = useContext(ServicesChannelsMenuContext);

  const getSectionContentData = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Contenido-de-Secciones')/items?$filter=Title eq 'Clientes 365'",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    setHeroSideData({
      titleRich: resultsArr[0]?.TitleRich,
      title: resultsArr[0]?.Title,
      bannerImage: resultsArr[0]?.BannerImage.Url,
      contentImage: resultsArr[0]?.ContentImage.Url,
      content: resultsArr[0]?.Content,
      secondaryTitle: resultsArr[0]?.SecondaryTitle,
      sideTitle1: resultsArr[0]?.SideTitle1,
      sideContent1: resultsArr[0]?.SideContent1,
      sideTitle2: resultsArr[0]?.SideTitle2,
      sideContent2: resultsArr[0]?.SideContent2,
    });
  };

  const getSecondaryNavBarButtons = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Secondary-NavBar-Buttons')/items",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    const result = resultsArr.find((r: any) => r.Title === "Clientes 365");

    setSecondaryNavBarButtons({
      btnLinkCotizar: result?.BtnLinkCotizar?.Description,
      btnImgCotizar: result?.BtnImgCotizar.Url,
      btnTitleCotizar: result?.BtnTitleCotizar,
      btnLinkBrochure: result?.BtnLinkBrochure?.Description,
      btnImgBrochure: result?.BtnImgBrochure.Url,
      btnTitleBrochure: result?.BtnTitleBrochure,
    });
  };

  const getSideList = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Seguros-Servicios-SideLists')/items?$filter=Categoria eq 'Clientes 365'",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    resultsArr.sort(
      (a: { Order0: number }, b: { Order0: number }) => a.Order0 - b.Order0
    );

    setSideList(
      resultsArr.map((item: any) => {
        return {
          title: item?.Text,
          id: item?.Order0,
        };
      })
    );
  };

  const getAdditionalInfo = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Video-Data')/items?$filter=Title eq 'Clientes 365'",
        GET_HEADERS
      )
      .then((response) => response.data.value[0])
      .catch((e) => console.error(e));

    setAdditionalInfo({
      videoLink: resultsArr?.VideoLink?.Url,
      posterImage: resultsArr?.PosterImage.Url,
    });
  };

  useEffect(() => {
    getSectionContentData();
    getSecondaryNavBarButtons();
    getSideList();
    getAdditionalInfo();
  }, []);
  return (
    <>
      <HeroMenuComponent
        bannerImage={heroSideData.bannerImage}
        title={heroSideData.titleRich}
      />
      <SecondaryNavBar
        title={heroSideData.secondaryTitle || heroSideData.titleRich}
        menuList={menuListData}
        subMenuList={[]}
        btnLinkCotizar={secondaryNavBarButtons.btnLinkCotizar}
        btnImgCotizar={secondaryNavBarButtons.btnImgCotizar}
        btnTitleCotizar={secondaryNavBarButtons.btnTitleCotizar}
        btnLinkBrochure={secondaryNavBarButtons.btnLinkBrochure}
        btnImgBrochure={secondaryNavBarButtons.btnImgBrochure}
        btnTitleBrochure={secondaryNavBarButtons.btnTitleBrochure}
      />

      <div className="container mx-auto lg:flex lg:justify-between md:mb-16 ">
        <div className="lg:w-1/4">
          <img
            className="w-40 lg:w-auto"
            src={heroSideData.contentImage}
            alt="cel clientes preview image"
          />
        </div>
        <div className="lg:w-1/3">
          <InformationComponent content={heroSideData.content} />
          <div className="mt-10 lg:mt-10 lg:w-4/4">
            {heroSideData.title && (
              <AppDownloadComponent category={heroSideData.title} />
            )}
          </div>
        </div>
        <div className="mt-10 lg:mt-14 lg:w-1/3 lg:pb-0">
          <SideBar>
            {heroSideData.sideTitle1 && (
              <SideBarList
                sideTitle={heroSideData.sideTitle1}
                sideBarList={sideList}
              />
            )}
          </SideBar>
        </div>
      </div>
      <div className="container mx-auto my-10 lg:mt-12 lg:mb-20 xl:mb-28">
        <VideoContainer
          videoLink={additionalInfo.videoLink}
          posterImage={additionalInfo.posterImage}
        />
      </div>
    </>
  );
};

export default Clientes365;
