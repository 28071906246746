import axios from "axios";
import React, { useEffect, useState } from "react";
import { BASE_URL, GET_HEADERS } from "../../components/Helper/requestData";

export const MultipleMenuContext = React.createContext({
  menuListData: [],
});

type MultipleMenuProps = {
  children?: React.ReactNode;
};

function MultipleMenu({ children }: MultipleMenuProps) {
  const [menuListData, setMenuListData] = useState([
    {
      id: 0,
      title: "",
      link: "",
      externalLink: "",
      hasSubMenu: false,
    },
  ]);

  const getMenuListData = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL + "_api/web/lists/getbytitle('Multiples-Menu-List')/items",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    const results = resultsArr.sort((a, b) => a.ItemId - b.ItemId);

    setMenuListData(
      results.map((item: any) => {
        return {
          id: item?.ItemId,
          title: item?.Title,
          link: item?.Link?.Description,
          externalLink: item?.ExternalLink?.Url,
          hasSubMenu: item?.HasSubMenu,
        };
      })
    );
  };

  useEffect(() => {
    getMenuListData();
  }, []);

  return (
    <MultipleMenuContext.Provider value={{ menuListData }}>
      {children}
    </MultipleMenuContext.Provider>
  );
}

export default MultipleMenu;
