import { useContext, useEffect, useState } from "react";
import HeroMenuComponent from "../../components/MenuLandingsLayout/HeroMenuComponent";
import SecondaryNavBar from "../../components/secondaryNavBar/SecondaryNavBar";
import ClaimsContent from "../../components/ClaimsContent/ClaimsContent";
import ClaimsContentCard from "../../components/ClaimsContent/ClaimsContentCard";
import ProductsContainer from "../../components/MenuLandingsLayout/ProductsContainer";
import CardsContainerClaims from "../../components/MenuLandingsLayout/CardsContainerClaims";
import { BASE_URL, GET_HEADERS } from "../../components/Helper/requestData";
import axios from "axios";
import { ClaimsMenuContext } from "../../context/Claims/ClaimsMenuContext";

const ClaimsLanding = () => {
  const [showCards, setShowCards] = useState(false);
  const [heroSideData, setHeroSideData] = useState({
    title: "",
    titleRich: "",
    bannerImage: "",
    secondaryTitle: "",
    sideTitle1: "",
    sideTitle2: "",
    content: "",
  });

  const [claimsCards, setClaimsCards] = useState([
    {
      image: "",
      imageOnHover: "",
      title: "",
      link: "",
    },
  ]);
  const [attentionCards, setAttentionCards] = useState([
    {
      cardId: 0,
      cardImg: "",
      cardTitle: "",
      content: "",
      link: null,
      linkType: "",
    },
  ]);

  const { menuListData } = useContext(ClaimsMenuContext);

  const getSectionContentData = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Contenido-de-Secciones')/items?$filter=Title eq 'Reclamaciones'",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    setHeroSideData({
      titleRich: resultsArr[0]?.TitleRich,
      title: resultsArr[0]?.Title,
      bannerImage: resultsArr[0]?.BannerImage?.Url,
      content: resultsArr[0]?.Content,
      secondaryTitle: resultsArr[0]?.SecondaryTitle,
      sideTitle1: resultsArr[0]?.SideTitle1,
      sideTitle2: resultsArr[0]?.SideTitle2,
    });
  };

  const getClaimsCards = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL + "_api/web/lists/getbytitle('Reclamaciones-Cards')/items",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    setClaimsCards(
      resultsArr.map((item: any) => {
        return {
          title: item?.Text,
          link: item?.Link?.Description,
          image: item?.Image?.Url,
          imageOnHover: item?.ImageOnHover?.Url,
        };
      })
    );
  };

  const getAttentionCards = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Canales-de-Atencion-Cards')/items",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    setAttentionCards(
      resultsArr.map((item: any) => {
        return {
          cardId: item?.Order,
          cardTitle: item?.Title,
          cardImg: item?.CardImg?.Url,
          content: item?.Content,
          link: item?.Link,
          linkType: item?.LinkType,
        };
      })
    );
  };

  useEffect(() => {
    getSectionContentData();
    getClaimsCards();
    getAttentionCards();
  }, []);
  return (
    <>
      <HeroMenuComponent
        bannerImage={heroSideData.bannerImage}
        title={heroSideData.titleRich}
      />
      <SecondaryNavBar
        title={heroSideData.secondaryTitle || heroSideData.titleRich}
        menuList={menuListData}
        subMenuList={[]}
      />
      <div className="container mx-auto md:mb-16 ">
        <div className="w-full mt-10 lg:mt-20 lg:mb-20">
          <ClaimsContent content={heroSideData.content} />
        </div>
        <div className="w-full pb-20 mt-11 claims-card-box md:mx-auto lg:pb-0">
          <ClaimsContentCard claimsCards={claimsCards} />
        </div>
      </div>

      <div className="desktop-slide-fix">
        <ProductsContainer
          title={heroSideData.sideTitle1}
          subTitle={heroSideData.sideTitle2}
          showCards={showCards}
          setShowCards={setShowCards}
        >
          <CardsContainerClaims showCards={showCards} cards={attentionCards} />
        </ProductsContainer>
      </div>
    </>
  );
};

export default ClaimsLanding;
