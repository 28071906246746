import { useContext, useEffect, useState } from "react";
import InformationComponent from "../../components/InformationComponent/InformationComponent";
import HeroMenuComponent from "../../components/MenuLandingsLayout/HeroMenuComponent";
import SecondaryNavBar from "../../components/secondaryNavBar/SecondaryNavBar";
import ClaimsTabContent from "../../components/TabsClaims/ClaimsTabContent";
import { ClaimsMenuContext } from "../../context/Claims/ClaimsMenuContext";
import axios from "axios";
import { BASE_URL, GET_HEADERS } from "../../components/Helper/requestData";
function ClaimsProperties() {
  const [heroSideData, setHeroSideData] = useState({
    title: "",
    titleRich: "",
    bannerImage: "",
    secondaryTitle: "",
    sideTitle1: "",
    sideTitle2: "",
    content: "",
    sideContent1: "",
  });

  // const [plansButtons, setPlansButtons] = useState({
  //   faqLink: "",
  //   faqTitle: "",
  //   faqIcon: "",
  // });

  const [tabsContent, setTabsContent] = useState([
    {
      id: 1,
      title: "",
      stepsTitle: "",
      stepsSubTitle: "",
      formBtnText: "",
      formBtnLink: "",
      docTitle: "",
      docSubTitle: "",
      docBtnText: "",
      docBtnContent: "",
    },
  ]);

  const [channelsCardsInfo, setChannelsCardsInfo] = useState([
    {
      cardId: 1,
      cardImg: "",
      cardTitle: "",
      content: "",
      linkText1: "",
      linkText2: "",
      link3: "",
      modalTitle: "",
      modalContent: "",
      producto: "",
    },
  ]);

  const { menuListData } = useContext(ClaimsMenuContext);

  const getSectionContentData = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Contenido-de-Secciones')/items?$filter=Title eq 'Reclamaciones Seguros de Propiedades'",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    setHeroSideData({
      titleRich: resultsArr[0]?.TitleRich,
      title: resultsArr[0]?.Title,
      bannerImage: resultsArr[0]?.BannerImage?.Url,
      content: resultsArr[0]?.Content,
      secondaryTitle: resultsArr[0]?.SecondaryTitle,
      sideTitle1: resultsArr[0]?.SideTitle1,
      sideTitle2: resultsArr[0]?.SideTitle2,
      sideContent1: resultsArr[0]?.SideContent1,
    });
  };

  // const getPlansButtons = async () => {
  //   const resultsArr = await axios
  //     .get(
  //       BASE_URL + "_api/web/lists/getbytitle('Bottom-Buttons')/items",
  //       GET_HEADERS
  //     )
  //     .then((response) => response.data.value)
  //     .catch((e) => console.error(e));

  //   const result = resultsArr.find(
  //     (r: any) => r.Title === "Reclamaciones Seguros de Propiedades"
  //   );

  //   setPlansButtons({
  //     faqLink: result?.FAQLink?.Description,
  //     faqIcon: result?.FAQIcon?.Url,
  //     faqTitle: result?.FAQTitle,
  //   });
  // };

  const getTabsContent = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Reclamaciones-Tabs-Contenido')/items?$filter=TabCategory eq 'Reclamaciones Seguros de Propiedades'",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    setTabsContent(
      resultsArr.map((item: any) => {
        return {
          id: item?.Order0,
          title: item?.Title,
          stepsTitle: item?.StepsTitle,
          stepsSubTitle: item?.StepsSubtitle,
          formBtnText: item?.FormBtn?.Description,
          formBtnLink: item?.FormBtn?.Url,
          docTitle: item?.DocTitle,
          docSubTitle: item?.DocSubtitle,
          docBtnText: item?.DocBtnText,
          docBtnContent: item?.DocBtnContent,
        };
      })
    );
  };

  const getChannelsCardsInfo = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Reclamaciones-Canales-Cards')/items?$filter=Category eq 'Propiedades'&$Select=*,Producto/Title&$expand=Producto/Title",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    setChannelsCardsInfo(
      resultsArr.map((item: any) => {
        return {
          cardId: item?.Order0,
          cardImg: item?.CardImg?.Url,
          cardTitle: item?.Title,
          content: item?.Content,
          linkText1: item?.LinkText1,
          linkText2: item?.LinkText2,
          link3: item?.Link3,
          modalTitle: item?.ModalTitle,
          modalContent: item?.ModalContent,
          producto: item?.Producto?.Title,
          hasVideo: item?.HasVideo,
          modal2Image: item?.Modal2Image,
        };
      })
    );
  };

  useEffect(() => {
    getSectionContentData();
    // getPlansButtons();
    getTabsContent();
    getChannelsCardsInfo();
  }, []);
  return (
    <>
      <HeroMenuComponent
        bannerImage={heroSideData.bannerImage}
        title={heroSideData.titleRich}
      />
      <SecondaryNavBar
        title={heroSideData.secondaryTitle || heroSideData.titleRich}
        menuList={menuListData}
        subMenuList={[]}
      />
      <div className="container mx-auto lg:flex lg:justify-between">
        <div className="lg:w-3/5">
          <InformationComponent content={heroSideData.content} />
        </div>
      </div>
      <div className="lg:mb-20">
        <ClaimsTabContent
          planList={tabsContent}
          // faqLink={plansButtons.faqLink}
          // faqIcon={plansButtons.faqIcon}
          // faqTitle={plansButtons.faqTitle}
          claimsCardSteps={channelsCardsInfo}
          channelsTitle={heroSideData.sideContent1}
          channelsText={heroSideData.sideTitle1}
        />
      </div>
    </>
  );
}

export default ClaimsProperties;
