import { useState, useEffect, useContext } from "react";
import axios from "axios";
import HeroMenuComponent from "../../components/MenuLandingsLayout/HeroMenuComponent";
import SecondaryNavBar from "../../components/secondaryNavBar/SecondaryNavBar";
import VehiclesSectionInformation from "../../components/VehiclesComponent/VehiclesSectionInformation";
import SideBar from "../../components/sideBar/SideBar";
import SideBarList from "../../components/sideBar/SideBarList";
import MoreInformationCard from "../../components/VariousCardsComponents/moreInfoCard/MoreInformationCard";
import TextButtonCardComponent from "../../components/VariousCardsComponents/btnCards/TextButtonCardComponent";
import VideoContainer from "../../components/videoContainer/VideoContainer";
import { BASE_URL, GET_HEADERS } from "../../components/Helper/requestData";
import { VehiclesMenuContext } from "../../context/Vehicles/VehiclesMenuContext";
import { SideListModel } from "../../context/models/SideList.model";

const AutoInspect = () => {
  const [heroSideData, setHeroSideData] = useState({
    title: "",
    titleRich: "",
    bannerImage: "",
    secondaryTitle: "",
    sideTitle1: "",
    sideContent1: "",
    sideTitle2: "",
    sideContent2: "",
    content: "",
  });

  const [plansButtons, setPlansButtons] = useState({
    whatsAppLink: "",
    whatsAppTitle: "",
    whatsAppIcon: "",
    extraInfo: "",
  });

  const [sideList, setSideList] = useState<SideListModel[]>([]);

  const [additionalInfo, setAdditionalInfo] = useState({
    videoLink: "",
    posterImage: "",
  });

  const { menuListData, subMenuListData } = useContext(VehiclesMenuContext);

  const getSectionContentData = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Contenido-de-Secciones')/items?$filter=Title eq 'Auto Inspección'",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    setHeroSideData({
      titleRich: resultsArr[0]?.TitleRich,
      title: resultsArr[0]?.Title,
      bannerImage: resultsArr[0]?.BannerImage.Url,
      secondaryTitle: resultsArr[0]?.SecondaryTitle,
      sideTitle1: resultsArr[0]?.SideTitle1,
      sideContent1: resultsArr[0]?.SideContent1,
      sideTitle2: resultsArr[0]?.SideTitle2,
      sideContent2: resultsArr[0]?.SideContent2,
      content: resultsArr[0]?.Content,
    });
  };
  const getPlansButtons = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL + "_api/web/lists/getbytitle('Bottom-Buttons')/items",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    const result = resultsArr.find((r: any) => r.Title === "Auto Inspección");

    setPlansButtons({
      whatsAppLink: result?.WhatsAppLink?.Description,
      whatsAppIcon: result?.WhatsAppIcon?.Url,
      whatsAppTitle: result?.WhatsAppTitle,
      extraInfo: result?.ExtraInfo,
    });
  };

  const getSideList = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Seguros-Servicios-SideLists')/items?$filter=Categoria eq 'Auto Inspección'",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    resultsArr.sort(
      (a: { Order0: number }, b: { Order0: number }) => a.Order0 - b.Order0
    );

    setSideList(
      resultsArr.map((item: any) => {
        return {
          title: item?.Text,
          id: item?.Order0,
        };
      })
    );
  };

  const getAdditionalInfo = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Video-Data')/items?$filter=Title eq 'Auto Inspección'",
        GET_HEADERS
      )
      .then((response) => response.data.value[0])
      .catch((e) => console.error(e));

    setAdditionalInfo({
      videoLink: resultsArr?.VideoLink?.Url,
      posterImage: resultsArr?.PosterImage.Url,
    });
  };

  useEffect(() => {
    getSectionContentData();
    getPlansButtons();
    getSideList();
    getAdditionalInfo();
  }, []);

  return (
    <>
      <HeroMenuComponent
        bannerImage={heroSideData.bannerImage}
        title={heroSideData.titleRich}
      />
      <SecondaryNavBar
        title={heroSideData.secondaryTitle || heroSideData.titleRich}
        menuList={menuListData}
        subMenuList={subMenuListData}
      />
      <div className="lg:flex lg:justify-between mx-auto container ">
        <div className="lg:w-3/5">
          <VehiclesSectionInformation content={heroSideData.content} />
          <div className="hidden lg:block">
            <TextButtonCardComponent
              textButtonCardLink={plansButtons.whatsAppLink}
              textButtonCardIcon={plansButtons.whatsAppIcon}
              textButtonCardtText={plansButtons.extraInfo}
              textButtonCardtTitle={plansButtons.whatsAppTitle}
            />
          </div>
        </div>
        <div className="mt-10 lg:mt-14 lg:w-1/3 ">
          <SideBar>
            <SideBarList
              sideTitle={heroSideData.sideTitle1}
              sideBarList={sideList}
            />
          </SideBar>

          <div className="hidden lg:block">
            <MoreInformationCard />
          </div>
        </div>
        <div className=" lg:hidden">
          <TextButtonCardComponent
            textButtonCardLink={plansButtons.whatsAppLink}
            textButtonCardIcon={plansButtons.whatsAppIcon}
            textButtonCardtText={plansButtons.extraInfo}
            textButtonCardtTitle={plansButtons.whatsAppTitle}
          />
        </div>
        <div className=" lg:hidden">
          <MoreInformationCard />
        </div>
      </div>
      <div className="container mx-auto my-10 lg:mt-16 lg:mb-28">
        <VideoContainer
          videoLink={additionalInfo.videoLink}
          posterImage={additionalInfo.posterImage}
        />
      </div>
    </>
  );
};

export default AutoInspect;
