export default class HeaderFooterDataDto {
  private _title?: string;
  private _phoneNumber?: string;
  private _whatsappNumber?: string;
  private _email?: string;
  private _emailIcon?: string;
  private _phoneIcon?: string;
  private _whatsappIcon?: string;
  private _whatsappText?: string;
  private _locationLink?: string;
  private _locationIcon?: string;
  private _locationText?: string;
  private _stamp?: string;
  private _logoWhite?: string;
  private _logoFitchRatings?: string;
  private _logoAmBest?: string;
  private _legalDocumentsText?: string;
  private _legalDocumentsLink?: string;
  private _glossaryText?: string;
  private _glossaryLink?: string;
  private _disclaimer?: string;

  public get phoneNumber(): string {
    return this._phoneNumber;
  }

  public set phoneNumber(value: string) {
    this._phoneNumber = value;
  }

  public get whatsappNumber(): string {
    return this._whatsappNumber;
  }

  public set whatsappNumber(value: string) {
    this._whatsappNumber = value;
  }

  public get email(): string {
    return this._email;
  }

  public set email(value: string) {
    this._email = value;
  }

  public get title(): string {
    return this._title;
  }

  public set title(value: string) {
    this._title = value;
  }

  public get phoneIcon(): string {
    return this._phoneIcon;
  }

  public set phoneIcon(value: string) {
    this._phoneIcon = value;
  }

  public get whatsappIcon(): string {
    return this._whatsappIcon;
  }

  public set whatsappIcon(value: string) {
    this._whatsappIcon = value;
  }

  public get whatsappText(): string {
    return this._whatsappText;
  }

  public set whatsappText(value: string) {
    this._whatsappText = value;
  }

  public get locationLink(): string {
    return this._locationLink;
  }

  public set locationLink(value: string) {
    this._locationLink = value;
  }

  public get locationIcon(): string {
    return this._locationIcon;
  }

  public set locationIcon(value: string) {
    this._locationIcon = value;
  }

  public get locationText(): string {
    return this._locationText;
  }

  public set locationText(value: string) {
    this._locationText = value;
  }

  public get emailIcon(): string {
    return this._emailIcon;
  }

  public set emailIcon(value: string) {
    this._emailIcon = value;
  }

  public get stamp(): string {
    return this._stamp;
  }

  public set stamp(value: string) {
    this._stamp = value;
  }

  public get logoWhite(): string {
    return this._logoWhite;
  }

  public set logoWhite(value: string) {
    this._logoWhite = value;
  }

  public get logoFitchRatings(): string {
    return this._logoFitchRatings;
  }

  public set logoFitchRatings(value: string) {
    this._logoFitchRatings = value;
  }

  public get logoAmBest(): string {
    return this._logoAmBest;
  }

  public set logoAmBest(value: string) {
    this._logoAmBest = value;
  }

  public get legalDocumentsText(): string {
    return this._legalDocumentsText;
  }

  public set legalDocumentsText(value: string) {
    this._legalDocumentsText = value;
  }

  public get legalDocumentsLink(): string {
    return this._legalDocumentsLink;
  }

  public set legalDocumentsLink(value: string) {
    this._legalDocumentsLink = value;
  }

  public get glossaryText(): string {
    return this._glossaryText;
  }

  public set glossaryText(value: string) {
    this._glossaryText = value;
  }

  public get glossaryLink(): string {
    return this._glossaryLink;
  }

  public set glossaryLink(value: string) {
    this._glossaryLink = value;
  }

  public get disclaimer(): string {
    return this._disclaimer;
  }

  public set disclaimer(value: string) {
    this._disclaimer = value;
  }
}
