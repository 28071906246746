import { BASE_URL, GET_HEADERS } from "../../components/Helper/requestData";
import { useContext, useEffect, useState } from "react";

import Error404Content from "../../components/error404Content/error404Content";
import { NavigationContext } from "../../routes/menulinks";
import axios from "axios";

type ErrorDataType = {
  title: string;
  content: string;
  icon: string;
  linkText: string;
};

const Error404 = () => {
  const [data, setData] = useState<ErrorDataType>({
    title: "",
    content: "",
    icon: "",
    linkText: "",
  });
  const { menuLinks } = useContext(NavigationContext);
  const getErrorData = async () => {
    const results = await axios
      .get(
        BASE_URL + "_api/web/lists/getbytitle('Datos-Error-404')/items",
        GET_HEADERS
      )
      .then((response) => response.data.value[0])
      .catch((e) => console.error(e));

    setData({
      title: results?.Title,
      content: results?.Content,
      icon: results?.Icon.Url,
      linkText: results?.LinkText,
    });
  };

  useEffect(() => {
    getErrorData();
  }, []);

  return (
    <div className="container mx-auto mb-10 space-x-10 lg:flex lg:justify-between md:mb-20 lg:items-center lg:py-20">
      <div className="lg:w-1/2">
        <img className="w-full" src={data.icon} alt="404 icon" />
      </div>
      <div className="lg:w-1/2 !m-0">
        <Error404Content
          title={data.title}
          content={data.content}
          linkText={data.linkText}
          link={menuLinks.home}
        />
      </div>
    </div>
  );
};

export default Error404;
