import axios from "axios";
import { useEffect, useState } from "react";
import { BASE_URL, GET_HEADERS } from "../../Helper/requestData";
import "./needHelpCard.css";

const NeedHelpCardShort = () => {
  const [data, setData] = useState({
    title: "Si ya tienes el producto y necesitas asistencia",
    message1: "¡Estamos para ti!",
    phoneHelp: "809 476 3232",
  });

  const getNeedHelpData = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Necesitas-Asistencia-Card')/items",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((err) => console.error(err));

    const result = resultsArr.find(
      (r: any) => r.Title === "Necesitas Asistencia"
    );

    setData({
      title: result?.Text,
      message1: result?.Message1,
      phoneHelp: result?.PhoneHelp,
    });
  };

  useEffect(() => {
    getNeedHelpData();
  }, []);
  return (
    <div className="need-help">
      <div className="need-help-title need-help-title--short">{data.title}</div>
      <div className="need-help-block need-help-block--nomargin">
        <h3 className="need-help-message">{`${data.message1}`}</h3>
        <a href={`tel:${data.phoneHelp}`}>{data.phoneHelp}</a>
      </div>
    </div>
  );
};

export default NeedHelpCardShort;
