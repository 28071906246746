import Select from "react-select";
import "./css/searchPublicationComponent.css";
import React from "react";

type SearchPublicationComponentFilterProps = {
  changeCurrOrder: (value: any) => void;
  changeCurrDocType: (value: any) => void;
  changeCurrDate: (value: any) => void;
  setSearchInput: React.Dispatch<React.SetStateAction<string>>;
};

const SearchPublicationComponentFilter = ({
  changeCurrOrder,
  changeCurrDocType,
  changeCurrDate,
  setSearchInput,
}: SearchPublicationComponentFilterProps) => {
  const OrderOptions = [
    { value: "asc", label: "Orden ascendente" },
    { value: "desc", label: "Orden descendente" },
  ];

  const DocTypeOptions = [
    {
      value: "Documentos institucionales",
      label: "Documentos institucionales",
    },
    { value: "Documentos legales", label: "Documentos legales" },
    { value: "Documentos de productos", label: "Documentos de productos" },
    {
      value: "Estados financieros y estadísticas",
      label: "Estados financieros y estadísticas",
    },
  ];
  const DateOptions = [
    { value: "desc", label: "De reciente a más antigua" },
    {
      value: "asc",
      label: "De antigua a más reciente",
    },
  ];

  const customStyles = {
    option: (provided, state) => {
      let background = "#fff";
      if (state.isFocused || state.isSelected) background = "#fff";
      return {
        ...provided,
        background,
        border: "none",
        fontFamily: "FreightSansProMedium",
        fontSize: "18px",
        color: "#264e72",
        cursor: "pointer",
        fontWeight: "400",
      };
    },
    container: (provided) => {
      return {
        ...provided,
        width: "100%",
        height: "57px",
      };
    },
    control: (provided) => {
      return {
        ...provided,
        width: "100%",
        height: "57px",
        borderRadius: "10px",
        padding: "0 8px",
        cursor: "pointer",
      };
    },
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return {
        ...provided,
        opacity,
        transition,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        fontSize: "18px",
        color: "#264e72",
        fontWeight: "400",
      };
    },
    placeholder: (provided) => ({
      ...provided,
      fontFamily: "FreightSansProMedium",
      fontSize: "18px",
      color: "#264e72",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      fontWeight: "400",
    }),
    dropdownIndicator: (provided, state) => {
      let color = "#264e72";
      if (state.isFocused) color = "#264e72";
      return {
        ...provided,
        fontFamily: "FreightSansProMedium",
        fontSize: "18px",
        color,
      };
    },
    indicatorSeparator: () => ({
      display: "none",
    }),
    menu: (provided) => ({
      ...provided,
      marginTop: "0",
      borderRadius: "10px",
    }),
    menuList: (provided) => {
      return {
        ...provided,
        marginTop: "0",
        borderRadius: "10px",
      };
    },
  };

  const handleChange = function (e) {
    setSearchInput(e.target.value);
  };

  return (
    <div className="lg:space-x-4 publication-search-box lg:flex lg:items-center">
      <p>Filtrar por:</p>
      <Select
        className="react-select-container"
        classNamePrefix="react-select"
        styles={customStyles}
        options={OrderOptions}
        onChange={(value) => changeCurrOrder(value.value)}
        placeholder={"Orden"}
        isSearchable={false}
      />

      <Select
        className="react-select-container"
        classNamePrefix="react-select"
        styles={customStyles}
        options={DocTypeOptions}
        onChange={(value) => changeCurrDocType(value.value)}
        placeholder={"Tipo de documento"}
        isSearchable={false}
      />

      <Select
        className="react-select-container"
        classNamePrefix="react-select"
        styles={customStyles}
        options={DateOptions}
        onChange={(value) => changeCurrDate(value.value)}
        placeholder={"Fecha"}
        isSearchable={false}
      />

      <input
        type="text"
        className="publication-search-box__input"
        placeholder="Buscar"
        onChange={handleChange}
      />
    </div>
  );
};

export default SearchPublicationComponentFilter;
