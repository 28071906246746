import { Information } from "./type";
import "./css/informationComponent.css";
function InformationComponent({ content }: Information) {
  return (
    <div className="information-section">
      <div className="">
        <div
          className="text-information"
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>
      </div>
    </div>
  );
}

export default InformationComponent;
