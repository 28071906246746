import { useEffect, useState } from "react";
import Faq from "../VariousCardsComponents/btnCards/Faq";
import ClaimsSideTabsMenu from "./ClaimsSideTabsMenu";
import ClaimsSingleSteps from "./ClaimsSingleSteps";
import "./css/claimsTabContent.css";

const ClaimsTabContent = ({
  planList,
  faqLink,
  faqTitle,
  faqIcon,
  claimsCardSteps,
  channelsTitle,
  channelsText,
}: any) => {
  const [active, setActive] = useState({
    activeId: 0,
    activeTitle: "",
  });

  useEffect(() => {
    if (screen.width >= 1024) {
      setActive({
        activeId: planList[0].id,
        activeTitle: planList[0].title,
      });
    } else {
      setActive({
        activeId: 0,
        activeTitle: "",
      });
    }
  }, [screen.width, planList]);

  return (
    <div className="claims-steps-content vehicle-plans">
      <div className="container mx-auto ">
        <div className="lg:flex lg:justify-between lg:items-start">
          <ClaimsSideTabsMenu
            setActive={setActive}
            active={active}
            sideMenuList={planList}
          />

          <div className="claims-step-info vehicle-plans-list">
            {planList.map((plan: any) =>
              (active.activeTitle === plan.title &&
                active.activeId === plan.id) ||
              (active.activeTitle === "" && active.activeId === 0) ? (
                <ClaimsSingleSteps
                  key={plan.id}
                  stepsTitle={plan.stepsTitle}
                  title={plan.title}
                  stepsSubTitle={plan.stepsSubTitle}
                  formBtnText={plan.formBtnText}
                  formBtnLink={plan.formBtnLink}
                  docTitle={plan.docTitle}
                  docSubTitle={plan.docSubTitle}
                  docBtnText={plan.docBtnText}
                  docBtnContent={plan.docBtnContent}
                  channelsTitle={channelsTitle}
                  channelsText={channelsText}
                  claimsCardSteps={claimsCardSteps}
                />
              ) : null
            )}

            <div className="flex flex-col justify-between mb-6 btn-cards md:flex-row">
              {faqLink && (
                <Faq faqLink={faqLink} faqTitle={faqTitle} faqIcon={faqIcon} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClaimsTabContent;
