import { Splide, SplideSlide } from "@splidejs/react-splide";
import "./css/workwithus.css";
import WorkLink from "./WorkLink";

function WorkWithUsSlide({ workRequirements, workLinkText, workLink }: any) {
  return (
    <div className="work-slide">
      <Splide
        options={{
          perPage: 4,
          pagination: false,
          breakpoints: {
            1024: {
              perPage: 3,
            },
            767: {
              perPage: 2,
            },
            640: {
              perPage: 1,
            },
          },
        }}
        renderControls={() => (
          <div className="splide__arrows">
            <div className="">
              <button
                type="button"
                className="splide__arrow splide__arrow--next "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="38.732"
                  height="70.464"
                  viewBox="0 0 38.732 70.464"
                >
                  <path
                    id="chevron-right"
                    d="M9,66.564,39.282,36.282,9,6"
                    transform="translate(-4.05 -1.05)"
                    fill="none"
                    stroke="#e2e2e2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="7"
                  />
                </svg>
              </button>
              <button
                type="button"
                className="splide__arrow splide__arrow--prev "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="38.732"
                  height="70.464"
                  viewBox="0 0 38.732 70.464"
                >
                  <path
                    id="chevron-right"
                    d="M9,66.564,39.282,36.282,9,6"
                    transform="translate(-4.05 -1.05)"
                    fill="none"
                    stroke="#e2e2e2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="7"
                  />
                </svg>
              </button>
            </div>
          </div>
        )}
      >
        {workRequirements.map((card, i) => (
          <SplideSlide key={i}>
            <div className="work-slide__card">
              <img src={card.image} alt={card.text} />
              <h2>{card.text}</h2>
            </div>
          </SplideSlide>
        ))}
      </Splide>
      <WorkLink text={workLinkText} link={workLink} />
    </div>
  );
}

export default WorkWithUsSlide;
