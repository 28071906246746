const ComparatorTableTh = ({ service, service2, colDiv }: any) => {
  return (
    <>
      {service2 ? (
        <>
          <th
            className="flex: 0 0 align-top service-table"
            style={{ width: `${100 / colDiv / 2}%` }}
          >
            {service}
          </th>

          <th
            className="flex: 0 0 align-top service-table"
            style={{ width: `${100 / colDiv / 2}%` }}
          >
            {service2}
          </th>
        </>
      ) : (
        <th
          className="flex: 0 0 align-top service-table"
          style={{ width: `${100 / colDiv}%` }}
        >
          {service}
        </th>
      )}
    </>
  );
};

export default ComparatorTableTh;
