const SpaceTablebodyElement = () => {
  return (
    <tbody className="opacity-0">
      <tr>
        <td></td>
      </tr>
    </tbody>
  );
};

export default SpaceTablebodyElement;
