import { BASE_URL, GET_HEADERS } from "../components/Helper/requestData";

import HeaderFooterDataDto from "../dtos/HeaderFooterDataDto";
import axios from "axios";

export async function getHeaderFooterDataService(): Promise<HeaderFooterDataDto> {
  const resultsArr = await axios
    .get(
      BASE_URL + "_api/web/lists/getbytitle('Header-Footer-Data')/items",
      GET_HEADERS
    )
    .then((response) => response.data.value[0])
    .catch((e) => console.error(e));

  const headerFooterData = new HeaderFooterDataDto();

  headerFooterData.title = resultsArr?.Title;
  headerFooterData.phoneNumber = resultsArr?.PhoneNumber;
  headerFooterData.whatsappNumber = resultsArr?.WhatsappNumber;
  headerFooterData.email = resultsArr?.Email;
  headerFooterData.emailIcon = resultsArr?.EmailIcon.Url;
  headerFooterData.phoneIcon = resultsArr?.PhoneIcon.Url;
  headerFooterData.whatsappIcon = resultsArr?.WhatsappIcon.Url;
  headerFooterData.whatsappText = resultsArr?.WhatsappText;
  headerFooterData.locationLink = resultsArr?.LocationLink.Description;
  headerFooterData.locationIcon = resultsArr?.LocationIcon.Url;
  headerFooterData.locationText = resultsArr?.LocationText;
  headerFooterData.stamp = resultsArr?.Stamp.Url;
  headerFooterData.logoWhite = resultsArr?.LogoWhite.Url;
  headerFooterData.logoFitchRatings = resultsArr?.LogoFitchRatings.Url;
  headerFooterData.logoAmBest = resultsArr?.LogoAmBest.Url;
  headerFooterData.legalDocumentsText = resultsArr?.LegalDocumentsText;
  headerFooterData.legalDocumentsLink =
    resultsArr?.LegalDocumentsLink.Description;
  headerFooterData.glossaryText = resultsArr?.GlossaryText;
  headerFooterData.glossaryLink = resultsArr?.GlossaryLink.Description;
  headerFooterData.disclaimer = resultsArr?.Disclaimer;

  return headerFooterData;
}
