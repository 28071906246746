import OfficeComponent from "./OfficeComponent";
import blueArrow from "../../assets/img/icons/arrow-dark-blue.svg";
import whiteArrow from "../../assets/img/icons/chevron-down-white-2.svg";

type LocComponentProps = {
  officeList: any;
  id: number;
  locTitle: string;
  locActive: any;
  setLocActive: any;
  officeActive: any;
  setOfficeActive: any;
};

const LocComponent = ({
  officeList,
  id,
  locTitle,
  locActive,
  setLocActive,
  officeActive,
  setOfficeActive,
}: LocComponentProps) => {
  const toggle = () => {
    setLocActive({ activeId: id });
  };

  const renderOffices = () => {
    return officeList.map((office) => (
      <OfficeComponent
        key={office.id}
        content={office.content}
        id={office.id}
        active={officeActive}
        setActive={setOfficeActive}
      />
    ));
  };

  return (
    <div className="mb-3 map-list__block cursor-pointer">
      <div
        className={`flex items-center justify-between map-list__region ${
          locActive.activeId === id ? "map-list__region--active " : ""
        }`}
        onClick={toggle}
      >
        <h6>{locTitle}</h6>
        <img
          className={`${locActive.activeId === id ? "blue-arrow-rotate" : ""}`}
          src={`${locActive.activeId === id ? whiteArrow : blueArrow}`}
        />
      </div>
      <div
        className={`info-and-map ${locActive.activeId === id ? " " : "hidden"}`}
      >
        <div className="map-list__offices-list">{renderOffices()}</div>
      </div>
    </div>
  );
};

export default LocComponent;
