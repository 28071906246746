import "./css/newsCardComponent.css";

import { NavigationContext } from "../../routes/menulinks";
import { NewsStructure } from "./news.model";
import { SelectedViewEnum } from "./news.enum";
import arrowOrange from "../../assets/img/icons/chevron-right-orange.svg";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

type NewsProp = {
  news: NewsStructure;
  selectedView: SelectedViewEnum | string;
};
const NewsCardComponent = ({ news, selectedView }: NewsProp) => {
  const { menuLinks } = useContext(NavigationContext);
  const navigate = useNavigate();
  const selectView = () => {
    if (selectedView === SelectedViewEnum.listView) {
      return "lg:flex lg:justify-between lg:mt-10 lg:mb-14";
    } else if (selectedView === SelectedViewEnum.groupView) {
      return "noticias__block lg:mt-10 lg:mb-5";
    } else return "";
  };

  const handleNavigate = () => {
    navigate({
      pathname: `${menuLinks.newsDetails}`,
      search: `?ID=${news.id}`,
    });
  };
  return (
    <div className={`news-card mt-10 mb-14 ${selectView()}`}>
      <div className="hidden img-container lg:block">
        <div
          onClick={handleNavigate}
          className="img-container cursor-pointer inline"
        >
          <img
            className="noticias__image"
            src={news?.img}
            alt="picture of news"
          />
        </div>
      </div>
      <div className="news-text-content ">
        <div onClick={handleNavigate} className="cursor-pointer inline">
          <div className="lg:mb-2 date-title">
            <h5 className="noticias__title lg:mb-2">{news?.title}</h5>
            <span className="noticias__date">
              {new Date(news?.date).toLocaleDateString("es-ES", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })}
            </span>
          </div>
          <img
            className="noticias__image sm:w-full my-5 lg:hidden"
            src={news?.img}
            alt="news ilustration"
          />

          <div className="news-previewContent">
            <div
              dangerouslySetInnerHTML={{
                __html: news?.content,
              }}
            ></div>
          </div>
        </div>
        <div
          onClick={handleNavigate}
          className={`noticias__link mt-3 cursor-pointer ${
            selectedView === SelectedViewEnum.groupView && "hidden"
          }`}
        >
          {news.linkText}
          <img src={arrowOrange} alt="orange arrow icon" />
        </div>
      </div>
    </div>
  );
};

export default NewsCardComponent;
