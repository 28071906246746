export const selectedService = (
  previous: HTMLCollection,
  serviceName: string,
  serviceParent: string
) => {
  const classListApply = function (
    method: string,
    element: any,
    className: string
  ): void {
    element.classList[method](className);
  };
  for (let index = 0; index < previous.length; index++) {
    const parentTitle =
      previous[index].parentElement?.parentElement?.parentElement?.parentElement
        ?.firstChild?.firstChild?.textContent;

    const titleArrow =
      previous[index].parentElement?.children[0].children[1].children[0];

    if (
      serviceName === previous[index].firstChild?.textContent &&
      parentTitle === serviceParent
    ) {
      const allTr = previous[index].parentElement?.children;
      classListApply("toggle", titleArrow, "white-arrow-rotate");

      if (allTr !== undefined) {
        for (let i = 1; i < allTr.length; i++) {
          allTr[i].classList.toggle("table-list-show");
        }
      }
    } else {
      classListApply("remove", titleArrow, "white-arrow-rotate");
      const allTr = previous[index].parentElement?.children;
      if (allTr !== undefined) {
        for (let i = 1; i < allTr.length; i++) {
          allTr[i].classList.remove("table-list-show");
        }
      }
    }
  }
};
