import "./css/headerNewsCompoment.css";

import { NavigationContext } from "../../routes/menulinks";
import { NewsModel } from "./news.model";
import arrowOrange from "../../assets/img/icons/chevron-right-orange.svg";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

const HeaderNewsCompoment = ({ news }: NewsModel) => {
  const { menuLinks } = useContext(NavigationContext);
  const navigate = useNavigate();
  return (
    <div className="header-news flex justify-between ">
      <div className="img-container">
        <img src={news?.img} alt={news?.title} />
      </div>
      <div className="news-text-content">
        <div className="lg:mb-2 date-title">
          <span className="noticias__date">
            {new Date(news?.date).toLocaleDateString("es-ES", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })}
          </span>
          <h5 className="noticias__title mt-2 mb-8">{news?.title}</h5>
        </div>

        <div className="news-previewContent">
          <div
            dangerouslySetInnerHTML={{
              __html: news?.content,
            }}
          ></div>
        </div>

        <div
          onClick={() => {
            navigate({
              pathname: `${menuLinks.newsDetails}`,
              search: `?ID=${news.id}`,
            });
          }}
          className="noticias__link mt-3 cursor-pointer"
        >
          {news?.linkText}
          <img src={arrowOrange} alt="orange arrow icon" />
        </div>
      </div>
    </div>
  );
};

export default HeaderNewsCompoment;
