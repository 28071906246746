import { Splide, SplideSlide } from "@splidejs/react-splide";
import "./css/workwithus.css";
import quotation from "../../assets/img/icons/quotation.svg";

function ExperienceSlide({ workExperience }: any) {
  return (
    <div className="experience-slide">
      <Splide
        options={{
          perPage: 1,
          pagination: true,
          type: "loop",
          focus: "center",
          padding: "24%",
          gap: "48px",
          breakpoints: {
            1024: {
              padding: "32px",
              gap: "32px",
            },
            768: {
              padding: "6px",
              gap: "6px",
            },
          },
        }}
        renderControls={() => (
          <div className="splide__arrows">
            <div className="">
              <button
                type="button"
                className="splide__arrow splide__arrow--next "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="38.732"
                  height="70.464"
                  viewBox="0 0 38.732 70.464"
                >
                  <path
                    id="chevron-right"
                    d="M9,66.564,39.282,36.282,9,6"
                    transform="translate(-4.05 -1.05)"
                    fill="none"
                    stroke="#e2e2e2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="7"
                  />
                </svg>
              </button>
              <button
                type="button"
                className="splide__arrow splide__arrow--prev "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="38.732"
                  height="70.464"
                  viewBox="0 0 38.732 70.464"
                >
                  <path
                    id="chevron-right"
                    d="M9,66.564,39.282,36.282,9,6"
                    transform="translate(-4.05 -1.05)"
                    fill="none"
                    stroke="#e2e2e2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="7"
                  />
                </svg>
              </button>
            </div>
          </div>
        )}
      >
        {workExperience.map((employee, i) => (
          <SplideSlide key={i}>
            <div className="relative experience-slide__card">
              <img src={employee.image} alt="employe picture" />
              <img className="quote" src={quotation} alt="quote" />
              <p>{employee.text}</p>
              <h2>{employee.nameAndPosition}</h2>
            </div>
          </SplideSlide>
        ))}
      </Splide>
    </div>
  );
}

export default ExperienceSlide;
