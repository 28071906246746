import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import darkGrayArrow from "../../assets/img/icons/chevron-dark-gray-right.svg";
import SecondarySubMenu from "./SecondarySubMenu";
import SecondarySubMenuMobile from "./SecondarySubMenuMobile";
import { menuListType, subMenuListType } from "./types";

type secondaryMenuModalProps = {
  show?: boolean;
  showSubMenu: boolean;
  setShowSubMenu: React.Dispatch<React.SetStateAction<boolean>>;
  menuList: menuListType[];
  subMenuList: subMenuListType[] | null | undefined;
};
const SecondaryMenuModal = ({
  show,
  showSubMenu,
  setShowSubMenu,
  menuList,
  subMenuList,
}: secondaryMenuModalProps) => {
  const [subMenu, setSubMenu] = useState<
    subMenuListType[] | null | undefined
  >();

  const handleMenu = (selectedMenu: string) => {
    const subMenuData = subMenuList?.filter(
      (list: subMenuListType) => list.parentMenu === selectedMenu
    );

    setSubMenu(subMenuData);
  };

  useEffect(() => {
    const subMenuLiElements = document.getElementsByClassName("sub-menu-li");

    if (subMenu) {
      for (let i = 0; i < subMenuLiElements.length; i++) {
        if (subMenuLiElements[i].textContent === subMenu[i].title) {
          subMenuLiElements[i].classList.toggle("sub-menu-li-show");

          if (subMenuLiElements[i].classList.contains("sub-menu-li-show")) {
            setShowSubMenu(true);
          } else {
            setShowSubMenu(false);
            setSubMenu(null);
          }
        }
      }
    }
  }, [subMenu, show]);

  return (
    <>
      <div className={`menu-modal  ${show ? "menu-modal-show" : ""}`}>
        <ul>
          {menuList?.map((list: menuListType) => {
            if (list.hasSubMenu) {
              return (
                <div key={list.id}>
                  <li
                    className="menu-modal-list"
                    onClick={() => {
                      handleMenu(list.title);
                    }}
                  >
                    <div className="flex justify-between mx-5">
                      <span className="">{list.title}</span>

                      <img
                        src={darkGrayArrow}
                        alt="gray arrow icon"
                        className={`menu-modal-arrow ${
                          subMenu && subMenu[0].parentMenu === list.title
                            ? "menu-modal-arrow-rotate"
                            : ""
                        }`}
                      />
                    </div>
                  </li>

                  <SecondarySubMenuMobile
                    subMenu={subMenu}
                    showSubMenu={showSubMenu}
                    parentList={list.title}
                  />
                </div>
              );
            }
            if (list.externalLink) {
              return (
                <a
                  href={list.externalLink}
                  key={list.id}
                  className="menu-modal-list w95"
                  target="_blank"
                  rel="noreferrer"
                >
                  <li className="ml-5">{list.title}</li>
                </a>
              );
            }
            return (
              <Link
                to={list.link}
                key={list.id}
                className="menu-modal-list w95"
              >
                <li className="ml-5">{list.title}</li>
              </Link>
            );
          })}
        </ul>
        <div className="hidden md:block ">
          <SecondarySubMenu subMenu={subMenu} showSubMenu={showSubMenu} />
        </div>
      </div>
    </>
  );
};

export default SecondaryMenuModal;
