type AssistancePlanProps = {
  tableTitle: string;
  tableDetails: Array<any>;
};

export default function AssistancePlanCoverageDesktop({
  tableTitle,
  tableDetails,
}: AssistancePlanProps) {
  const renderDetails = () => {
    return tableDetails.map((d) => (
      <tr key={d.order}>
        <td>{d.text}</td>
        <td data-label={d.dataLabel}>{d.limit}</td>
      </tr>
    ));
  };

  return (
    <table>
      <thead className="table-body">
        <tr className="titles-container2">
          <th>{tableTitle}</th>
          <th className="hidden lg:block">Monto USD / Límite</th>
        </tr>
      </thead>
      <tbody className="table-list table-list-2">{renderDetails()}</tbody>
    </table>
  );
}
