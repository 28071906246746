import React, { useReducer, useEffect, useContext } from "react";
import { ConstributionMockData } from "../../pages/AboutUs/mock/socialResponsibility.mock";
import {
  ContributionSection,
  ContributionSectionDetails,
} from "../models/Contribution.model";

type ServicesSectionProviderProps = { children: React.ReactNode };

const ServicesSectionContext = React.createContext<
  ContributionSectionDetails | undefined
>(undefined);

type Option = {
  type: "contributionSection";
  value: ContributionSection[] | undefined;
};

const reducer = (
  state: ContributionSectionDetails,
  Option: Option
): ContributionSectionDetails => {
  if (Option.type === "contributionSection") {
    return {
      ...state,
      ContributionSection: Option.value,
    };
  }
  return { ...state };
};

const ContributionSectionContextProvider = ({
  children,
}: ServicesSectionProviderProps) => {
  const [ContributionSectionData, setContributionSection] = useReducer(
    reducer,
    {} as ContributionSectionDetails
  );

  useEffect(() => {
    setContributionSection({
      type: "contributionSection",
      value: ConstributionMockData,
    });
  }, []);

  return (
    <ServicesSectionContext.Provider value={ContributionSectionData}>
      {children}
    </ServicesSectionContext.Provider>
  );
};

const useContributionSection = () => {
  const context = useContext(ServicesSectionContext);

  if (context === undefined) {
    throw new Error("Context must be used within a Provider");
  }
  return context;
};

export { useContributionSection, ContributionSectionContextProvider };
