import { Helmet } from "react-helmet";

export interface PageHeadersProps {
  title?: string;
}

export function PageHeaders(props: PageHeadersProps) {
  return (
    <Helmet>
      <title>
        {(props.title ? props.title + " | " : "") + "Seguros Reservas"}
      </title>
    </Helmet>
  );
}
