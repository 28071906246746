import { useState } from "react";
import ModalComponentResponsibility from "../ModalComponent/ModalComponentResponsibility";

interface Data {
  title: string;
  imageSlide: string;
  imageModal: string;
  content: string;
  size: string;
  orden: number;
}
function SmallRespoCardServices(props: Data) {
  const [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <>
      <div className="inner" onClick={openModal}>
        <div className="overlay"></div>
        <img
          src={props.imageSlide}
          alt="imagen-responsabilidad-complementario"
          className="responsabilidad-slide__img"
        />
        <div className="responsabilidad__block responsabilidad__block--desktop">
          <div className="responsabilidad__info">
            <div className="responsabilidad__title">{props.title}</div>
          </div>
        </div>
      </div>
      <ModalComponentResponsibility closeModal={closeModal} isOpen={isOpen}>
        <div className="modal-contribution">
          <h1 className="lg:hidden">{props.title}</h1>
          <div className="block h-full lg:flex lg:flex-nowrap lg:items-stretch">
            <div className="lg:w-1/2">
              <img
                src={props.imageModal}
                className="respo-modal-image"
                alt="respo-modal-image"
              />
            </div>
            <div className="lg:w-1/2 content-fix">
              <h1 className="hidden lg:block">{props.title}</h1>
              <div
                className="overflow-auto h-5/6"
                dangerouslySetInnerHTML={{ __html: props.content }}
              ></div>
            </div>
          </div>
        </div>
      </ModalComponentResponsibility>
    </>
  );
}
export default SmallRespoCardServices;
