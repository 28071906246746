import { Splide, SplideSlide } from "@splidejs/react-splide";
import { Grid } from "@splidejs/splide-extension-grid";
import { useContext } from "react";
import { ClaimsVehicleDataContext } from "../../context/Claims/ClaimsVehicleDataContext";
import CardsComponentSteps, {
  ClaimsCardsInterface,
} from "./CardsComponentSteps";

type CardsStepsCardContainerVehicleProps = {
  cards: ClaimsCardsInterface[] | undefined;
  showCards: boolean;
};
const CardsStepsCardContainerVehicle = ({
  cards,
  showCards,
}: CardsStepsCardContainerVehicleProps) => {
  const { modalTexts, modalPasos } = useContext(ClaimsVehicleDataContext);
  const renderCards = () => {
    return cards?.map((card) => (
      <SplideSlide key={card.cardId}>
        <>
          <CardsComponentSteps
            cardImg={card.cardImg}
            cardTitle={card.cardTitle}
            isSlide={true}
            linkText1={card.linkText1}
            linkText2={card.linkText2}
            link3={card.link3}
            content={card.content}
            modalTitle={card.modalTitle}
            modalContent={card.modalContent}
            modalTexts={modalTexts}
            modalPasos={modalPasos}
            hasVideo={card.hasVideo}
            modal2Image={card.modal2Image}
          />
        </>
      </SplideSlide>
    ));
  };
  return (
    <div>
      {cards.length > 0 && (
        <Splide
          className="hidden lg:block vehicle-colum-slide"
          Extensions={{ Grid }}
          options={{
            arrows: true,
            perPage: 2,
            padding: "36px",
            grid: {
              rows: 1,
              cols: 2,
            },
          }}
          renderControls={() => (
            <div className="splide__arrows">
              <div className="splide__arrow splide__arrow--next ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="38.732"
                  height="70.464"
                  viewBox="0 0 38.732 70.464"
                >
                  <path
                    id="chevron-right"
                    d="M9,66.564,39.282,36.282,9,6"
                    transform="translate(-4.05 -1.05)"
                    fill="none"
                    stroke="#00aef0"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="7"
                  />
                </svg>
              </div>
              <div className="splide__arrow splide__arrow--prev ">
                <svg
                  className="left-arrow"
                  xmlns="http://www.w3.org/2000/svg"
                  width="38.732"
                  height="70.464"
                  viewBox="0 0 38.732 70.464"
                >
                  <path
                    id="chevron-right"
                    d="M9,66.564,39.282,36.282,9,6"
                    transform="translate(-4.05 -1.05)"
                    fill="none"
                    stroke="#00aef0"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="7"
                  />
                </svg>
              </div>
            </div>
          )}
        >
          {renderCards()}
        </Splide>
      )}
      <div
        className={`${
          showCards ? "hidden" : "flex items-center justify-center"
        } lg:hidden`}
      >
        <Splide
          className="vehicle-colum-slide"
          renderControls={() => (
            <div className="splide__arrows">
              <div
                className="splide__arrow splide__arrow--next"
                style={{ marginRight: "-40px" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="38.732"
                  height="70.464"
                  viewBox="0 0 38.732 70.464"
                >
                  <path
                    id="chevron-right"
                    d="M9,66.564,39.282,36.282,9,6"
                    transform="translate(-4.05 -1.05)"
                    fill="none"
                    stroke="#00aef0"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="7"
                  />
                </svg>
              </div>
              <div
                className="splide__arrow splide__arrow--prev"
                style={{ marginLeft: "-40px" }}
              >
                <svg
                  className="left-arrow"
                  xmlns="http://www.w3.org/2000/svg"
                  width="38.732"
                  height="70.464"
                  viewBox="0 0 38.732 70.464"
                >
                  <path
                    id="chevron-right"
                    d="M9,66.564,39.282,36.282,9,6"
                    transform="translate(-4.05 -1.05)"
                    fill="none"
                    stroke="#00aef0"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="7"
                  />
                </svg>
              </div>
            </div>
          )}
          options={{
            arrows: true,
          }}
        >
          {renderCards()}
        </Splide>
      </div>
    </div>
  );
};

export default CardsStepsCardContainerVehicle;
