import question from "../../../assets/img/icons/question-icon.svg";
import "./css/btnCards.css";

type FaqProps = {
  faqLink: string;
  faqTitle: string;
  faqIcon: string;
  faqActive?: boolean;
};
const Faq = ({
  faqLink = "#",
  faqTitle = "Preguntas Frecuentes",
  faqIcon = question,
  faqActive = true,
}: FaqProps) => {
  return (
    <a
      className={`mb-2 orange-btn btn-card md:mb-4 ${
        faqActive ? "flex" : "hidden"
      }`}
      href={faqLink}
    >
      <img src={faqIcon} className="icon-img" />
      <span className="btn-title">{faqTitle}</span>
    </a>
  );
};

export default Faq;
