import { useState } from "react";
import whiteArrow from "../../assets/img/icons/chevron-right-white.svg";

type AssistancePlanProps = {
  tableTitle: string;
  tableDetails: Array<any>;
};
export default function AssistancePlanCoverageMobile({
  tableTitle,
  tableDetails,
}: AssistancePlanProps) {
  const [isActive, setActive] = useState(false);

  const toggleClass = () => {
    setActive(!isActive);
  };

  const renderDetails = () => {
    return tableDetails.map((d) => (
      <tr key={d.order}>
        <td>{d.text}</td>
        <td data-label={d.dataLabel}>{d.limit}</td>
      </tr>
    ));
  };

  return (
    <table>
      <thead className="table-body">
        <tr
          className="flex titles-container2 lg:table-row"
          onClick={() => toggleClass()}
        >
          <th className="w-full lg:w-auto">{tableTitle}</th>
          <th className="flex items-center lg:hidden">
            <img
              src={whiteArrow}
              alt="chevronRight"
              className={`white-arrow ${isActive ? "" : "rotate-270deg"}`}
            />
          </th>
        </tr>
      </thead>
      <tbody className={`table-list table-list-2 ${isActive ? "hidden" : ""}`}>
        {renderDetails()}
      </tbody>
    </table>
  );
}
