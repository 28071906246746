import HeroMenuComponent from "../../components/MenuLandingsLayout/HeroMenuComponent";
import SecondaryNavBar from "../../components/secondaryNavBar/SecondaryNavBar";
import { useContext, useEffect, useState } from "react";
import VissionMission from "../../components/VissionMissionComponent/VissionMission";
import IntroTitle from "../../components/introTitleComponent/IntroTitle";
import ProductsContainer from "../../components/MenuLandingsLayout/ProductsContainer";
import CardsValuesCardContainer from "../../components/MenuLandingsLayout/CardsValuesCardContainer";
import axios from "axios";
import { BASE_URL, GET_HEADERS } from "../../components/Helper/requestData";
import { AboutUsMenuContext } from "../../context/AboutUs/AboutUsMenuContext";

const CorporateIdentity = () => {
  const [showCards, setShowCards] = useState(false);

  const [heroSideData, setHeroSideData] = useState({
    title: "",
    titleRich: "",
    bannerImage: "",
    secondaryTitle: "",
    sideTitle1: "",
    sideContent1: "",
    sideTitle2: "",
    content: "",
  });

  const [missionVisionData, setMissionVisionData] = useState([
    {
      title: "",
      text: "",
    },
  ]);
  const [valuesCardsInfo, setValuesCardsInfo] = useState([
    {
      cardId: 1,
      cardImg: "",
      cardTitle: "",
    },
  ]);

  const { menuListData } = useContext(AboutUsMenuContext);

  const getSectionContentData = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Contenido-de-Secciones')/items?$filter=Title eq 'Identidad Corporativa'",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    setHeroSideData({
      titleRich: resultsArr[0]?.TitleRich,
      title: resultsArr[0]?.Title,
      bannerImage: resultsArr[0]?.BannerImage?.Url,
      content: resultsArr[0]?.Content,
      secondaryTitle: resultsArr[0]?.SecondaryTitle,
      sideTitle1: resultsArr[0]?.SideTitle1,
      sideTitle2: resultsArr[0]?.SideTitle2,
      sideContent1: resultsArr[0]?.SideContent1,
    });
  };

  const getMissionVisionData = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Identidad-Corporativa-Mision-Vision')/items",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    const results = resultsArr.sort((a, b) => a.Order - b.Order);

    setMissionVisionData(
      results.map((item: any) => {
        return {
          id: item?.Order,
          title: item?.Title,
          text: item?.Text,
        };
      })
    );
  };

  const getValuesCardsInfo = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Identidad-Corporativa-Valores')/items",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    const results = resultsArr.sort((a, b) => a.Order - b.Order);

    setValuesCardsInfo(
      results.map((item: any) => {
        return {
          cardId: item?.Order,
          cardImg: item?.CardImg?.Url,
          cardTitle: item?.Title,
        };
      })
    );
  };

  useEffect(() => {
    getSectionContentData();
    getMissionVisionData();
    getValuesCardsInfo();
  }, []);

  return (
    <>
      <HeroMenuComponent
        bannerImage={heroSideData.bannerImage}
        title={heroSideData.titleRich}
      />
      <SecondaryNavBar
        title={heroSideData.secondaryTitle || heroSideData.titleRich}
        menuList={menuListData}
        subMenuList={[]}
      />
      <div className="container mx-auto lg:flex lg:justify-between">
        <div className="w-full mt-10 lg:mt-20 lg:mb-20">
          <IntroTitle content={heroSideData.content} />
        </div>
      </div>
      <div className="container mx-auto lg:flex lg:justify-between md:mb-16 ">
        <VissionMission missionVisionData={missionVisionData} />
      </div>
      <div>
        <div className="values-slide-fix">
          <ProductsContainer
            title={heroSideData.sideTitle1}
            showCards={showCards}
            setShowCards={setShowCards}
          >
            <CardsValuesCardContainer
              showCards={showCards}
              cards={valuesCardsInfo}
            />
          </ProductsContainer>
        </div>
      </div>
    </>
  );
};

export default CorporateIdentity;
