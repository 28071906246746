import axios from "axios";
import { useEffect, useState } from "react";
import { BASE_URL, GET_HEADERS } from "../../Helper/requestData";
import "./travelIconList.css";

const TravelIconList = () => {
  const [iconList, setIconList] = useState([
    {
      icon: "",
      text: "",
      order: 0,
    },
  ]);

  const renderList = () => {
    const orderedIconList = iconList;
    orderedIconList.sort((a, b) => a.order - b.order);
    return orderedIconList.map((item: any) => (
      <div
        key={item.order}
        className="flex mb-12 lg:mb-0 travel-icon-block lg:block "
      >
        <img className="travel-icon-image" src={item.icon} alt="travel icon" />
        <p className="travel-icon-text">{item.text}</p>
      </div>
    ));
  };

  const getIconListData = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL + "_api/web/lists/getbytitle('Viajes-Reservas-Iconos')/items",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    setIconList(
      resultsArr.map((item: any) => {
        return {
          order: item?.Order0,
          text: item?.Text,
          icon: item?.Icon?.Url,
        };
      })
    );
  };

  useEffect(() => {
    getIconListData();
  }, []);

  return (
    <div className="travel-icon-list lg:flex lg:space-x-2">{renderList()}</div>
  );
};

export default TravelIconList;
