import { useEffect, useState } from "react";
import comparatorIcon from "../../assets/img/icons/comparator-icon.svg";
import ComparatorDesktopComponentV2 from "./comparatorComponent/ComparatorDesktopComponentV2";
import ComparatorMobileComponent from "./comparatorComponent/ComparatorMobileComponent";
import Faq from "../VariousCardsComponents/btnCards/Faq";
import GeneralConditions from "../VariousCardsComponents/btnCards/GeneralConditions";
import MoreInformationCard from "../VariousCardsComponents/moreInfoCard/MoreInformationCard";
import VehiclePlanListSideMenu from "./VehiclePlanListSideMenu";
import VehicleSinglePlan from "./VehicleSinglePlan";

const VehiclesPlans = ({
  planList,
  generalConditionLink,
  generalConditionTitle,
  generalConditionIcon,
  faqLink,
  faqTitle,
  faqIcon,
  comparatorTitle,
}: any) => {
  const [active, setActive] = useState({
    activeId: 0,
    activeTitle: "",
  });

  useEffect(() => {
    if (screen.width >= 1024) {
      setActive({
        activeId: planList[0].id,
        activeTitle: planList[0].title,
      });
    } else {
      setActive({
        activeId: 0,
        activeTitle: "",
      });
    }
  }, [screen.width, planList]);
  const [showFullScreenContent, setShowFullScreenContent] = useState(false);
  return (
    <div className="vehicle-plans">
      <div className="container mx-auto ">
        <div className="section-head flex justify-between mb-2">
          <h5>Planes</h5>
          <div
            className="comparator-div flex justify-between items-center"
            onClick={() => setShowFullScreenContent(true)}
          >
            <img
              src={comparatorIcon}
              alt="comparator-icon"
              className="comparator-img"
            />
            <span className="comparator-title">Comparar Planes</span>
          </div>
        </div>
        <hr className="hidden md:block" />
        <div className="lg:flex lg:justify-between">
          <VehiclePlanListSideMenu
            setActive={setActive}
            active={active}
            sideMenuList={planList}
          />
          <div className="vehicle-plans-list">
            {planList.map((plan: any) =>
              (active?.activeTitle === plan?.title &&
                active?.activeId === plan?.id) ||
              (active?.activeTitle === "" && active?.activeId === 0) ? (
                <VehicleSinglePlan
                  key={plan?.id}
                  planServiceOne={plan?.planServiceOne}
                  planServiceTwo={plan?.planServiceTwo}
                  planServiceThree={plan?.planServiceThree}
                  planServiceFour={plan?.planServiceFour}
                  planTitle={plan?.title}
                  planContent={plan?.content}
                />
              ) : null
            )}

            <MoreInformationCard />

            <div className="btn-cards flex flex-col justify-between  mb-6 md:flex-row">
              {generalConditionLink && (
                <GeneralConditions
                  generalConditionLink={generalConditionLink}
                  generalConditionTitle={generalConditionTitle}
                  generalConditionIcon={generalConditionIcon}
                />
              )}
              {faqLink && (
                <Faq faqLink={faqLink} faqTitle={faqTitle} faqIcon={faqIcon} />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="lg:hidden">
        <ComparatorMobileComponent
          showFullScreenContent={showFullScreenContent}
          setShowFullScreenContent={setShowFullScreenContent}
          planList={planList}
          title={comparatorTitle}
        />
      </div>

      <div className="hidden lg:block">
        <ComparatorDesktopComponentV2
          showFullScreenContent={showFullScreenContent}
          setShowFullScreenContent={setShowFullScreenContent}
          planList={planList}
          title={comparatorTitle}
        />
      </div>
    </div>
  );
};

export default VehiclesPlans;
