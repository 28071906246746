import whiteArrow from "../../../assets/img/icons/chevron-down-white.svg";
const AccordionLayout = ({
  title,
  children,
  index,
  activeIndex,
  setActiveIndex,
}: any) => {
  const handleSetIndex = (i: number) => {
    if (activeIndex !== i) {
      setActiveIndex(i);
    } else if (activeIndex === i) {
      setActiveIndex(0);
    }
  };

  return (
    <>
      <div
        onClick={() => handleSetIndex(index)}
        className="flex w-full justify-between mt-2 rounded accordeon-title"
      >
        <div className="flex">
          <div className="">{title}</div>
        </div>
        <div className="flex items-center">
          {activeIndex === index ? (
            <img
              src={whiteArrow}
              className={`w-4 ${activeIndex && "rotate-arrow-accordeon"}`}
            />
          ) : (
            <img src={whiteArrow} className="w-4" />
          )}
        </div>
      </div>

      {activeIndex === index && <>{children}</>}
    </>
  );
};

export default AccordionLayout;
