import GoogleMapReact from "google-map-react";
import { useEffect, useState } from "react";
import mapMarker from "../../assets/img/icons/map-marker.svg";
const LocationPin = ({ _lat, _lng }: any) => (
  <div>
    <img className="map-marker" src={mapMarker} alt="pin-icon" />
  </div>
);

const MapComponent = ({ data, center }: any) => {
  const [coordinates, setCoordinates] = useState({ lat: 10, lng: 10 });

  const renderPins = () => {
    return data.map((pin, i) => (
      <LocationPin lat={pin.center.lat} lng={pin.center.lng} key={i} />
    ));
  };

  useEffect(() => {
    setCoordinates(center);
  }, [center]);

  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: "AIzaSyCdksnCUgXhBruwG3mM2h4RsxN2OF2kGrg" }}
      defaultZoom={18}
      center={coordinates}
      yesIWantToUseGoogleMapApiInternals
    >
      {renderPins()}
    </GoogleMapReact>
  );
};

export default MapComponent;
