import { Link } from "react-router-dom";

type props = {
  icon: string;
  title: string;
  type: string;
  link?: string;
  linkText?: string;
  message?: string;
};

const ContactCard = ({ icon, title, linkText, message, link, type }: props) => {
  function getCleanNumber(number: string) {
    const numbers = "0123456789";
    let cleanNumber = "";

    number &&
      number.split("").forEach((n: string) => {
        if (numbers.includes(n)) {
          cleanNumber += n;
        }
      });

    return cleanNumber;
  }

  const renderText = () => {
    if (type === "email") {
      return (
        <a className="bubbles__link" href={`mailto:${link}`}>
          {linkText}
        </a>
      );
    } else if (type === "phone") {
      return (
        <a className="bubbles__link" href={`tel:${getCleanNumber(link)}`}>
          {linkText}
        </a>
      );
    } else if (type === "whatsapp") {
      return (
        <a
          className="bubbles__link"
          href={`https://api.whatsapp.com/send?phone=${getCleanNumber(link)}`}
        >
          {linkText}
        </a>
      );
    } else {
      return (
        <Link className="bubbles__link" to={link}>
          {linkText}
        </Link>
      );
    }
  };

  return (
    <div className="bubbles">
      <img
        className="bubbles__icon bubbles__icon--desktop"
        src={icon}
        alt="icon"
      />
      <div>
        <div className="bubbles__title">
          <img className="bubbles__icon" src={icon} alt="icon" />
          <h3 className="bubbles__icon-text">{title}</h3>
        </div>
        {renderText()}
        {message && <p className="bubbles__text">{message}</p>}
      </div>
    </div>
  );
};

export default ContactCard;
