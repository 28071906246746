import locationIcon from "../../assets/img/icons/location-blue.png";
import phone from "../../assets/img/icons/small-phone-blue.png";
import mail from "../../assets/img/icons/mail-orange.svg";
import linkIcon from "../../assets/img/icons/link-icon-orange.svg";
import "./css/centroAutomovilista.css";

const mockData = [
  {
    id: 1,
    titleLocation: "Santo Domingo",
    addresLocation: " Av. 27 de Febrero No. 452, casi esq. Nuñez de Cáceres",
    phoneLocation: "809-565-8222",
  },
  {
    id: 2,
    titleLocation: "Santiago",
    addresLocation: "Calle A, No. 1, Reparto Tavares Oeste.",
    phoneLocation: "809-565-8222",
  },
];

const knowMore = {
  title: "Quiero saber más",
  portalIcon: linkIcon,
  portalLink: "https://www.centrodelautomovilista.com",
  portalText: "Portal Web Centro del Automovilista",
  emailSectionIcon: mail,
  email: "info@centrodelautomovilista.com",
};

const CentroAutomovilistaInfo = () => {
  return (
    <div>
      <div className="centro-container ">
        <h3>Sucursales</h3>
        <div className="location-container">
          {mockData.map((location) => (
            <div className="location-box" key={location.id}>
              <h4 className="">{location.titleLocation}</h4>
              <div>
                <a className="flex items-center mb-3">
                  <img src={locationIcon} alt="location" className="mr-2" />
                  <span>{location.addresLocation}</span>
                </a>
                <a
                  href={`tel:${location.phoneLocation}`}
                  className="flex items-center"
                >
                  <img src={phone} alt="phone" className="mr-2" />
                  <span>{location.phoneLocation}</span>
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="md:px-5 md:py-5 know-more">
        <h3>{knowMore.title}</h3>

        <a
          href={knowMore.portalLink}
          className="flex items-center mb-3 underline"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={knowMore.portalIcon}
            alt="know more icon"
            className="mr-2 w-6"
          />
          <span>{knowMore.portalText}</span>
        </a>
        <a href={`mailto:${knowMore.email}`} className="flex items-center">
          <img
            src={knowMore.emailSectionIcon}
            alt="email icon"
            className="mr-2 w-6"
          />
          <span>{knowMore.email}</span>
        </a>
      </div>
    </div>
  );
};

export default CentroAutomovilistaInfo;
