import { useContext, useEffect, useState } from "react";
import HeroMenuComponent from "../../components/MenuLandingsLayout/HeroMenuComponent";
import SecondaryNavBar from "../../components/secondaryNavBar/SecondaryNavBar";
import InformationComponent from "../../components/InformationComponent/InformationComponent";
import "./css/services.css";
import ContactCardsContainer from "../../components/contactanos/ContactCardsContainer";
import axios from "axios";
import { BASE_URL, GET_HEADERS } from "../../components/Helper/requestData";
import ContactanosForm from "../../components/contactanos/ContactanosForm";
import { ServicesChannelsMenuContext } from "../../context/ServicesChannels/ServicesChannelsMenuContext";

const Contact = () => {
  const [heroSideData, setHeroSideData] = useState({
    title: "",
    titleRich: "",
    bannerImage: "",
    secondaryTitle: "",
    sideTitle1: "",
    sideContent1: "",
    sideTitle2: "",
    sideContent2: "",
    content: "",
  });

  const [sideCardList, setSideCardList] = useState([
    {
      order: 0,
      icon: "",
      title: "",
      type: "",
      link: "",
      linkText: "",
      message: "",
    },
  ]);

  const { menuListData } = useContext(ServicesChannelsMenuContext);

  const getSectionContentData = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Contenido-de-Secciones')/items?$filter=Title eq 'Contáctanos'",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    setHeroSideData({
      titleRich: resultsArr[0]?.TitleRich,
      title: resultsArr[0]?.Title,
      bannerImage: resultsArr[0]?.BannerImage.Url,
      secondaryTitle: resultsArr[0]?.SecondaryTitle,
      sideTitle1: resultsArr[0]?.SideTitle1,
      sideContent1: resultsArr[0]?.SideContent1,
      sideTitle2: resultsArr[0]?.SideTitle2,
      sideContent2: resultsArr[0]?.SideContent2,
      content: resultsArr[0]?.Content,
    });
  };

  const getSideCardListData = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL + "_api/web/lists/getbytitle('Contactanos-SideCards')/items",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    setSideCardList(
      resultsArr.map((item: any) => {
        return {
          icon: item?.Icon.Url,
          title: item?.Title,
          type: item?.LinkType,
          link: item?.Link.Description,
          linkText: item?.LinkText,
          message: item?.Message,
          order: item?.Order,
        };
      })
    );
  };

  useEffect(() => {
    getSectionContentData();
    getSideCardListData();
  }, []);

  return (
    <>
      <HeroMenuComponent
        bannerImage={heroSideData.bannerImage}
        title={heroSideData.titleRich}
      />
      <SecondaryNavBar
        title={heroSideData.secondaryTitle || heroSideData.titleRich}
        menuList={menuListData}
        subMenuList={[]}
      />
      <div className="container mx-auto lg:flex lg:justify-between">
        <div className="lg:w-3/5">
          <InformationComponent content={heroSideData.content} />
        </div>
      </div>
      <div className="container mx-auto lg:flex lg:justify-between md:mb-16 ">
        <ContactCardsContainer sideCardList={sideCardList} />
        <ContactanosForm />
      </div>
    </>
  );
};

export default Contact;
