import { useContext, useState } from "react";
import blueArrow from "../../assets/img/icons/arrow-dark-blue.svg";
import ProductsContainer from "../MenuLandingsLayout/ProductsContainer";
import CardsStepsCardContainerVehicle from "../MenuLandingsLayout/CardsStepsCardContainerVehicle";
import VideoContainer from "../videoContainer/VideoContainer";
import { ClaimsVehicleDataContext } from "../../context/Claims/ClaimsVehicleDataContext";

const ClaimsSingleStepsVehicle = ({
  stepsTitle,
  stepsSubTitle,
  formBtnText,
  formBtnLink,
  docTitle,
  docSubTitle,
  docBtnText,
  docBtnContent,
  channelsTitle,
  channelsText,
}: any) => {
  const [showDocContent, setShowDocContent] = useState(false);
  const { videoData, channelsCardsInfo } = useContext(ClaimsVehicleDataContext);

  const handleClick = () => {
    setShowDocContent(!showDocContent);
  };

  const [showCards, setShowCards] = useState(false);
  return (
    <div className="vehicle-plan claims-list mb-4 w-full">
      <div>
        <div className="block mb-12 xl:flex lg:mb-8">
          <div className="xl:w-3/5">
            {stepsTitle && <h3>{stepsTitle}</h3>}
            {stepsSubTitle && <p>{stepsSubTitle}</p>}
            {formBtnLink && (
              <a className="link-border" href={formBtnLink}>
                {formBtnText}
              </a>
            )}
          </div>
          <div className="xl:w-3/6">
            <VideoContainer
              videoLink={videoData.videoLink}
              posterImage={videoData.posterImage}
            />
          </div>
        </div>
        <h3>{docTitle}</h3>
        <p>{docSubTitle}</p>
        <div className="btn-toogle cursor-pointer" onClick={handleClick}>
          {docBtnText}{" "}
          <img
            src={blueArrow}
            alt="chevron icon"
            className={`${showDocContent ? "blue-arrow-rotate" : "blue-arrow"}`}
          />
        </div>
        {showDocContent && (
          <div
            dangerouslySetInnerHTML={{ __html: docBtnContent }}
            className="required-documents"
          ></div>
        )}

        <div className="mt-6 steps-slide-fix">
          <section>
            <h2 dangerouslySetInnerHTML={{ __html: channelsTitle }}></h2>
            <p className="mt-5">{channelsText}</p>
          </section>
          <ProductsContainer showCards={showCards} setShowCards={setShowCards}>
            <CardsStepsCardContainerVehicle
              showCards={showCards}
              cards={channelsCardsInfo}
            />
          </ProductsContainer>
        </div>
      </div>
    </div>
  );
};

export default ClaimsSingleStepsVehicle;
