import axios from "axios";
import { useEffect, useState } from "react";
import { BASE_URL, GET_HEADERS } from "../../Helper/requestData";
import "./needHelpCard.css";

const NeedHelpCard = () => {
  const [data, setData] = useState({
    title: "Si ya tienes el producto y necesitas asistencia",
    phoneNumber: "809-960-7333",
    email: "servicioalcliente@segurosreservas.com",
    message1: "¡Estamos para ti!",
    phone1: "1 800 369 0820",
    phone2: "1 305 434 7012",
    message2: `Desde cualquier parte del mundo, vía telefónica`,
    wordlPhone: "1 305 232 9040",
    message3: "WhatsApp",
    message4: "Email",
    emailRedbridge: "service@redbridge.cc",
    whatsapp: "1 786 653 3717",
    phoneHelp: "809 476 3232",
  });

  const getNeedHelpData = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Necesitas-Asistencia-Card')/items",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((err) => console.error(err));

    const result = resultsArr.find(
      (r: any) => r.Title === "Necesitas Asistencia"
    );

    setData({
      title: result?.Text,
      phoneNumber: result?.PhoneNumber,
      email: result?.Email,
      message1: result?.Message1,
      phone1: result?.Phone1,
      phone2: result?.Phone2,
      message2: result?.Message2,
      wordlPhone: result?.WordlPhone,
      message3: result?.Message3,
      message4: result?.Message4,
      emailRedbridge: result?.EmailRedbridge,
      whatsapp: result?.Whatsapp,
      phoneHelp: result?.PhoneHelp,
    });
  };

  useEffect(() => {
    getNeedHelpData();
  }, []);

  return (
    <div className="need-help">
      <div className="need-help-title"> {data.title}</div>

      <div className="need-help-block">
        <h3 className="need-help-message">{`${data.message1}`}</h3>
        <a href={`tel:${data.phone1}`}>
          <span>+</span>
          {data.phone1}
        </a>
        <a href={`tel:${data.phone2}`}>
          <span>+</span>
          {data.phone2}
        </a>
      </div>

      <div className="need-help-block">
        <h3 className="need-help-message">{`${data.message2}`}</h3>
        <a href={`tel:${data.wordlPhone}`}>
          <span>+</span>
          {data.wordlPhone}
        </a>
      </div>

      <div className="need-help-block">
        <h3 className="need-help-message">{`${data.message3}`}</h3>
        <a href={`tel:${data.whatsapp}`}>
          <span>+</span>
          {data.whatsapp}
        </a>
      </div>
      <div className="need-help-block">
        <h3 className="need-help-message">{`${data.message4}`}</h3>
        <a className="need-help-orange-large" href={`mailto:${data.whatsapp}`}>
          {data.emailRedbridge}
        </a>
      </div>
    </div>
  );
};

export default NeedHelpCard;
