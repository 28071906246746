import { Link } from "react-router-dom";

type CardsContainerClaimsType = {
  cardTitle: string;
  cardImg: string;
  isSlide: boolean;
  content: string;
  link: any;
  linkType: string;
};

const CardsComponentClaims = ({
  cardTitle,
  cardImg,
  isSlide,
  content,
  link,
  linkType,
}: CardsContainerClaimsType) => {
  const renderContent = () => {
    if (linkType === "email") {
      return (
        <a href={`mailto:${link?.Description}`}>
          <div className="claims-channel-card__top">
            <img src={cardImg} alt="card content" />
            <h2>{cardTitle}</h2>
          </div>
          <div dangerouslySetInnerHTML={{ __html: content }}></div>
        </a>
      );
    } else if (linkType === "phone") {
      return (
        <a href={`tel:${link?.Description}`}>
          <div className="claims-channel-card__top">
            <img src={cardImg} alt="card content" />
            <h2>{cardTitle}</h2>
          </div>
          <div dangerouslySetInnerHTML={{ __html: content }}></div>
        </a>
      );
    } else if (linkType === "externalUrl") {
      return (
        <a href={link?.Url} target="_blank" rel="noreferrer">
          <div className="claims-channel-card__top">
            <img src={cardImg} alt="card content" />
            <h2>{cardTitle}</h2>
          </div>
          <div dangerouslySetInnerHTML={{ __html: content }}></div>
        </a>
      );
    } else {
      return (
        <Link to={link ? link?.Description : "#"}>
          <div className="claims-channel-card__top">
            <img src={cardImg} alt="card content" />
            <h2>{cardTitle}</h2>
          </div>
          <div dangerouslySetInnerHTML={{ __html: content }}></div>
        </Link>
      );
    }
  };

  return (
    <div
      className={
        isSlide
          ? "card-box-slide card-box-slide--claims-fix "
          : "claims-channel-card"
      }
    >
      {renderContent()}
    </div>
  );
};

export default CardsComponentClaims;
