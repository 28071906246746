import SocialMediaDto from "../../dtos/SocialMediaDto";
import { useEffect, useState } from "react";
import { getSocialMediaDataService } from "../../services/SocialMediaDataService";

const SocialNetworkComponent = () => {
  const [socialMediaData, setSocialMediaData] = useState<SocialMediaDto[]>([
    new SocialMediaDto(),
  ]);

  async function getSocialMediaData() {
    setSocialMediaData(await getSocialMediaDataService());
  }

  function renderList() {
    return socialMediaData.map((item: any) => {
      return (
        <a key={item.order} className="footer__social-media" href={item?.link}>
          <img className="inline-block" src={item?.icon} alt={item?.title} />
        </a>
      );
    });
  }

  useEffect(() => {
    getSocialMediaData();
  }, []);

  return (
    <>
      <div className="footer__social-box">{renderList()}</div>
    </>
  );
};

export default SocialNetworkComponent;
