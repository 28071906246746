import { BASE_URL, GET_HEADERS } from "../Helper/requestData";
import { useContext, useEffect, useState } from "react";

import ContactsComponent from "../contacts/ContactsComponent";
import { Link } from "react-router-dom";
import { NavigationContext } from "../../routes/menulinks";
import SocialNetworkComponent from "../socialNetworks/SocialNetworkComponent";
import arrow from "../../assets/img/icons/chevron-right.svg";
import axios from "axios";

const FooterMenus = ({ headerFooterData }: any) => {
  const [ourCompany, setOurCompany] = useState(false);
  const [ourInsurance, setOurInsurance] = useState(false);
  const [navLinks, setNavLinks] = useState([
    {
      title: "",
    },
  ]);
  const { menuLinks } = useContext(NavigationContext);
  const getData = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL + "_api/web/lists/getbytitle('Main-NavBar-Data')/items",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    setNavLinks(
      resultsArr.map((item: any) => {
        return {
          title: item?.Title,
        };
      })
    );
  };

  useEffect(() => {
    getData();
  }, []);

  function renderLinks() {
    return navLinks.map((l, i) => {
      if (l.title === "Seguros de Vehículos") {
        return (
          <li key={i}>
            <Link to={menuLinks.segurosVehiculos}>{l.title}</Link>
          </li>
        );
      }
      if (l.title === "Seguros de Personas") {
        return (
          <li key={i}>
            <Link to={menuLinks.segurosPersonas}>{l.title}</Link>
          </li>
        );
      }
      if (l.title === "Seguros de Propiedades") {
        return (
          <li key={i}>
            <Link to={menuLinks.segurosPropiedades}>{l.title}</Link>
          </li>
        );
      }
      if (l.title === "Seguros Múltiples") {
        return (
          <li key={i}>
            <Link to={menuLinks.segurosMultiples}>{l.title}</Link>
          </li>
        );
      }
      return null;
    });
  }
  return (
    <>
      <div className="footer__cols lg:flex lg:space-x-4">
        <div className="lg:w-1/4">
          <div
            className="footer__collapse-link"
            onClick={() => setOurInsurance(!ourInsurance)}
          >
            Nuestra Empresa
            <img
              src={arrow}
              alt="arrow"
              className={`transition ease-in-out duration-100 lg:hidden ${
                ourInsurance ? "rotate-90" : ""
              } `}
            />
          </div>
          <ul
            className={`footer__link-list  ${
              ourInsurance ? "xs:block " : "hidden"
            } lg:block`}
          >
            <li>
              <Link to={menuLinks.corporateidentity}>Nosotros</Link>
            </li>
            <li>
              <Link to={menuLinks.claimsLanding}>Reclamaciones</Link>
            </li>
            <li>
              <Link to={menuLinks.news}>Noticias</Link>
            </li>
            <li>
              <a
                target="_blank"
                href={menuLinks.servicesLanding + "#Facilidades-Pagos"}
                rel="noreferrer"
              >
                Vías y Facilidades de Pago
              </a>
            </li>

            <li>
              <Link to={menuLinks.servicesLanding}>Canales de Servicios</Link>
            </li>
            <li>
              <Link to={menuLinks.contact}>Contáctanos</Link>
            </li>
          </ul>
        </div>
        <div className="lg:w-1/4">
          <div
            className="footer__collapse-link"
            onClick={() => setOurCompany(!ourCompany)}
          >
            Nuestros Seguros
            <img
              src={arrow}
              alt="arrow"
              className={`transition ease-in-out duration-100 lg:hidden ${
                ourCompany ? "rotate-90" : ""
              } `}
            />
          </div>
          <ul
            className={`footer__link-list ${
              ourCompany ? "xs:block" : "hidden"
            } lg:block`}
          >
            {renderLinks()}
          </ul>
        </div>
        <div className="lg:w-1/3">
          <div className="footer__title">{headerFooterData?.title}</div>
          <ContactsComponent
            sucursales={true}
            headerFooterData={headerFooterData}
          />
          <SocialNetworkComponent />
        </div>
        <div className="footer__cert-box xl:w-1/4 lg:block">
          <img src={headerFooterData?.stamp} alt="stamp" />
        </div>
      </div>
    </>
  );
};

export default FooterMenus;
