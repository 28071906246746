import { Splide, SplideSlide } from "@splidejs/react-splide";
import { useContext } from "react";
import arrow from "../../assets/img/icons/chevron-dark-gray.svg";
import { ClaimsPeopleDataContext } from "../../context/Claims/ClaimsPeopleDataContext";
import CardsComponentSteps, {
  ClaimsCardsInterface,
} from "./CardsComponentSteps";

type CardsContainerProps = {
  cards: ClaimsCardsInterface[] | undefined;
  showCards: boolean;
};
const CardsStepsCardContainer = ({ cards, showCards }: CardsContainerProps) => {
  const { modalTexts, modalPasos } = useContext(ClaimsPeopleDataContext);
  return (
    <div className="flex flex-col gap-6 mx-auto cards-container lg:grid lg:grid-cols-2">
      {cards.length > 0 &&
        cards.map((card) => (
          <div
            key={card.cardId}
            className={`${showCards ? "block" : "hidden"} lg:flex`}
          >
            <CardsComponentSteps
              cardImg={card.cardImg}
              cardTitle={card.cardTitle}
              isSlide={false}
              linkText1={card.linkText1}
              linkText2={card.linkText2}
              link3={card.link3}
              content={card.content}
              modalTitle={card.modalTitle}
              modalContent={card.modalContent}
              modal2Image={card.modal2Image}
              hasVideo={card.hasVideo}
              modalPasos={modalPasos}
              modalTexts={modalTexts}
            />
          </div>
        ))}

      <div
        className={`${showCards ? "hidden" : "block self-center"} lg:hidden`}
      >
        <Splide
          renderControls={() => (
            <div className="splide__arrows">
              <div className="container ">
                <div className="splide__arrow splide__arrow--next ">
                  <img src={arrow} alt="arrow" />
                </div>
                <div className="splide__arrow splide__arrow--prev ">
                  <img src={arrow} alt="arrow" className="left-arrow " />
                </div>
              </div>
            </div>
          )}
        >
          {cards.map((card) => (
            <SplideSlide key={card.cardId}>
              <>
                <CardsComponentSteps
                  cardImg={card.cardImg}
                  cardTitle={card.cardTitle}
                  isSlide={true}
                  linkText1={card.linkText1}
                  linkText2={card.linkText2}
                  link3={card.link3}
                  content={card.content}
                  modalTitle={card.modalTitle}
                  modalContent={card.modalContent}
                  hasVideo={card.hasVideo}
                  modal2Image={card.modal2Image}
                  modalPasos={modalPasos}
                  modalTexts={modalTexts}
                />
              </>
            </SplideSlide>
          ))}
        </Splide>
      </div>
    </div>
  );
};

export default CardsStepsCardContainer;
