import { useEffect, useState } from "react";

import { BASE_URL } from "../Helper/requestData";
import FooterMenus from "./FooterMenus";
import HeaderFooterDataDto from "../../dtos/HeaderFooterDataDto";
import { Link } from "react-router-dom";
import { getHeaderFooterDataService } from "../../services/HeaderFooterDataService";

const FooterComponent = () => {
  const [headerFooterData, setHeaderFooterData] = useState<HeaderFooterDataDto>(
    new HeaderFooterDataDto()
  );

  async function getHeaderFooterData() {
    setHeaderFooterData(await getHeaderFooterDataService());
  }

  useEffect(() => {
    getHeaderFooterData();
  }, []);
  return (
    <footer className="section footer">
      <div className="container mx-auto py-16  lg:py-0">
        <img
          src={headerFooterData.logoWhite}
          alt="Seguros Reservas logo white"
          className="footer__logo lg:hidden "
        />

        <FooterMenus headerFooterData={headerFooterData} />
        <div className="pt-8 footer__bottom">
          <div className="lg:flex">
            <div className="lg:w-1/2">
              <img
                src={headerFooterData.logoWhite}
                alt="Seguros Reservas logo white"
                className="hidden m-0 footer__logo lg:block"
              />
            </div>

            <div className="xl:text-right lg:w-1/2">
              <div className="lg:flex justify-end gap-3 mb-2">
                <img
                  src={headerFooterData?.logoAmBest}
                  alt="logo ambest"
                  className="w-1/2 lg:w-1/4"
                />
                <img
                  src={headerFooterData?.logoFitchRatings}
                  alt="logo fitchratings"
                  className="w-1/2 lg:w-1/4 mt-4 lg:mt-0"
                />
              </div>
              <Link to={BASE_URL + headerFooterData?.legalDocumentsLink}>
                {headerFooterData?.legalDocumentsText}
              </Link>
              <span className="mx-2">|</span>
              <Link to={BASE_URL + headerFooterData?.glossaryLink}>
                {headerFooterData?.glossaryText}
              </Link>
              <span className="hidden disclaimer lg:block">
                {headerFooterData?.disclaimer}
              </span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterComponent;
