import { BASE_URL } from "../Helper/requestData";

type sucursalProp = {
  sucursales: boolean;
  headerFooterData: any;
};

const ContactsComponent = ({ sucursales, headerFooterData }: sucursalProp) => {
  function getCleanNumber(number: string) {
    const numbers = "0123456789";
    let cleanNumber = "";

    number &&
      number.split("").forEach((n: string) => {
        if (numbers.includes(n)) {
          cleanNumber += n;
        }
      });

    return cleanNumber;
  }

  return (
    <>
      <a
        className="footer__contact"
        href={`tel:${headerFooterData?.phoneNumber}`}
      >
        <img src={headerFooterData?.phoneIcon} alt="phone icon" />
        <span>{headerFooterData?.phoneNumber}</span>
      </a>

      <a
        className="footer__contact"
        href={`https://api.whatsapp.com/send?phone=${getCleanNumber(
          headerFooterData?.whatsappNumber
        )}`}
      >
        <img src={headerFooterData?.whatsappIcon} alt="whatsapp icon" />
        <span>
          {headerFooterData?.whatsappNumber}
          <span className="blue">{headerFooterData?.whatsappText}</span>
        </span>
      </a>
      {sucursales ? (
        <a
          className="footer__contact"
          href={BASE_URL + headerFooterData?.locationLink}
        >
          <img src={headerFooterData?.locationIcon} alt="location icon" />
          <span>{headerFooterData?.locationText}</span>
        </a>
      ) : null}
      <a className="footer__contact" href={`mailto:${headerFooterData?.email}`}>
        <img src={headerFooterData?.emailIcon} alt="mail icon" />
        <span>{headerFooterData?.email}</span>
      </a>
    </>
  );
};

export default ContactsComponent;
