import whiteArrow from "../../assets/img/icons/chevron-right.svg";
import orangeArrow from "../../assets/img/icons/chevron-right-orange.svg";
const OtherServicesTable = ({ tableList, handleClick }: any) => {
  return (
    <tbody>
      {tableList !== undefined &&
        tableList.map((list: any, i: number) =>
          list.serviceType ? (
            <tr
              className="titles-container"
              onClick={() => {
                handleClick(list.serviceName, list.serviceParent);
              }}
              key={i}
            >
              <th>{list.serviceName}</th>
              <th>
                <img
                  src={whiteArrow}
                  alt="white arrow icon"
                  className="white-arrow md:hidden"
                />
              </th>
            </tr>
          ) : (
            <tr key={i} className="table-list">
              <td colSpan={2}>
                <p>
                  <b>
                    <img src={orangeArrow} className="mr-2 w-2 inline" />
                    {list.serviceName}{" "}
                  </b>
                  {list.additionalInfo}
                </p>
              </td>
            </tr>
          )
        )}
    </tbody>
  );
};

export default OtherServicesTable;
