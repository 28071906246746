import { useState, useEffect, useContext } from "react";
import axios from "axios";
import InformationComponent from "../../components/InformationComponent/InformationComponent";
import HeroMenuComponent from "../../components/MenuLandingsLayout/HeroMenuComponent";
import FuneralAssistanceTableDesktop from "../../components/PeopleComponents/FuneralAssistanceTableDesktop";
import FuneralAssistanceTableMobile from "../../components/PeopleComponents/FuneralAssistanceTableMobile";
import SecondaryNavBar from "../../components/secondaryNavBar/SecondaryNavBar";
import SideBar from "../../components/sideBar/SideBar";
import SideBarList from "../../components/sideBar/SideBarList";
import { BASE_URL, GET_HEADERS } from "../../components/Helper/requestData";

import MoreInformationCard from "../../components/VariousCardsComponents/moreInfoCard/MoreInformationCard";
import { PeopleMenuContext } from "../../context/People/PeopleMenuContext";
import { SideListModel } from "../../context/models/SideList.model";

const FuneralAssistance = () => {
  const [heroSideData, setHeroSideData] = useState({
    title: "",
    titleRich: "",
    bannerImage: "",
    secondaryTitle: "",
    sideTitle1: "",
    sideContent1: "",
    sideTitle2: "",
    sideContent2: "",
    content: "",
    middleTitle: "",
  });

  const [sideList, setSideList] = useState<SideListModel[]>([]);
  const [secondaryNavBarButtons, setSecondaryNavBarButtons] = useState({
    // btnLinkCotizar: "",
    // btnImgCotizar: "",
    // btnTitleCotizar: "",
    btnLinkBrochure: "",
    btnImgBrochure: "",
    btnTitleBrochure: "",
    btnBlockActive: true,
  });
  const [mobilePlanesInfo, setMobilePlanesInfo] = useState([
    {
      id: 0,
      title: "",
      mainTitle: "",
      labelOne: "",
      labelTwo: "",
      labelThree: "",
      valueOne: "",
      valueTwo: "",
      valueType: "",
      serviceOne: "",
      serviceTwo: "",
    },
  ]);

  const [desktopPlanesInfo, setDesktopPlanesInfo] = useState([
    {
      id: 0,
      title: "",
      mainTitle: "",
      labelOne: "",
      labelTwo: "",
      labelThree: "",
      valueOne: "",
      valueTwo: "",
      valueThree: "",
    },
  ]);

  const { menuListData, subMenuListData } = useContext(PeopleMenuContext);

  const getSectionContentData = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Contenido-de-Secciones')/items?$filter=Title eq 'Asistencia Funeraria'",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    setHeroSideData({
      titleRich: resultsArr[0]?.TitleRich,
      title: resultsArr[0]?.Title,
      bannerImage: resultsArr[0]?.BannerImage.Url,
      secondaryTitle: resultsArr[0]?.SecondaryTitle,
      sideTitle1: resultsArr[0]?.SideTitle1,
      sideContent1: resultsArr[0]?.SideContent1,
      sideTitle2: resultsArr[0]?.SideTitle2,
      sideContent2: resultsArr[0]?.SideContent2,
      content: resultsArr[0]?.Content,
      middleTitle: resultsArr[0]?.MiddleTitle,
    });
  };

  const getSecondaryNavBarButtons = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Secondary-NavBar-Buttons')/items",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    const result = resultsArr.find((r: any) => r.Title === "Vía Reservas");

    setSecondaryNavBarButtons({
      // btnLinkCotizar: result?.BtnLinkCotizar?.Description,
      // btnImgCotizar: result?.BtnImgCotizar?.Url,
      // btnTitleCotizar: result?.BtnTitleCotizar,
      btnLinkBrochure: result?.BtnLinkBrochure?.Description,
      btnImgBrochure: result?.BtnImgBrochure?.Url,
      btnTitleBrochure: result?.BtnTitleBrochure,
      btnBlockActive: result?.Activo,
    });
  };

  const getSideList = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Seguros-Servicios-SideLists')/items?$filter=Categoria eq 'Asistencia Funeraria'",

        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    resultsArr.sort(
      (a: { Order0: number }, b: { Order0: number }) => a.Order0 - b.Order0
    );

    setSideList(
      resultsArr.map((item: any) => {
        return {
          title: item?.Text,
          id: item?.Order0,
        };
      })
    );
  };

  const getMobilePlanesInfo = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Asistencia-Funeraria-Planes')/items?$filter=DeviceType eq 'mobile'",

        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    setMobilePlanesInfo(
      resultsArr.map((item: any) => {
        return {
          title: item?.Title,
          mainTitle: item?.MainTitle,
          id: item?.Order0,
          labelOne: item?.Label1,
          labelTwo: item?.Label2,
          labelThree: item?.Label3,
          valueOne: item?.Value1,
          valueTwo: item?.Value2,
          valueType: item?.ValueType,
          serviceOne: item?.Service1,
          serviceTwo: item?.Service2,
        };
      })
    );
  };

  const getDesktopPlanesInfo = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Asistencia-Funeraria-Planes')/items?$filter=DeviceType eq 'desktop'",

        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    setDesktopPlanesInfo(
      resultsArr.map((item: any) => {
        return {
          title: item?.Title,
          mainTitle: item?.MainTitle,
          id: item?.Order0,
          labelOne: item?.Label1,
          labelTwo: item?.Label2,
          valueOne: item?.Value1,
          valueTwo: item?.Value2,
          labelThree: item?.Label3,
          valueThree: item?.Value3,
        };
      })
    );
  };

  useEffect(() => {
    getSectionContentData();
    getSideList();
    getMobilePlanesInfo();
    getDesktopPlanesInfo();
    getSecondaryNavBarButtons();
  }, []);

  return (
    <>
      <HeroMenuComponent
        bannerImage={heroSideData.bannerImage}
        title={heroSideData.titleRich}
      />
      <SecondaryNavBar
        title={heroSideData.secondaryTitle || heroSideData.titleRich}
        menuList={menuListData}
        subMenuList={subMenuListData}
        btnLinkBrochure={secondaryNavBarButtons.btnLinkBrochure}
        btnImgBrochure={secondaryNavBarButtons.btnImgBrochure}
        btnTitleBrochure={secondaryNavBarButtons.btnTitleBrochure}
        btnBlockActive={secondaryNavBarButtons.btnBlockActive}
      />

      <div className="container mx-auto lg:flex lg:justify-between md:mb-16 ">
        <div className="lg:w-3/5">
          <InformationComponent content={heroSideData.content} />
          <div className="mt-10 lg:mt-10 lg:w-3/4"></div>
        </div>
        <div className="mt-10 lg:mt-14 lg:w-1/3">
          <SideBar>
            {heroSideData.sideTitle1 && (
              <SideBarList
                sideTitle={heroSideData.sideTitle1}
                sideBarList={sideList}
              />
            )}
          </SideBar>

          <div className="mt-10 lg:hidden multirisk-plans">
            <h2>{heroSideData?.middleTitle}</h2>
            <hr className="hidden lg:block" />
            <div>
              {mobilePlanesInfo?.map((e) =>
                e.mainTitle ? null : (
                  <FuneralAssistanceTableMobile
                    key={e?.id}
                    title={e?.title}
                    values={e}
                  />
                )
              )}
            </div>
          </div>

          <div className="my-10 lg:hidden">
            <MoreInformationCard />
          </div>
        </div>
      </div>
      <div className="container hidden mx-auto lg:block">
        <div className=" multirisk-plans">
          <h2>{heroSideData?.middleTitle}</h2>
          <hr className="hidden lg:block" />
          <div>
            <FuneralAssistanceTableDesktop
              list={desktopPlanesInfo}
              headerTitle="Tarifas"
            />
          </div>
        </div>
        <div className="mb-24 mt-14">
          <MoreInformationCard />
        </div>
      </div>
    </>
  );
};

export default FuneralAssistance;
