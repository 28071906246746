import arrow from "../../assets/img/icons/chevron-right-gray.svg";
import blueArrow from "../../assets/img/icons/chevron-right-blue-thin.svg";
import { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL, GET_HEADERS } from "../Helper/requestData";
import { Link } from "react-router-dom";

const SectionComponentOne = () => {
  const [dataState, setDataState] = useState([
    {
      iconTitle: "",
      iconActive: "",
      iconInactive: "",
      text: "",
      image: "",
      enlace: "",
      enlaceText: "",
      seeAllLink: "",
    },
  ]);

  const [slideIndex, setSlideIndex] = useState(0);

  const getData = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL + "_api/web/lists/getbytitle('Home-S1-Adquiere-Seguro')/items",

        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    setDataState(
      resultsArr.map((item: any) => {
        return {
          iconTitle: item?.Title,
          iconActive: item?.IconActive.Url,
          iconInactive: item?.IconInactive.Url,
          text: item?.nqho,
          image: item?.Image.Url,
          enlace: item?.Enlace.Url,
          enlaceText: item?.OData__x0076_qs9,
          seeAllLink: item?.Enlace_x0020_Ver_x0020_Productos.Description,
        };
      })
    );
  };

  useEffect(() => {
    getData();
  }, []);

  const handleArrowLeft = () => {
    if (slideIndex !== 0) {
      setSlideIndex(slideIndex - 1);
    }
  };
  const handleArrowRight = () => {
    if (slideIndex < dataState.length - 1) {
      setSlideIndex(slideIndex + 1);
    }
  };
  return (
    <div className="section seguros">
      <div className="container mx-auto">
        <div className="title">Adquiere tu seguro en línea</div>
        <div className="icon-slider lg:px-32 ">
          <img
            src={slideIndex !== 0 ? blueArrow : arrow}
            alt="left-arrow"
            className={`hidden sm:block ${
              slideIndex !== 0 ? "left-arrow-blue " : "left-arrow"
            }`}
            onClick={handleArrowLeft}
          />

          <div className="icons-container lg:px-20 ">
            {dataState.map((slide, index) => (
              <div
                key={slide.image}
                className={`icon-box-general ${
                  slideIndex === index ? "icon-box-active" : "icon-box"
                }`}
                onClick={() => {
                  setSlideIndex(index);
                }}
              >
                <img
                  src={
                    slideIndex === index
                      ? dataState[slideIndex].iconActive
                      : dataState[index].iconInactive
                  }
                  alt="icon image"
                />
                <p>{dataState[index].iconTitle}</p>
              </div>
            ))}
          </div>
          <img
            src={slideIndex !== 2 ? blueArrow : arrow}
            alt="right-arrow"
            className={`hidden sm:block ${
              slideIndex !== 2 ? "right-arrow" : " right-arrow-gray"
            }`}
            onClick={handleArrowRight}
          />
        </div>

        <div id="main-slider" className=" xl:px-40">
          <div className="items-center xl:space-x-4 box xl:flex">
            <div className="xl:w-1/2">
              <img
                src={dataState[slideIndex].image}
                alt="content image"
                className="seguros__image"
              />
            </div>
            <div className="xl:w-1/2">
              <div className="seguros__info">
                <p className="seguros__text">{dataState[slideIndex].text}</p>
                <Link
                  className="seguros__link"
                  to={dataState[slideIndex].seeAllLink}
                >
                  Ver todos los productos
                </Link>
                <a
                  href={dataState[slideIndex].enlace}
                  className="seguros__button"
                >
                  {dataState[slideIndex].enlaceText}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionComponentOne;
