export default class SocialMediaDto {
  private _title?: string;
  private _link?: string;
  private _icon?: string;
  private _order?: number;

  public get title(): string {
    return this._title;
  }

  public set title(value: string) {
    this._title = value;
  }

  public get link(): string {
    return this._link;
  }

  public set link(value: string) {
    this._link = value;
  }

  public get icon(): string {
    return this._icon;
  }

  public set icon(value: string) {
    this._icon = value;
  }

  public get order(): number {
    return this._order;
  }

  public set order(value: number) {
    this._order = value;
  }
}
