import orangeArrow from "../../assets/img/icons/chevron-right-orange.svg";

export type SubsidiariesComponentProps = {
  image: string;
  link: string;
  info: string;
  linkText: string;
};

const SubsidiariesComponent = ({
  image,
  link,
  info,
  linkText,
}: SubsidiariesComponentProps) => {
  return (
    <div className="p-6 subsidiaries-box lg:flex lg:items-center">
      <div className="image-box">
        <img className="sub-logo" src={image} alt="logo image" />
      </div>
      <div className="sub-text">
        <h2>{info}</h2>
        {link && linkText && (
          <a href={link} target="_blank" rel="noreferrer">
            {linkText} <img src={orangeArrow} alt="chevron logo" />
          </a>
        )}
      </div>
    </div>
  );
};

export default SubsidiariesComponent;
