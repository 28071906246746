import "./css/vehicleMultiriskTable.css";

export type VehicleMultiriskTableType = {
  id?: number;
  mainTitle?: string;
  planDescription?: string;
  topSectionTitle?: string;
  topList?: string;
  bottomSectionTitle?: string;
  bottomSectionText?: string;
};
const VehicleMultiriskTable = ({
  mainTitle,
  planDescription,
  topSectionTitle,
  topList,
  bottomSectionTitle,
  bottomSectionText,
}: VehicleMultiriskTableType) => {
  return (
    <div className="multiriskTable">
      <div className="header">
        <h3>{mainTitle}</h3>
      </div>
      <div className="content-information">
        <div className="description">
          <p className="hidden md:block">{planDescription}</p>
        </div>
        <div className="top">
          <h4>{topSectionTitle}</h4>
          <div
            className="list-parent-div "
            dangerouslySetInnerHTML={{ __html: topList }}
          ></div>
        </div>
        <div className="bottom">
          <h4>{bottomSectionTitle}</h4>
          <p>{bottomSectionText}</p>
        </div>
      </div>
    </div>
  );
};

export default VehicleMultiriskTable;
