const SideBarListBlue = ({
  sideBarList,
  sideBarContent,
  sideBarTitle,
}: any) => {
  return (
    <div className="side-bar-list side-bar-list--blue">
      <div className="mb-4 text-content">
        <div className="list-title">{sideBarTitle}</div>
        <div dangerouslySetInnerHTML={{ __html: sideBarContent }}></div>
      </div>
      <div dangerouslySetInnerHTML={{ __html: sideBarList }}></div>
    </div>
  );
};

export default SideBarListBlue;
