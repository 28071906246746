import { SortNewsEnum } from "./news.enum";
import { newsFilterViewMock } from "../../pages/News/mock/news.mock";
import Select from "react-select";

const FilterComponent = ({ setFilterSort, filterPlaceholder }: any) => {
  const customStyles = {
    option: (provided, state) => {
      let background = "#fff";

      if (state.isFocused || state.isSelected) {
        background = "#fff";
      }
      return {
        ...provided,
        background,
        border: "none",
        fontFamily: "FreightSansProMedium",
        fontSize: "18px",
        color: "#264e72",
        cursor: "pointer",
        fontWeight: "400",
      };
    },
    container: (provided) => {
      return {
        ...provided,
        width: "100%",
        height: "57px",
      };
    },
    control: (provided) => {
      return {
        ...provided,
        width: "100%",
        height: "57px",
        borderRadius: "10px",
        padding: "0 8px",
        cursor: "pointer",
      };
    },
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return {
        ...provided,
        opacity,
        transition,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        fontSize: "18px",
        color: "#264e72",
        fontWeight: "400",
      };
    },
    placeholder: (provided) => ({
      ...provided,
      fontFamily: "FreightSansProMedium",
      fontSize: "18px",
      color: "#264e72",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      fontWeight: "400",
    }),
    dropdownIndicator: (provided, state) => {
      let color = "#264e72";

      if (state.isFocused) {
        color = "#264e72";
      }

      return {
        ...provided,
        fontFamily: "FreightSansProMedium",
        fontSize: "18px",
        color,
      };
    },
    indicatorSeparator: () => ({
      display: "none",
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: "10px",
    }),
    menuList: (provided) => {
      return {
        ...provided,
        marginTop: "0",
        borderRadius: "10px",
      };
    },
  };

  const OrderOptions = [
    {
      value: SortNewsEnum.recentOldest,
      label: newsFilterViewMock[0]?.dateOptionOne,
    },
    {
      value: SortNewsEnum.oldestRecent,
      label: newsFilterViewMock[0]?.dateOptionTwo,
    },
  ];

  const handleChange = (value) => {
    const filterValue = value as SortNewsEnum;
    setFilterSort(filterValue);
  };

  return (
    <div className="date-filter lg:flex lg:items-center  ">
      <h3 className="mb-3 w-44">{filterPlaceholder}</h3>

      <Select
        className="react-select-news-container "
        classNamePrefix="react-select-news"
        styles={customStyles}
        options={OrderOptions}
        placeholder="Fecha"
        isSearchable={false}
        onChange={(value) => handleChange(value.value)}
      />
    </div>
  );
};

export default FilterComponent;
