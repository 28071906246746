import { Information } from "./type";
import "./css/claimsContent.css";
function ClaimsContent({ content }: Information) {
  return (
    <div className="claims-section lg:max-w-screen-lg lg:mx-auto">
      <div
        className="claims-section-intro"
        dangerouslySetInnerHTML={{ __html: content }}
      ></div>
    </div>
  );
}

export default ClaimsContent;
