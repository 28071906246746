import { useState, useEffect, useContext } from "react";
import axios from "axios";
import InformationComponent from "../../components/InformationComponent/InformationComponent";
import HeroMenuComponent from "../../components/MenuLandingsLayout/HeroMenuComponent";
import ImmigrationSupportTableDesktop from "../../components/PeopleComponents/ImmigrationSupportTableDesktop";
import ImmigrationSupportTableMobile from "../../components/PeopleComponents/ImmigrationSupportTableMobile";
import SecondaryNavBar from "../../components/secondaryNavBar/SecondaryNavBar";
import SideBar from "../../components/sideBar/SideBar";
import SideBarList from "../../components/sideBar/SideBarList";
import Faq from "../../components/VariousCardsComponents/btnCards/Faq";
import MoreInformationCard from "../../components/VariousCardsComponents/moreInfoCard/MoreInformationCard";
import "./css/immigrationSupport.css";
import { BASE_URL, GET_HEADERS } from "../../components/Helper/requestData";
import { PeopleMenuContext } from "../../context/People/PeopleMenuContext";
import { SideListModel } from "../../context/models/SideList.model";

const ImmigrationSupport = () => {
  const [heroSideData, setHeroSideData] = useState({
    title: "",
    titleRich: "",
    bannerImage: "",
    secondaryTitle: "",
    sideTitle1: "",
    sideContent1: "",
    sideTitle2: "",
    sideContent2: "",
    content: "",
    middleTitle: "",
  });

  const [secondaryNavBarButtons, setSecondaryNavBarButtons] = useState({
    btnLinkCotizar: "",
    btnImgCotizar: "",
    btnTitleCotizar: "",
    btnLinkBrochure: "",
    btnImgBrochure: "",
    btnTitleBrochure: "",
    btnBlockActive: true,
  });

  const [plansButtons, setPlansButtons] = useState({
    faqLink: "",
    faqTitle: "",
    faqIcon: "",
    faqActive: true,
  });

  const [sideList, setSideList] = useState<SideListModel[]>([]);

  const [planesInfo, setPlanesInfo] = useState([
    {
      title: "",
      mainTitle: "",
      id: 0,
      labelOne: "",
      valueOne: "",
      labelTwo: "",
      valueTwo: "",
    },
  ]);

  const { menuListData, subMenuListData } = useContext(PeopleMenuContext);

  const getSectionContentData = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Contenido-de-Secciones')/items?$filter=Title eq 'Respaldo Migratorio'",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    setHeroSideData({
      titleRich: resultsArr[0]?.TitleRich,
      title: resultsArr[0]?.Title,
      bannerImage: resultsArr[0]?.BannerImage.Url,
      secondaryTitle: resultsArr[0]?.SecondaryTitle,
      sideTitle1: resultsArr[0]?.SideTitle1,
      sideContent1: resultsArr[0]?.SideContent1,
      sideTitle2: resultsArr[0]?.SideTitle2,
      sideContent2: resultsArr[0]?.SideContent2,
      content: resultsArr[0]?.Content,
      middleTitle: resultsArr[0]?.MiddleTitle,
    });
  };
  const getSecondaryNavBarButtons = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Secondary-NavBar-Buttons')/items",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    const result = resultsArr.find(
      (r: any) => r.Title === "Respaldo Migratorio"
    );

    setSecondaryNavBarButtons({
      btnLinkCotizar: result?.BtnLinkCotizar?.Description,
      btnImgCotizar: result?.BtnImgCotizar?.Url,
      btnTitleCotizar: result?.BtnTitleCotizar,
      btnLinkBrochure: result?.BtnLinkBrochure?.Description,
      btnImgBrochure: result?.BtnImgBrochure?.Url,
      btnTitleBrochure: result?.BtnTitleBrochure,
      btnBlockActive: result?.Activo,
    });
  };

  const getPlansButtons = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL + "_api/web/lists/getbytitle('Bottom-Buttons')/items",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    const result = resultsArr.find(
      (r: any) => r.Title === "Respaldo Migratorio"
    );

    setPlansButtons({
      faqLink: result?.FAQLink?.Description,
      faqIcon: result?.FAQIcon?.Url,
      faqTitle: result?.FAQTitle,
      faqActive: result?.Activo,
    });
  };

  const getSideList = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Seguros-Servicios-SideLists')/items?$filter=Categoria eq 'Respaldo Migratorio'",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    resultsArr.sort(
      (a: { Order0: number }, b: { Order0: number }) => a.Order0 - b.Order0
    );

    setSideList(
      resultsArr.map((item: any) => {
        return {
          title: item?.Text,
          id: item?.Order0,
        };
      })
    );
  };

  const getPlanesInfo = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Respaldo-Migratorio-Planes')/items",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    setPlanesInfo(
      resultsArr.map((item: any) => {
        return {
          title: item?.Title,
          mainTitle: item?.MainTitle,
          id: item?.Order0,
          labelOne: item?.Label1,
          valueOne: item?.Value1,
          labelTwo: item?.Label2,
          valueTwo: item?.Value2,
        };
      })
    );
  };

  useEffect(() => {
    getSectionContentData();
    getSecondaryNavBarButtons();
    getPlansButtons();
    getSideList();
    getPlanesInfo();
  }, []);

  return (
    <>
      <HeroMenuComponent
        bannerImage={heroSideData.bannerImage}
        title={heroSideData.titleRich}
      />
      <SecondaryNavBar
        title={heroSideData.secondaryTitle || heroSideData.titleRich}
        menuList={menuListData}
        subMenuList={subMenuListData}
        btnLinkCotizar={secondaryNavBarButtons.btnLinkCotizar}
        btnImgCotizar={secondaryNavBarButtons.btnImgCotizar}
        btnTitleCotizar={secondaryNavBarButtons.btnTitleCotizar}
        btnLinkBrochure={secondaryNavBarButtons.btnLinkBrochure}
        btnImgBrochure={secondaryNavBarButtons.btnImgBrochure}
        btnTitleBrochure={secondaryNavBarButtons.btnTitleBrochure}
        btnBlockActive={secondaryNavBarButtons.btnBlockActive}
      />

      <div className="container mx-auto lg:flex lg:justify-between md:mb-20 lg:mb-10 xl:mb-16">
        <div className="lg:w-3/5">
          <InformationComponent content={heroSideData.content} />
        </div>
        <div className="mt-10 lg:mt-14 lg:w-1/3">
          <SideBar>
            {heroSideData.sideTitle1 && (
              <SideBarList
                sideTitle={heroSideData.sideTitle1}
                sideBarList={sideList}
              />
            )}
          </SideBar>
        </div>

        <div className="mt-10 lg:hidden multirisk-plans">
          <h2>{heroSideData.middleTitle}</h2>
          <hr className="hidden lg:block" />
          <div>
            {planesInfo?.map((e) =>
              e.mainTitle ? null : (
                <ImmigrationSupportTableMobile
                  key={e?.id}
                  title={e?.title}
                  values={e}
                />
              )
            )}
          </div>
        </div>

        <div className="my-10 lg:hidden">
          <MoreInformationCard />
          <Faq
            faqLink={plansButtons.faqLink}
            faqTitle={plansButtons.faqTitle}
            faqIcon={plansButtons.faqIcon}
          />
        </div>
      </div>

      <div className="container hidden mx-auto lg:block">
        <div className=" multirisk-plans">
          <h2>{heroSideData.middleTitle}</h2>
          <hr className="hidden lg:block" />
          <div>
            <ImmigrationSupportTableDesktop
              list={planesInfo}
              headerTitle="Tarifas"
            />
          </div>
        </div>
        <div className="mb-24 mt-14">
          <MoreInformationCard />
          <Faq
            faqLink={plansButtons.faqLink}
            faqTitle={plansButtons.faqTitle}
            faqIcon={plansButtons.faqIcon}
          />
        </div>
      </div>
    </>
  );
};

export default ImmigrationSupport;
