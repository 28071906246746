import { Listbox, Transition } from "@headlessui/react";
import { Fragment } from "react";

const ListBoxComponentComparator = ({
  planList,
  selectedPlan,
  setSelectedPlan,
}: any) => {
  return (
    <Listbox value={selectedPlan} onChange={setSelectedPlan}>
      <div className="relative">
        <Listbox.Button className="relative w-full selector">
          <span className="block truncate selector-title">
            {selectedPlan?.title}
          </span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"></span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="selector-options">
            {planList &&
              planList?.map((person: any, personIdx: any) => (
                <Listbox.Option
                  key={personIdx}
                  className={({ active }) =>
                    `selector-option ${active && "bg-blue-200"}`
                  }
                  value={person}
                >
                  {({ selected }) => (
                    <>
                      <span className="selector-option-title">
                        {person?.title}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600"></span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};

export default ListBoxComponentComparator;
