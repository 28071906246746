import { useState } from "react";
import { Link } from "react-router-dom";
import { CardsInterface2 } from "../../context/models/Insurace.model";
import ModalComponent from "../ModalComponent/ModalComponent";
import CentroAutomovilistaInfo from "../VehiclesComponent/CentroAutomovilistaInfo";
import ModalBtnsComponent from "./ModalBtnsComponent";

const CardsComponent = ({
  cardTitle,
  cardImg,
  isSlide,
  cardImgHover,
  content,
  btnColorCotizar,
  btnLinkCotizar,
  btnImgCotizar,
  btnTitleCotizar,
  btnLinkBrochure,
  btnImgBrochure,
  btnTitleBrochure,
  btnColorBrochure,
  btnLinkPreguntas,
  btnImgPreguntas,
  btnTitlePreguntas,
  btnColorPreguntas,
  btnLinkVideo,
  btnImgVideo,
  btnTitleVideo,
  btnColorVideo,
  btnLinkFormulario,
  btnImgFormulario,
  btnTitleFormulario,
  btnColorFormulario,
  cardListItems,
  centroAutomovilista,
  pageLink,
}: CardsInterface2) => {
  const [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <div className={isSlide ? "card-box-slide " : "cards"} onClick={openModal}>
      <div className="card-img">
        <img src={cardImg} alt="card-image" className="card-image" />
        <img
          src={cardImgHover}
          alt="card-image-hover"
          className="card-image-hover hidden"
        />
      </div>

      <div className="card-title">{cardTitle}</div>

      <ModalComponent closeModal={closeModal} isOpen={isOpen}>
        <div className="mt-2 card-modal-container md:px-5">
          <h3 className="card-modal-title">{cardTitle}</h3>
          <div dangerouslySetInnerHTML={{ __html: content }}></div>
          {pageLink && (
            <Link to={pageLink} className="cardPageLink">
              <strong>Ir a página </strong>
            </Link>
          )}
        </div>
        {/* Ul List */}
        {cardListItems ? (
          <div
            className="list-container"
            dangerouslySetInnerHTML={{ __html: cardListItems }}
          ></div>
        ) : null}
        {centroAutomovilista && <CentroAutomovilistaInfo />}

        {/* Buttons */}
        <ModalBtnsComponent
          btnColorCotizar={btnColorCotizar}
          btnLinkCotizar={btnLinkCotizar}
          btnImgCotizar={btnImgCotizar}
          btnTitleCotizar={btnTitleCotizar}
          btnLinkBrochure={btnLinkBrochure}
          btnImgBrochure={btnImgBrochure}
          btnTitleBrochure={btnTitleBrochure}
          btnColorBrochure={btnColorBrochure}
          btnLinkPreguntas={btnLinkPreguntas}
          btnImgPreguntas={btnImgPreguntas}
          btnTitlePreguntas={btnTitlePreguntas}
          btnColorPreguntas={btnColorPreguntas}
          btnLinkVideo={btnLinkVideo}
          btnImgVideo={btnImgVideo}
          btnTitleVideo={btnTitleVideo}
          btnColorVideo={btnColorVideo}
          btnLinkFormulario={btnLinkFormulario}
          btnImgFormulario={btnImgFormulario}
          btnTitleFormulario={btnTitleFormulario}
          btnColorFormulario={btnColorFormulario}
        />
      </ModalComponent>
    </div>
  );
};

export default CardsComponent;
