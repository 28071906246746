import "./css/error404Content.css";
import { Link } from "react-router-dom";

type error404TextProps = {
  title: string;
  content: string;
  link: string;
  linkText: string;
};

const Error404Content = ({
  title,
  content,
  link,
  linkText,
}: error404TextProps) => {
  return (
    <div>
      <div className="mt-5 error-block">
        <h1>{title}</h1>
        <p dangerouslySetInnerHTML={{ __html: content }}></p>
        <Link to={link}>{linkText}</Link>
      </div>
    </div>
  );
};

export default Error404Content;
