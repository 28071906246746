import { BASE_URL, GET_HEADERS } from "../../components/Helper/requestData";
import { useEffect, useState } from "react";

import CardsContainer from "../../components/MenuLandingsLayout/CardsContainer";
import HeroMenuComponent from "../../components/MenuLandingsLayout/HeroMenuComponent";
import ProductsContainer from "../../components/MenuLandingsLayout/ProductsContainer";
import SectionInformation from "../../components/MenuLandingsLayout/SectionInformation";
import axios from "axios";

const Multiple = () => {
  const [showCards, setShowCards] = useState(false);
  const [multipleData, setMultipleData] = useState({
    title: "",
    bannerImage: "",
    content: "",
    contentImage: "",
  });

  const [cardData, setCardData] = useState([
    {
      cardId: 0,
      cardImg: "",
      cardTitle: "",
      cardImgHover: "",
      content: "",
      btnLinkPreguntas: "",
      btnImgPreguntas: "",
      btnTitlePreguntas: "",
      btnColorPreguntas: "",
      cardListItems: "",
      pageLink: "",
    },
  ]);

  const getMultipleData = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Contenido-de-Secciones')/items?$filter=Title eq 'Seguros Múltiples'",
        GET_HEADERS
      )
      .then((response) => response.data.value[0])
      .catch((e) => console.error(e));

    setMultipleData({
      title: resultsArr?.TitleRich,
      bannerImage: resultsArr?.BannerImage.Url,
      content: resultsArr?.Content,
      contentImage: resultsArr?.ContentImage.Url,
    });
  };

  const getCardData = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Seguros-Multiples-Productos')/items",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    setCardData(
      resultsArr
        .map((item: any) => {
          return {
            cardId: item?.CardId,
            cardImg: item?.CardImg?.Url,
            cardTitle: item?.CardTitle,
            cardImgHover: item?.CardImgHover?.Url,
            content: item?.Content,

            btnLinkPreguntas: item?.BtnLinkPreguntas?.Url,
            btnImgPreguntas: item?.BtnImgPreguntas?.Url,
            btnTitlePreguntas: item?.BtnTitlePreguntas,
            btnColorPreguntas: item?.BtnColorPreguntas,

            cardListItems: item?.CardListItems,
            pageLink: item?.PageLink?.Description,
          };
        })
        .sort(
          (a: { cardId: number }, b: { cardId: number }) => a.cardId - b.cardId
        )
    );
  };

  useEffect(() => {
    getMultipleData();
    getCardData();
  }, []);

  return (
    <>
      <HeroMenuComponent
        title={multipleData?.title}
        bannerImage={multipleData?.bannerImage}
      />
      <SectionInformation
        content={multipleData?.content}
        contentImage={multipleData?.contentImage}
      />
      <ProductsContainer showCards={showCards} setShowCards={setShowCards}>
        <CardsContainer showCards={showCards} cards={cardData} />
      </ProductsContainer>
    </>
  );
};

export default Multiple;
