import { Splide, SplideSlide } from "@splidejs/react-splide";
import { useEffect, useState, useRef } from "react";
import OptionsComponent from "../OptionsComponent/OptionsComponent";
import axios from "axios";
import { BASE_URL, GET_HEADERS } from "../Helper/requestData";

const PaymentFacilities = ({ title }: any) => {
  const [slideIndex, setSlideIndex] = useState(0);
  const [hideIndexTabs, setHideIndexTabs] = useState(0);
  const [channelInfo, setChannelInfo] = useState([
    {
      inactiveIcon: "",
      activeIcon: "",
      channelName: "",
      channelcontent: "",
      channelIntro: "",
    },
  ]);

  const [options, setOptions] = useState([
    {
      title: "",
      id: 0,
      content: "",
    },
  ]);

  const mainSlider: any = useRef();
  const thumbSlider: any = useRef();

  const getChannelInfo = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Canales-de-Servicios-Sliders')/items?$filter=Categoria eq 'Pagos'",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    setChannelInfo(
      resultsArr.map((item: any) => {
        return {
          inactiveIcon: item?.InactiveIcon.Url,
          activeIcon: item?.ActiveIcon.Url,
          channelName: item?.Title,
          channelcontent: item?.Content,
          channelIntro: item?.IntroText,
        };
      })
    );
  };

  const getOptionsData = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL + "_api/web/lists/getbytitle('Finanprimas-Opciones')/items",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    setOptions(
      resultsArr.map((item: any) => {
        return {
          title: item?.Title,
          id: item?.ItemId,
          content: item?.Content,
        };
      })
    );
  };

  const renderIcons = (index: number, slide: any) => {
    if (slideIndex === undefined && index === 0) {
      return (
        <img
          className="splide-slide-icon-active"
          src={slide.activeIcon}
          alt="active icon"
        />
      );
    }
    if (slideIndex === index) {
      return (
        <img
          className="splide-slide-icon-active"
          src={slide.activeIcon}
          alt="active icon"
        />
      );
    } else {
      return (
        <img
          className="splide-slide-icon-inactive"
          src={slide.inactiveIcon}
          alt="inactive icon"
        />
      );
    }
  };

  useEffect(() => {
    getChannelInfo();
    getOptionsData();
  }, []);

  useEffect(() => {
    if (mainSlider.current && thumbSlider.current) {
      mainSlider.current.sync(thumbSlider.current.splide);
    }
  }, [mainSlider, thumbSlider]);

  return (
    <a id="Facilidades-Pagos">
      <div className="section services-channels">
        <h1 className="services-channels__title">
          <b>{title}</b>
        </h1>
        <div className="section services-slide">
          <Splide
            onActive={() => {
              setSlideIndex(thumbSlider?.current?.splide.index);
            }}
            className="splide services-carrousel services-carrousel--center"
            ref={thumbSlider}
            options={{
              loop: false,
              pagination: false,
              padding: 30,
              gap: "20px",
              drag: true,
              arrows: false,
              isNavigation: true,
              breakpoints: {
                1024: {
                  arrows: true,
                  focus: "center",
                },
              },
            }}
            renderControls={() => (
              <div className="splide__arrows lg:hidden">
                <button
                  type="button"
                  className="splide__arrow splide__arrow--next "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="38.732"
                    height="70.464"
                    viewBox="0 0 38.732 70.464"
                  >
                    <path
                      id="chevron-right"
                      d="M9,66.564,39.282,36.282,9,6"
                      transform="translate(-4.05 -1.05)"
                      fill="none"
                      stroke="#00aef0"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="7"
                    />
                  </svg>
                </button>
                <button
                  type="button"
                  className="splide__arrow splide__arrow--prev "
                >
                  <svg
                    className="left-arrow"
                    xmlns="http://www.w3.org/2000/svg"
                    width="38.732"
                    height="70.464"
                    viewBox="0 0 38.732 70.464"
                  >
                    <path
                      id="chevron-right"
                      d="M9,66.564,39.282,36.282,9,6"
                      transform="translate(-4.05 -1.05)"
                      fill="none"
                      stroke="#00aef0"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="7"
                    />
                  </svg>
                </button>
              </div>
            )}
          >
            {channelInfo.map((slide, index) => (
              <SplideSlide key={`${index}`}>
                {renderIcons(index, slide)}
                <h2>{slide.channelName}</h2>
              </SplideSlide>
            ))}
          </Splide>
          <Splide
            onActive={() => {
              setHideIndexTabs(mainSlider?.current?.splide.index);
            }}
            className="info-slide"
            ref={mainSlider}
            options={{ pagination: false, arrows: false }}
          >
            {channelInfo.map((slide) => (
              <SplideSlide key={`${slide}`}>
                <div
                  dangerouslySetInnerHTML={{ __html: slide.channelcontent }}
                />
                {hideIndexTabs === 2 ? (
                  <div className="lg:flex lg:gap-8">
                    <div className="lg:w-1/4"></div>
                    <div className="lg:w-3/4">
                      <OptionsComponent contentList={options} />
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </SplideSlide>
            ))}
          </Splide>
        </div>
      </div>
    </a>
  );
};

export default PaymentFacilities;
