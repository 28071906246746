import orangeArrow from "../../assets/img/icons/chevron-right-orange-2.svg";
import arrowDarkBlue from "../../assets/img/icons/chevron-right-dark-blue.svg";

type OfficeComponentProps = {
  content: string;
  id: number;
  setActive: any;
  active: any;
};

const OfficeComponent = ({
  content,
  id,
  setActive,
  active,
}: OfficeComponentProps) => {
  const toggle = (identificationNumber: number) => {
    setActive({ activeId: identificationNumber });
  };

  return (
    <div
      className={`flex map-list__office-info ${
        active.activeId === id ? "map-list__office-info--active" : ""
      }`}
      onClick={() => toggle(id)}
    >
      <div dangerouslySetInnerHTML={{ __html: content }}></div>
      <button type="button" className="cursor-pointer">
        <img
          src={`${active.activeId === id ? orangeArrow : arrowDarkBlue}`}
          alt="arrow icon"
        />
      </button>
    </div>
  );
};
export default OfficeComponent;
