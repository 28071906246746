import AdminCard from "../../components/DirectiveComponent/AdminCard";
import DirectorCard from "../../components/DirectiveComponent/DirectorCard";
import Tabs from "react-responsive-tabs";
import HeroMenuComponent from "../../components/MenuLandingsLayout/HeroMenuComponent";
import SecondaryNavBar from "../../components/secondaryNavBar/SecondaryNavBar";
import VehiclesSectionInformation from "../../components/VehiclesComponent/VehiclesSectionInformation";
import "./css/getTabs.css";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL, GET_HEADERS } from "../../components/Helper/requestData";
import { AboutUsMenuContext } from "../../context/AboutUs/AboutUsMenuContext";

const CouncilAndExecutives = () => {
  const [heroSideData, setHeroSideData] = useState({
    title: "",
    titleRich: "",
    bannerImage: "",
    secondaryTitle: "",
    sideTitle1: "",
    sideContent1: "",
    sideTitle2: "",
    content: "",
    contentImage: "",
    sideContent2: "",
    middleTitle: "",
    middleContent: "",
  });

  const [tabs, setTabs] = useState([]);

  const [councilAndExecutives, setcouncilAndExecutives] = useState([
    {
      id: 1,
      name: "",
      position: "",
      image: "",
      email: "",
      ext: "",
      groupType: "",
      area: [],
      leader: false,
    },
  ]);

  const { menuListData } = useContext(AboutUsMenuContext);

  const getSectionContentData = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Contenido-de-Secciones')/items?$filter=Title eq 'Consejo y Principales Ejecutivos'",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    setHeroSideData({
      titleRich: resultsArr[0]?.TitleRich,
      title: resultsArr[0]?.Title,
      bannerImage: resultsArr[0]?.BannerImage?.Url,
      content: resultsArr[0]?.Content,
      secondaryTitle: resultsArr[0]?.SecondaryTitle,
      sideTitle1: resultsArr[0]?.SideTitle1,
      sideTitle2: resultsArr[0]?.SideTitle2,
      sideContent1: resultsArr[0]?.SideContent1,
      contentImage: resultsArr[0]?.ContentImage?.Url,
      sideContent2: resultsArr[0]?.SideContent2,
      middleTitle: resultsArr[0]?.MiddleTitle,
      middleContent: resultsArr[0]?.MiddleContent,
    });
  };
  const getCouncilAndExecutives = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Consejo-y-Principales-Directores-Detalles')/items",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    setcouncilAndExecutives(
      resultsArr.map((item: any) => {
        return {
          id: item?.Order0,
          name: item?.Name,
          position: item?.Position,
          image: item?.Image?.Url,
          email: item?.Email,
          ext: item?.Ext,
          groupType: item?.GroupType,
          area: item?.Area,
          leader: item?.Leader,
        };
      })
    );
  };

  const getDirectorsTabs = async () => {
    const executivesTabs = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Consejo-y-Principales-Directores-Detalles')/fields?$filter=EntityPropertyName eq 'Area'",
        GET_HEADERS
      )
      .then((response) => response.data.value[0]?.Choices)
      .catch((e) => console.error(e));

    setTabs(executivesTabs);
  };

  function getTabs() {
    return tabs.map((tab, i) => ({
      key: i,
      tabClassName: "tab-director",
      panelClassName: "panel-director",
      title: tab,
      getContent: () => (
        <DirectorCard infoContent={councilAndExecutives} area={tab} />
      ),
    }));
  }

  useEffect(() => {
    getSectionContentData();
    getCouncilAndExecutives();
    getDirectorsTabs();
  }, []);
  return (
    <>
      <HeroMenuComponent
        bannerImage={heroSideData.bannerImage}
        title={heroSideData.titleRich}
      />
      <SecondaryNavBar
        title={heroSideData.secondaryTitle || heroSideData.titleRich}
        menuList={menuListData}
        subMenuList={[]}
      />
      <div className="container mx-auto">
        <div className="lg:w-4/5">
          <VehiclesSectionInformation content={heroSideData.content} />
        </div>
        <div className="my-10 lg:my-16">
          <AdminCard cardsInfo={councilAndExecutives} />
        </div>
        <div className="lg:w-4/5">
          <VehiclesSectionInformation content={heroSideData.middleContent} />
        </div>
        <div className="mb-20 lg:mb-0 lg:my-12">
          <Tabs showMore={false} items={getTabs()} />
        </div>
      </div>
    </>
  );
};

export default CouncilAndExecutives;
