import "./css/VissionMission.css";

type aboutUsCardProps = {
  title?: string;
  text?: string;
};

function AboutUsCard({ title, text }: aboutUsCardProps) {
  return (
    <div className="about-card">
      <h2>{title}</h2>
      <p>{text}</p>
    </div>
  );
}

const VissionMission = ({ missionVisionData }: any) => {
  return (
    <div className="grid gap-y-5 mt-11 lg:mt-0 md:grid-cols-2 gap-9 lg:max-w-5xl lg:mx-auto">
      {missionVisionData.map((card, i) => (
        <AboutUsCard key={i} title={card.title} text={card.text} />
      ))}
    </div>
  );
};

export default VissionMission;
