import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { validations } from "./validations";
import { saveForm } from "./FormServices";
import { useState } from "react";
import SentComponent from "./SentComponent";

interface InitStateType {
  nombre: string;
  telefono: string;
  correoElectronico: string;
  asunto: string;
  tipoDeContacto: string;
  mensaje: string;
}

const INITIAL_STATE: InitStateType = {
  nombre: "",
  telefono: "",
  correoElectronico: "",
  asunto: "",
  tipoDeContacto: "",
  mensaje: "",
};
const ContactanosForm = () => {
  const {
    register,
    getValues,
    reset,
    formState: { errors, isValid },
  } = useForm({
    mode: "onBlur",
    defaultValues: INITIAL_STATE,
  });
  const [status, setStatus] = useState(false);

  const handleCancel = () => {
    reset(INITIAL_STATE);
  };

  const handleSend = () => {
    if (!isValid) return;
    saveForm(getValues());
    reset(INITIAL_STATE);
    setStatus(true);
  };

  return (
    <div className="lg:order-firts lg:w-7/12 mb-24">
      <div className="contact-form">
        <h3 className="contact-form__title">
          Escríbenos si deseas ser contactado
        </h3>
        {status && <SentComponent onClick={() => setStatus(false)} />}
        <p className="contact-form__subtitle">
          Los campos con (*) son obligatorios
        </p>
        <div className="form-block">
          <input
            type="text"
            className="contact-form__input"
            placeholder="Nombre*"
            {...register("nombre", {
              required: { value: true, message: "Este campo es obligatorio" },
              pattern: validations.letrasYespacios,
            })}
          />
          <ErrorMessage
            errors={errors}
            name="nombre"
            render={({ message }) => (
              <p className="text-red-600 ml-1">{message}</p>
            )}
          />
        </div>

        <div className="form-block lg:flex lg:space-x-3">
          <div className="lg:w-1/2">
            <input
              type="text"
              className="contact-form__input"
              placeholder="Teléfono*"
              {...register("telefono", {
                required: { value: true, message: "Este campo es obligatorio" },
                pattern: validations.soloNumeros,
              })}
            />
            <ErrorMessage
              errors={errors}
              name="telefono"
              render={({ message }) => (
                <p className="text-red-600 ml-1">{message}</p>
              )}
            />
          </div>
          <div className="lg:w-1/2">
            <input
              type="text"
              className="contact-form__input"
              placeholder="Correo Electrónico*"
              {...register("correoElectronico", {
                required: { value: true, message: "Este campo es obligatorio" },
                pattern: validations.emails,
              })}
            />
            <ErrorMessage
              errors={errors}
              name="correoElectronico"
              render={({ message }) => (
                <p className="text-red-600 ml-1">{message}</p>
              )}
            />
          </div>
        </div>
        <div className="form-block lg:flex lg:space-x-3">
          <div className="lg:w-1/2">
            <input
              type="text"
              className="contact-form__input"
              placeholder="Asunto*"
              {...register("asunto", {
                required: { value: true, message: "Este campo es obligatorio" },
                pattern: validations.letrasYespacios,
              })}
            />
            <ErrorMessage
              errors={errors}
              name="asunto"
              render={({ message }) => (
                <p className="text-red-600 ml-1">{message}</p>
              )}
            />
          </div>
          <div className="lg:w-1/2">
            <select
              className="contact-form__select"
              {...register("tipoDeContacto", {
                required: { value: true, message: "Este campo es obligatorio" },
              })}
            >
              <option value="">Tipo de Contacto*</option>
              <option value="Pregunta">Pregunta</option>
              <option value="Reclamación">Reclamación</option>
              <option value="Reclamación Confidencial">
                Reclamación Confidencial
              </option>
              <option value="Cuéntanos tu Experiencia">
                Cuéntanos tu Experiencia
              </option>
            </select>
            <ErrorMessage
              errors={errors}
              name="asunto"
              render={({ message }) => (
                <p className="text-red-600 ml-1">{message}</p>
              )}
            />
          </div>
        </div>
      </div>
      <div className="form-block">
        <textarea
          placeholder="Mensaje"
          className="contact-form__textarea"
          {...register("mensaje", {
            pattern: validations.letrasNumerosEspaciosEspeciales,
          })}
        />
        <ErrorMessage
          errors={errors}
          name="mensaje"
          render={({ message }) => (
            <p className="text-red-600 ml-1">{message}</p>
          )}
        />
      </div>
      <div className="form-block lg:flex lg:space-x-3">
        <button
          type="button"
          className="contact-form__button lg:w-1/2"
          onClick={handleCancel}
        >
          Cancelar
        </button>
        <button
          type="button"
          className={
            isValid === true
              ? "contact-form__button contact-form__button--solid lg:w-1/2"
              : "contact-form__button contact-form__button--solid lg:w-1/2 isDisabled"
          }
          onClick={handleSend}
        >
          Enviar
        </button>
      </div>
    </div>
  );
};

export default ContactanosForm;
