import { useState, useEffect, useContext } from "react";
import axios from "axios";
import InformationComponent from "../../components/InformationComponent/InformationComponent";
import HeroMenuComponent from "../../components/MenuLandingsLayout/HeroMenuComponent";
import SecondaryNavBar from "../../components/secondaryNavBar/SecondaryNavBar";
import SideBar from "../../components/sideBar/SideBar";
import SideBarList from "../../components/sideBar/SideBarList";
import Faq from "../../components/VariousCardsComponents/btnCards/Faq";
import MoreInformationCard from "../../components/VariousCardsComponents/moreInfoCard/MoreInformationCard";
import BlueArrowList from "../../components/VariousCardsComponents/blueArrowList/BlueArrowList";
import OptionsComponent from "../../components/OptionsComponent/OptionsComponent";
import { BASE_URL, GET_HEADERS } from "../../components/Helper/requestData";
import { PeopleMenuContext } from "../../context/People/PeopleMenuContext";
import { SideListModel } from "../../context/models/SideList.model";

const IndividualLife = () => {
  const [heroSideData, setHeroSideData] = useState({
    title: "",
    titleRich: "",
    bannerImage: "",
    secondaryTitle: "",
    sideTitle1: "",
    sideContent1: "",
    sideTitle2: "",
    sideContent2: "",
    content: "",
  });

  const [
    characteristicsAndModalitiesData,
    setCharacteristicsAndModalitiesData,
  ] = useState([
    {
      title: "",
      id: 0,
      content: "",
    },
  ]);

  const [plansButtons, setPlansButtons] = useState({
    faqLink: "",
    faqTitle: "",
    faqIcon: "",
    faqActive: true,
  });

  const [sideList, setSideList] = useState<SideListModel[]>([]);

  const { menuListData, subMenuListData } = useContext(PeopleMenuContext);

  const getSectionContentData = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Contenido-de-Secciones')/items?$filter=Title eq 'Vida Individual'",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    setHeroSideData({
      titleRich: resultsArr[0]?.TitleRich,
      title: resultsArr[0]?.Title,
      bannerImage: resultsArr[0]?.BannerImage.Url,
      secondaryTitle: resultsArr[0]?.SecondaryTitle,
      sideTitle1: resultsArr[0]?.SideTitle1,
      sideContent1: resultsArr[0]?.SideContent1,
      sideTitle2: resultsArr[0]?.SideTitle2,
      sideContent2: resultsArr[0]?.SideContent2,
      content: resultsArr[0]?.Content,
    });
  };

  const getCharacteristicsAndModalitiesData = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Vida-Individual-Caracteristicas-Modalidades')/items",

        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    setCharacteristicsAndModalitiesData(
      resultsArr.map((item: any) => {
        return {
          title: item?.Title,
          content: item?.Content,
          id: item?.ID,
        };
      })
    );
  };

  const getPlansButtons = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL + "_api/web/lists/getbytitle('Bottom-Buttons')/items",

        {
          headers: {
            "X-RequestDigest": (
              document.getElementById("__REQUESTDIGEST") as HTMLInputElement
            ).value,
            Accept: "application/json; odata=nometadata",
            "Content-Type": "application/json; odata=nometadata",
          },
        }
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    const result = resultsArr.find((r: any) => r.Title === "Vida Individual");

    setPlansButtons({
      faqLink: result?.FAQLink?.Description,
      faqIcon: result?.FAQIcon?.Url,
      faqTitle: result?.FAQTitle,
      faqActive: result?.Activo,
    });
  };

  const getSideList = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Seguros-Servicios-SideLists')/items?$filter=Categoria eq 'Vida Individual'",

        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    resultsArr.sort(
      (a: { Order0: number }, b: { Order0: number }) => a.Order0 - b.Order0
    );

    setSideList(
      resultsArr.map((item: any) => {
        return {
          title: item?.Text,
          id: item?.Order0,
        };
      })
    );
  };

  useEffect(() => {
    getSectionContentData();
    getCharacteristicsAndModalitiesData();
    getPlansButtons();
    getSideList();
  }, []);

  return (
    <>
      <HeroMenuComponent
        bannerImage={heroSideData.bannerImage}
        title={heroSideData.titleRich}
      />
      <SecondaryNavBar
        title={heroSideData.secondaryTitle || heroSideData.titleRich}
        menuList={menuListData}
        subMenuList={subMenuListData}
      />

      <div className="container mx-auto lg:flex lg:justify-between md:mb-16 ">
        <div className="lg:w-3/5">
          <InformationComponent content={heroSideData.content} />
          <div className="mt-10 lg:mt-10">
            <div className="hidden lg:block">
              <BlueArrowList contentList={characteristicsAndModalitiesData} />
            </div>

            <div className="lg:hidden">
              <OptionsComponent
                contentList={characteristicsAndModalitiesData}
              />
            </div>
          </div>
          <div className="hidden lg:block">
            <Faq
              faqLink={plansButtons.faqLink}
              faqTitle={plansButtons.faqTitle}
              faqIcon={plansButtons.faqIcon}
              faqActive={plansButtons.faqActive}
            />
          </div>
        </div>
        <div className="mt-10 lg:mt-14 lg:w-1/3">
          <SideBar>
            {heroSideData.sideContent1 && (
              <SideBarList
                sideContent={heroSideData.sideContent1}
                sideTitle={heroSideData.sideTitle1}
                sideBarList={sideList}
              />
            )}
          </SideBar>
          <div className="my-10">
            <MoreInformationCard />
          </div>
          <div className="mb-12 lg:hidden">
            <Faq
              faqLink={plansButtons.faqLink}
              faqTitle={plansButtons.faqTitle}
              faqIcon={plansButtons.faqIcon}
              faqActive={plansButtons.faqActive}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default IndividualLife;
