type WorkLinkProps = {
  text: string;
  link: string;
};

const WorkLink = ({ text = "Trabaje con Nosotros", link }: WorkLinkProps) => {
  return (
    <div>
      <a className="work-link" href={link}>
        {text}
      </a>
    </div>
  );
};

export default WorkLink;
