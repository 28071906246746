interface Data {
  enlace?: string;
  enlaceText?: string;
  enlaceColor?: string;
}
function ServiciosCardEnlace(props: Data) {
  const filterLinks = () => {
    if (props?.enlaceColor === "Orange") {
      return "servicios__link";
    } else if (props?.enlaceColor === "Dark") {
      return "servicios__link servicios__link--dark";
    } else {
      return "servicios__link servicios__link--white";
    }
  };

  return (
    <>
      {props?.enlaceText && props?.enlace && (
        <a className={filterLinks()} href={props?.enlace}>
          {props?.enlaceText}
        </a>
      )}
    </>
  );
}

export default ServiciosCardEnlace;
