import ComparatorTableTh from "./ComparatorTableTh";
import grayX from "../../../assets/img/icons/gray-x-not-available.svg";
import orangeCheck from "../../../assets/img/icons/orange-checkmark-icon.svg";
import blueAsterisk from "../../../assets/img/icons/blue-asterisk-icon.svg";

const ComparatorDesktopServiceTable = ({ tableService, planCShow }: any) => {
  let colDiv = 3;
  const renderTableData = (value, charge, value2) => {
    if (value === "Incluida" || value === "Incluido") {
      return (
        <td align="center" valign="middle" className="flex-1  ">
          <img src={orangeCheck} className="w-5 h-6 float-center" />
        </td>
      );
    } else if (charge) {
      return (
        <td align="center" valign="middle" className="flex-1  ">
          <img src={blueAsterisk} className="w-4 h-6 float-center" />
        </td>
      );
    } else if (
      (value && value.includes("No aplica")) ||
      value === null ||
      value === undefined
    ) {
      return (
        <td align="center" valign="middle" className="flex-1  ">
          <img src={grayX} className="w-4 h-6 float-center" />
        </td>
      );
    } else if (value && value2) {
      return (
        <>
          <td className="flex-1 align-center text-center ">{value}</td>
          <td className="flex-1 align-center text-center ">{value2}</td>
        </>
      );
    } else if (value) {
      return <td className="flex-1 align-center text-center ">{value}</td>;
    }
  };
  return (
    <table className="service-table border-collapse ">
      <tbody>
        {tableService?.map((service: any, i: number) => {
          service.serviceType && service?.serviceValueA2 && colDiv++;
          service.serviceType && service?.serviceValueB2 && colDiv++;
          service.serviceType && service?.serviceValueC2 && colDiv++;
          service.serviceType && planCShow && colDiv++;

          return service.serviceType ? (
            <tr key={i}>
              <th
                className="flex: 0 0 align-top service-table text-left"
                style={{ width: `${100 / colDiv}%` }}
              >
                {service?.serviceName}
              </th>
              <ComparatorTableTh
                planCShow={planCShow}
                service={service?.serviceValueA}
                service2={service?.serviceValueA2}
                colDiv={colDiv}
              />

              <ComparatorTableTh
                planCShow={planCShow}
                service={service?.serviceValueB}
                service2={service?.serviceValueB2}
                colDiv={colDiv}
              />

              {planCShow && (
                <ComparatorTableTh
                  planCShow={planCShow}
                  service={service?.serviceValueC}
                  service2={service?.serviceValueC2}
                  colDiv={colDiv}
                />
              )}
            </tr>
          ) : (
            <tr key={i}>
              <td className="flex-1 align-center ">{service?.serviceName}</td>
              {renderTableData(
                service?.serviceValueA,
                service?.additionalChargeA,
                service?.serviceValueA2
              )}
              {renderTableData(
                service?.serviceValueB,
                service?.additionalChargeB,
                service?.serviceValueB2
              )}
              {planCShow &&
                renderTableData(
                  service?.serviceValueC,
                  service?.additionalChargeC,
                  service?.serviceValueC2
                )}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default ComparatorDesktopServiceTable;
