import axios from "axios";
import { BASE_URL } from "../Helper/requestData";

type Data = {
  nombre: string;
  telefono: string;
  correoElectronico: string;
  asunto: string;
  tipoDeContacto: string;
  mensaje: string;
};

async function getListMetadata(listName: string): Promise<string> {
  return axios
    .get(
      `${BASE_URL}_api/lists/getbytitle('${listName}')?$select=ListItemEntityTypeFullName`,
      {
        headers: {
          "X-RequestDigest": (
            document.getElementById("__REQUESTDIGEST") as HTMLInputElement
          ).value,
          Accept: "application/json; odata=verbose",
        },
      }
    )
    .then((res) => res.data.d.ListItemEntityTypeFullName)
    .catch((error) => console.error(error));
}

export async function saveForm(data: Data) {
  const formulario = JSON.stringify({
    Nombre: data.nombre,
    Telefono: data.telefono,
    CorreoElectronico: data.correoElectronico,
    Asunto: data.asunto,
    TipodeContacto: data.tipoDeContacto,
    Mensaje: data.mensaje,
    __metadata: { type: await getListMetadata("Formularios Contactanos") },
  });

  const query =
    BASE_URL + "_api/web/lists/getbytitle('Formularios Contactanos')/items";

  const axiosConfig = {
    headers: {
      "X-RequestDigest": (
        document.getElementById("__REQUESTDIGEST") as HTMLInputElement
      ).value,
      Accept: "application/json; odata=verbose",
      "Content-Type": "application/json;odata=verbose",
      "IF-MATCH": "*",
    },
  };

  return axios
    .post(query, formulario, axiosConfig)
    .then((res) => res.data)
    .catch((error) => console.error(error));
}
