import { useEffect, useRef, useState } from "react";
import whiteArrow from "../../assets/img/icons/chevron-right.svg";

const GeneralItemsTable = ({ tableList, handleClick }: any) => {
  const buttonClick = useRef<any>();
  const [colSpan, setColSpan] = useState(0);
  useEffect(() => {
    if (screen.width < 768) {
      setColSpan(2);
    } else {
      setColSpan(0);
    }
  }, [screen.width]);

  return (
    <tbody className="table-body">
      {tableList !== undefined &&
        tableList.map((list: any, i: number) =>
          list.serviceType ? (
            <tr
              ref={buttonClick}
              className="titles-container"
              key={i}
              onClick={() => {
                handleClick(list.serviceName, list.serviceParent);
              }}
            >
              <th>{list.serviceName}</th>
              <th className="mr-5  md:hidden">
                <img
                  src={whiteArrow}
                  alt="white arrow icon"
                  className="white-arrow"
                />
              </th>
              <th className="hidden md:block">{list.serviceValue}</th>
            </tr>
          ) : (
            <tr key={i} className="table-list">
              <td colSpan={colSpan} className="general-list-width">
                <p> {list.serviceName}</p>
                <p className="md:hidden">
                  {list.serviceValue && <b>Límite: </b>}
                  {list.serviceValue}
                </p>
              </td>
              <td className="hidden md:block">{list.serviceValue}</td>
            </tr>
          )
        )}
    </tbody>
  );
};

export default GeneralItemsTable;
