import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";

import AboutUsMenu from "../context/AboutUs/AboutUsMenuContext";
import Airships from "../pages/Multiple/Airships";
import AllRisk from "../pages/Properties/AllRisk";
import AsistenteVirtual from "../pages/Services/AsistenteVirtual";
import AssistancePlan from "../pages/People/AssistancePlan";
import AutoInspect from "../pages/Vehicles/AutoInspect";
import Bail from "../pages/Multiple/Bail";
import Boats from "../pages/Multiple/Boats";
import Boiler from "../pages/Properties/Boiler";
import CivilLiability from "../pages/Multiple/CivilLiability";
import ClaimsGeneralRisks from "../pages/Claims/ClaimsGeneralRisks";
import ClaimsLanding from "../pages/Claims/ClaimsLanding";
import ClaimsMenu from "../context/Claims/ClaimsMenuContext";
import ClaimsMultiple from "../pages/Claims/ClaimsMultiple";
import ClaimsPeople from "../pages/Claims/ClaimsPeople";
import ClaimsProperties from "../pages/Claims/ClaimsProperties";
import ClaimsVehicle from "../pages/Claims/ClaimsVehicle";
import Clientes365 from "../pages/Services/Clientes365";
import CollectiveLife from "../pages/People/CollectiveLife";
import CondoInsurance from "../pages/Properties/CondoInsurance";
import Contact from "../pages/Services/Contact";
import CorporateIdentity from "../pages/AboutUs/CorporateIdentity";
import CouncilAndExecutives from "../pages/AboutUs/CouncilAndExecutives";
import DigitalTag from "../pages/Vehicles/DigitalTag";
import ElectricVehicles from "../pages/Vehicles/ElectricVehicles";
import ElectronicDevices from "../pages/Properties/ElectronicDevices";
import Error404 from "../pages/Error/Error404";
import ExtraSafeLife from "../pages/People/ExtraSafeLife";
import Fidelity from "../pages/Multiple/Fidelity";
import FireAndAlliedLines from "../pages/Properties/FireAndAlliedLines";
import FooterComponent from "../components/footer/FooterComponent";
import FuneralAssistance from "../pages/People/funeralAssistance";
import GlassBreakage from "../pages/Multiple/GlassBreakage";
import HeaderComponent from "../components/header/HeaderComponent";
import Home from "../pages/Home/Home";
import ImmigrationSupport from "../pages/People/ImmigrationSupport";
import IndividualLife from "../pages/People/IndividualLife";
import InstitutionalPublications from "../pages/AboutUs/InstitutionalPublications";
import Intermediarios365 from "../pages/Services/Intermediarios365";
import InternationalHealthJustV2 from "../pages/People/InternationalHealthJustV2";
import MachineryBreakdown from "../pages/Properties/MachineryBreakdown";
import MerchandiseTransports from "../pages/Multiple/MerchandiseTransports";
import MoneyTheft from "../pages/Multiple/MoneyTheft";
import MotorVehicles from "../pages/Vehicles/MotorVehicles";
import Mounting from "../pages/Properties/Mounting";
import MultiRiskPymes from "../pages/Properties/MultiriskPymes";
import Multiple from "../pages/Multiple/Multiple";
import MultipleMenu from "../context/Multiple/MultipleMenuContext";
import { NavigationContext } from "./menulinks";
import News from "../pages/News/News";
import NewsData from "../context/News/NewsContext";
import NewsDetails from "../pages/News/NewsDetails";
import Offices from "../pages/Services/Offices";
import OurHistory from "../pages/AboutUs/OurHistory";
import { PageHeaders } from "../components/pageHeaders/PageHeaders";
import People from "../pages/People/People";
import PeopleMenu from "../context/People/PeopleMenuContext";
import PersonalAccidentCard from "../pages/People/PersonalAccidentCard";
import PersonalAccidents from "../pages/People/PersonalAccidents";
import PersonalAccidentsStudents from "../pages/People/PersonalAccidentsStudents";
import Properties from "../pages/Properties/Properties";
import PropertyMenu from "../context/Properties/PropertyMenuContext";
import QrCode from "../pages/Services/QrCode";
import ReInsurance from "../pages/AboutUs/Reinsurance";
import Rescue365 from "../pages/Vehicles/Rescue365";
import ReservasLife from "../pages/People/ReservasLife";
import ReservasTravel from "../pages/People/ReservasTravel";
import SafeFurniture from "../pages/Properties/SafeFurniture";
import SafeHouse from "../pages/Properties/SafeHouse";
import ScrollToTop from "../components/scroll/ScrollToTop";
import SeriousIllness from "../pages/People/SeriousIllness";
import ServicesChannelsMenu from "../context/ServicesChannels/ServicesChannelsMenuContext";
import ServicesLanding from "../pages/Services/ServicesLanding";
import SocialResponsibility from "../pages/AboutUs/SocialResponsibility";
import StudentLife from "../pages/People/StudentLife";
import Subsidiaries from "../pages/AboutUs/Subsidiaries";
import ValuableItems from "../pages/Multiple/ValuableItems";
import VehicleMultirisk from "../pages/Vehicles/VehicleMultirisk";
import Vehicles from "../pages/Vehicles/Vehicles";
import VehiclesMenu from "../context/Vehicles/VehiclesMenuContext";
import Via from "../pages/Vehicles/Via";
import WorkWithUs from "../pages/AboutUs/WorkWithUs";
import Politics from "../pages/Vehicles/Politics";

const Router = () => {
  const { menuLinks } = useContext(NavigationContext);
  const [renderRoutes, setRenderRoutes] = useState(false);
  useEffect(() => {
    menuLinks.home && setRenderRoutes(true);
  }, [menuLinks]);
  return (
    <BrowserRouter>
      <HeaderComponent />
      <ScrollToTop>
        {renderRoutes && (
          <Routes>
            <Route
              path={`${menuLinks.home}`}
              element={
                <React.Suspense fallback={<>...</>}>
                  <PageHeaders title="Inicio" />
                  <Home />
                </React.Suspense>
              }
            />
            <Route
              path={`${menuLinks.segurosVehiculos}`}
              element={
                <React.Suspense fallback={<>...</>}>
                  <PageHeaders title="Seguros de Vehículos" />
                  <Vehicles />
                </React.Suspense>
              }
            />

            <Route
              path={`${menuLinks.segurosPersonas}`}
              element={
                <React.Suspense fallback={<>...</>}>
                  <PageHeaders title="Seguros de Personas" />
                  <People />
                </React.Suspense>
              }
            />
            <Route
              path={`${menuLinks.segurosPropiedades}`}
              element={
                <React.Suspense fallback={<>...</>}>
                  <PageHeaders title="Seguros de Propiedades" />
                  <Properties />
                </React.Suspense>
              }
            />
            <Route
              path={`${menuLinks.segurosMultiples}`}
              element={
                <React.Suspense fallback={<>...</>}>
                  <PageHeaders title="Seguros Múltiples" />
                  <Multiple />
                </React.Suspense>
              }
            />
            <Route
              path={`${menuLinks.vehiculosDeMotor}`}
              element={
                <React.Suspense fallback={<>...</>}>
                  <PageHeaders title="Vehículos de Motor" />
                  <VehiclesMenu>
                    <MotorVehicles />
                  </VehiclesMenu>
                </React.Suspense>
              }
            />
            <Route
              path={`${menuLinks.vehiculosElectricos}`}
              element={
                <React.Suspense fallback={<>...</>}>
                  <PageHeaders title="Vehículos Eléctricos" />
                  <VehiclesMenu>
                    <ElectricVehicles />
                  </VehiclesMenu>
                </React.Suspense>
              }
            />
            <Route
              path={`${menuLinks.rescate365}`}
              element={
                <React.Suspense fallback={<>...</>}>
                  <PageHeaders title="Rescate 365" />
                  <VehiclesMenu>
                    <Rescue365 />
                  </VehiclesMenu>
                </React.Suspense>
              }
            />
            <Route
              path={`${menuLinks.autoInspeccion}`}
              element={
                <React.Suspense fallback={<>...</>}>
                  <PageHeaders title="Auto Inspección" />
                  <VehiclesMenu>
                    <AutoInspect />
                  </VehiclesMenu>
                </React.Suspense>
              }
            />
            <Route
              path={`${menuLinks.marbeteDigital}`}
              element={
                <React.Suspense fallback={<>...</>}>
                  <PageHeaders title="Marbete Digital" />
                  <VehiclesMenu>
                    <DigitalTag />
                  </VehiclesMenu>
                </React.Suspense>
              }
            />
            <Route
              path={`${menuLinks.navesAereas}`}
              element={
                <React.Suspense fallback={<>...</>}>
                  <PageHeaders title="Naves Aéreas" />
                  <MultipleMenu>
                    <Airships />
                  </MultipleMenu>
                </React.Suspense>
              }
            />
            <Route
              path={`${menuLinks.embarcacionesMaritimas}`}
              element={
                <React.Suspense fallback={<>...</>}>
                  <PageHeaders title="Embarcaciones Marítimas" />
                  <MultipleMenu>
                    <Boats />
                  </MultipleMenu>
                </React.Suspense>
              }
            />
            <Route
              path={`${menuLinks.viaReservas}`}
              element={
                <React.Suspense fallback={<>...</>}>
                  <PageHeaders title="Vía Reservas" />
                  <VehiclesMenu>
                    <Via />
                  </VehiclesMenu>
                </React.Suspense>
              }
            />
            <Route
              path={`${menuLinks.politicas}`}
              element={
                <React.Suspense fallback={<>...</>}>
                  <PageHeaders title="Políticas de Privacidad Vía Reservas" />
                  <VehiclesMenu>
                    <Politics />
                  </VehiclesMenu>
                </React.Suspense>
              }
            />

            <Route
              path={`${menuLinks.multiriesgoVehiculos}`}
              element={
                <React.Suspense fallback={<>...</>}>
                  <PageHeaders title="Multiriesgo Vehículos" />
                  <VehiclesMenu>
                    <VehicleMultirisk />
                  </VehiclesMenu>
                </React.Suspense>
              }
            />

            <Route
              path={`${menuLinks.saludInternacionalJust}`}
              element={
                <React.Suspense fallback={<>...</>}>
                  <PageHeaders title="Salud Internacional Just" />
                  <PeopleMenu>
                    <InternationalHealthJustV2 />
                  </PeopleMenu>
                </React.Suspense>
              }
            />

            <Route
              path={`${menuLinks.viajesReservas}`}
              element={
                <React.Suspense fallback={<>...</>}>
                  <PageHeaders title="Viajes Reservas" />
                  <PeopleMenu>
                    <ReservasTravel />
                  </PeopleMenu>
                </React.Suspense>
              }
            />
            <Route
              path={`${menuLinks.vidaColetiva}`}
              element={
                <React.Suspense fallback={<>...</>}>
                  <PageHeaders title="Vida Colectiva" />
                  <PeopleMenu>
                    <CollectiveLife />
                  </PeopleMenu>
                </React.Suspense>
              }
            />
            <Route
              path={`${menuLinks.vidaExtra}`}
              element={
                <React.Suspense fallback={<>...</>}>
                  <PageHeaders title="Vida Extra Segura" />
                  <PeopleMenu>
                    <ExtraSafeLife />
                  </PeopleMenu>
                </React.Suspense>
              }
            />
            <Route
              path={`${menuLinks.vidaReservas}`}
              element={
                <React.Suspense fallback={<>...</>}>
                  <PageHeaders title="Vida Reservas" />
                  <PeopleMenu>
                    <ReservasLife />
                  </PeopleMenu>
                </React.Suspense>
              }
            />
            <Route
              path={`${menuLinks.accidentesPersonalesTarjeta}`}
              element={
                <React.Suspense fallback={<>...</>}>
                  <PageHeaders title="Accidentes Personales Tarjetas Banreservas" />
                  <PeopleMenu>
                    <PersonalAccidentCard />
                  </PeopleMenu>
                </React.Suspense>
              }
            />
            <Route
              path={`${menuLinks.planDeAsistencia}`}
              element={
                <React.Suspense fallback={<>...</>}>
                  <PageHeaders title="Plan de Asistencia Turismo Seguro" />
                  <PeopleMenu>
                    <AssistancePlan />
                  </PeopleMenu>
                </React.Suspense>
              }
            />
            <Route
              path={`${menuLinks.vidaEstudiantil}`}
              element={
                <React.Suspense fallback={<>...</>}>
                  <PageHeaders title="Vida Estudiantil" />
                  <PeopleMenu>
                    <StudentLife />
                  </PeopleMenu>
                </React.Suspense>
              }
            />
            <Route
              path={`${menuLinks.vidaIndividual}`}
              element={
                <React.Suspense fallback={<>...</>}>
                  <PageHeaders title="Vida Individual" />
                  <PeopleMenu>
                    <IndividualLife />
                  </PeopleMenu>
                </React.Suspense>
              }
            />
            <Route
              path={`${menuLinks.accidentesPersonales}`}
              element={
                <React.Suspense fallback={<>...</>}>
                  <PageHeaders title="Accidentes Personales" />
                  <PeopleMenu>
                    <PersonalAccidents />
                  </PeopleMenu>
                </React.Suspense>
              }
            />
            <Route
              path={`${menuLinks.accidentesEstudiantil}`}
              element={
                <React.Suspense fallback={<>...</>}>
                  <PageHeaders title="Accidentes Personales Estudiantil" />
                  <PeopleMenu>
                    <PersonalAccidentsStudents />
                  </PeopleMenu>
                </React.Suspense>
              }
            />
            <Route
              path={`${menuLinks.enfermedadesGraves}`}
              element={
                <React.Suspense fallback={<>...</>}>
                  <PageHeaders title="Enfermedades Graves" />
                  <PeopleMenu>
                    <SeriousIllness />
                  </PeopleMenu>
                </React.Suspense>
              }
            />
            <Route
              path={`${menuLinks.asistenciaFuneraria}`}
              element={
                <React.Suspense fallback={<>...</>}>
                  <PageHeaders title="Asistencia Funeraria" />
                  <PeopleMenu>
                    <FuneralAssistance />
                  </PeopleMenu>
                </React.Suspense>
              }
            />
            <Route
              path={`${menuLinks.respaldoMigratorio}`}
              element={
                <React.Suspense fallback={<>...</>}>
                  <PageHeaders title="Respaldo Migratorio" />
                  <PeopleMenu>
                    <ImmigrationSupport />
                  </PeopleMenu>
                </React.Suspense>
              }
            />
            <Route
              path={`${menuLinks.viviendaSegura}`}
              element={
                <React.Suspense fallback={<>...</>}>
                  <PageHeaders title="Vivienda Segura" />
                  <PropertyMenu>
                    <SafeHouse />
                  </PropertyMenu>
                </React.Suspense>
              }
            />
            <Route
              path={`${menuLinks.mobiliarioSeguro}`}
              element={
                <React.Suspense fallback={<>...</>}>
                  <PageHeaders title="Mobiliario Seguro" />
                  <PropertyMenu>
                    <SafeFurniture />
                  </PropertyMenu>
                </React.Suspense>
              }
            />
            <Route
              path={`${menuLinks.condominioSeguros}`}
              element={
                <React.Suspense fallback={<>...</>}>
                  <PageHeaders title="Seguros para Condominios" />
                  <PropertyMenu>
                    <CondoInsurance />
                  </PropertyMenu>
                </React.Suspense>
              }
            />
            <Route
              path={`${menuLinks.multiriesgoPymes}`}
              element={
                <React.Suspense fallback={<>...</>}>
                  <PageHeaders title="Multiriesgo PYMES" />
                  <PropertyMenu>
                    <MultiRiskPymes />
                  </PropertyMenu>
                </React.Suspense>
              }
            />
            <Route
              path={`${menuLinks.incendioYLineasAliadas}`}
              element={
                <React.Suspense fallback={<>...</>}>
                  <PageHeaders title="Incendio y Líneas Aliadas" />
                  <PropertyMenu>
                    <FireAndAlliedLines />
                  </PropertyMenu>
                </React.Suspense>
              }
            />
            <Route
              path={`${menuLinks.roturaMaquinarias}`}
              element={
                <React.Suspense fallback={<>...</>}>
                  <PageHeaders title="Rotura de Maquinarias" />
                  <PropertyMenu>
                    <MachineryBreakdown />
                  </PropertyMenu>
                </React.Suspense>
              }
            />
            <Route
              path={`${menuLinks.riesgoContratista}`}
              element={
                <React.Suspense fallback={<>...</>}>
                  <PageHeaders title="Todo Riesgo Contratista" />
                  <PropertyMenu>
                    <AllRisk />
                  </PropertyMenu>
                </React.Suspense>
              }
            />
            <Route
              path={`${menuLinks.riesgoMontaje}`}
              element={
                <React.Suspense fallback={<>...</>}>
                  <PageHeaders title="Todo Riesgo Montaje" />
                  <PropertyMenu>
                    <Mounting />
                  </PropertyMenu>
                </React.Suspense>
              }
            />
            <Route
              path={`${menuLinks.riesgoElectronicos}`}
              element={
                <React.Suspense fallback={<>...</>}>
                  <PageHeaders title="Todo Riesgo Equipos Electrónicos" />
                  <PropertyMenu>
                    <ElectronicDevices />
                  </PropertyMenu>
                </React.Suspense>
              }
            />
            <Route
              path={`${menuLinks.calderas}`}
              element={
                <React.Suspense fallback={<>...</>}>
                  <PageHeaders title="Calderas" />
                  <PropertyMenu>
                    <Boiler />
                  </PropertyMenu>
                </React.Suspense>
              }
            />
            <Route
              path={`${menuLinks.bail}`}
              element={
                <React.Suspense fallback={<>...</>}>
                  <PageHeaders title="Fianzas" />
                  <MultipleMenu>
                    <Bail />
                  </MultipleMenu>
                </React.Suspense>
              }
            />
            <Route
              path={`${menuLinks.fidelity}`}
              element={
                <React.Suspense fallback={<>...</>}>
                  <PageHeaders title="Fidelidad" />
                  <MultipleMenu>
                    <Fidelity />
                  </MultipleMenu>
                </React.Suspense>
              }
            />
            <Route
              path={`${menuLinks.cilvilLiability}`}
              element={
                <React.Suspense fallback={<>...</>}>
                  <PageHeaders title="Responsabilidad Civil" />
                  <MultipleMenu>
                    <CivilLiability />
                  </MultipleMenu>
                </React.Suspense>
              }
            />
            <Route
              path={`${menuLinks.merchandiseTransports}`}
              element={
                <React.Suspense fallback={<>...</>}>
                  <PageHeaders title="Transporte de Mercancías" />
                  <MultipleMenu>
                    <MerchandiseTransports />
                  </MultipleMenu>
                </React.Suspense>
              }
            />
            <Route
              path={`${menuLinks.glassBreakage}`}
              element={
                <React.Suspense fallback={<>...</>}>
                  <PageHeaders title="Rotura de Cristales y Letreros" />
                  <MultipleMenu>
                    <GlassBreakage />
                  </MultipleMenu>
                </React.Suspense>
              }
            />
            <Route
              path={`${menuLinks.valuableObjects}`}
              element={
                <React.Suspense fallback={<>...</>}>
                  <PageHeaders title="Artículos Valiosos" />
                  <MultipleMenu>
                    <ValuableItems />
                  </MultipleMenu>
                </React.Suspense>
              }
            />
            <Route
              path={`${menuLinks.moneyTheft}`}
              element={
                <React.Suspense fallback={<>...</>}>
                  <PageHeaders title="Robo de Dinero y Valores" />
                  <MultipleMenu>
                    <MoneyTheft />
                  </MultipleMenu>
                </React.Suspense>
              }
            />
            <Route
              path={`${menuLinks.servicesLanding}`}
              element={
                <React.Suspense fallback={<>...</>}>
                  <PageHeaders title="Canales de Servicio y Pagos" />
                  <ServicesChannelsMenu>
                    <ServicesLanding />
                  </ServicesChannelsMenu>
                </React.Suspense>
              }
            />
            <Route
              path={`${menuLinks.intermediarios365}`}
              element={
                <React.Suspense fallback={<>...</>}>
                  <PageHeaders title="Intermediarios 365" />
                  <ServicesChannelsMenu>
                    <Intermediarios365 />
                  </ServicesChannelsMenu>
                </React.Suspense>
              }
            />
            <Route
              path={`${menuLinks.clientes365}`}
              element={
                <React.Suspense fallback={<>...</>}>
                  <PageHeaders title="Clientes 365" />
                  <ServicesChannelsMenu>
                    <Clientes365 />
                  </ServicesChannelsMenu>
                </React.Suspense>
              }
            />
            <Route
              path={`${menuLinks.qrCode}`}
              element={
                <React.Suspense fallback={<>...</>}>
                  <PageHeaders title="Código QR" />
                  <ServicesChannelsMenu>
                    <QrCode />
                  </ServicesChannelsMenu>
                </React.Suspense>
              }
            />
            <Route
              path={`${menuLinks.virtualAssistance}`}
              element={
                <React.Suspense fallback={<>...</>}>
                  <PageHeaders title="Asistente Virtual" />
                  <ServicesChannelsMenu>
                    <AsistenteVirtual />
                  </ServicesChannelsMenu>
                </React.Suspense>
              }
            />
            <Route
              path={`${menuLinks.contact}`}
              element={
                <React.Suspense fallback={<>...</>}>
                  <PageHeaders title="Contáctanos" />
                  <ServicesChannelsMenu>
                    <Contact />
                  </ServicesChannelsMenu>
                </React.Suspense>
              }
            />
            <Route
              path={`${menuLinks.offices}`}
              element={
                <React.Suspense fallback={<>...</>}>
                  <PageHeaders title="Oficinas y Sucursales" />
                  <ServicesChannelsMenu>
                    <Offices />
                  </ServicesChannelsMenu>
                </React.Suspense>
              }
            />
            <Route
              path={`${menuLinks.claimsLanding}`}
              element={
                <React.Suspense fallback={<>...</>}>
                  <PageHeaders title="Reclamaciones" />
                  <ClaimsMenu>
                    <ClaimsLanding />
                  </ClaimsMenu>
                </React.Suspense>
              }
            />

            <Route
              path={`${menuLinks.claimsPerson}`}
              element={
                <React.Suspense fallback={<>...</>}>
                  <PageHeaders title="Reclamaciones Seguros de Personas" />
                  <ClaimsMenu>
                    <ClaimsPeople />
                  </ClaimsMenu>
                </React.Suspense>
              }
            />
            <Route
              path={`${menuLinks.claimsGeneralRisks}`}
              element={
                <React.Suspense fallback={<>...</>}>
                  <PageHeaders title="Reclamaciones Riesgos Generales" />
                  <ClaimsMenu>
                    <ClaimsGeneralRisks />
                  </ClaimsMenu>
                </React.Suspense>
              }
            />
            <Route
              path={`${menuLinks.claimsProperties}`}
              element={
                <React.Suspense fallback={<>...</>}>
                  <PageHeaders title="Reclamaciones Seguros de Propiedades" />
                  <ClaimsMenu>
                    <ClaimsProperties />
                  </ClaimsMenu>
                </React.Suspense>
              }
            />
            <Route
              path={`${menuLinks.claimsMultiple}`}
              element={
                <React.Suspense fallback={<>...</>}>
                  <PageHeaders title="Reclamaciones Seguros Múltiples" />
                  <ClaimsMenu>
                    <ClaimsMultiple />
                  </ClaimsMenu>
                </React.Suspense>
              }
            />
            <Route
              path={`${menuLinks.claimsVehicles}`}
              element={
                <React.Suspense fallback={<>...</>}>
                  <PageHeaders title="Reclamaciones Seguros de Vehículos" />
                  <ClaimsMenu>
                    <ClaimsVehicle />
                  </ClaimsMenu>
                </React.Suspense>
              }
            />

            <Route
              path={`${menuLinks.corporateidentity}`}
              element={
                <React.Suspense fallback={<>...</>}>
                  <PageHeaders title="Identidad Corporativa" />
                  <AboutUsMenu>
                    <CorporateIdentity />
                  </AboutUsMenu>
                </React.Suspense>
              }
            />

            <Route
              path={`${menuLinks.institutionalPublications}`}
              element={
                <React.Suspense fallback={<>...</>}>
                  <PageHeaders title="Publicaciones Institucionales" />
                  <AboutUsMenu>
                    <InstitutionalPublications />
                  </AboutUsMenu>
                </React.Suspense>
              }
            />
            <Route
              path={`${menuLinks.subsidiaries}`}
              element={
                <React.Suspense fallback={<>...</>}>
                  <PageHeaders title="Filiales" />
                  <AboutUsMenu>
                    <Subsidiaries />
                  </AboutUsMenu>
                </React.Suspense>
              }
            />
            <Route
              path={`${menuLinks.workWithUs}`}
              element={
                <React.Suspense fallback={<>...</>}>
                  <PageHeaders title="Trabaje con Nosotros" />
                  <AboutUsMenu>
                    <WorkWithUs />
                  </AboutUsMenu>
                </React.Suspense>
              }
            />
            <Route
              path={`${menuLinks.council}`}
              element={
                <React.Suspense fallback={<>...</>}>
                  <PageHeaders title="Consejo y Principales Ejecutivos" />
                  <AboutUsMenu>
                    <CouncilAndExecutives />
                  </AboutUsMenu>
                </React.Suspense>
              }
            />
            <Route
              path={`${menuLinks.ourHistory}`}
              element={
                <React.Suspense fallback={<>...</>}>
                  <PageHeaders title="Nuestra Historia" />
                  <AboutUsMenu>
                    <OurHistory />
                  </AboutUsMenu>
                </React.Suspense>
              }
            />

            <Route
              path={`${menuLinks.socialResposibility}`}
              element={
                <React.Suspense fallback={<>...</>}>
                  <PageHeaders title="Responsabilidad Social Corporativa" />
                  <AboutUsMenu>
                    <SocialResponsibility />
                  </AboutUsMenu>
                </React.Suspense>
              }
            />
            <Route
              path={`${menuLinks.reinsurance}`}
              element={
                <React.Suspense fallback={<>...</>}>
                  <PageHeaders title="Reaseguradores" />
                  <AboutUsMenu>
                    <ReInsurance />
                  </AboutUsMenu>
                </React.Suspense>
              }
            />
            <Route
              path={`${menuLinks.news}`}
              element={
                <React.Suspense fallback={<>...</>}>
                  <PageHeaders title="Noticias y Novedades" />
                  <NewsData>
                    <News />
                  </NewsData>
                </React.Suspense>
              }
            />
            <Route
              path={`${menuLinks.newsDetails}`}
              element={
                <React.Suspense fallback={<>...</>}>
                  <PageHeaders title="Noticias y Novedades" />
                  <NewsData>
                    <NewsDetails />
                  </NewsData>
                </React.Suspense>
              }
            />
            <Route
              path={`${menuLinks.error404}`}
              element={
                <React.Suspense fallback={<>...</>}>
                  <PageHeaders title="404" />
                  <Error404 />
                </React.Suspense>
              }
            />
            <Route
              path="*"
              element={<Navigate to={`${menuLinks.error404}`} />}
            />
          </Routes>
        )}
      </ScrollToTop>
      <FooterComponent />
    </BrowserRouter>
  );
};

export default Router;
