const ClaimsSideTabsMenu = ({
  setActive,
  active,
  sideMenuList,
}: any) => {
  const handleActive = ({ target }: any, id: number) => {
    setActive({ activeId: id, activeTitle: target.textContent });
  };

  return (
    <div className="hidden w-1/2 planList-side-menu lg:block">
      <ul>
        {sideMenuList.map((li: any, i: number) => (
          <li
            className={`list-item ${active.activeId === li.id && "list-item-active"
              }`}
            onClick={(e: any) => handleActive(e, li.id)}
            key={i}
          >
            {li.title}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ClaimsSideTabsMenu;
