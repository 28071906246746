import SectionComponentOne from "../../components/HomeComponents/SectionComponentOne";
import SectionComponentThree from "../../components/HomeComponents/SectionComponentThree";
import HeroComponent from "../../components/HomeComponents/HeroComponent";
import SectionComponentFour from "../../components/HomeComponents/SectionComponentFour";
import SectionComponentTwo from "../../components/HomeComponents/SectionComponentTwo";
import NewsData from "../../context/News/NewsContext";

const Home = () => {
  return (
    <div className="home">
      <HeroComponent />
      <SectionComponentOne />
      <SectionComponentTwo />
      <SectionComponentThree />
      <NewsData>
        <SectionComponentFour />
      </NewsData>
    </div>
  );
};

export default Home;
