import "./css/DirectiveComponent.css";

type AdminCardProps = {
  name: string;
  position: string;
  id: number;
  image: string;
};

function AdminCardCreate({ name, position, id, image }: AdminCardProps) {
  return (
    <div
      className="directive-card flex flex-col flex-wrap items-center justify-center"
      key={id}
    >
      <img className="admin-pictures" src={image} alt="Director picture" />
      <h2>{name}</h2>
      <p>{position}</p>
    </div>
  );
}

const AdminCard = ({ cardsInfo }: any) => {
  return (
    <div className="grid grid-cols-2 gap-2 lg:grid-cols-3 xl:grid-cols-4 lg:gap-5">
      {cardsInfo &&
        cardsInfo
          .filter((card) => card.groupType === "admin")
          .map((card, i) => (
            <AdminCardCreate
              key={i}
              name={card.name}
              position={card.position}
              id={card.id}
              image={card.image}
            />
          ))}
    </div>
  );
};

export default AdminCard;
