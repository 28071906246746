type section = {
  content: string | undefined;
  contentImage: string | undefined;
};

const SectionInformation = ({ content, contentImage }: section) => {
  return (
    <div className="sectionInformation">
      <div className="container mx-auto lg:flex lg:justify-between">
        <div className="right-div">
          <div className="title-div md:hidden">
            <h4>
              {content?.substring(
                content?.search("<h4>") + 4,
                content?.search("</h4>")
              )}
            </h4>
            <h5>
              {content?.substring(
                content?.search("<h5>") + 4,
                content?.search("</h5>")
              )}
            </h5>
          </div>
          <div className="img-div">
            <img src={contentImage} alt="info-img" />
          </div>
        </div>

        <div
          className="text-div"
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>
      </div>
    </div>
  );
};

export default SectionInformation;
