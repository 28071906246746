import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { BASE_URL, GET_HEADERS } from "../../components/Helper/requestData";

import InformationComponent from "../../components/InformationComponent/InformationComponent";
import HeroMenuComponent from "../../components/MenuLandingsLayout/HeroMenuComponent";
import SecondaryNavBar from "../../components/secondaryNavBar/SecondaryNavBar";
import ClaimsSingleStepsVehicle from "../../components/TabsClaims/ClaimsSingleStepsVehicle";
import Faq from "../../components/VariousCardsComponents/btnCards/Faq";
import { ClaimsMenuContext } from "../../context/Claims/ClaimsMenuContext";
import ClaimsVehicleData from "../../context/Claims/ClaimsVehicleDataContext";

const ClaimsVehicle = () => {
  const [heroSideData, setHeroSideData] = useState({
    title: "",
    titleRich: "",
    bannerImage: "",
    secondaryTitle: "",
    sideTitle1: "",
    sideTitle2: "",
    content: "",
    sideContent1: "",
  });

  const [plansButtons, setPlansButtons] = useState({
    faqLink: "",
    faqTitle: "",
    faqIcon: "",
    faqActive: true,
  });

  const [tabsContent, setTabsContent] = useState({
    id: 1,
    title: "",
    stepsTitle: "",
    stepsSubTitle: "",
    formBtnText: "",
    formBtnLink: "",
    docTitle: "",
    docSubTitle: "",
    docBtnText: "",
    docBtnContent: "",
  });

  const { menuListData } = useContext(ClaimsMenuContext);

  const getSectionContentData = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Contenido-de-Secciones')/items?$filter=Title eq 'Reclamaciones Seguros de Vehículos'",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    setHeroSideData({
      titleRich: resultsArr[0]?.TitleRich,
      title: resultsArr[0]?.Title,
      bannerImage: resultsArr[0]?.BannerImage?.Url,
      content: resultsArr[0]?.Content,
      secondaryTitle: resultsArr[0]?.SecondaryTitle,
      sideTitle1: resultsArr[0]?.SideTitle1,
      sideTitle2: resultsArr[0]?.SideTitle2,
      sideContent1: resultsArr[0]?.SideContent1,
    });
  };

  const getPlansButtons = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL + "_api/web/lists/getbytitle('Bottom-Buttons')/items",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    const result = resultsArr.find(
      (r: any) => r.Title === "Reclamaciones Seguros de Vehículos"
    );

    setPlansButtons({
      faqLink: result?.FAQLink?.Description,
      faqIcon: result?.FAQIcon?.Url,
      faqTitle: result?.FAQTitle,
      faqActive: result?.Activo,
    });
  };

  const getTabsContent = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Reclamaciones-Tabs-Contenido')/items?$filter=TabCategory eq 'Reclamaciones Seguros de Vehículos'",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    const result = resultsArr[0];

    setTabsContent({
      id: result?.Order0,
      title: result?.Title,
      stepsTitle: result?.StepsTitle,
      stepsSubTitle: result?.StepsSubtitle,
      formBtnText: result?.FormBtn?.Description,
      formBtnLink: result?.FormBtn?.Url,
      docTitle: result?.DocTitle,
      docSubTitle: result?.DocSubtitle,
      docBtnText: result?.DocBtnText,
      docBtnContent: result?.DocBtnContent,
    });
  };

  useEffect(() => {
    getSectionContentData();
    getPlansButtons();
    getTabsContent();
  }, []);
  return (
    <>
      <HeroMenuComponent
        bannerImage={heroSideData.bannerImage}
        title={heroSideData.titleRich}
      />
      <SecondaryNavBar
        title={heroSideData.secondaryTitle || heroSideData.titleRich}
        menuList={menuListData}
        subMenuList={[]}
      />
      <div className="container mx-auto lg:flex lg:justify-between">
        <div className="lg:w-3/5">
          <InformationComponent content={heroSideData.content} />
        </div>
      </div>
      <div className="lg:mb-20">
        <div className="container mx-auto">
          <div className="claims-steps-content vehicle-plans">
            <div className="claims-step-info vehicle-plans-list vehicle-plans-list--full">
              <ClaimsVehicleData>
                <ClaimsSingleStepsVehicle
                  stepsTitle={tabsContent.stepsTitle}
                  stepsSubTitle={tabsContent.stepsSubTitle}
                  formBtnText={tabsContent.formBtnText}
                  formBtnLink={tabsContent.formBtnLink}
                  docTitle={tabsContent.docTitle}
                  docSubTitle={tabsContent.docSubTitle}
                  docBtnText={tabsContent.docBtnText}
                  docBtnContent={tabsContent.docBtnContent}
                  channelsTitle={heroSideData.sideContent1}
                  channelsText={heroSideData.sideTitle1}
                />
              </ClaimsVehicleData>
              <div className="flex flex-col justify-between mb-6 btn-cards md:flex-row ">
                {plansButtons.faqLink && (
                  <Faq
                    faqLink={plansButtons.faqLink}
                    faqTitle={plansButtons.faqTitle}
                    faqIcon={plansButtons.faqIcon}
                    faqActive={plansButtons.faqActive}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClaimsVehicle;
