function CardMembershipValues({ cardImg, isSlide }: any) {
  return (
    <div
      className={
        isSlide
          ? "card-box-slide values-card-fix "
          : "items-center bg-white  flex flex-col justify-center p-3 w-11/12"
      }
      style={{ boxShadow: "0px 3px 21px #0000000F" }}
    >
      <img
        src={cardImg}
        alt="company-logo"
        style={{
          padding: "0px",
          margin: "0px",
          width: "100%",
          objectFit: "contain",
        }}
      />
    </div>
  );
}

export default CardMembershipValues;
