import VideoContainer from "../videoContainer/VideoContainer";
import "./css/howToModal.css";

interface HowToModalVideoProps {
  posterImage: string;
  videoLink: string;
  title: string;
  text: string;
}

const HowToModalVideo = ({
  posterImage,
  videoLink,
  title,
  text,
}: HowToModalVideoProps) => {
  return (
    <div>
      <div className="mx-auto how-modal">
        <div className="how-modal__titlebox">
          <h1 className="how-modal__title">{title}</h1>
          <div className="lg:w-2/5">
            <p
              className="how-modal__text"
              dangerouslySetInnerHTML={{ __html: text }}
            ></p>
          </div>
        </div>
        <div className="how-modal__titlebox">
          <VideoContainer posterImage={posterImage} videoLink={videoLink} />
        </div>
      </div>
    </div>
  );
};

export default HowToModalVideo;
