import { useContext, useEffect, useState } from "react";
import InformationComponent from "../../components/InformationComponent/InformationComponent";
import HeroMenuComponent from "../../components/MenuLandingsLayout/HeroMenuComponent";
import SecondaryNavBar from "../../components/secondaryNavBar/SecondaryNavBar";
import SideBar from "../../components/sideBar/SideBar";
import SideBarList from "../../components/sideBar/SideBarList";
import TravelIconList from "../../components/VariousCardsComponents/travelIconlist/TravelIconList";
import MoreInformationCard from "../../components/VariousCardsComponents/moreInfoCard/MoreInformationCard";
import NeedHelpCard from "../../components/VariousCardsComponents/needHelpCard/NeedHelpCard";
import BlueArrowList from "../../components/VariousCardsComponents/blueArrowList/BlueArrowList";
import { BASE_URL, GET_HEADERS } from "../../components/Helper/requestData";
import axios from "axios";
import { PeopleMenuContext } from "../../context/People/PeopleMenuContext";
import { SideListModel } from "../../context/models/SideList.model";

const ReservasTravel = () => {
  const [heroSideData, setHeroSideData] = useState({
    title: "",
    titleRich: "",
    bannerImage: "",
    secondaryTitle: "",
    sideTitle1: "",
    sideContent1: "",
    sideTitle2: "",
    sideContent2: "",
    content: "",
  });

  const [secondaryNavBarButtons, setSecondaryNavBarButtons] = useState({
    btnLinkCotizar: "",
    btnImgCotizar: "",
    btnTitleCotizar: "",
    btnLinkBrochure: "",
    btnImgBrochure: "",
    btnTitleBrochure: "",
    btnBlockActive: true,
  });

  const [planesData, setPlanesData] = useState([
    {
      title: "",
      titleBlue: "",
      id: 0,
      content: "",
    },
  ]);

  const [sideList, setSideList] = useState<SideListModel[]>([]);

  const { menuListData, subMenuListData } = useContext(PeopleMenuContext);

  const getSectionContentData = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Contenido-de-Secciones')/items?$filter=Title eq 'Viajes Reservas'",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    setHeroSideData({
      titleRich: resultsArr[0]?.TitleRich,
      title: resultsArr[0]?.Title,
      bannerImage: resultsArr[0]?.BannerImage.Url,
      secondaryTitle: resultsArr[0]?.SecondaryTitle,
      sideTitle1: resultsArr[0]?.SideTitle1,
      sideContent1: resultsArr[0]?.SideContent1,
      sideTitle2: resultsArr[0]?.SideTitle2,
      sideContent2: resultsArr[0]?.SideContent2,
      content: resultsArr[0]?.Content,
    });

    setPlanesData(
      resultsArr.map((item: any) => {
        return {
          title: item?.MiddleTitle,
          titleBlue: item?.MiddleTitle,
          id: item?.Id,
          content: item?.MiddleContent,
        };
      })
    );
  };

  const getSecondaryNavBarButtons = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Secondary-NavBar-Buttons')/items",

        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    const result = resultsArr.find((r: any) => r.Title === "Viajes Reservas");

    setSecondaryNavBarButtons({
      btnLinkCotizar: result?.BtnLinkCotizar?.Description,
      btnImgCotizar: result?.BtnImgCotizar?.Url,
      btnTitleCotizar: result?.BtnTitleCotizar,
      btnLinkBrochure: result?.BtnLinkBrochure?.Description,
      btnImgBrochure: result?.BtnImgBrochure?.Url,
      btnTitleBrochure: result?.BtnTitleBrochure,
      btnBlockActive: result?.Activo,
    });
  };

  const getPlanesData = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL + "_api/web/lists/getbytitle('Viajes-Reservas-Planes')/items",

        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    setPlanesData(
      resultsArr.map((item: any) => {
        return {
          title: item?.Text,
          titleBlue: item?.Text,
          id: item?.Order,
          content: item?.Content,
        };
      })
    );
  };

  const getSideList = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Seguros-Servicios-SideLists')/items?$filter=Categoria eq 'Viajes Reservas'",

        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    resultsArr.sort(
      (a: { Order0: number }, b: { Order0: number }) => a.Order0 - b.Order0
    );

    setSideList(
      resultsArr.map((item: any) => {
        return {
          title: item?.Text,
          id: item?.Order0,
        };
      })
    );
  };

  useEffect(() => {
    getSectionContentData();
    getSecondaryNavBarButtons();
    getPlanesData();
    getSideList();
  }, []);

  return (
    <>
      <HeroMenuComponent
        bannerImage={heroSideData.bannerImage}
        title={heroSideData.titleRich}
      />
      <SecondaryNavBar
        title={heroSideData.secondaryTitle || heroSideData.titleRich}
        menuList={menuListData}
        subMenuList={subMenuListData}
        btnLinkCotizar={secondaryNavBarButtons.btnLinkCotizar}
        btnImgCotizar={secondaryNavBarButtons.btnImgCotizar}
        btnTitleCotizar={secondaryNavBarButtons.btnTitleCotizar}
        btnLinkBrochure={secondaryNavBarButtons.btnLinkBrochure}
        btnImgBrochure={secondaryNavBarButtons.btnImgBrochure}
        btnTitleBrochure={secondaryNavBarButtons.btnTitleBrochure}
        btnBlockActive={secondaryNavBarButtons.btnBlockActive}
      />
      <div className="container mx-auto lg:flex lg:justify-between md:mb-16 ">
        <div className="lg:w-3/5">
          <InformationComponent content={heroSideData.content} />
          <div className="mt-10 lg:mt-14 lg:w-full">
            <TravelIconList />
          </div>
          <div className="mt-10 lg:mt-10 lg:w-3/4">
            <div className="titlebluebox">
              <BlueArrowList contentList={planesData} />
            </div>
          </div>

          <div className="hidden my-10 lg:block">
            <NeedHelpCard />
            <MoreInformationCard />
          </div>
        </div>
        <div className="mt-10 lg:mt-14 lg:w-1/3">
          <SideBar>
            {heroSideData.sideTitle1 && (
              <SideBarList
                sideTitle={heroSideData.sideTitle1}
                sideBarList={sideList}
              />
            )}
          </SideBar>
          <div className="block my-10 lg:hidden">
            <NeedHelpCard />
            <MoreInformationCard />
          </div>
        </div>
      </div>
    </>
  );
};

export default ReservasTravel;
