/* eslint-disable no-console */
import { useEffect, useState } from "react";
import menuX from "../../../assets/img/icons/side-menu-x.svg";
import "./css/comparatorComponentV2.css";
import plus from "../../../assets/img/icons/white-plus-icon.svg";
import ListBoxComponentComparatorV2 from "./ListBoxComponentComparatorV2";
import ComparatorDesktopServiceTable from "./ComparatorDesktopServiceTable";

export default function ComparatorDesktopComponentV2({
  showFullScreenContent,
  setShowFullScreenContent,
  planList,
  title,
}: any) {
  const [planListState, setPlanListState] = useState<any[]>();
  const [planCShow, setPlanCShow] = useState<boolean>(false);
  const [selectedPlanA, setSelectedPlanA] = useState<any>();
  const [selectedPlanB, setSelectedPlanB] = useState<any>();
  const [selectedPlanC, setSelectedPlanC] = useState<any>();
  const [tableServiceOne, setTableServiceOne] = useState<any>();
  const [tableServiceTwo, setTableServiceTwo] = useState<any>();
  const [tableServiceThree, setTableServiceThree] = useState<any>();
  const [tableServiceFour, setTableServiceFour] = useState<any>();
  const [tableServiceFive, setTableServiceFive] = useState<any>();
  const [tableServiceSix, setTableServiceSix] = useState<any>();
  const [tableServiceSeven, setTableServiceSeven] = useState<any>();

  useEffect(() => {
    const list = [...planList];
    const newList = list.map((p: any) => {
      return {
        ...p,
        title: "Plan " + p.title,
      };
    });
    setPlanListState(newList);
  }, [planList]);

  useEffect(() => {
    if (planListState !== undefined && planListState !== null) {
      setSelectedPlanA(planListState[0]);
      setSelectedPlanB(planListState[1]);
      setSelectedPlanC(planListState[2]);
    }
  }, [planListState, planCShow]);
  useEffect(() => {
    const serviceOne =
      selectedPlanA &&
      selectedPlanA.planServiceOne &&
      selectedPlanA?.planServiceOne.map((plan: any, i: number) => {
        return {
          serviceName: plan?.serviceName,
          serviceType: plan?.serviceType,
          serviceValueA: selectedPlanA?.planServiceOne[i]?.serviceValue,
          serviceValueB: selectedPlanB?.planServiceOne[i]?.serviceValue,
          serviceValueC: selectedPlanC?.planServiceOne[i]?.serviceValue,
        };
      });
    setTableServiceOne(serviceOne);
    const serviceTwo =
      selectedPlanA &&
      selectedPlanA.planServiceTwo &&
      selectedPlanA?.planServiceTwo.map((plan: any, i: number) => {
        return {
          serviceName: plan?.serviceName,
          serviceType: plan?.serviceType,
          serviceValueA: selectedPlanA?.planServiceTwo[i]?.serviceValue,
          serviceValueB: selectedPlanB?.planServiceTwo[i]?.serviceValue,
          serviceValueC: selectedPlanC?.planServiceTwo[i]?.serviceValue,
        };
      });
    setTableServiceTwo(serviceTwo);
    const serviceThree =
      selectedPlanA &&
      selectedPlanA.planServiceThree &&
      selectedPlanA?.planServiceThree.map((plan: any, i: number) => {
        return {
          serviceName: plan?.serviceName,
          serviceType: plan?.serviceType,
          serviceValueA: selectedPlanA?.planServiceThree[i]?.serviceValue,
          serviceValueB: selectedPlanB?.planServiceThree[i]?.serviceValue,
          serviceValueC: selectedPlanC?.planServiceThree[i]?.serviceValue,
        };
      });
    setTableServiceThree(serviceThree);
    const serviceFour =
      selectedPlanA &&
      selectedPlanA.planServiceFour &&
      selectedPlanA?.planServiceFour.map((plan: any, i: number) => {
        return {
          serviceName: plan?.serviceName,
          serviceType: plan?.serviceType,
          serviceValueA: selectedPlanA?.planServiceFour[i]?.serviceValue,
          serviceValueB: selectedPlanB?.planServiceFour[i]?.serviceValue,
          serviceValueC: selectedPlanC?.planServiceFour[i]?.serviceValue,
        };
      });
    setTableServiceFour(serviceFour);
    const serviceFive =
      selectedPlanA &&
      selectedPlanA.planServiceFive &&
      selectedPlanA?.planServiceFive.map((plan: any, i: number) => {
        return {
          serviceName: plan?.serviceName,
          serviceType: plan?.serviceType,
          serviceValueA: selectedPlanA?.planServiceFive[i]?.serviceValue,
          serviceValueB: selectedPlanB?.planServiceFive[i]?.serviceValue,
          serviceValueC: selectedPlanC?.planServiceFive[i]?.serviceValue,
        };
      });
    setTableServiceFive(serviceFive);
    const serviceSix =
      selectedPlanA &&
      selectedPlanA.planServiceSix &&
      selectedPlanA?.planServiceSix.map((plan: any, i: number) => {
        return {
          serviceName: plan?.serviceName,
          serviceType: plan?.serviceType,
          serviceValueA: selectedPlanA?.planServiceSix[i]?.serviceValue,
          serviceValueB: selectedPlanB?.planServiceSix[i]?.serviceValue,
          serviceValueC: selectedPlanC?.planServiceSix[i]?.serviceValue,
        };
      });
    setTableServiceSix(serviceSix);
    const serviceSeven =
      selectedPlanA &&
      selectedPlanA.planServiceSeven &&
      selectedPlanA?.planServiceSeven.map((plan: any, i: number) => {
        return {
          serviceName: plan?.serviceName,
          serviceType: plan?.serviceType,
          serviceValueA: selectedPlanA?.planServiceSeven[i]?.serviceValue,
          serviceValueB: selectedPlanB?.planServiceSeven[i]?.serviceValue,
          serviceValueC: selectedPlanC?.planServiceSeven[i]?.serviceValue,
        };
      });
    setTableServiceSeven(serviceSeven);
  }, [selectedPlanA, selectedPlanB, selectedPlanC, planCShow]);
  const handleClick = () => {
    setShowFullScreenContent(!showFullScreenContent);
    setPlanCShow(false);
  };
  return (
    <div
      className={`transition ease-out transform ${
        showFullScreenContent ? "" : "-translate-x-full "
      }  duration-400 comparator-container `}
    >
      <div className="comparator-desktop-header">
        <div className="comparator-desktop-title">
          <h1 className="push-right">Comparador de planes</h1>

          <div
            dangerouslySetInnerHTML={{ __html: title }}
            className="insurance-titles"
          ></div>
        </div>
        <div onClick={handleClick} className="close-icon">
          <img
            src={menuX}
            alt="close"
            className="close-icon-image cursor-pointer"
          />
        </div>
      </div>
      <div className="container mx-auto">
        <div className="selector-plans-container w-full justify-center">
          <div className={`flex: 0 0 ${planCShow ? "w-1/4" : "w-1/3"}`}>
            <h6 className="mt-2">Seleccionar Plan:</h6>
          </div>

          <div className="selector-btn-container flex-1">
            <div className="selector-btn-box">
              <ListBoxComponentComparatorV2
                planList={planListState}
                selectedPlan={selectedPlanA}
                setSelectedPlan={setSelectedPlanA}
              />
            </div>

            <div className="selector-btn-box">
              <ListBoxComponentComparatorV2
                planList={planListState}
                selectedPlan={selectedPlanB}
                setSelectedPlan={setSelectedPlanB}
              />
            </div>
            {planCShow ? (
              <div className="selector-btn-box">
                <ListBoxComponentComparatorV2
                  planList={planListState}
                  selectedPlan={selectedPlanC}
                  setSelectedPlan={setSelectedPlanC}
                />
              </div>
            ) : (
              <img
                src={plus}
                alt="plus-icon"
                className="plus-icon self-start mt-2"
                onClick={() => setPlanCShow(true)}
              />
            )}
          </div>
        </div>

        <ComparatorDesktopServiceTable
          tableService={tableServiceOne}
          planCShow={planCShow}
        />
        <ComparatorDesktopServiceTable
          tableService={tableServiceTwo}
          planCShow={planCShow}
        />
        <ComparatorDesktopServiceTable
          tableService={tableServiceThree}
          planCShow={planCShow}
        />
        <ComparatorDesktopServiceTable
          tableService={tableServiceFour}
          planCShow={planCShow}
        />
        <ComparatorDesktopServiceTable
          tableService={tableServiceFive}
          planCShow={planCShow}
        />
        <ComparatorDesktopServiceTable
          tableService={tableServiceSix}
          planCShow={planCShow}
        />
        <ComparatorDesktopServiceTable
          tableService={tableServiceSeven}
          planCShow={planCShow}
        />
        <h4 className="mt-8 blue-asterisk font-bold">
          (*) Coberturas sujetas a deducible
        </h4>
        <h4 className="mt-2 blue-asterisk font-bold">
          (**) Coberturas sujetas a deducible y coaseguro
        </h4>
      </div>
    </div>
  );
}
