import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import SmallRespoCardServices from "./SmallRespoCardServices";
import BigRespoCardServices from "./BigRespoCardServices";
import "./css/ResponsibilitySlide.css";

const ResponsibilitySlide = ({ title, cardsData }: any) => {
  const renderCards = () => {
    return cardsData.map((c, i) => (
      <BigRespoCardServices
        key={i}
        title={c.title}
        size={c.size}
        imageSlide={c.imageSlide}
        imageModal={c.imageModal}
        content={c.content}
        orden={c.orden}
      />
    ));
  };

  const cardsCreator = () => {
    const bigCards: React.ReactNode[] = [];
    const smallCards: React.ReactNode[] = [];
    const smallGroupCards = [];
    const allCards = [];
    cardsData.forEach((c) => {
      if (c.size === "Big") {
        bigCards.push(
          <BigRespoCardServices
            title={c.title}
            size={c.size}
            imageSlide={c.imageSlide}
            imageModal={c.imageModal}
            content={c.content}
            orden={c.orden}
          />
        );
      }
      if (c.size === "Small") {
        smallCards.push(
          <SmallRespoCardServices
            title={c.title}
            size={c.size}
            imageSlide={c.imageSlide}
            imageModal={c.imageModal}
            content={c.content}
            orden={c.orden}
          />
        );
      }
    });

    for (let x = 0; x < smallCards.length; x += 2) {
      smallGroupCards.push(
        <>
          {smallCards[x]}
          {smallCards[x + 1]}
        </>
      );
    }

    for (let i = 0; i < bigCards.length; i++) {
      allCards.push(bigCards[i]);
      allCards.push(smallGroupCards[i]);
    }

    return allCards;
  };

  return (
    <div className="responsabilidad mb-14">
      <div className="">
        <div className="mb-8 title title--left">{title}</div>
        <div className="hidden splide respo-slide-1 lg:block">
          <Splide
            className="splide"
            options={{
              type: "slide",
              perPage: 2,
              arrows: true,
              pagination: true,
            }}
            renderControls={() => (
              <div className="splide__arrows">
                <div className="splide__arrow splide__arrow--next ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="38.732"
                    height="70.464"
                    viewBox="0 0 38.732 70.464"
                  >
                    <path
                      id="chevron-right"
                      d="M9,66.564,39.282,36.282,9,6"
                      transform="translate(-4.05 -1.05)"
                      fill="none"
                      stroke="#00aef0"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="7"
                    />
                  </svg>
                </div>
                <div className="splide__arrow splide__arrow--prev ">
                  <svg
                    className="left-arrow"
                    xmlns="http://www.w3.org/2000/svg"
                    width="38.732"
                    height="70.464"
                    viewBox="0 0 38.732 70.464"
                  >
                    <path
                      id="chevron-right"
                      d="M9,66.564,39.282,36.282,9,6"
                      transform="translate(-4.05 -1.05)"
                      fill="none"
                      stroke="#00aef0"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="7"
                    />
                  </svg>
                </div>
              </div>
            )}
          >
            {(() =>
              cardsCreator().map((s: any, i: number) => (
                <SplideSlide key={i}>{s}</SplideSlide>
              )))()}
          </Splide>
        </div>
        <div className="block splide respo-slide-2 lg:hidden">
          <Splide
            className="splide"
            options={{
              type: "slide",
              perPage: 2,
              arrows: true,
              pagination: true,
              breakpoints: {
                1023: {
                  perPage: 1,
                },
              },
            }}
            renderControls={() => (
              <div className="splide__arrows lg:hidden">
                <div className="splide__arrow splide__arrow--next ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="38.732"
                    height="70.464"
                    viewBox="0 0 38.732 70.464"
                  >
                    <path
                      id="chevron-right"
                      d="M9,66.564,39.282,36.282,9,6"
                      transform="translate(-4.05 -1.05)"
                      fill="none"
                      stroke="#00aef0"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="7"
                    />
                  </svg>
                </div>
                <div className="splide__arrow splide__arrow--prev ">
                  <svg
                    className="left-arrow"
                    xmlns="http://www.w3.org/2000/svg"
                    width="38.732"
                    height="70.464"
                    viewBox="0 0 38.732 70.464"
                  >
                    <path
                      id="chevron-right"
                      d="M9,66.564,39.282,36.282,9,6"
                      transform="translate(-4.05 -1.05)"
                      fill="none"
                      stroke="#00aef0"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="7"
                    />
                  </svg>
                </div>
              </div>
            )}
          >
            {(() =>
              renderCards()?.map((s: any, i: number) => (
                <SplideSlide key={i}>{s}</SplideSlide>
              )))()}
          </Splide>
        </div>
      </div>
    </div>
  );
};

export default ResponsibilitySlide;
