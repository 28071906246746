import { Splide, SplideSlide } from "@splidejs/react-splide";
import arrow from "../../assets/img/icons/chevron-dark-gray.svg";
import CardsComponentClaims from "./CardsComponentClaims";

type CardsContainerClaimsProps = {
  cards: any;
  showCards: boolean;
};
const CardsContainerClaims = ({
  cards,
  showCards,
}: CardsContainerClaimsProps) => {
  return (
    <div className="container flex flex-col mx-auto cards-container lg:grid md:grid-cols-2 md:gap-8 channels-card-desktop lg:max-w-screen-lg ">
      {cards?.map((card) => (
        <div
          key={card.cardId}
          className={`${showCards ? "block" : "hidden"} lg:flex`}
        >
          <CardsComponentClaims
            cardImg={card.cardImg}
            cardTitle={card.cardTitle}
            isSlide={false}
            content={card.content}
            link={card.link}
            linkType={card.linkType}
          />
        </div>
      ))}

      <div
        className={`${showCards ? "hidden" : "block self-center"} lg:hidden`}
      >
        <Splide
          renderControls={() => (
            <div className="splide__arrows">
              <div className="container ">
                <button
                  type="button"
                  className="splide__arrow splide__arrow--next "
                >
                  <img src={arrow} alt="arrow" />
                </button>
                <button
                  type="button"
                  className="splide__arrow splide__arrow--prev "
                >
                  <img src={arrow} alt="arrow" className="left-arrow " />
                </button>
              </div>
            </div>
          )}
        >
          {cards?.map((card) => (
            <SplideSlide key={card.cardId}>
              <>
                <CardsComponentClaims
                  cardImg={card.cardImg}
                  cardTitle={card.cardTitle}
                  isSlide={true}
                  content={card.content}
                  link={card.link}
                  linkType={card.linkType}
                />
              </>
            </SplideSlide>
          ))}
        </Splide>
      </div>
    </div>
  );
};

export default CardsContainerClaims;
