import "./css/ourHistory.css";

import { BASE_URL, GET_HEADERS } from "../../components/Helper/requestData";
import { useContext, useEffect, useState } from "react";

import { AboutUsMenuContext } from "../../context/AboutUs/AboutUsMenuContext";
import HeroMenuComponent from "../../components/MenuLandingsLayout/HeroMenuComponent";
import InformationComponent from "../../components/InformationComponent/InformationComponent";
import SecondaryNavBar from "../../components/secondaryNavBar/SecondaryNavBar";
import axios from "axios";

const OurHistory = () => {
  const [heroSideData, setHeroSideData] = useState({
    title: "",
    titleRich: "",
    bannerImage: "",
    secondaryTitle: "",
    sideTitle1: "",
    sideContent1: "",
    sideTitle2: "",
    content: "",
    contentImage: "",
    sideContent2: "",
    middleTitle: "",
  });
  const [riskRatings, setRiskRatings] = useState([
    {
      title: "",
      image: "",
      text: "",
    },
  ]);

  const [timeline, setTimeline] = useState([
    {
      year: "",
      content: "",
    },
  ]);

  const { menuListData } = useContext(AboutUsMenuContext);

  const getSectionContentData = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Contenido-de-Secciones')/items?$filter=Title eq 'Nuestra Historia'",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    setHeroSideData({
      titleRich: resultsArr[0]?.TitleRich,
      title: resultsArr[0]?.Title,
      bannerImage: resultsArr[0]?.BannerImage?.Url,
      content: resultsArr[0]?.Content,
      secondaryTitle: resultsArr[0]?.SecondaryTitle,
      sideTitle1: resultsArr[0]?.SideTitle1,
      sideTitle2: resultsArr[0]?.SideTitle2,
      sideContent1: resultsArr[0]?.SideContent1,
      contentImage: resultsArr[0]?.ContentImage?.Url,
      sideContent2: resultsArr[0]?.SideContent2,
      middleTitle: resultsArr[0]?.MiddleTitle,
    });
  };
  const getRiskRatings = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Calificaciones de Riesgo')/items",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    setRiskRatings(
      resultsArr.map((item: any) => {
        return {
          title: item?.Title,
          image: item?.Image.Url,
          text: item?.Text,
        };
      })
    );
  };

  const getTimeline = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Nuestra-Historia-Timeline')/items",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    const results = resultsArr.sort((a, b) => Number(a.Year) - Number(b.Year));

    setTimeline(
      results.map((item: any) => {
        return {
          year: item?.Year,
          content: item?.Content,
        };
      })
    );
  };
  function renderRatings() {
    return riskRatings.map((rating) => {
      return (
        <>
          <hr className="hidden md:block" />
          <div className="mt-5 md:flex md:justify-between ">
            <div className="image-container justify-center ">
              <img src={rating.image} alt={rating.title} />
            </div>
            <div
              className="text-content mt-5 md:mt-0"
              dangerouslySetInnerHTML={{ __html: rating.text }}
            ></div>
          </div>
        </>
      );
    });
  }
  useEffect(() => {
    getSectionContentData();
    getRiskRatings();
    getTimeline();
  }, []);
  return (
    <>
      <HeroMenuComponent
        bannerImage={heroSideData.bannerImage}
        title={heroSideData.titleRich}
      />
      <SecondaryNavBar
        title={heroSideData.secondaryTitle || heroSideData.titleRich}
        menuList={menuListData}
        subMenuList={[]}
      />
      <div className="container mx-auto lg:flex lg:justify-between">
        <div className="lg:w-3/5">
          <InformationComponent content={heroSideData.content} />
        </div>
      </div>
      <div className="container mx-auto mb-20 our-history">
        <div className="flex justify-between mb-2 section-head">
          <h5>{heroSideData.sideTitle1}</h5>
        </div>

        {renderRatings()}

        <div className="flex flex-wrap md:flex-nowrap timeline-container mt-14 xl:mt-24 xl:mb-32">
          {timeline?.map((time, i) => (
            <div
              className={`timeline-box md:flex-col ${
                i % 2 !== 0 ? "md:flex-col-reverse box-text-top" : ""
              }`}
              key={time.year}
            >
              <div
                className={`bubble-box md:flex ${
                  i % 2 !== 0 ? "items-start" : "items-end"
                }`}
              >
                <div
                  className={`year-bubble ${
                    i % 2 !== 0 && "md:flex-col-reverse year-bubble-down"
                  }`}
                >
                  <p>{time.year}</p>
                </div>
              </div>
              <div
                className={`timeline-bar flex items-center ${
                  (i === 0 && "rounded-t md: rounded-l  md:rounded-r-none") ||
                  (i === timeline.length - 1 && "rounded-b md: rounded-r ")
                }`}
              >
                <div className="circle">
                  <div className="inner-circle"></div>
                </div>
              </div>
              <div
                className="text-year "
                dangerouslySetInnerHTML={{
                  __html: time.content,
                }}
              ></div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
export default OurHistory;
