import { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL, GET_HEADERS } from "../Helper/requestData";
import "./appDownloadComponent.css";

const AppDownloadComponent = ({ category }: any) => {
  const [data, setData] = useState({
    text: "",
    googlePlayImg: "",
    googlePlayLink: "",
    iPhoneImg: "",
    iPhoneLink: "",
  });

  async function getData() {
    const resultsArr = await axios
      .get(
        BASE_URL +
          `_api/web/lists/getbytitle('App-Download-Component')/items?$filter=Categoria eq '${category}'`,
        GET_HEADERS
      )
      .then((response) => response.data.value[0])
      .catch((e) => console.error(e));

    setData({
      text: resultsArr?.Text,
      googlePlayImg: resultsArr?.GooglePlayImg?.Url,
      googlePlayLink: resultsArr?.GooglePlayLink?.Url,
      iPhoneImg: resultsArr?.IPhoneImg?.Url,
      iPhoneLink: resultsArr?.IPhoneLink?.Url,
    });
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="app-download">
      <p>{data.text}</p>
      <div className="app-download-links-container">
        <a href={data.googlePlayLink}>
          <img src={data.googlePlayImg} alt="google" className="mr-3" />
        </a>
        <a href={data.iPhoneLink}>
          <img src={data.iPhoneImg} alt="iPhone" />
        </a>
      </div>
    </div>
  );
};

export default AppDownloadComponent;
