import { useContext, useEffect, useState } from "react";
import InformationComponent from "../../components/InformationComponent/InformationComponent";
import HeroMenuComponent from "../../components/MenuLandingsLayout/HeroMenuComponent";
import SecondaryNavBar from "../../components/secondaryNavBar/SecondaryNavBar";
import SideBar from "../../components/sideBar/SideBar";
import SideBarList from "../../components/sideBar/SideBarList";
import MoreInformationCard from "../../components/VariousCardsComponents/moreInfoCard/MoreInformationCard";
import axios from "axios";
import { BASE_URL, GET_HEADERS } from "../../components/Helper/requestData";
import AccidentesPersonalesTarjetasPlan from "./AccidentesPersonalesTarjetasPlan";
import BlueArrowList from "../../components/VariousCardsComponents/blueArrowList/BlueArrowList";
import { PeopleMenuContext } from "../../context/People/PeopleMenuContext";
import { SideListModel } from "../../context/models/SideList.model";

const PersonalAccidentCard = () => {
  const [heroSideData, setHeroSideData] = useState({
    title: "",
    titleRich: "",
    bannerImage: "",
    secondaryTitle: "",
    sideTitle1: "",
    sideContent1: "",
    sideTitle2: "",
    sideContent2: "",
    content: "",
  });

  const [characteristicsData, setCharacteristicsData] = useState([
    {
      title: "",
      id: 0,
      content: "",
    },
  ]);

  const [sideList, setSideList] = useState<SideListModel[]>([]);

  const [finalPlans, setFinalPlans] = useState([
    {
      id: 0,
      title: "",
      content: "",
      planServiceOne: null,
    },
  ]);

  const { menuListData, subMenuListData } = useContext(PeopleMenuContext);

  const getSectionContentData = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Contenido-de-Secciones')/items?$filter=Title eq 'Accidentes Personales Tarjetas Banreservas'",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    setHeroSideData({
      titleRich: resultsArr[0]?.TitleRich,
      title: resultsArr[0]?.Title,
      bannerImage: resultsArr[0]?.BannerImage.Url,
      secondaryTitle: resultsArr[0]?.SecondaryTitle,
      sideTitle1: resultsArr[0]?.SideTitle1,
      sideContent1: resultsArr[0]?.SideContent1,
      sideTitle2: resultsArr[0]?.SideTitle2,
      sideContent2: resultsArr[0]?.SideContent2,
      content: resultsArr[0]?.Content,
    });

    setCharacteristicsData(
      resultsArr.map((item: any) => {
        return {
          title: item?.MiddleTitle,
          id: item?.Id,
          content: item?.MiddleContent,
        };
      })
    );
  };

  function groupBy(objectArray: any[], property: string | number) {
    return objectArray.reduce(function (acc, obj) {
      const key = obj[property];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  }

  const getSideList = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Seguros-Servicios-SideLists')/items?$filter=Categoria eq 'Accidentes Personales Tarjetas Banreservas'",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    resultsArr.sort(
      (a: { Order0: number }, b: { Order0: number }) => a.Order0 - b.Order0
    );

    setSideList(
      resultsArr.map((item: any) => {
        return {
          title: item?.Text,
          id: item?.Order0,
        };
      })
    );
  };

  const getPlanes = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Planes-de-Accidentes-Personales-Tarjetas')/items",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    return resultsArr.map((item: any) => {
      return {
        plan: item?.Title,
        content: item?.Content,
        order: item?.Order0,
      };
    });
  };

  const getPlanList = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Detalles-Planes-de-Accidentes-Personales-Tarjetas')/items?$select=*,Categoria/Title&$expand=Categoria",
        GET_HEADERS
      )
      .then((response) => response.data.value.flat())
      .catch((e) => console.error(e));

    return resultsArr.map((item: any) => {
      return {
        id: item?.Order0,
        title: item?.Title,
        serviceName: item?.Title,
        serviceParent: item?.Categoria?.Title,
        serviceValue: item?.Limit,
        tableGroup: item?.TableGroup,
        serviceType: item?.TableType,
        show: item?.Show,
      };
    });
  };

  const getFinalPlans = async () => {
    const planes: any = [];
    const cardPlans: any = await getPlanes();
    const detailsCardPlans: any = await getPlanList();

    cardPlans.forEach((p: any) => {
      planes.push({
        id: p?.order,
        title: p?.plan,
        content: p?.content,
        planServiceOne:
          groupBy(
            groupBy(detailsCardPlans, "serviceParent")[p?.plan],
            "tableGroup"
          ).Coberturas?.sort((a: any, b: any) => a.id - b.id) || null,
      });
    });
    setFinalPlans(planes);
  };

  useEffect(() => {
    getSectionContentData();
    getSideList();
    getFinalPlans();
  }, []);

  return (
    <>
      <HeroMenuComponent
        bannerImage={heroSideData.bannerImage}
        title={heroSideData.titleRich}
      />
      <SecondaryNavBar
        title={heroSideData.secondaryTitle || heroSideData.titleRich}
        menuList={menuListData}
        subMenuList={subMenuListData}
      />

      <div className="container mx-auto lg:flex lg:justify-between md:mb-16 ">
        <div className="lg:w-3/5">
          <InformationComponent content={heroSideData.content} />
          <div className="mt-10 lg:mt-10 lg:w-3/4">
            <div>
              <BlueArrowList contentList={characteristicsData} />
            </div>
          </div>
        </div>
        <div className="mt-10 lg:mt-14 lg:w-1/3">
          <SideBar>
            {heroSideData.sideTitle1 && (
              <SideBarList
                sideTitle={heroSideData.sideTitle1}
                sideBarList={sideList}
              />
            )}
          </SideBar>
        </div>
      </div>
      <AccidentesPersonalesTarjetasPlan
        planList={finalPlans}
        mainTitle={heroSideData.sideTitle2}
      />
      <div className="container mx-auto my-10 lg:hidden">
        <MoreInformationCard />
      </div>
      <div className="container mx-auto mt-10 lg:mt-10">
        <div className="hidden my-10 lg:flex space-x-7">
          <MoreInformationCard />
        </div>
      </div>
    </>
  );
};

export default PersonalAccidentCard;
