import { useState } from "react";

import blueArrow from "../../assets/img/icons/arrow-dark-blue.svg";
const FuneralAssistanceTableMobile = ({ title, values }: any) => {
  const [showContent, setShowContent] = useState(false);
  return (
    <>
      <div
        className="immigration-plan-title flex items-center justify-between "
        onClick={() => setShowContent(!showContent)}
      >
        <div>{title}</div>
        <img
          src={blueArrow}
          alt="blue Arrow"
          className={`${showContent ? "blue-arrow-rotate" : "blue-arrow"}`}
        />
      </div>
      {showContent && (
        <table className="immigration-table">
          <tbody>
            <tr className="title-container ">
              <td>Coberturas</td>
              <td></td>
            </tr>
            <tr className="table-data ">
              <td className="" colSpan={2}>
                <div>{values?.serviceOne}</div>
                <div>
                  <strong>{values?.valueType} </strong>
                  {values?.valueOne}
                </div>
              </td>
            </tr>
            <tr className="table-data ">
              <td className="" colSpan={2}>
                <div>{values?.serviceTwo}</div>
                <div>
                  <strong>{values?.valueType} </strong>
                  {values?.valueTwo}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </>
  );
};

export default FuneralAssistanceTableMobile;
