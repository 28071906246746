import { BASE_URL, GET_HEADERS } from "../Helper/requestData";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { useEffect, useState } from "react";

import axios from "axios";
import whitearrow from "../../assets/img/icons/chevron-right-white.svg";

const HeroComponent = () => {
  const [slideData, setSlideData] = useState([
    {
      title: "",
      titleStrong: "",
      text: "",
      image: "",
      enlace: "",
      enlaceText: "",
    },
  ]);

  const getData = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL + "_api/web/lists/getbytitle('Home-Hero-Slider')/items",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    setSlideData(
      resultsArr.map((item: any) => {
        return {
          title: item.Title,
          titleStrong: item.OData__x0076_xg4,
          text: item.jgqy,
          image: item.Image.Url,
          enlace: item.Enlace.Url,
          enlaceText: item.bwhm,
        };
      })
    );
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="hero-section">
      {slideData && (
        <Splide
          options={{
            easing: "ease",
          }}
          renderControls={() => (
            <div className="splide__arrows">
              <div className="container relative mx-auto xl:absolute container--fix">
                <div className="splide__arrow splide__arrow--next">
                  <img src={whitearrow} alt="arrow right" />
                </div>
                <div className="splide__arrow splide__arrow--prev">
                  <img
                    src={whitearrow}
                    alt="arrow left"
                    className="left-arrow xl:ml-16"
                  />
                </div>
              </div>
            </div>
          )}
        >
          {slideData.map((slide, i) => (
            <SplideSlide key={i}>
              <>
                <img
                  src={slide.image}
                  alt="hero image"
                  className="hero-section__img"
                />
                <div className="container mx-auto">
                  <div className="flex">
                    <div className="lg:w-2/3 xl:w-1/2 blue-bg">
                      <div className="hero-section__title">
                        {slide.title} <strong>{slide.titleStrong}</strong>
                      </div>
                      <p className="hero-section__text">{slide.text}</p>

                      {slide.enlace && (
                        <a className="hero-section__link" href={slide.enlace}>
                          {slide.enlaceText}
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </>
            </SplideSlide>
          ))}
        </Splide>
      )}
    </div>
  );
};

export default HeroComponent;
