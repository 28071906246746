import { useEffect, useState } from "react";
import "./css/optionsComponent.css";
import orangeArrow from "../../assets/img/icons/chevron-right-orange.svg";
import { SubsidiariesInfoType } from "../../pages/AboutUs/mock/subsidiaries.mock";
import SubsidiariesComponent from "./SubsidiariesComponent";

export type OptionsComponentPropSubsidiaries = {
  id: number;
  title: string;
  content?: string;
  blueBoxImage?: string;
  blueBoxText?: string;
  knowMoreLink?: string;
  knowMoreText?: string;
};

export type OptionsComponentPropsSubsidiaries = {
  contentList: OptionsComponentPropSubsidiaries[];
  subsidiaries: SubsidiariesInfoType[];
};

const OptionsComponentSubsudiaries = ({
  contentList,
  subsidiaries,
}: OptionsComponentPropsSubsidiaries) => {
  const [Active, setActive] = useState<number>();
  useEffect(() => {
    setActive(1);
  }, []);
  const handleSelected = (id: number) => {
    setActive(id);
  };

  return (
    <div>
      <div className="tabs-controls-container">
        {contentList.map((list: OptionsComponentPropSubsidiaries) => (
          <div
            key={list.id}
            className={`tab-control ${
              Active === list.id && "tab-control-active"
            }`}
            onClick={() => handleSelected(list.id)}
          >
            {list.title}
          </div>
        ))}
      </div>
      <div className="content-container">
        {contentList.map(
          (list: OptionsComponentPropSubsidiaries) =>
            list.id === Active && (
              <>
                <div
                  key={list.id}
                  dangerouslySetInnerHTML={{ __html: list.content }}
                ></div>
                <div className="p-6 my-12 lg:p-9 lg:flex blue-box lg:items-center">
                  <img className="logo" src={list.blueBoxImage} alt="logo" />
                  <div>
                    <p>{list.blueBoxText}</p>
                    <a
                      className="flex items-center"
                      target="_blank"
                      rel="noreferrer"
                      href={list.knowMoreLink}
                    >
                      {list.knowMoreText}{" "}
                      <img src={orangeArrow} alt="Orange Arrow" />
                    </a>
                  </div>
                </div>
              </>
            )
        )}
      </div>
      <div className="mb-10 lg:grid lg:grid-cols-2 lg:gap-12">
        {subsidiaries
          .filter((x) => x.parent === contentList[Active - 1]?.title)
          .sort((a, b) => a.id - b.id)
          .map((item, i) => (
            <SubsidiariesComponent
              key={i}
              info={item.info}
              link={item.link}
              image={item.image}
              linkText={item.linkText}
            />
          ))}
      </div>
    </div>
  );
};

export default OptionsComponentSubsudiaries;
