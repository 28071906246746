import React from "react";
import "./css/sideBar.css";

type SideBarProps = {
  children: React.ReactNode;
};

const SideBar = ({ children }: SideBarProps) => {
  return <div className="side-bar-container">{children}</div>;
};

export default SideBar;
