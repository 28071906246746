import { useState, useEffect } from "react";
import axios from "axios";
import "./moreInformation.css";
import phone from "../../../assets/img/icons/dark-blue-icon-phone.svg";
import mail from "../../../assets/img/icons/dark-blue-icon-mail.svg";
import whatsapp from "../../../assets/img/icons/dark-blue-icon-whatsapp.svg";
import { BASE_URL, GET_HEADERS } from "../../Helper/requestData";

const MoreInformationCard = () => {
  const [cardData, setCardData] = useState({
    title: "",
    phoneNumber: "",
    email: "",
    whatsapp: "",
  });

  const getCardData = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL + "_api/web/lists/getbytitle('Mas-Informacion-Card')/items",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    setCardData({
      title: resultsArr[0]?.Title,
      phoneNumber: resultsArr[0]?.PhoneNumber,
      email: resultsArr[0]?.Email,
      whatsapp: resultsArr[0]?.WhatsApp,
    });
  };

  useEffect(() => {
    getCardData();
  }, []);

  return (
    <div className="more-info">
      <div className="more-info-title"> {cardData.title}</div>

      <a href={`tel:${cardData.whatsapp}`}>
        <img src={whatsapp} alt="whatsapp icon" />
        <span>{cardData.whatsapp}</span>
      </a>
      <a href={`tel:${cardData.phoneNumber}`}>
        <img src={phone} alt="phone" />
        <span>{cardData.phoneNumber}</span>
      </a>
      <a href={`mailto:${cardData.email}`}>
        <img src={mail} alt="email" />
        <span>{cardData.email}</span>
      </a>
    </div>
  );
};

export default MoreInformationCard;
