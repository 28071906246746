import "./css/btnCards.css";

type GeneralConditionProps = {
  generalConditionLink: string;
  generalConditionTitle?: string;
  generalConditionIcon?: string;
  generalConditionActive?: boolean;
};

const GeneralConditions = ({
  generalConditionLink = "#",
  generalConditionTitle = "Condiciones Generales",
  generalConditionIcon,
  generalConditionActive = true,
}: GeneralConditionProps) => {
  return (
    <a
      className={`blue-btn btn-card mb-2 md:mb-4 ${
        generalConditionActive ? "flex" : "hidden"
      }`}
      href={generalConditionLink}
    >
      <img src={generalConditionIcon} alt="file icon" className="icon-img" />
      <span className="btn-title">{generalConditionTitle}</span>
    </a>
  );
};

export default GeneralConditions;
