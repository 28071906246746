const FuneralAssistanceTableDesktop = ({ list }: any) => {
  return (
    <>
      <table className="immigration-table">
        <tbody>
          <tr className="title-container ">
            <td>{list[0]?.mainTitle}</td>
            <td>{list[0]?.labelOne}</td>
            <td>{list[0]?.labelTwo}</td>
            <td>{list[0]?.labelThree}</td>
          </tr>
          {list.map((e: any) =>
            e?.mainTitle ? null : (
              <tr className="table-data " key={e?.id}>
                <td>{e?.title}</td>
                <td className="">{e?.valueOne}</td>
                <td>{e?.valueTwo}</td>
                <td>{e?.valueThree}</td>
              </tr>
            )
          )}
        </tbody>
      </table>
    </>
  );
};

export default FuneralAssistanceTableDesktop;
