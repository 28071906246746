import sentImg from "../../assets/img/contactanos-sent-msg.svg";

const SentComponent = ({ onClick }: any) => {
  return (
    <img
      className="contact-form__sent"
      src={sentImg}
      alt="sent message"
      onClick={onClick}
    ></img>
  );
};

export default SentComponent;
