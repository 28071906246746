import { useEffect, useState } from "react";
import menuX from "../../../assets/img/icons/side-menu-x.svg";
import "./css/comparatorComponentV2.css";
import plus from "../../../assets/img/icons/white-plus-icon.svg";
import ListBoxComponentComparatorV2 from "./ListBoxComponentComparatorV2";
import ComparatorDesktopServiceTable from "../../PeopleComponents/comparatorComponentInternationalHealth/ComparatorDesktopServiceTable";

export default function ComparatorDesktopComponentV2({
  showFullScreenContent,
  setShowFullScreenContent,
  planList,
  title,
}: any) {
  const [planListState, setPlanListState] = useState<any[]>();
  const [planCShow, setPlanCShow] = useState<boolean>(false);
  const [selectedPlanA, setSelectedPlanA] = useState<any>();
  const [selectedPlanB, setSelectedPlanB] = useState<any>();
  const [selectedPlanC, setSelectedPlanC] = useState<any>();
  const [tableServiceOne, setTableServiceOne] = useState<any>();
  const [tableServiceTwo, setTableServiceTwo] = useState<any>();
  const [tableAdditionalService, setTableAdditionalService] = useState<any>();
  useEffect(() => {
    const list = [...planList];
    const newList = list.map((p: any) => {
      return {
        ...p,
        title: "Plan " + p.title,
      };
    });
    setPlanListState(newList);
  }, [planList]);

  useEffect(() => {
    if (planListState !== undefined && planListState !== null) {
      setSelectedPlanA(planListState[0]);
      setSelectedPlanB(planListState[1]);
      setSelectedPlanC(planListState[2]);
    }
  }, [planListState, planCShow]);

  useEffect(() => {
    const serviceOne =
      selectedPlanA &&
      selectedPlanA?.planServiceOne &&
      selectedPlanA?.planServiceOne.map((plan: any, i: number) => {
        return {
          serviceName: plan?.serviceName,
          serviceType: plan?.serviceType,
          serviceValueA: selectedPlanA?.planServiceOne[i]?.serviceValue,
          serviceValueA2: selectedPlanA?.planServiceOne[i]?.serviceValue2,
          serviceValueB:
            selectedPlanB?.planServiceOne &&
            selectedPlanB?.planServiceOne[i]?.serviceValue,
          serviceValueB2:
            selectedPlanB?.planServiceOne &&
            selectedPlanB?.planServiceOne[i]?.serviceValue2,
          serviceValueC:
            selectedPlanC?.planServiceOne &&
            selectedPlanC?.planServiceOne[i]?.serviceValue,
          serviceValueC2:
            selectedPlanC?.planServiceOne &&
            selectedPlanC?.planServiceOne[i]?.serviceValue2,
        };
      });
    setTableServiceOne(serviceOne);

    const serviceTwo =
      selectedPlanA &&
      selectedPlanA?.planServiceTwo &&
      selectedPlanA?.planServiceTwo.map((plan: any, i: number) => {
        return {
          serviceName: plan?.serviceName,
          serviceType: plan?.serviceType,
          serviceValueA: selectedPlanA?.planServiceTwo[i]?.serviceValue,
          serviceValueB:
            selectedPlanB?.planServiceTwo &&
            selectedPlanB?.planServiceTwo[i]?.serviceValue,
          serviceValueC:
            selectedPlanC?.planServiceTwo &&
            selectedPlanC?.planServiceTwo[i]?.serviceValue,
        };
      });

    setTableServiceTwo(serviceTwo);
    const additionalService =
      selectedPlanA &&
      selectedPlanA?.planAdditionalService &&
      selectedPlanA?.planAdditionalService.map((plan: any, i: number) => {
        return {
          serviceName: plan?.serviceName,
          serviceType: plan?.serviceType,
          additionalChargeA: plan?.additionalCharge,
          additionalChargeB:
            selectedPlanB?.planAdditionalService[i]?.additionalCharge,
          additionalChargeC:
            selectedPlanC?.planAdditionalService[i]?.additionalCharge,
          serviceValueA: plan?.serviceType
            ? ""
            : selectedPlanA?.planAdditionalService[i]?.serviceValue,
          serviceValueB: plan?.serviceType
            ? ""
            : selectedPlanB?.planAdditionalService[i]?.serviceValue,
          serviceValueC: plan?.serviceType
            ? ""
            : selectedPlanC?.planAdditionalService[i]?.serviceValue,
        };
      });

    setTableAdditionalService(additionalService);
  }, [selectedPlanA, selectedPlanB, selectedPlanC, planCShow]);

  const handleClick = () => {
    setShowFullScreenContent(!showFullScreenContent);
    setPlanCShow(false);
  };

  return (
    <div
      className={`transition ease-out transform ${
        showFullScreenContent ? "" : "-translate-x-full "
      }  duration-400 comparator-container `}
    >
      <div className="comparator-desktop-header">
        <div className="comparator-desktop-title">
          <h1 className="push-right">Comparador de planes</h1>

          <div
            dangerouslySetInnerHTML={{ __html: title }}
            className="insurance-titles"
          ></div>
        </div>
        <div onClick={handleClick} className="close-icon">
          <img
            src={menuX}
            alt="close"
            className="close-icon-image cursor-pointer"
          />
        </div>
      </div>
      <div className="container mx-auto">
        <div className="selector-plans-container w-full justify-center">
          <div className={`flex: 0 0 ${planCShow ? "w-1/4" : "w-1/3"}`}>
            <h6 className="mt-2">Seleccionar Plan:</h6>
          </div>

          <div className="selector-btn-container flex-1">
            <div className="selector-btn-box">
              <ListBoxComponentComparatorV2
                planList={planListState}
                selectedPlan={selectedPlanA}
                setSelectedPlan={setSelectedPlanA}
              />
            </div>

            <div className="selector-btn-box">
              <ListBoxComponentComparatorV2
                planList={planListState}
                selectedPlan={selectedPlanB}
                setSelectedPlan={setSelectedPlanB}
              />
            </div>
            {planCShow ? (
              <div className="selector-btn-box">
                <ListBoxComponentComparatorV2
                  planList={planListState}
                  selectedPlan={selectedPlanC}
                  setSelectedPlan={setSelectedPlanC}
                />
              </div>
            ) : (
              <img
                src={plus}
                alt="plus-icon"
                className="plus-icon self-start mt-2"
                onClick={() => setPlanCShow(true)}
              />
            )}
          </div>
        </div>

        <div className="comparator-list-table flex">
          <ComparatorDesktopServiceTable
            tableService={tableServiceOne}
            planCShow={planCShow}
          />
        </div>
        <div className="comparator-list-table flex mt-3">
          <ComparatorDesktopServiceTable
            tableService={tableServiceTwo}
            planCShow={planCShow}
          />
        </div>
        <div className="comparator-list-table flex mt-3">
          <ComparatorDesktopServiceTable
            tableService={tableAdditionalService}
            planCShow={planCShow}
          />
        </div>
        <h4 className="mt-8 blue-asterisk font-bold">
          * Disponibles sujeto al pago de la prima adicional correspondiente.
        </h4>
      </div>
    </div>
  );
}
