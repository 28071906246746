import HeroMenuComponent from "../../components/MenuLandingsLayout/HeroMenuComponent";
import SecondaryNavBar from "../../components/secondaryNavBar/SecondaryNavBar";
import ChannelsServices from "../../components/channelsServices/channelsServices";
import PaymentFacilities from "../../components/PaymentMethods/PaymentFacilities";
import OptionsComponent from "../../components/OptionsComponent/OptionsComponent";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL, GET_HEADERS } from "../../components/Helper/requestData";
import { ServicesChannelsMenuContext } from "../../context/ServicesChannels/ServicesChannelsMenuContext";

const ServicesLanding = () => {
  const [heroSideData, setHeroSideData] = useState({
    title: "",
    titleRich: "",
    bannerImage: "",
    secondaryTitle: "",
    sideTitle1: "",
    sideContent1: "",
    sideTitle2: "",
    sideContent2: "",
    content: "",
    middleTitle: "",
    contentImage: "",
  });

  const [options, setOptions] = useState([
    {
      title: "",
      id: 0,
      content: "",
    },
  ]);

  const { menuListData } = useContext(ServicesChannelsMenuContext);

  const getSectionContentData = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Contenido-de-Secciones')/items?$filter=Title eq 'Canales de Servicios y Pagos'",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    setHeroSideData({
      titleRich: resultsArr[0]?.TitleRich,
      title: resultsArr[0]?.Title,
      bannerImage: resultsArr[0]?.BannerImage.Url,
      secondaryTitle: resultsArr[0]?.SecondaryTitle,
      sideTitle1: resultsArr[0]?.SideTitle1,
      sideContent1: resultsArr[0]?.SideContent1,
      sideTitle2: resultsArr[0]?.SideTitle2,
      sideContent2: resultsArr[0]?.SideContent2,
      content: resultsArr[0]?.Content,
      middleTitle: resultsArr[0]?.MiddleTitle,
      contentImage: resultsArr[0]?.ContentImage.Url,
    });
  };

  const getOptionsData = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL + "_api/web/lists/getbytitle('Vias-de-Pago-Opciones')/items",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    setOptions(
      resultsArr.map((item: any) => {
        return {
          title: item?.Title,
          id: item?.ItemId,
          content: item?.Content,
        };
      })
    );
  };

  useEffect(() => {
    getSectionContentData();
    getOptionsData();
  }, []);

  return (
    <>
      <HeroMenuComponent
        bannerImage={heroSideData.bannerImage}
        title={heroSideData.titleRich}
      />
      <SecondaryNavBar
        title={heroSideData.secondaryTitle || heroSideData.titleRich}
        menuList={menuListData}
        subMenuList={[]}
      />
      <div className="container mx-auto md:mb-16">
        <ChannelsServices
          titleLarge={heroSideData.sideContent2}
          titleSmall={heroSideData.sideContent1}
        />
        <PaymentFacilities title={heroSideData.sideTitle2} />
        <div className="section services-channels" id="Facilidades-Pagos">
          <h1 className="services-channels__title">
            <b>{heroSideData.middleTitle}</b>
          </h1>
          <div className="lg:flex lg:space-x-4 lg:mt-10 lg:px-16">
            <div className="w-full lg:w-2/5">
              <img
                className="mx-auto my-12 lg:mt-0 tpago-logo"
                src={heroSideData.contentImage}
                alt="t-pago-logo"
              />
            </div>
            <div className="w-full lg:w-3/5">
              <h2 className="hidden mb-10 lg:block tpago-text">
                {heroSideData.sideTitle1}
              </h2>
              <OptionsComponent contentList={options} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicesLanding;
