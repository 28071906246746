import arrow from "../../assets/img/icons/chevron-right.svg";
import menuIcon from "../../assets/img/icons/menu-white.svg";
import { useState } from "react";
import "./css/secondaryMenu.css";
import SecondaryMenuModal from "./SecondaryMenuModal";
import { menuListType, subMenuListType } from "./types";

type secondaryMenuProp = {
  title: string;
  btnLinkCotizar?: string;
  btnImgCotizar?: string;
  btnTitleCotizar?: string;
  btnLinkBrochure?: string;
  btnImgBrochure?: string;
  btnTitleBrochure?: string;
  menuList: menuListType[];
  subMenuList: subMenuListType[];
  btnBlockActive?: boolean;
};

const SecondaryNavBar = ({
  title,
  btnLinkCotizar,
  btnImgCotizar,
  btnTitleCotizar,
  btnLinkBrochure,
  btnImgBrochure,
  btnTitleBrochure,
  btnBlockActive,
  menuList,
  subMenuList,
}: secondaryMenuProp) => {
  const [menu, setMenu] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState(false);
  const handleClick = () => {
    setMenu(!menu);
    setShowSubMenu(false);
  };

  return (
    <div className="secondary-menu">
      <div className="container md:mx-auto md:flex md:items-center md:justify-between">
        <div className="menu-bar " onClick={handleClick}>
          <div className="inner-menu-bar ">
            <div className="flex items-center">
              <img src={menuIcon} alt="menu icon" className="mr-3 menu-icon" />

              <div
                className="flex title-menu"
                dangerouslySetInnerHTML={{ __html: title }}
              ></div>
            </div>
            <img
              src={arrow}
              alt="arrow icon"
              className={` ${menu ? "arrow-icon-rotate" : "arrow-icon"}`}
            />
          </div>
        </div>
        <SecondaryMenuModal
          menuList={menuList}
          subMenuList={subMenuList}
          show={menu}
          showSubMenu={showSubMenu}
          setShowSubMenu={setShowSubMenu}
        />
        {btnLinkCotizar || btnLinkBrochure ? (
          <div
            className={`btn-containers ${btnBlockActive ? "hidden" : "flex"}`}
          >
            {btnLinkCotizar && (
              <a className="flex items-center menu-btn" href={btnLinkCotizar}>
                <img src={btnImgCotizar} className="icon-img" />
                <span>{btnTitleCotizar}</span>
              </a>
            )}

            {btnLinkBrochure && (
              <a
                className="flex items-center menu-btn md:ml-2 lg:ml-16 xl:ml-24"
                href={btnLinkBrochure}
              >
                <img src={btnImgBrochure} className="icon-img" />
                <span>{btnTitleBrochure}</span>
              </a>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default SecondaryNavBar;
