import { Link, useNavigate } from "react-router-dom";
import { Splide, SplideSlide } from "@splidejs/react-splide";

import { NavigationContext } from "../../routes/menulinks";
import { NewsContext } from "../../context/News/NewsContext";
import arrow from "../../assets/img/icons/chevron-right-gray.svg";
import arrowOrange from "../../assets/img/icons/chevron-right-orange-thin.svg";
import { useContext } from "react";

const SectionComponentFour = () => {
  const navigate = useNavigate();
  const { newsList } = useContext(NewsContext);
  const { menuLinks } = useContext(NavigationContext);
  const renderCards = () => {
    return newsList.map((news, i) => {
      return (
        <div className="noticias__block" key={i}>
          <div className="noticias__image-container">
            <img
              className="noticias__image sm:w-full"
              src={news.img}
              alt="noticias image"
            />
          </div>
          <div className="noticias__bottom">
            <div className="top-section">
              <span className="noticias__date">
                {new Date(news?.date).toLocaleDateString("es-ES", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })}
              </span>
              <div className="noticias__title">{news.title}</div>
            </div>
            <div className="bottom-section">
              <p className="noticias__text">{news.text}</p>
              <div
                onClick={() => {
                  navigate({
                    pathname: `${menuLinks.newsDetails}`,
                    search: `?ID=${news.id}`,
                  });
                }}
                className="noticias__link cursor-pointer"
              >
                {news.linkText}
                <img src={arrowOrange} alt="arrow icon" />
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <div className="section noticias">
      <div className="container mx-auto">
        <div className="title">Noticias y Novedades</div>
        <Splide
          className="splide"
          options={{
            perPage: 3,
            breakpoints: {
              1024: {
                perPage: 2,
              },
              768: {
                perPage: 1,
              },
            },
          }}
          renderControls={() => (
            <div className="splide__arrows">
              <div className="container relative mx-auto xl:absolute container--fix">
                <div className="splide__arrow splide__arrow--next ">
                  <img src={arrow} alt="arrow icon" className="right-arrow" />
                </div>
                <div className="splide__arrow splide__arrow--prev ">
                  <img src={arrow} alt="arrow icon" className="left-arrow" />
                </div>
              </div>
            </div>
          )}
        >
          {(() =>
            newsList &&
            renderCards().map((s, i) => (
              <SplideSlide key={i}>{s}</SplideSlide>
            )))()}
        </Splide>

        <div className="noticias__box">
          <Link to={menuLinks.news} className="noticias__button">
            Ver Noticias
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SectionComponentFour;
