const message = "Caracteres inválidos";
export const validations = {
  soloLetras: {
    value: /^[\wñáéíóúÑÁÉÍÓÚ]+$/i,
    message,
  },
  letrasYespacios: {
    value: /^[\wñáéíóúÑÁÉÍÓÚ\s]+$/i,
    message,
  },

  emails: {
    value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g,
    message,
  },
  soloNumeros: {
    value: /^[\d]*$/,
    message,
  },
  numerosYespacios: {
    value: /^[\d\s]*$/,
    message,
  },
  letrasNumerosEspaciosEspeciales: {
    value: /^[\d\w#/.&,*+$%!?~`"';><\\[\]{}(_|)-ñáéíóúÑÁÉÍÓÚ\s]+$/i,
    message,
  },
  letrasNumerosEspacios: {
    value: /^[\d\wñáéíóúÑÁÉÍÓÚ\s]+$/i,
    message,
  },
  letrasYnumeros: {
    value: /^[\d\wñáéíóúÑÁÉÍÓÚ]+$/i,
    message,
  },
};
