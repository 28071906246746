import { useContext, useEffect, useState } from "react";
import InformationComponent from "../../components/InformationComponent/InformationComponent";
import HeroMenuComponent from "../../components/MenuLandingsLayout/HeroMenuComponent";
import SecondaryNavBar from "../../components/secondaryNavBar/SecondaryNavBar";
import SideBar from "../../components/sideBar/SideBar";
import SideBarList from "../../components/sideBar/SideBarList";
import ProductsContainer from "../../components/MenuLandingsLayout/ProductsContainer";
import CardsContainerImageOnly from "../../components/MenuLandingsLayout/CardsContainerImageOnly";
import InternationalHealthJustPlans from "../../components/PeopleComponents/InternationalHealthJustPlans";
import axios from "axios";
import { BASE_URL, GET_HEADERS } from "../../components/Helper/requestData";
import { PeopleMenuContext } from "../../context/People/PeopleMenuContext";
import { SideListModel } from "../../context/models/SideList.model";

const InternationalHealthJustV2 = () => {
  const [showCards, setShowCards] = useState(false);
  const [heroSideData, setHeroSideData] = useState({
    title: "",
    titleRich: "",
    bannerImage: "",
    secondaryTitle: "",
    sideTitle1: "",
    sideContent1: "",
    sideTitle2: "",
    sideContent2: "",
    content: "",
    middleTitle: "",
  });

  const [secondaryNavBarButtons, setSecondaryNavBarButtons] = useState({
    btnLinkBrochure: "",
    btnImgBrochure: "",
    btnTitleBrochure: "",
    btnBlockActive: true,
  });

  const [sideList, setSideList] = useState<SideListModel[]>([]);

  const [finalPlans, setFinalPlans] = useState([
    {
      id: 0,
      title: "",
      content: "",
      planServiceOne: null,
      planServiceTwo: null,
      planServiceThree: null,
      planServiceFour: null,
      planServiceFive: null,
      planServiceSix: null,
      planServiceSeven: null,
    },
  ]);

  const [plansButtons, setPlansButtons] = useState({
    generalConditionLink: "",
    generalConditionTitle: "",
    generalConditionIcon: "",
    generalConditionActive: true,
    faqLink: "",
    faqTitle: "",
    faqIcon: "",
    faqActive: true,
  });

  const [cardsData, setCardsData] = useState([
    {
      cardId: 0,
      cardImg: "",
    },
  ]);

  const { menuListData, subMenuListData } = useContext(PeopleMenuContext);

  const getSectionContentData = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Contenido-de-Secciones')/items?$filter=Title eq 'Salud Internacional Just'",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    setHeroSideData({
      titleRich: resultsArr[0]?.TitleRich,
      title: resultsArr[0]?.Title,
      bannerImage: resultsArr[0]?.BannerImage.Url,
      secondaryTitle: resultsArr[0]?.SecondaryTitle,
      sideTitle1: resultsArr[0]?.SideTitle1,
      sideContent1: resultsArr[0]?.SideContent1,
      sideTitle2: resultsArr[0]?.SideTitle2,
      sideContent2: resultsArr[0]?.SideContent2,
      content: resultsArr[0]?.Content,
      middleTitle: resultsArr[0]?.MiddleTitle,
    });
  };

  function groupBy(objectArray: any[], property: string | number) {
    return objectArray.reduce(function (acc, obj) {
      const key = obj[property];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  }

  const getSecondaryNavBarButtons = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Secondary-NavBar-Buttons')/items",

        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    const result = resultsArr.find(
      (r: any) => r.Title === "Salud Internacional Just"
    );

    setSecondaryNavBarButtons({
      btnLinkBrochure: result?.BtnLinkBrochure?.Description,
      btnImgBrochure: result?.BtnImgBrochure?.Url,
      btnTitleBrochure: result?.BtnTitleBrochure,
      btnBlockActive: result?.Activo,
    });
  };

  const getSideList = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Seguros-Servicios-SideLists')/items?$filter=Categoria eq 'Salud Internacional Just'",

        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    resultsArr.sort(
      (a: { Order0: number }, b: { Order0: number }) => a.Order0 - b.Order0
    );

    setSideList(
      resultsArr.map((item: any) => {
        return {
          title: item?.Text,
          id: item?.Order0,
        };
      })
    );
  };

  const getPlanes = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Planes Salud Internacional Just')/items",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    return resultsArr.map((item: any) => {
      return {
        plan: item?.Title,
        content: item?.Content,
        order: item?.Order0,
      };
    });
  };
  const getPlanList = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Detalles Planes Salud Internacional Just')/items?$select=*,Categoria/Title&$expand=Categoria&$top=10000",

        GET_HEADERS
      )
      .then((response) => response.data.value.flat())
      .catch((e) => console.error(e));

    return resultsArr.map((item: any) => {
      return {
        id: item?.Order0,
        title: item?.Title,
        serviceName: item?.Title,
        serviceParent: item?.Categoria?.Title,
        serviceValue: item?.Limit,
        tableGroup: item?.TableGroup,
        serviceType: item?.TableType,
      };
    });
  };

  const getFinalPlans = async () => {
    const planes: any = [];
    const cardPlans: any = await getPlanes();
    const detailsCardPlans: any = await getPlanList();

    cardPlans.forEach((p: any) => {
      planes.push({
        id: p?.order,
        title: p?.plan,
        content: p?.content,
        planServiceOne:
          groupBy(
            groupBy(detailsCardPlans, "serviceParent")[p?.plan],
            "tableGroup"
          ).Coberturas?.sort((a: any, b: any) => a.id - b.id) || null,
        planServiceTwo:
          groupBy(
            groupBy(detailsCardPlans, "serviceParent")[p?.plan],
            "tableGroup"
          )["Coberturas en R.D."]?.sort((a: any, b: any) => a.id - b.id) ||
          null,
        planServiceThree:
          groupBy(
            groupBy(detailsCardPlans, "serviceParent")[p?.plan],
            "tableGroup"
          )["Coberturas fuera de R.D."]?.sort(
            (a: any, b: any) => a.id - b.id
          ) || null,
        planServiceFour:
          groupBy(
            groupBy(detailsCardPlans, "serviceParent")[p?.plan],
            "tableGroup"
          )["Cuidado de la maternidad"]?.sort(
            (a: any, b: any) => a.id - b.id
          ) || null,
        planServiceFive:
          groupBy(
            groupBy(detailsCardPlans, "serviceParent")[p?.plan],
            "tableGroup"
          )["Ambulancia aérea y terrestre"]?.sort(
            (a: any, b: any) => a.id - b.id
          ) || null,
        planServiceSix:
          groupBy(
            groupBy(detailsCardPlans, "serviceParent")[p?.plan],
            "tableGroup"
          )["Enfermedades graves"]?.sort((a: any, b: any) => a.id - b.id) ||
          null,
        planServiceSeven:
          groupBy(
            groupBy(detailsCardPlans, "serviceParent")[p?.plan],
            "tableGroup"
          )["Beneficios complementarios"]?.sort(
            (a: any, b: any) => a.id - b.id
          ) || null,
      });
    });
    setFinalPlans(planes);
  };

  const getPlansButtons = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL + "_api/web/lists/getbytitle('Bottom-Buttons')/items",

        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    const result = resultsArr.find(
      (r: any) => r.Title === "Salud Internacional Just"
    );

    setPlansButtons({
      generalConditionLink: result?.GeneralConditionLink?.Description,
      generalConditionIcon: result?.GeneralConditionIcon?.Url,
      generalConditionTitle: result?.GeneralConditionTitle,
      generalConditionActive: result?.Activo,
      faqLink: result?.FAQLink?.Description,
      faqIcon: result?.FAQIcon?.Url,
      faqTitle: result?.FAQTitle,
      faqActive: result?.Activo,
    });
  };

  const getCardsData = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Salud-Internacional-Just-Proveedores-y-Prestadores')/items",

        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    setCardsData(
      resultsArr.map((item: any) => {
        return {
          cardId: item?.Order0,
          cardImg: item?.Image.Url,
        };
      })
    );
  };

  useEffect(() => {
    getSectionContentData();
    getSecondaryNavBarButtons();
    getSideList();
    getFinalPlans();
    getPlansButtons();
    getCardsData();
  }, []);
  return (
    <>
      <HeroMenuComponent
        bannerImage={heroSideData.bannerImage}
        title={heroSideData.titleRich}
      />
      <SecondaryNavBar
        title={heroSideData.secondaryTitle || heroSideData.titleRich}
        menuList={menuListData}
        subMenuList={subMenuListData}
        btnLinkBrochure={secondaryNavBarButtons.btnLinkBrochure}
        btnImgBrochure={secondaryNavBarButtons.btnImgBrochure}
        btnTitleBrochure={secondaryNavBarButtons.btnTitleBrochure}
        btnBlockActive={secondaryNavBarButtons.btnBlockActive}
      />

      <div className="container mx-auto lg:flex lg:justify-between md:mb-16 ">
        <div className="lg:w-3/5">
          <InformationComponent content={heroSideData.content} />
        </div>
        <div className="mt-10 lg:mt-14 lg:w-1/3">
          <SideBar>
            {heroSideData.sideTitle1 && (
              <SideBarList
                sideTitle={heroSideData.sideTitle1}
                sideBarList={sideList}
              />
            )}
          </SideBar>
        </div>
      </div>
      <InternationalHealthJustPlans
        planList={finalPlans}
        generalConditionLink={plansButtons.generalConditionLink}
        generalConditionTitle={plansButtons.generalConditionTitle}
        generalConditionIcon={plansButtons.generalConditionIcon}
        faqLink={plansButtons.faqLink}
        faqTitle={plansButtons.faqTitle}
        faqIcon={plansButtons.faqIcon}
        comparatorTitle={heroSideData.titleRich}
        footerNotes={heroSideData.sideContent1}
      />
      <ProductsContainer
        showCards={showCards}
        setShowCards={setShowCards}
        title={heroSideData.sideTitle2}
        disableButton={true}
      >
        <CardsContainerImageOnly showCards={showCards} cards={cardsData} />
      </ProductsContainer>
    </>
  );
};

export default InternationalHealthJustV2;
