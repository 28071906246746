import { Information } from "./type";
import "./css/introTitleComponent.css";
function IntroTitle({ content }: Information) {
  return (
    <div className="intro-section lg:max-w-screen-lg lg:mx-auto">
      <div
        className="intro-section-text"
        dangerouslySetInnerHTML={{ __html: content }}
      ></div>
    </div>
  );
}

export default IntroTitle;
