import HeroMenuComponent from "../../components/MenuLandingsLayout/HeroMenuComponent";
import SecondaryNavBar from "../../components/secondaryNavBar/SecondaryNavBar";
import VehiclesSectionInformation from "../../components/VehiclesComponent/VehiclesSectionInformation";
import "./css/getTabs.css";
import ResponsibilityCards from "../../components/Responsibility/ResponsibilityCards";
import SideBarListBlue from "../../components/sideBar/SideBarListBlue";
import { ContributionSectionContextProvider } from "../../context/Responsibility/ResponsibilitySlide.context";
import ResponsibilitySlide from "../../components/ResposibilitySlide/ResponsibilitySlide";
import { useContext, useEffect, useState } from "react";
import { AboutUsMenuContext } from "../../context/AboutUs/AboutUsMenuContext";
import axios from "axios";
import { BASE_URL, GET_HEADERS } from "../../components/Helper/requestData";
import MoreInformationCard from "../../components/VariousCardsComponents/moreInfoCard/MoreInformationCard";
import { ContributionSection } from "../../context/models/Contribution.model";

const SocialResponsibility = () => {
  const [heroSideData, setHeroSideData] = useState({
    title: "",
    titleRich: "",
    bannerImage: "",
    secondaryTitle: "",
    sideTitle1: "",
    sideContent1: "",
    sideTitle2: "",
    content: "",
    contentImage: "",
    sideContent2: "",
    middleTitle: "",
    middleContent: "",
  });

  const [pilars, setPilars] = useState([
    {
      text: "",
      image: "",
    },
  ]);
  const [cardsData, setCardsData] = useState<ContributionSection[] | undefined>(
    [
      {
        title: "",
        imageSlide: "",
        imageModal: "",
        content: "",
        size: "",
        orden: 0,
      },
    ]
  );

  const { menuListData } = useContext(AboutUsMenuContext);

  const getSectionContentData = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Contenido-de-Secciones')/items?$filter=Title eq 'Responsabilidad Social Corporativa'",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    setHeroSideData({
      titleRich: resultsArr[0]?.TitleRich,
      title: resultsArr[0]?.Title,
      bannerImage: resultsArr[0]?.BannerImage?.Url,
      content: resultsArr[0]?.Content,
      secondaryTitle: resultsArr[0]?.SecondaryTitle,
      sideTitle1: resultsArr[0]?.SideTitle1,
      sideTitle2: resultsArr[0]?.SideTitle2,
      sideContent1: resultsArr[0]?.SideContent1,
      contentImage: resultsArr[0]?.ContentImage?.Url,
      sideContent2: resultsArr[0]?.SideContent2,
      middleTitle: resultsArr[0]?.MiddleTitle,
      middleContent: resultsArr[0]?.MiddleContent,
    });
  };

  const getPilars = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Responsabilidad-Social-Corporativa-Pilares')/items",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    const results = resultsArr.sort((a, b) => a.Order0 - b.Order0);

    setPilars(
      results.map((item: any) => {
        return {
          text: item?.Title,
          image: item?.Image?.Url,
        };
      })
    );
  };

  const getContributionSectionData = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Responsabilidad-Social-Corporativa-Slides')/items",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    const results = resultsArr.sort((a, b) => a.Order0 - b.Order0);

    setCardsData(
      results?.map((item: any) => {
        return {
          orden: item?.Order0,
          title: item?.Title,
          imageSlide: item?.ImageSlide?.Url,
          imageModal: item?.ImageModal?.Url,
          content: item?.Content,
          size: item?.Size,
        };
      })
    );
  };

  useEffect(() => {
    getSectionContentData();
    getPilars();
    getContributionSectionData();
  }, []);

  return (
    <>
      <HeroMenuComponent
        bannerImage={heroSideData.bannerImage}
        title={heroSideData.titleRich}
      />
      <SecondaryNavBar
        title={heroSideData.secondaryTitle || heroSideData.titleRich}
        menuList={menuListData}
        subMenuList={[]}
      />
      <div className="container mx-auto mb-20">
        <div className="lg:w-5/5">
          <VehiclesSectionInformation content={heroSideData.content} />
        </div>
        <div>
          <ResponsibilityCards pilars={pilars} />
        </div>
        <div className="lg:w-5/5">
          <VehiclesSectionInformation content={heroSideData.sideContent1} />
        </div>
        <div>
          <ContributionSectionContextProvider>
            <ResponsibilitySlide
              title={heroSideData.sideTitle1}
              cardsData={cardsData}
            />
          </ContributionSectionContextProvider>
        </div>
        <div className="lg:w-4/5">
          <SideBarListBlue
            sideBarContent={heroSideData.sideContent2}
            sideBarList={heroSideData.middleContent}
          />
        </div>
        <div className="block my-10 lg:mt-16">
          <MoreInformationCard />
        </div>
      </div>
    </>
  );
};

export default SocialResponsibility;
