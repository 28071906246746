import { useContext, useEffect, useState } from "react";
import InformationComponent from "../../components/InformationComponent/InformationComponent";
import HeroMenuComponent from "../../components/MenuLandingsLayout/HeroMenuComponent";
import SecondaryNavBar from "../../components/secondaryNavBar/SecondaryNavBar";
import SideBar from "../../components/sideBar/SideBar";
import SideBarList from "../../components/sideBar/SideBarList";
import NeedHelpCardShort from "../../components/VariousCardsComponents/needHelpCard/NeedHelpCardShort";

import MoreInformationCard from "../../components/VariousCardsComponents/moreInfoCard/MoreInformationCard";
import { BASE_URL, GET_HEADERS } from "../../components/Helper/requestData";
import axios from "axios";
import AssistancePlanCoverageDesktop from "../../components/PeopleComponents/AssistancePlanCoverageDesktop";
import AssistancePlanCoverageMobile from "../../components/PeopleComponents/AssistancePlanCoverageMobile";
import { PeopleMenuContext } from "../../context/People/PeopleMenuContext";
import { SideListModel } from "../../context/models/SideList.model";

const AssistancePlan = () => {
  const [heroSideData, setHeroSideData] = useState({
    title: "",
    titleRich: "",
    bannerImage: "",
    secondaryTitle: "",
    sideTitle1: "",
    sideContent1: "",
    sideTitle2: "",
    sideContent2: "",
    content: "",
    middleTitle: "",
  });

  const [sideList, setSideList] = useState<SideListModel[]>([]);

  const [coverageDetails, setCoverageDetails] = useState([
    {
      order: 0,
      text: "",
      dataLabel: "",
      limit: "",
    },
  ]);

  const { menuListData, subMenuListData } = useContext(PeopleMenuContext);

  const getSectionContentData = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Contenido-de-Secciones')/items?$filter=Title eq 'Plan de Asistencia Turismo Seguro'",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    setHeroSideData({
      titleRich: resultsArr[0]?.TitleRich,
      title: resultsArr[0]?.Title,
      bannerImage: resultsArr[0]?.BannerImage.Url,
      secondaryTitle: resultsArr[0]?.SecondaryTitle,
      sideTitle1: resultsArr[0]?.SideTitle1,
      sideContent1: resultsArr[0]?.SideContent1,
      sideTitle2: resultsArr[0]?.SideTitle2,
      sideContent2: resultsArr[0]?.SideContent2,
      content: resultsArr[0]?.Content,
      middleTitle: resultsArr[0]?.MiddleTitle,
    });
  };

  const getSideList = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Seguros-Servicios-SideLists')/items?$filter=Categoria eq 'Plan de Asistencia Turismo Seguro'",

        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    resultsArr.sort(
      (a: { Order0: number }, b: { Order0: number }) => a.Order0 - b.Order0
    );

    setSideList(
      resultsArr.map((item: any) => {
        return {
          title: item?.Text,
          id: item?.Order0,
        };
      })
    );
  };

  const getCoverageDetails = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Plan-de-Asistencia-Turismo-Seguro-Coverage-Details')/items",

        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    resultsArr.sort(
      (a: { Order0: number }, b: { Order0: number }) => a.Order0 - b.Order0
    );

    setCoverageDetails(
      resultsArr.map((item: any) => {
        return {
          text: item?.Text,
          order: item?.Order0,
          dataLabel: item?.DataLabel,
          limit: item?.Limit,
        };
      })
    );
  };

  useEffect(() => {
    getSectionContentData();
    getSideList();
    getCoverageDetails();
  }, []);

  return (
    <>
      <HeroMenuComponent
        bannerImage={heroSideData.bannerImage}
        title={heroSideData.titleRich}
      />
      <SecondaryNavBar
        title={heroSideData.secondaryTitle || heroSideData.titleRich}
        menuList={menuListData}
        subMenuList={subMenuListData}
      />

      <div className="container mx-auto lg:flex lg:justify-between md:mb-16 ">
        <div className="lg:w-3/5">
          <InformationComponent content={heroSideData.content} />
        </div>
        <div className="mt-10 lg:mt-14 lg:w-1/3">
          <SideBar>
            {heroSideData.sideTitle1 && (
              <SideBarList
                sideTitle={heroSideData.sideTitle1}
                sideBarList={sideList}
              />
            )}
          </SideBar>
          <div className="block vehicle-plans lg:hidden">
            <div className="">
              <div>
                <div className="flex justify-between mb-2 section-head">
                  <h5>{heroSideData.middleTitle}</h5>
                </div>
              </div>
              <hr className="hidden md:block"></hr>
              {/* mobile */}
              <div className="table-responsive">
                <div className="block plan-list">
                  <AssistancePlanCoverageMobile
                    tableTitle={heroSideData.sideTitle2}
                    tableDetails={coverageDetails}
                  />
                </div>
              </div>
              <div className="mt-10 lg:mt-10">
                <div className="hidden my-10 lg:flex space-x-7">
                  <NeedHelpCardShort />
                  <MoreInformationCard />
                </div>
              </div>
            </div>
          </div>
          <div className="my-10 lg:hidden">
            <NeedHelpCardShort />
            <MoreInformationCard />
          </div>
        </div>
      </div>
      <div className="hidden vehicle-plans lg:block">
        <div className="container mx-auto">
          <div>
            <div className="flex justify-between mb-2 section-head">
              <h5>{heroSideData.middleTitle}</h5>
            </div>
          </div>
          <hr className="hidden md:block"></hr>
          {/* desktop */}
          <div className="table-responsive">
            <div className="block plan-list">
              <AssistancePlanCoverageDesktop
                tableTitle={heroSideData.sideTitle2}
                tableDetails={coverageDetails}
              />
            </div>
          </div>
          <div className="mt-10 lg:mt-10">
            <div className="hidden my-10 lg:flex space-x-7">
              <NeedHelpCardShort />
              <MoreInformationCard />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AssistancePlan;
