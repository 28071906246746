import { useEffect, useState } from "react";
import blueArrow from "../../assets/img/icons/arrow-dark-blue.svg";
import CardsStepsCardContainer from "../MenuLandingsLayout/CardsStepsCardContainer";
import ProductsContainer from "../MenuLandingsLayout/ProductsContainer";

const ClaimsSingleSteps = ({
  stepsTitle,
  title,
  stepsSubTitle,
  formBtnText,
  formBtnLink,
  docTitle,
  docSubTitle,
  docBtnText,
  docBtnContent,
  claimsCardSteps,
  channelsTitle,
  channelsText,
}: any) => {
  const [showContent, setShowContent] = useState(false);
  const [showDocContent, setShowDocContent] = useState(false);
  const [showCards, setShowCards] = useState(false);

  const handleClick = () => {
    setShowDocContent(!showDocContent);
  };
  useEffect(() => {
    if (screen.width >= 1024) {
      setShowContent(true);
    }
  }, []);

  return (
    <div className="vehicle-plan claims-list mb-4 w-full">
      <div
        className="flex items-center justify-between plan-title lg:hidden"
        onClick={() => setShowContent(!showContent)}
      >
        <h6>{title}</h6>

        <img
          src={blueArrow}
          alt="blue Arrow"
          className={`${showContent ? "blue-arrow-rotate" : "blue-arrow"}`}
        />
      </div>
      <div className={`py-3 lg:p-0 ${showContent ? "block" : "hidden"}`}>
        {stepsTitle && <h3>{stepsTitle}</h3>}
        {stepsSubTitle && <p>{stepsSubTitle}</p>}
        {formBtnLink && (
          <a className="link-border" href={formBtnLink}>
            {formBtnText}
          </a>
        )}
        <h3>{docTitle}</h3>
        <p>{docSubTitle}</p>
        <div className="btn-toogle" onClick={handleClick}>
          {docBtnText}{" "}
          <img
            src={blueArrow}
            alt="chevron icon"
            className={`${showDocContent ? "blue-arrow-rotate" : "blue-arrow"}`}
          />
        </div>
        {showDocContent && (
          <div
            className="required-documents"
            dangerouslySetInnerHTML={{ __html: docBtnContent }}
          ></div>
        )}

        <div className="mt-6 steps-slide-fix">
          <section>
            <h2 dangerouslySetInnerHTML={{ __html: channelsTitle }}></h2>
            <p className="mt-5">{channelsText}</p>
          </section>
          <ProductsContainer showCards={showCards} setShowCards={setShowCards}>
            <CardsStepsCardContainer
              showCards={showCards}
              cards={claimsCardSteps.filter(
                (cData: any) => cData.producto === title
              )}
            />
          </ProductsContainer>
        </div>
      </div>
    </div>
  );
};

export default ClaimsSingleSteps;
