import { CardsInterface2 } from "../../context/models/Insurace.model";

const CardsComponentImageOnly = ({ cardImg, isSlide }: CardsInterface2) => {
  return (
    <div className={isSlide ? "card-box-slide " : "cards-image-only "}>
      <div>
        <img src={cardImg} className="card-image-only" />
      </div>
    </div>
  );
};

export default CardsComponentImageOnly;
