import { useEffect, useState } from "react";
import "./css/optionsComponent.css";

export type OptionsComponentProp = {
  id: number;
  title: string;
  content?: string;
};

export type OptionsComponentProps = {
  contentList: OptionsComponentProp[];
  title?: string;
};

const OptionsComponent = ({ contentList, title }: OptionsComponentProps) => {
  const [Active, setActive] = useState<number>();
  useEffect(() => {
    setActive(1);
  }, []);
  const handleSelected = (id: number) => {
    setActive(id);
  };

  return (
    <div>
      {title && <h3 className="options-title"> {title}</h3>}
      <div className="tabs-controls-container">
        {contentList.map((list: OptionsComponentProp) => (
          <div
            key={list.id}
            className={`tab-control ${
              Active === list.id && "tab-control-active"
            }`}
            onClick={() => handleSelected(list.id)}
          >
            {list.title}
          </div>
        ))}
      </div>
      <div className="content-container">
        {contentList.map(
          (list: OptionsComponentProp) =>
            list.id === Active && (
              <div
                key={list.id}
                dangerouslySetInnerHTML={{ __html: list.content }}
              ></div>
            )
        )}
      </div>
    </div>
  );
};

export default OptionsComponent;
