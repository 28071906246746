import ServiciosCardEnlace from "./ServiciosCardEnlace";
interface Data {
  title: string;
  text: string;
  image: string;
  enlace: string;
  enlaceText: string;
  enlaceColor: string;
  orden: number;
  size: string;
}

function SmallCardServices(props: Data) {
  const getFilterColor = () => {
    if (props.enlaceColor === "Orange") {
      return "rgb(12, 69, 126)";
    } else if (props.enlaceColor === "Dark") {
      return "rgb(0, 174, 240)";
    } else {
      return "rgb(248, 148, 32)";
    }
  };
  return (
    <>
      {props.orden % 3 === 0 ? (
        <div className="inner">
          <div
            className="slide__img-filter"
            style={{
              backgroundColor: `${getFilterColor()}`,
            }}
          />
          <img
            src={props.image}
            alt="imagen-servicio-complementario"
            className="servicios-slide__img"
          />
          <div className="servicios__block servicios__block--small servicios__block--bottom">
            <div className="servicios__info">
              <div className="servicios__title">{props.title}</div>
              <p className="servicios__text">{props.text}</p>
              <ServiciosCardEnlace
                enlace={props.enlace}
                enlaceText={props.enlaceText}
                enlaceColor={props.enlaceColor}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="inner left-div">
          <div
            className="slide__img-filter"
            style={{
              backgroundColor: `${getFilterColor()}`,
            }}
          />
          <img
            src={props.image}
            alt="imagen-servicio-complementario"
            className="servicios-slide__img"
          />
          <div className="servicios__block servicios__block--small">
            <div className="servicios__info">
              <div className="servicios__title">{props.title}</div>
              <p className="servicios__text">{props.text}</p>
              <ServiciosCardEnlace
                enlace={props.enlace}
                enlaceText={props.enlaceText}
                enlaceColor={props.enlaceColor}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default SmallCardServices;
