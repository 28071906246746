import { useContext, useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { NavigationContext } from "../../routes/menulinks";
import { addresModalType } from "./type";
import arrow from "../../assets/img/icons/chevron-right.svg";
import phone from "../../assets/img/icons/small-phone-blue.png";

const TopRibbon = ({
  showAddressModal,
  setShowAddressModal,
}: addresModalType) => {
  const [ribbonDate, setRibbonDate] = useState<Date | string>("");
  const { menuLinks } = useContext(NavigationContext);
  const date = new Date();

  useEffect(() => {
    setRibbonDate(
      date.toLocaleDateString("es-ES", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      })
    );
  }, [date]);

  const handleClick = () => {
    setShowAddressModal(!showAddressModal);
  };

  return (
    <div className="top-ribbon">
      <div className="container mx-auto">
        <div className="flex justify-between">
          <div className="top-ribbon__contact lg:hidden">
            <img src={phone} alt="phone-icon" />
            <span>Contáctanos</span>
          </div>
          <div
            onClick={handleClick}
            className="top-ribbon__dropdown lg:hidden"
            id="addressActivator"
          >
            <img src={arrow} alt="arrow icon" className="arrow" />
          </div>
          <span className="hidden date lg:block">{ribbonDate}</span>
          <div className="hidden top-ribbon__links lg:flex">
            <Link to={menuLinks.servicesLanding}>Canales de Servicios</Link>
            <Link to={menuLinks.contact}>Contáctanos</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopRibbon;
