import { BASE_URL, GET_HEADERS } from "../../components/Helper/requestData";
import React, { useEffect, useState } from "react";

import { NewsStructure } from "../../components/NewsComponents/news.model";
import axios from "axios";

export const NewsContext = React.createContext({
  newsList: [],
});

type NewsContextProps = {
  children?: React.ReactNode;
};

function NewsData({ children }: NewsContextProps) {
  const [newsList, setNewsList] = useState<NewsStructure[]>();

  const getNewsList = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Noticias-y-Novedades-Detalles')/items",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));
    resultsArr.sort(
      (a: { Order0: number }, b: { Order0: number }) => a.Order0 - b.Order0
    );
    setNewsList(
      resultsArr.map((item: any) => {
        return {
          title: item?.Title,
          id: item?.Order0,
          img: item?.Image?.Url,
          date: item?.Date,
          content: item?.Content,
          linkText: item?.LinkText,
          topNews: item?.TopNews,
          text: item?.Text,
        };
      })
    );
  };

  useEffect(() => {
    getNewsList();
  }, []);

  return (
    <NewsContext.Provider value={{ newsList }}>{children}</NewsContext.Provider>
  );
}

export default NewsData;
