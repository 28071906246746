/* eslint-disable no-console */
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { useEffect, useState } from "react";
import menuX from "../../../assets/img/icons/side-menu-x.svg";
import "./css/comparatorComponent.css";
import Acordeon from "./Acordeon";
import ListBoxComponentComparator from "./ListBoxComponentComparator";

export default function ComparatorMobileComponent({
  showFullScreenContent,
  setShowFullScreenContent,
  planList,
  title,
}: any) {
  const handleClick = () => {
    setShowFullScreenContent(!showFullScreenContent);
    handleClear();
  };

  const [plans, setPlans] = useState<any[]>([]);
  const [planListBox, setPlanListBox] = useState<any[]>();
  const [Active, setActive] = useState<number>();
  const [selectedPlanA, setSelectedPlanA] = useState<any>();
  const [selectedPlanB, setSelectedPlanB] = useState<any>();

  useEffect(() => {
    const list = [...planList];
    const newList = list.map((p: any) => {
      return {
        ...p,
        title: "Plan " + p.title,
      };
    });
    newList.unshift({
      id: 0,
      title: "Seleccionar",
    });
    setPlanListBox(newList);
  }, [planList]);

  useEffect(() => {
    if (planListBox !== undefined) {
      setSelectedPlanA(planListBox[0]);
      setSelectedPlanB(planListBox[0]);
    }
  }, [planListBox]);
  useEffect(() => {
    const filter = [];

    if (selectedPlanA && selectedPlanB) {
      if (
        selectedPlanA?.title !== selectedPlanB.title &&
        selectedPlanA?.title !== "Seleccionar" &&
        selectedPlanB?.title !== "Seleccionar"
      ) {
        filter.push(
          planListBox?.filter((p: any) =>
            p.title.includes(selectedPlanA.title)
          )[0]
        );
        filter.push(
          planListBox?.filter((p: any) =>
            p.title.includes(selectedPlanB.title)
          )[0]
        );
        setPlans(filter);
      } else {
        setPlans([]);
      }
    }
  }, [selectedPlanA, selectedPlanB]);

  const handleActive = (active: number) => {
    setActive(active);
  };

  const handleClear = () => {
    setPlans([]);
    setActive(undefined);
  };

  return (
    <div
      className={`transition ease-out transform ${
        showFullScreenContent ? "" : "-translate-x-full "
      }  duration-400 comparator-container`}
    >
      <div className="fullScreen-top ">
        <div onClick={handleClick}>
          <img src={menuX} alt="close" className="w-15" />
        </div>
      </div>
      <div className="title-container m-5">
        <h1>Comparador de planes</h1>
        <div dangerouslySetInnerHTML={{ __html: title }}></div>
      </div>
      <div className="selector-div">
        <h5 className="text-center">Selecciona los planes a comparar</h5>
        <div className="selects-container">
          <ListBoxComponentComparator
            planList={planListBox}
            selectedPlan={selectedPlanA}
            setSelectedPlan={setSelectedPlanA}
          />
          <div className="mt-3">
            <ListBoxComponentComparator
              planList={planListBox}
              selectedPlan={selectedPlanB}
              setSelectedPlan={setSelectedPlanB}
            />
          </div>
        </div>
      </div>
      <div className="selector-btn-options mx-auto">
        <Splide
          options={{
            arrows: false,
          }}
          onActive={(active) => {
            handleActive(active.index);
          }}
        >
          {plans.length >= 2 &&
            plans.map((p: any, i: number) => (
              <SplideSlide key={i}>
                <div className={`selector-btn selector-btn-active`}>
                  {p?.title}
                </div>
              </SplideSlide>
            ))}
        </Splide>
      </div>

      {plans.length >= 2 && Active !== undefined ? (
        <Acordeon plans={plans} Active={Active} />
      ) : null}
    </div>
  );
}
