import axios from "axios";
import React, { useEffect, useState } from "react";
import { BASE_URL, GET_HEADERS } from "../../components/Helper/requestData";

export const PeopleMenuContext = React.createContext({
  menuListData: [],
  subMenuListData: [],
});

type PeopleMenuProps = {
  children?: React.ReactNode;
};

function PeopleMenu({ children }: PeopleMenuProps) {
  const [menuListData, setMenuListData] = useState([
    {
      id: 0,
      title: "",
      link: "",
      externalLink: "",
      hasSubMenu: false,
    },
  ]);

  const [subMenuListData, setSubMenuListData] = useState([
    {
      id: 0,
      title: "",
      link: "",
      externalLink: "",
      parentMenu: "",
    },
  ]);

  const getMenuListData = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL + "_api/web/lists/getbytitle('Personas-Menu-List')/items",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    const results = resultsArr.sort((a, b) => a.ItemId - b.ItemId);

    setMenuListData(
      results.map((item: any) => {
        return {
          id: item?.ItemId,
          title: item?.Title,
          link: item?.Link?.Description,
          externalLink: item?.ExternalLink?.Url,
          hasSubMenu: item?.HasSubMenu,
        };
      })
    );
  };

  const getSubMenuListData = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL + "_api/web/lists/getbytitle('Personas-SubMenu-List')/items",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    const results = resultsArr.sort((a, b) => a.ItemId - b.ItemId);

    setSubMenuListData(
      results.map((item: any) => {
        return {
          id: item?.ItemId,
          title: item?.Title,
          link: item?.Link?.Description,
          externalLink: item?.ExternalLink?.Url,
          parentMenu: item?.ParentMenu,
        };
      })
    );
  };

  useEffect(() => {
    getMenuListData();
    getSubMenuListData();
  }, []);

  return (
    <PeopleMenuContext.Provider value={{ menuListData, subMenuListData }}>
      {children}
    </PeopleMenuContext.Provider>
  );
}

export default PeopleMenu;
