import { Information } from "../../pages/Vehicles/mock/vehiclesSectionInformation.mock";

function VehiclesSectionInformation({ content }: Information) {
  return (
    <div className="motor-vehicles">
      <div className="">
        <div
          className="text-information"
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>
      </div>
    </div>
  );
}

export default VehiclesSectionInformation;
