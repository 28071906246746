import { BASE_URL, GET_HEADERS } from "../../components/Helper/requestData";
import { useEffect, useState } from "react";

import CardsContainer from "../../components/MenuLandingsLayout/CardsContainer";
import HeroMenuComponent from "../../components/MenuLandingsLayout/HeroMenuComponent";
import ProductsContainer from "../../components/MenuLandingsLayout/ProductsContainer";
import SectionInformation from "../../components/MenuLandingsLayout/SectionInformation";
import axios from "axios";

const People = () => {
  const [showCards, setShowCards] = useState(false);
  const [peopleData, setPeopleData] = useState({
    title: "",
    bannerImage: "",
    content: "",
    contentImage: "",
  });

  const [cardData, setCardData] = useState([
    {
      cardId: 0,
      cardImg: "",
      cardTitle: "",
      cardImgHover: "",
      content: "",
      btnLinkCotizar: "",
      btnImgCotizar: "",
      btnTitleCotizar: "",
      btnColorCotizar: "",
      btnLinkBrochure: "",
      btnImgBrochure: "",
      btnTitleBrochure: "",
      btnColorBrochure: "",
      btnLinkPreguntas: "",
      btnImgPreguntas: "",
      btnTitlePreguntas: "",
      btnColorPreguntas: "",
      btnLinkFormulario: "",
      btnImgFormulario: "",
      btnTitleFormulario: "",
      btnColorFormulario: "",
      cardListItems: "",
      pageLink: "",
    },
  ]);

  const getPeopleData = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Contenido-de-Secciones')/items?$filter=Title eq 'Seguros de Personas'",
        GET_HEADERS
      )
      .then((response) => response.data.value[0])
      .catch((e) => console.error(e));

    setPeopleData({
      title: resultsArr?.TitleRich,
      bannerImage: resultsArr?.BannerImage.Url,
      content: resultsArr?.Content,
      contentImage: resultsArr?.ContentImage.Url,
    });
  };

  const getCardData = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Seguros-Personas-Productos')/items",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    setCardData(
      resultsArr
        .map((item: any) => {
          return {
            cardId: item?.CardId,
            cardImg: item?.CardImg?.Url,
            cardTitle: item?.CardTitle,
            cardImgHover: item?.CardImgHover?.Url,
            content: item?.Content,

            btnLinkCotizar: item?.BtnLinkCotizar?.Url,
            btnImgCotizar: item?.BtnImgCotizar?.Url,
            btnTitleCotizar: item?.BtnTitleCotizar,
            btnColorCotizar: item?.BtnColorCotizar,

            btnLinkBrochure: item?.BtnLinkBrochure?.Url,
            btnImgBrochure: item?.BtnImgBrochure?.Url,
            btnTitleBrochure: item?.BtnTitleBrochure,
            btnColorBrochure: item?.BtnColorBrochure,

            btnLinkPreguntas: item?.BtnLinkPreguntas?.Url,
            btnImgPreguntas: item?.BtnImgPreguntas?.Url,
            btnTitlePreguntas: item?.BtnTitlePreguntas,
            btnColorPreguntas: item?.BtnColorPreguntas,

            btnLinkFormulario: item?.BtnLinkVideo?.Url,
            btnImgFormulario: item?.BtnIconVideo?.Url,
            btnTitleFormulario: item?.BtnTitleVideo,
            btnColorFormulario: item?.BtnColorVideo,
            cardListItems: item?.CardListItems,
            pageLink: item?.PageLink?.Description,
          };
        })
        .sort(
          (a: { cardId: number }, b: { cardId: number }) => a.cardId - b.cardId
        )
    );
  };

  useEffect(() => {
    getPeopleData();
    getCardData();
  }, []);

  return (
    <>
      <HeroMenuComponent
        title={peopleData?.title}
        bannerImage={peopleData?.bannerImage}
      />
      <SectionInformation
        content={peopleData?.content}
        contentImage={peopleData?.contentImage}
      />
      <ProductsContainer showCards={showCards} setShowCards={setShowCards}>
        <CardsContainer showCards={showCards} cards={cardData} />
      </ProductsContainer>
    </>
  );
};

export default People;
