import "./css/NewsDetails.css";

import { BASE_URL, GET_HEADERS } from "../../components/Helper/requestData";
import { Link, useSearchParams } from "react-router-dom";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { useContext, useEffect, useState } from "react";

import HeroMenuComponent from "../../components/MenuLandingsLayout/HeroMenuComponent";
import { NavigationContext } from "../../routes/menulinks";
import NewsCardComponent from "../../components/NewsComponents/NewsCardComponent";
import { NewsContext } from "../../context/News/NewsContext";
import { NewsStructure } from "../../components/NewsComponents/news.model";
import ProductsContainer from "../../components/MenuLandingsLayout/ProductsContainer";
import { SelectedViewEnum } from "../../components/NewsComponents/news.enum";
import arrow from "../../assets/img/icons/chevron-dark-gray.svg";
import axios from "axios";
import orangeArrow from "../../assets/img/icons/chevron-right-orange-2.svg";

const NewsDetails = () => {
  const { newsList } = useContext(NewsContext);
  const { menuLinks } = useContext(NavigationContext);
  const [searchParams] = useSearchParams();
  const [newsData, setNewsData] = useState<NewsStructure>();
  const [relatedArticles, setRelatedArticles] = useState<NewsStructure[]>();
  const [showCards, setShowCards] = useState(false);
  const [heroSideData, setHeroSideData] = useState({
    title: "",
    titleRich: "",
    bannerImage: "",
    secondaryTitle: "",
    sideTitle1: "",
    sideContent1: "",
    sideTitle2: "",
    content: "",
    contentImage: "",
    sideContent2: "",
    middleTitle: "",
    middleContent: "",
  });

  const getSectionContentData = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Contenido-de-Secciones')/items?$filter=Title eq 'Noticias y Novedades Detalles'",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    setHeroSideData({
      titleRich: resultsArr[0]?.TitleRich,
      title: resultsArr[0]?.Title,
      bannerImage: resultsArr[0]?.BannerImage?.Url,
      content: resultsArr[0]?.Content,
      secondaryTitle: resultsArr[0]?.SecondaryTitle,
      sideTitle1: resultsArr[0]?.SideTitle1,
      sideTitle2: resultsArr[0]?.SideTitle2,
      sideContent1: resultsArr[0]?.SideContent1,
      contentImage: resultsArr[0]?.ContentImage?.Url,
      sideContent2: resultsArr[0]?.SideContent2,
      middleTitle: resultsArr[0]?.MiddleTitle,
      middleContent: resultsArr[0]?.MiddleContent,
    });
  };

  useEffect(() => {
    const currentID = Number(searchParams.get("ID"));
    const data =
      newsList && newsList.find((news: NewsStructure) => news.id === currentID);
    const related =
      newsList &&
      newsList.filter((news: NewsStructure) => news.id !== currentID);

    setRelatedArticles(related);
    setNewsData(data);
  }, [searchParams, newsList]);

  useEffect(() => {
    getSectionContentData();
  }, []);
  return (
    <>
      <HeroMenuComponent
        bannerImage={heroSideData.bannerImage}
        title={heroSideData.titleRich}
      />
      <div className="container mx-auto news-details my-10 lg:pb-28">
        <div className="header-container flex">
          <img
            src={orangeArrow}
            alt="arrow icon"
            className="orange-arrow mr-2 xl:mr-5"
          />
          <Link className="back-btn" to={menuLinks.news}>
            {heroSideData.sideTitle1}
          </Link>
        </div>

        <div className="news-content mt-10">
          <div className="date-title lg:flex flex-col-reverse">
            <h5 className="noticias__title ">{newsData?.title}</h5>
            <span className="noticias__date">
              {new Date(newsData?.date).toLocaleDateString("es-ES", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })}
            </span>
          </div>
        </div>

        <div>
          <img
            className="noticias__image sm:w-full my-5 lg:hidden"
            src={newsData?.img}
            alt={newsData?.title}
          />

          <div className="news-content mt-8">
            <div
              dangerouslySetInnerHTML={{
                __html: newsData?.content,
              }}
            ></div>
          </div>
        </div>
      </div>
      <ProductsContainer
        showCards={showCards}
        setShowCards={setShowCards}
        title={heroSideData.sideTitle2}
        disableButton={true}
      >
        <div className="section noticias news-slider-container">
          <div className="container mx-auto">
            <Splide
              options={{
                perPage: 3,
                breakpoints: {
                  1024: {
                    perPage: 3,
                  },
                  1022: {
                    perPage: 2,
                  },
                  765: {
                    perPage: 1,
                  },
                },
              }}
              className="splide"
              renderControls={() => (
                <div className="splide__arrows">
                  <div className="container relative mx-auto xl:absolute container--fix">
                    <div className={`splide__arrow splide__arrow--next `}>
                      <img src={arrow} alt="arrow icon" />
                    </div>
                    <div className={`splide__arrow splide__arrow--prev `}>
                      <img
                        src={arrow}
                        alt="arrow icon"
                        className="left-arrow "
                      />
                    </div>
                  </div>
                </div>
              )}
            >
              {relatedArticles?.map((news) => (
                <SplideSlide key={news?.id}>
                  <div className="news-slider">
                    <NewsCardComponent
                      news={news}
                      selectedView={SelectedViewEnum?.groupView}
                    />
                  </div>
                </SplideSlide>
              ))}
            </Splide>
          </div>
        </div>
      </ProductsContainer>
    </>
  );
};

export default NewsDetails;
