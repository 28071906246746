import { useContext, useEffect, useState } from "react";
import HeroMenuComponent from "../../components/MenuLandingsLayout/HeroMenuComponent";
import SecondaryNavBar from "../../components/secondaryNavBar/SecondaryNavBar";
import InformationComponent from "../../components/InformationComponent/InformationComponent";
import "./css/services.css";
import OfficesMapsList from "../../components/OfficesMapsList/OfficesMapsList";
import MoreInformationCard from "../../components/VariousCardsComponents/moreInfoCard/MoreInformationCard";
import axios from "axios";
import { BASE_URL, GET_HEADERS } from "../../components/Helper/requestData";
import { ServicesChannelsMenuContext } from "../../context/ServicesChannels/ServicesChannelsMenuContext";

const Offices = () => {
  const [heroSideData, setHeroSideData] = useState({
    title: "",
    titleRich: "",
    bannerImage: "",
    secondaryTitle: "",
    sideTitle1: "",
    sideContent1: "",
    sideTitle2: "",
    sideContent2: "",
    content: "",
  });

  const { menuListData } = useContext(ServicesChannelsMenuContext);

  const getSectionContentData = async () => {
    const resultsArr = await axios
      .get(
        BASE_URL +
          "_api/web/lists/getbytitle('Contenido-de-Secciones')/items?$filter=Title eq 'Oficinas y Sucursales'",
        GET_HEADERS
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));

    setHeroSideData({
      titleRich: resultsArr[0]?.TitleRich,
      title: resultsArr[0]?.Title,
      bannerImage: resultsArr[0]?.BannerImage.Url,
      secondaryTitle: resultsArr[0]?.SecondaryTitle,
      sideTitle1: resultsArr[0]?.SideTitle1,
      sideContent1: resultsArr[0]?.SideContent1,
      sideTitle2: resultsArr[0]?.SideTitle2,
      sideContent2: resultsArr[0]?.SideContent2,
      content: resultsArr[0]?.Content,
    });
  };

  useEffect(() => {
    getSectionContentData();
  }, []);
  return (
    <>
      <HeroMenuComponent
        bannerImage={heroSideData.bannerImage}
        title={heroSideData.titleRich}
      />
      <SecondaryNavBar
        title={heroSideData.secondaryTitle || heroSideData.titleRich}
        menuList={menuListData}
        subMenuList={[]}
      />
      <div className="container mx-auto lg:flex lg:justify-between ">
        <div className="lg:w-4/5">
          <InformationComponent content={heroSideData.content} />
        </div>
      </div>
      <div className="container mx-auto lg:flex lg:justify-between lg:mb-16">
        <div className="w-full">
          <OfficesMapsList />
        </div>
      </div>
      <div className="container mx-auto lg:hidden lg:space-x-4">
        <div className="hidden w-full lg:w-4/12 lg:block"></div>
        <div className="w-full lg:w-8/12">
          <MoreInformationCard />
        </div>
      </div>
    </>
  );
};

export default Offices;
