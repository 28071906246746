const ImmigrationSupportTableDesktop = ({ list }: any) => {
  return (
    <>
      <table className="immigration-table">
        <tbody>
          <tr className="title-container ">
            <td>{list[0]?.mainTitle}</td>
            <td>{list[1]?.labelOne}</td>
            <td>{list[1]?.labelTwo}</td>
          </tr>
          {list.map((e: any) =>
            e?.mainTitle ? null : (
              <tr className="table-data " key={e?.id}>
                <td className="font-semibold">{e?.title}</td>
                <td className="">{e?.valueOne}</td>
                <td>{e?.valueTwo}</td>
              </tr>
            )
          )}
        </tbody>
      </table>
    </>
  );
};

export default ImmigrationSupportTableDesktop;
