import "./css/main.css";
import "./css/splide.min.css";

import Navigation from "./routes/menulinks";
import Router from "./routes/Router";

function App() {
  return (
    <div>
      <Navigation>
        <Router />
      </Navigation>
    </div>
  );
}

export default App;
