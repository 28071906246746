import orangeArrowBold from "../../assets/img/icons/chevron-orange-bold.svg";

const SideBarList = ({ sideBarList, sideTitle, sideContent }: any) => {
  return (
    <div className="side-bar-list">
      <div className="mb-4 text-content">
        <div className="list-title">{sideTitle}</div>
        <div dangerouslySetInnerHTML={{ __html: sideContent }}></div>
      </div>
      <ul>
        {sideBarList.map((list: any) => (
          <li className="mb-2 side-bar-list-item" key={list.id}>
            <img src={orangeArrowBold} />
            <span>{list.title}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SideBarList;
